import React from 'react';
import { Button, Image } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import cx from 'classnames';
import addFileIcon from '../assets/icons/add-file.svg';

const AssetsButton = ({ children, className, onClick = () => {}, disable }) => {
  const [id, setId] = React.useState();

  React.useEffect(() => {
    setId(uuid());
  }, []);

  return (
    <Button
      disabled={disable}
      htmlFor={id}
      className={cx(
        className,
        'btn btn-outline-secondary btn-back-white d-inline-flex align-items-center',
      )}
      onClick={onClick}
    >
      {children}
      <Image src={addFileIcon} alt="" className="ml-2" height="16" />
    </Button>
  );
};

export default AssetsButton;
