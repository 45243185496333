import React from 'react';
import classnames from 'classnames';
import styles from '../../styles/modules/ServiceBoards.module.scss';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.076"
      height="11.022"
      viewBox="0 0 11.076 11.022"
    >
      <path
        // fill="#969696"
        fill="white"
        className={classnames(styles.pen_icon)}
        d="M6.888 3.128l2.253 2.253-5.7 5.7-2.255-2.249zm3.963-.543l-1-1a1 1 0 00-1.409 0l-.963.963L9.728 4.8l1.123-1.123a.768.768 0 000-1.092zM.007 12a.256.256 0 00.31.305l2.511-.609L.576 9.442z"
        data-name="Path 3158"
        transform="translate(-.001 -1.289)"
      />
    </svg>
  );
}

export default Icon;
