import React from 'react';
import Card from 'react-bootstrap/Card';
import { Route, Switch, useRouteMatch } from 'react-router';
import classnames from 'classnames';
import styles from '../../styles/modules/AddAssets.module.scss';
import AssetsList from './AssetsList';
import AssetDetails from './AssetDetails';
import AssetMedia from './AssetMedia';
import AssetContext from '../../contexts/AssetContext';

function MyAssets() {
  const { path } = useRouteMatch();

  const [assets, setAssets] = React.useState([]);

  const push = value => setAssets([...assets, value]);

  const pop = () => {
    const newValue = [...assets];
    newValue.pop();

    setAssets(newValue);
  };

  const goto = index => setAssets(assets.slice(0, index + 1));

  const reset = () => setAssets([]);

  const replace = (index, value) => {
    const newValue = [...assets];
    newValue[index] = value;

    setAssets(newValue);
  };

  return (
    <AssetContext.Provider value={{ path: assets, push, pop, goto, reset, replace }}>
      <Card className={classnames(styles.main_card_height, 'text-font mt-4')}>
        <Switch>
          <Route exact path={`${path}`}>
            <AssetsList />
          </Route>
          <Route exact path={`${path}/details`}>
            <AssetDetails />
          </Route>
          <Route exact path={`${path}/media/:mediaId`}>
            <AssetMedia />
          </Route>
        </Switch>
      </Card>
    </AssetContext.Provider>
  );
}

export default MyAssets;
