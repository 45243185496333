import React from 'react';
import { Card } from 'react-bootstrap';
import classNames from 'classnames';
import folderIcon from '../../assets/icons/folder.svg';
import styles from '../../styles/modules/AddAssets.module.scss';

const AssetImage = ({ name, src }) => {
  return (
    <Card className={classNames('p-3 shadow-sm')}>
      <img src={src ?? folderIcon} alt="artbox" className="d-block mb-3" />
      <Card.Title className={classNames('mb-0 text-center text-size-xs', styles.asset_name)}>
        {name}
      </Card.Title>
    </Card>
  );
};

export default AssetImage;
