import React from 'react';
import { Card } from 'react-bootstrap';
import AddCardButton from './AddCardButton';
import PaymentCard from './PaymentCard';

const PaymentMethod = () => {
  return (
    <Card>
      <Card.Header>
        <Card.Title className="font-weight-600">Payment Method</Card.Title>
        <Card.Subtitle>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</Card.Subtitle>
      </Card.Header>
      <Card.Body>
        <div className="px-0 px-lg-5">
          <div className="d-flex flex-wrap flex-column flex-md-row justify-content-center">
            <PaymentCard isDefault />
            <PaymentCard />
            <PaymentCard />
            <AddCardButton />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default PaymentMethod;
