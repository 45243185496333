import React from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { useCreateBoard } from '../../hooks/boards.hooks';
import UserContext from '../../contexts/UserContext';
import { useCreateTeam } from '../../hooks/teams.hooks';

const schema = yup.object({
  title: yup.string().required('Required').min(6, 'Must be minimum of 6 characters'),
});

const CreateBoard = ({ show, onClose }) => {
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const { user } = React.useContext(UserContext);

  const { mutateAsync: createBoard, isLoading } = useCreateBoard();
  const { mutateAsync: createTeam, isLoading: isTeamLoading } = useCreateTeam();

  const onSubmit = async data => {
    try {
      const res = await createBoard({ userId: user?.id, data });
      if (res.status) {
        await createTeam({
          name: data.title,
          owner: user?.id,
          project_id: res.data.id,
        });
        queryClient.invalidateQueries(['boards', user?.id]);
        reset();
        onClose();
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Create Board</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)} className="mb-3">
          <Form.Group controlId="title">
            <Form.Control
              {...register('title')}
              type="text"
              placeholder="Add Board Title"
              isInvalid={errors?.title}
            />
            <Form.Control.Feedback type="invalid">{errors?.title?.message}</Form.Control.Feedback>
          </Form.Group>
          <Button variant="primary" type="submit" disabled={isLoading || isTeamLoading}>
            {isLoading || isTeamLoading ? (
              <Spinner size="sm" variant="light" animation="border" />
            ) : (
              'Create Board'
            )}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateBoard;
