import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import DueDatePicker from './DueDatePicker';

const InProgressButton = ({ isLoading, styles, onSave, className, id }) => {
  const [clickOnButton, setClickOnButton] = useState(false);
  const [position, setPosition] = useState({});
  const ref = useRef();

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !document.getElementsByClassName('ant-picker-dropdown')[0]?.contains(event.target) &&
        !document.getElementsByClassName('ant-picker-dropdown')[1]?.contains(event.target)
      ) {
        setClickOnButton(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return (
    <div key={id} ref={ref}>
      <Button
        className={`text-size-xs py-0 py-1 ml-lg-1 ml-xl-3 font-weight-600 ${className} ${styles.btn_structure}`}
        disabled={isLoading}
        onClick={e => {
          const percent = (100 / window.innerHeight) * e.clientY;
          if (percent > 70) {
            setPosition({
              bottom: '100%',
            });
          } else {
            setPosition({
              top: '100%',
            });
          }

          setClickOnButton(!clickOnButton);
        }}
      >
        Move to In Progress
      </Button>
      {clickOnButton && (
        <DueDatePicker
          style={{
            ...position,
          }}
          className="ven-due-date-popup details"
          styles={styles}
          saveDueDate={(date, time) => {
            if (date && time) {
              onSave(
                moment(
                  `${date.format('DD-MM-YYYY')} ${time.format('HH:mm:ss')}`,
                  'DD-MM-YYYY HH:mm:ss',
                ),
              );
              setClickOnButton(false);
            }
          }}
        />
      )}
    </div>
  );
};

export default InProgressButton;
