import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { useCreateAsset } from '../../hooks/assets.hooks';

const schema = yup.object({
  title: yup
    .string()
    .required('Required')
    .matches(/^[a-zA-Z0-9&.,%$#@'";]+(\s{0,1}[a-zA-Z0-9&.,%$#@'";])*$/, 'Must be valid'),
});

function CreateAsset({ show, onClose, isSubFolder, assetParentId, onAdd }) {
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const { mutateAsync: createAsset, isLoading } = useCreateAsset();

  const onSubmit = async data => {
    try {
      const payload = { folder_name: data.title };
      if (isSubFolder) {
        payload.parent_id = assetParentId;
      }

      const res = await createAsset(payload);
      if (isSubFolder) {
        queryClient.invalidateQueries(['sub-asset', assetParentId]);
      } else {
        queryClient.invalidateQueries('assets');
      }

      onClose();
      reset();
      onAdd(res.data.id);
    } catch (err) {
      toast.error(err?.message);
    }
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          Create
          {isSubFolder ? ' Sub ' : ' '}
          Asset
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group controlId="title">
            <Form.Label>Asset Folder Name</Form.Label>
            <Form.Control {...register('title')} type="text" isInvalid={errors?.title} />
            <Form.Control.Feedback type="invalid">{errors?.title?.message}</Form.Control.Feedback>
          </Form.Group>
          <Button type="submit" block disabled={isLoading}>
            {isLoading ? <Spinner size="sm" variant="light" animation="border" /> : 'Create'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

CreateAsset.defaultProps = {
  show: false,
  onClose: () => {},
  onAdd: () => {},
  isSubFolder: false,
  assetParentId: null,
};

export default CreateAsset;
