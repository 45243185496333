import React, { useEffect, useState } from 'react';
import { Button, Form, Image } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import classnames from 'classnames';
import InputGroup from 'react-bootstrap/InputGroup';
import { toast } from 'react-toastify';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import icon from '../../assets/icons/dimension.svg';
import styles from '../../styles/modules/NewRequest.module.scss';
import descriptionIcon from '../../assets/icons/description-icon.svg';
import fileIcon from '../../assets/icons/fileformat.svg';
import FileButton from '../../components/FileButton';
import crossIcon from '../../assets/icons/red-cross-icon.svg';
import RequestContext from '../../contexts/RequestContext';
import AssetsButton from '../../components/AssetsButton';
import AssetsViewer from './RequestionDescription/AssetsViewer';
import MediaImage from '../../components/MediaImage';
import AssetImage from '../../components/AssetImage';

const RequestSummary = ({ publishTask }) => {
  const { request, updateRequest } = React.useContext(RequestContext);
  const [inputFieldList, setInputFieldList] = useState([]);
  const [customSize, setCustomSize] = useState({
    width: '',
    height: '',
    resolution: '',
  });
  const [typeOfImages, setTypeOfImages] = useState(false);
  const [fileFormat, setFileFormat] = useState([]);
  const [sourceFile, setSourceFile] = useState('');
  const [show, setShow] = useState(false);
  const [isImage, setIsImage] = useState(false);

  const addTextField = e => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      if (e.target.value.trim() !== '') {
        const id = inputFieldList.length;
        const list = [...inputFieldList];
        list.push({
          id: id + 1,
        });
        setInputFieldList(list);
        setTimeout(() => {
          document?.getElementById(`${id + 1}`).focus();
        }, 100);
      }
    }
  };

  const updateRequestData = (key, value) => {
    const tempRequestData = { ...request.data };
    tempRequestData[key] = value;
    updateRequest({ ...request, data: tempRequestData, form: 'summery' });
  };

  const deleteTextField = id => {
    const list = [];
    if (inputFieldList.length === 1) {
      list.push({
        ...inputFieldList[0],
        id: 0,
        text: '',
      });
      setInputFieldList(list);
      updateRequestData(
        'steps',
        list.filter(data => data.text !== ''),
      );
      return;
    }

    inputFieldList.map(inputField => {
      if (inputField.id !== id) {
        list.push(inputField);
      }

      return inputField;
    });
    setInputFieldList(list);
    updateRequestData(
      'steps',
      list.filter(data => data.text !== ''),
    );
  };

  const onChange = (id, value) => {
    const inputData = [...inputFieldList];
    inputFieldList.forEach((inputField, index) => {
      if (inputField.id === id) {
        inputData[index].text = value;
      }
    });
    setInputFieldList(inputData);
    updateRequestData(
      'steps',
      inputData.filter(data => data.text !== ''),
    );
  };

  useEffect(() => {
    setIsImage(request.files.length > 0 || request.assets.length > 0);
    setFileFormat(
      request.data.file_formats
        ? request.data.file_formats.slice(undefined, request.data.file_formats.length - 1)
        : [],
    );
    setSourceFile(
      request.data.file_formats
        ? request.data.file_formats[request.data.file_formats.length - 1]
        : '',
    );
    setTypeOfImages(request.data.type_of_images && request.data.type_of_images !== '');
    setInputFieldList(
      request.data.steps && request.data.steps.length !== 0
        ? request.data.steps
        : [{ id: 0, text: '' }],
    );
    setCustomSize({
      resolution: request.data.dimension?.includes('Custom_Size')
        ? request.data.dimension?.split(' ')[4]
        : '',
      height: request.data.dimension?.includes('Custom_Size')
        ? Number.parseInt(request.data.dimension?.split(' ')[3].replace('”', ''), 10)
        : '',
      width: request.data.dimension?.includes('Custom_Size')
        ? Number.parseInt(request.data.dimension?.split(' ')[1].replace('”', ''), 10)
        : '',
    });
  }, [request]);

  const setFileForRequest = medias => {
    const tempFiles = [...request.files];
    Object.keys(medias).forEach(key => tempFiles.push(medias[key]));

    updateRequest({ ...request, files: tempFiles });
  };

  const selectSourceFile = file_type => {
    const file_format = [...fileFormat];
    file_format.push(file_type);
    updateRequestData('file_formats', file_format);
  };

  const isFileUploading = () => {
    let hasMediaId = false;
    for (let i = 0; i < request.files.length; i += 1) {
      const file = request.files[i];
      if (file.media_id) {
        hasMediaId = true;
      } else {
        hasMediaId = false;
      }

      if (!hasMediaId) {
        break;
      }
    }

    if (hasMediaId && request.form === 'summary') {
      toast.success('Files uploaded successfully');
    }
  };

  const setMediaForRequest = selectedAssets => {
    if (selectedAssets) {
      const tempAssets = [...request.assets];
      selectedAssets.forEach(selectedAsset => {
        if (
          tempAssets.filter(
            asset =>
              asset.media_id === selectedAsset.media_id &&
              asset.asset_id === selectedAsset.asset_id,
          ).length === 0
        ) {
          setShow(false);
          tempAssets.push({
            media_id: selectedAsset.media_id,
            asset_id: selectedAsset.asset_id,
            media: selectedAsset.media,
          });
        }
      });
      if (tempAssets.length > request.assets.length) {
        toast.success('Asset added successfully.');
      } else if (tempAssets.length === request.assets.length && selectedAssets.length > 0) {
        toast.error('Asset already added.');
      }

      updateRequest({ ...request, assets: tempAssets });
    }
  };

  const handleOnDragEnd = result => {
    const arr = Array.from(inputFieldList);
    const [reorderedItem] = arr.splice(result.source.index, 1);
    arr.splice(result.destination.index, 0, reorderedItem);

    setInputFieldList(arr.map((i, idx) => ({ ...i, id: idx })));

    updateRequestData(
      'steps',
      arr.map((i, idx) => ({ ...i, id: idx })),
    );
  };

  return (
    <>
      <Card className="pl-md-0 px-lg-5 no-border pt-3 text-font">
        <div className={styles.force_pad}>
          <div className="d-flex flex-row  mb-3">
            <Image src={icon} alt="" className="mr-2" width="24" height="24" />
            <h5 className="text-size-md text-primary font-weight-600">Dimensions</h5>
          </div>
          <Form.Control
            size="lg"
            as="select"
            className={classnames(`text-size-xs grn-arrw shadow`, styles.dimension_arrow)}
            value={request.data.type}
            onChange={e => {
              updateRequestData('type', e.target.value);
            }}
          >
            <option value="" className={styles.options} selected>
              Request Type
            </option>
            <option value="flyers" className={styles.options}>
              Flyers
            </option>
            <option value="posters" className={styles.options}>
              Posters
            </option>
            <option value="business_cards" className={styles.options}>
              Business Cards
            </option>
            <option value="icons" className={styles.options}>
              Icons
            </option>
            <option value="landing_pages" className={styles.options}>
              Landing Pages
            </option>
            <option value="logos" className={styles.options}>
              Logos
            </option>
            <option value="custom_illustrations" className={styles.options}>
              Custom Illustrations
            </option>
          </Form.Control>
          <h6 className="text-size-sm font-weight-600 pt-4 pb-1">
            What size should your design be?
          </h6>
          <Form.Check
            custom
            type="checkbox"
            name="size"
            label="Standard A4 (8.5” x 11” 300 DPI)"
            id="standardA4"
            className=" text-size-xs py-2"
            checked={request.data.dimension?.includes('Standard_A4')}
            onChange={() => {
              updateRequestData('dimension', 'Standard_A4 8.5” x 11” 300 DPI');
            }}
          />
          <Form.Check
            custom
            type="checkbox"
            name="size"
            label="Half Sheet (5.5’ x 8.5” 300 DPI)"
            id="halfsheet"
            className="text-size-xs py-2"
            checked={request.data.dimension?.includes('Half_Sheet')}
            onChange={() => {
              updateRequestData('dimension', 'Half_Sheet 5.5’ x 8.5” 300 DPI');
            }}
          />
          <Form.Check
            custom
            type="checkbox"
            name="size"
            label="Standard A5 (5.8” x 8.3’ 300 DPI)"
            id="standard"
            className="text-size-xs py-2"
            checked={request.data.dimension?.includes('Standard_A5')}
            onChange={() => {
              updateRequestData('dimension', 'Standard_A5 5.8” x 8.3’ 300 DPI');
            }}
          />
          <Form.Check
            custom
            type="checkbox"
            name="size"
            label="DL (3.9” x 8.3’ 300 DPI)"
            id="dl"
            className="text-size-xs py-2"
            checked={request.data.dimension?.includes('DL')}
            onChange={() => {
              updateRequestData('dimension', 'DL 3.9” x 8.3’ 300 DPI');
            }}
          />
          <Form.Check
            custom
            type="checkbox"
            name="size"
            label="Custom Size"
            id="custom"
            className="text-size-xs py-2"
            checked={request.data.dimension?.includes('Custom_Size')}
          />
          <div className="text-size-xs ml-3 pt-2">
            {'W '}
            <input
              type="number"
              min="0"
              value={customSize.width}
              className={styles.small_input}
              onChange={e => {
                setCustomSize({
                  ...customSize,
                  width: e.target.value,
                });

                if (
                  e.target.value !== '' &&
                  customSize.height !== '' &&
                  customSize.resolution !== ''
                ) {
                  updateRequestData(
                    'dimension',
                    `Custom_Size ${e.target.value}” x ${customSize.height}” ${customSize.resolution} DPI`,
                  );
                }
              }}
            />
            {' H '}
            <input
              type="number"
              min="0"
              value={customSize.height}
              className={styles.small_input}
              onChange={e => {
                setCustomSize({
                  ...customSize,
                  height: e.target.value,
                });

                if (
                  customSize.width !== '' &&
                  e.target.value !== '' &&
                  customSize.resolution !== ''
                ) {
                  updateRequestData(
                    'dimension',
                    `Custom_Size ${customSize.width}” x ${e.target.value}” ${customSize.resolution} DPI`,
                  );
                }
              }}
            />
            {' DPI '}
            <input
              type="number"
              min="0"
              value={customSize.resolution}
              className={styles.small_input}
              onChange={e => {
                setCustomSize({
                  ...customSize,
                  resolution: e.target.value,
                });

                if (customSize.width !== '' && customSize.height !== '' && e.target.value !== '') {
                  updateRequestData(
                    'dimension',
                    `Custom_Size ${customSize.width}” x ${customSize.height}” ${e.target.value} DPI`,
                  );
                }
              }}
            />
          </div>

          <hr className="my-5" />
          <div className="d-flex flex-row mb-3">
            <Image src={descriptionIcon} alt="" className="mr-2" width="24" height="24" />
            <h5 className="text-size-md text-primary font-weight-600">Description</h5>
          </div>

          <p className="text-size-xs">
            Add your instructions in list to help your designer complete all parts of your request
          </p>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="steps">
              {provided => (
                <ul className="list-unstyled" {...provided.droppableProps} ref={provided.innerRef}>
                  {inputFieldList.map((item, index) => (
                    <Draggable key={item.id} draggableId={String(item.id)} index={index}>
                      {prov => (
                        <li ref={prov.innerRef} {...prov.draggableProps} {...prov.dragHandleProps}>
                          <Form.Group className="shadow">
                            <InputGroup>
                              <InputGroup.Append>
                                <InputGroup.Text>
                                  <FontAwesomeIcon icon={faGripVertical} className="text-muted" />
                                </InputGroup.Text>
                              </InputGroup.Append>
                              <Form.Control
                                id={item.id}
                                type="text"
                                size="lg"
                                placeholder={`Step ${index + 1}`}
                                className="text-size-xs no-border-right"
                                onKeyPress={e => addTextField(e)}
                                value={item.text ? item.text : ''}
                                onChange={e => {
                                  onChange(item.id, e.target.value);
                                }}
                              />
                              <InputGroup.Append>
                                <InputGroup.Text className="px-0 py-0 bg-white">
                                  <Button variant="white" onClick={() => deleteTextField(item.id)}>
                                    <img src={crossIcon} alt="delete" width="10" />
                                  </Button>
                                </InputGroup.Text>
                              </InputGroup.Append>
                            </InputGroup>
                          </Form.Group>
                        </li>
                      )}
                    </Draggable>
                  ))}
                </ul>
              )}
            </Droppable>
          </DragDropContext>

          <p className="text-size-xs">Press Enter for another Step</p>

          <div className="d-flex flex-row align-items-center mb-3 pt-3">
            <Form.Check
              custom
              id="designToInclude"
              className={`${styles.check_size}`}
              checked={request.data.is_include_text}
              onChange={() => {
                updateRequestData('is_include_text', !request.data.is_include_text);
              }}
            />
            <div className="text-size-sm mb-0 font-weight-600">
              Does your design need to include text?
            </div>
          </div>

          <p className="text-size-xs pb-2">
            Include text copy exactly as you’d like it to appear in your design
          </p>

          <Form.Group className="pb-2">
            <Form.Control
              as="textarea"
              rows="4"
              placeholder="Enter exact text copy here.."
              className="shadow"
              value={request.data.text}
              onChange={e => {
                updateRequestData('text', e.target.value);
              }}
              disabled={!request.data.is_include_text}
            />
          </Form.Group>

          <div className="d-flex flex-row align-items-center mb-3 pt-3">
            <Form.Check
              custom
              id="assetsToShare"
              className={`${styles.check_size}`}
              checked={isImage}
              onChange={() => {
                setIsImage(!isImage);
              }}
            />
            <h6 className="text-size-sm mb-0 font-weight-600">
              Do you have any assets to share with us?
            </h6>
          </div>

          <p className="text-size-xs pb-2">
            If you want to share any photo, content, logo, font etc.
          </p>

          <div className="d-flex flex-wrap my-2">
            {request.assets?.map(asset => (
              <AssetImage asset={asset} />
            ))}
          </div>

          {request.files?.map((file, index) => (
            <MediaImage
              media={file}
              onLoadComplete={media => {
                const value = {
                  media_id: media.id,
                  media,
                };
                request.files[index] = value;

                isFileUploading();
              }}
            />
          ))}
          <div className="my-2">
            <FileButton
              // className="text-size-xs mr-3 mb-0 font-weight-600 text-darker mt-2 mt-md-0"
              className={`text-size-xs mr-3 mb-0 font-weight-600 text-darker ${
                !isImage ? 'disable-custom-button' : ''
              }`}
              onFileUploaded={setFileForRequest}
              disable={!isImage}
            >
              Add Files
            </FileButton>
            <AssetsButton
              className="text-size-xs font-weight-600 text-darker ml-2"
              onClick={() => {
                setShow(true);
              }}
              disable={!isImage}
            >
              Add Assets
            </AssetsButton>
          </div>
          <div className="d-flex flex-row align-items-center mb-3 pt-3">
            <Form.Check
              custom
              id="needImages"
              className={`${styles.check_size}`}
              checked={typeOfImages}
              onChange={() => {
                setTypeOfImages(!typeOfImages);
              }}
            />
            <div className="text-size-sm mb-0 font-weight-600">Do you need images?</div>
          </div>

          <Form.Group className="pb-2">
            <Form.Control
              as="textarea"
              rows="4"
              placeholder="Explain the type of image you need.."
              className="shadow"
              value={request.data.type_of_images}
              onChange={e => {
                updateRequestData('type_of_images', e.target.value);
              }}
              disabled={!typeOfImages}
            />
          </Form.Group>

          <hr className="my-5" />

          <div className="d-flex flex-row mb-3">
            <Image src={fileIcon} alt="" className="mr-2" width="24" height="24" />
            <h5 className="text-size-md text-primary font-weight-600">File Format</h5>
          </div>

          <div className="mb-3 font-weight-500">
            <Form.Check
              custom
              id="customControlInlineJPEG"
              inline
              label=".jpeg"
              className="text-size-xs"
              checked={fileFormat.includes('.jpeg')}
              onChange={() => {
                let types = [...fileFormat];
                if (!fileFormat.includes('.jpeg')) {
                  types.push('.jpeg');
                } else {
                  types = types.filter(type => type !== '.jpeg');
                }

                types.push(sourceFile);
                updateRequestData('file_formats', types);
              }}
            />
            <Form.Check
              custom
              id="customControlInlinePNG"
              inline
              label=".png"
              className="text-size-xs"
              checked={fileFormat.includes('.png')}
              onChange={() => {
                let types = [...fileFormat];
                if (!fileFormat.includes('.png')) {
                  types.push('.png');
                } else {
                  types = types.filter(type => type !== '.png');
                }

                types.push(sourceFile);
                updateRequestData('file_formats', types);
              }}
            />
            <Form.Check
              custom
              id="customControlInlinePDF"
              inline
              label=".pdf"
              className="text-size-xs"
              checked={fileFormat.includes('.pdf')}
              onChange={() => {
                let types = [...fileFormat];
                if (!fileFormat.includes('.pdf')) {
                  types.push('.pdf');
                } else {
                  types = types.filter(type => type !== '.pdf');
                }

                types.push(sourceFile);
                updateRequestData('file_formats', types);
              }}
            />
            <Form.Check
              custom
              id="customControlInlineGIF"
              inline
              label=".gif"
              className="text-size-xs"
              checked={fileFormat.includes('.gif')}
              onChange={() => {
                let types = [...fileFormat];
                if (!fileFormat.includes('.gif')) {
                  types.push('.gif');
                } else {
                  types = types.filter(type => type !== '.gif');
                }

                types.push(sourceFile);
                updateRequestData('file_formats', types);
              }}
            />
            <Form.Check
              custom
              id="customControlInlinePPT"
              inline
              label=".ppt"
              className="text-size-xs"
              checked={fileFormat.includes('.ppt')}
              onChange={() => {
                let types = [...fileFormat];
                if (!fileFormat.includes('.ppt')) {
                  types.push('.ppt');
                } else {
                  types = types.filter(type => type !== '.ppt');
                }

                types.push(sourceFile);
                updateRequestData('file_formats', types);
              }}
            />
          </div>

          <p>
            <span className="text-size-xs font-weight-600">Source File</span>
            {' ( '}
            <Form.Check
              type="radio"
              inline
              custom
              id="summary_ai"
              name="summary_source_file"
              label="AI"
              className="text-size-xs"
              checked={sourceFile === '.AI'}
              onClick={() => {
                selectSourceFile('.AI');
              }}
            />
            <Form.Check
              type="radio"
              inline
              custom
              id="summary_psd"
              name="summary_source_file"
              label="PSD"
              className="text-size-xs"
              checked={sourceFile === '.PSD'}
              onClick={() => {
                selectSourceFile('.PSD');
              }}
            />
            <Form.Check
              type="radio"
              inline
              custom
              id="summary_indd"
              name="summary_source_file"
              label="INDD"
              className="text-size-xs"
              checked={sourceFile === '.INDD'}
              onClick={() => {
                selectSourceFile('.INDD');
              }}
            />
            <Form.Check
              type="radio"
              inline
              custom
              id="summary_xd"
              name="summary_source_file"
              label="XD"
              className="text-size-xs"
              checked={sourceFile === '.XD'}
              onClick={() => {
                selectSourceFile('.XD');
              }}
            />
            {' ) '}
          </p>
          <div className="d-flex flex-column align-items-center align-items-md-start">
            <Button
              type="button"
              className="text-size-xs mt-5 font-weight-500"
              onClick={() => {
                publishTask(1);
              }}
            >
              Send to my Designer
            </Button>

            <Button
              type="button"
              variant="link"
              className="text-size-xs mt-2 px-0 d-block text-underline text-darker"
              onClick={() => {
                publishTask(0);
              }}
            >
              Save Draft
            </Button>
          </div>
        </div>
      </Card>
      <AssetsViewer show={show} setShow={setShow} onSelectMedia={setMediaForRequest} />
    </>
  );
};

export default RequestSummary;
