import React, { useState } from 'react';
import {
  Card,
  Popover,
  Spinner,
  Badge,
  Overlay,
  OverlayTrigger,
  Tooltip,
  Button,
} from 'react-bootstrap';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import styles from '../../styles/modules/ServiceBoards.module.scss';
import customStyles from '../../styles/modules/Boards.module.scss';
import tickIcon from '../../assets/icons/tick-icon.svg';
import PencilIcon from '../../assets/icons/PencilIcon';
import EditCollection from './EditCollection';
import {
  useAddCollectionToBoard,
  useCollections,
  useRemoveCollectionFromBoard,
} from '../../hooks/collection.hooks';
import { useBoardCollections } from '../../hooks/boards.hooks';
import CreateCollection from './CreateCollection';
import closeIcon from '../../assets/icons/close-icon.svg';
import removeIcon from '../../assets/icons/cross-icon.svg';
import EditBoard from './EditBoard';
import RoleBased from '../../components/RoleBased';
import { ROLES, SECTION_KEY } from '../../utils/enums';

const BoardsCard = ({
  boardId,
  heading,
  user,
  onClick,
  noAddCollection,
  noEditBoard,
  collections: boardCollectionsList,
}) => {
  const queryClient = useQueryClient();

  const [editCollection, setEditCollection] = React.useState(null);
  const [showEdit, setShowEdit] = React.useState(false);
  const toggleEdit = () => setShowEdit(!showEdit);
  const [enabled, setEnabled] = useState(false);
  const [boardCollections, setBoardCollections] = useState([]);

  const { data: collections } = useCollections();
  const { data: boardCollectionsData, isFetching, refetch } = useBoardCollections(boardId, enabled);

  const { mutateAsync: addCollectionToBoard } = useAddCollectionToBoard();
  const { mutateAsync: removeCollectionFromBoard } = useRemoveCollectionFromBoard();

  const toggleCollection = async collection => {
    try {
      if (boardCollections?.map(({ collection_id }) => collection_id).includes(collection.id)) {
        await removeCollectionFromBoard({ id: collection.id, boardId });
        toast.success(`Collection tag removed!`);
      } else {
        await addCollectionToBoard({ id: collection.id, boardId });
        toast.success(`Collection tag added!`);
      }

      queryClient.invalidateQueries(['board-collections', boardId]);
      setEnabled(true);
    } catch (err) {
      toast.error(err?.message);
    }
  };

  const removeSpecificCollectionTag = async (collection, e) => {
    if (e.detail === 1) {
      try {
        if (
          boardCollections
            ?.map(({ collection_id }) => collection_id)
            .includes(collection.collection.id)
        ) {
          await removeCollectionFromBoard({ id: collection.collection.id, boardId });
        }

        queryClient.invalidateQueries(['board-collections', boardId]);
        setEnabled(true);
        toast.success(`Collection tag removed!`);
      } catch (err) {
        toast.error(err?.message);
      }
    }
  };

  const [showPopover, setShowPopover] = React.useState(false);
  const target = React.useRef(null);

  React.useEffect(
    function loadBoardCollectionData() {
      if (!isFetching) {
        if (boardCollectionsData?.length > 0 && enabled) {
          setBoardCollections(boardCollectionsData);
          setEnabled(false);
        } else {
          setBoardCollections(boardCollectionsList);
        }
      }
    },
    [isFetching, boardCollectionsList, boardCollectionsData],
  );

  React.useEffect(
    function fetchCollectionData() {
      if (enabled) {
        refetch();
      }
    },
    [enabled],
  );

  return (
    <div>
      <div
        className={classnames(
          'card card-body w-100 d-block px-3 py-4',
          styles.border_radius_primary,
          styles.card_shadow,
        )}
      >
        <div className="w-100 d-flex align-items-center justify-content-between">
          <button
            type="button"
            onClick={onClick}
            className={`bg-transparent border-0 ${!noEditBoard ? 'w-75' : 'w-100'}`}
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>
                  <span>{heading}</span>
                </Tooltip>
              }
            >
              <h6 className="custom-overflow-box mb-0">{heading}</h6>
            </OverlayTrigger>
          </button>

          {!noEditBoard && (
            <RoleBased
              sectionKey={[SECTION_KEY.board_edit]}
              acceptedRoles={[ROLES.SUPER_ADMIN, ROLES.ADMIN]}
            >
              <button
                type="button"
                onClick={toggleEdit}
                className="bg-transparent border-0 ml-auto"
              >
                <FontAwesomeIcon icon={faEdit} className="text-primary" />
              </button>
            </RoleBased>
          )}
        </div>
      </div>

      <div className="d-flex align-items-center">
        {user.map(username => (
          <Card
            className={`my-3 px-2 py-1 mr-2 ${styles.border_radius_primary} ${styles.card_shadow} ${styles.card_user}`}
          >
            <div className={`${styles.name_list}`}>
              <h5
                className={classnames(styles.card_name, styles.card_user, 'mb-0', 'text-size-xxs')}
              >
                {username}
              </h5>
            </div>
          </Card>
        ))}

        <div className="d-flex align-items-center flex-wrap mt-3">
          {!noAddCollection && (
            <>
              <RoleBased
                sectionKey={[SECTION_KEY.collection_and_collection_filter_view_add]}
                acceptedRoles={[ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.MANAGERS]}
              >
                <button
                  ref={target}
                  onClick={() => setShowPopover(!showPopover)}
                  type="submit"
                  className={`${styles.sidesection_icon} mr-3 mb-2 px-0 d-flex align-items-center justify-content-between`}
                >
                  <FontAwesomeIcon icon={faPlus} size="sm" />
                </button>
              </RoleBased>

              <Overlay target={target.current} show={showPopover} placement="auto" flip>
                {props => (
                  <Popover
                    {...props}
                    id="popover-names"
                    className={classnames(
                      'text-font custom-board-popover',
                      customStyles.popover_collection_card,
                    )}
                  >
                    <Popover.Content className="py-4">
                      <div className="text-right">
                        <button
                          type="button"
                          onClick={() => setShowPopover(!showPopover)}
                          className={styles.button_cross}
                        >
                          <img src={closeIcon} alt="close" className={styles.cross_img} />
                        </button>
                      </div>

                      <h6 className="text-center text-secondary">
                        <span className="mr-2">Change Collection</span>
                        {isFetching && <Spinner animation="border" variant="primary" size="sm" />}
                      </h6>
                      <ul className={classnames('pl-0', styles.scrollable_collection_tag_box)}>
                        {collections.map(collection => (
                          <li
                            key={collection.id}
                            className={classnames(
                              'd-flex align-items-center p-2',
                              styles.collection_edit_list_item,
                            )}
                          >
                            <button
                              type="button"
                              className="bg-transparent border-0 p-0 d-flex align-items-center flex-grow-1"
                              onClick={() => toggleCollection(collection)}
                            >
                              <div className={styles.collection_checked}>
                                {boardCollections
                                  .map(({ collection_id }) => collection_id)
                                  .includes(collection.id) && (
                                  <img src={tickIcon} alt="tick" className="" width="8" />
                                )}
                              </div>
                              <div htmlFor="name" className="mb-0">
                                {collection.title}
                              </div>
                            </button>
                            <button
                              type="button"
                              className="bg-transparent border-0"
                              onClick={() => setEditCollection(collection)}
                            >
                              <PencilIcon />
                            </button>
                          </li>
                        ))}
                      </ul>
                      <div className="text-center py-3">
                        <CreateCollection>Create a collection</CreateCollection>
                      </div>
                    </Popover.Content>
                  </Popover>
                )}
              </Overlay>
            </>
          )}

          {boardCollections?.map(collection => (
            <Badge
              variant="light"
              key={collection?.id}
              className="mr-2 mb-2 border d-flex align-items-center"
            >
              {collection?.collection?.title}
              <Button
                variant="white"
                className={classnames('px-0 py-0 ml-1', styles.remove_badge_btn)}
                onClick={e => removeSpecificCollectionTag(collection, e)}
              >
                <img src={removeIcon} alt="cancel" />
              </Button>
            </Badge>
          ))}
        </div>
      </div>

      <EditCollection
        show={!!editCollection}
        onClose={() => setEditCollection(null)}
        collection={editCollection}
      />

      <EditBoard show={showEdit} onClose={toggleEdit} boardId={boardId} />
    </div>
  );
};

export default BoardsCard;
