import moment from 'moment';
import React from 'react';
import { Button, Table } from 'react-bootstrap';
import { useLocation } from 'react-router';
import { v4 as uuid } from 'uuid';

const tableData = [
  { _id: uuid(), status: 'Start Date', active: 'start_date' },
  { _id: uuid(), status: 'Last Order Date', active: 'updated_at' },
  { _id: uuid(), status: 'Next Payment Date', active: 'end_date' },
  { _id: uuid(), status: 'Trial End Date', active: 'trial_end' },
];

const SubscriptionDetails = () => {
  const { state } = useLocation();
  return (
    <div className="mx-0 mx-lg-5 px-4 px-xl-0">
      <Table responsive bordered>
        <thead>
          <tr className="text-font ">
            <th className="text-darker text-size-xs font-weight-600">Status</th>
            <th className="text-darker text-size-xs font-weight-600">{state?.data.status}</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map(({ _id, status, active }) => (
            <tr key={_id}>
              <td className="text-size-xs text-secondary font-weight-500 text-font">{status}</td>
              <td className="text-size-xs text-secondary font-weight-500 text-font">
                {moment(state?.data[active]).format('MMM DD, YYYY')}
              </td>
            </tr>
          ))}
          <tr>
            <td className="text-size-xs text-secondary font-weight-500 text-font">Actions</td>
            <td>
              <Button
                type="button"
                className="text-size-xs mr-3 px-5 text-font font-weight-500 mb-3 mb-lg-0"
                onClick={() => {
                  window.open(
                    'https://kimp360wp.codebuddy.codes/my-account/subscriptions/',
                    '_blank',
                  );
                }}
              >
                Cancel
              </Button>
              <Button
                type="button"
                variant="outline-dark"
                className=" px-3 text-font font-weight-500 white-space-pre"
                onClick={() => {
                  window.open(
                    'https://kimp360wp.codebuddy.codes/my-account/payment-methods/',
                    '_blank',
                  );
                }}
              >
                Change Payment Method
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>

      <h5 className="text-size-sm my-4 font-weight-600 text-font">Subscription Details</h5>
      <Table responsive bordered>
        <thead>
          <tr className="text-font">
            <th className="text-darker text-size-xs font-weight-600">Product</th>
            <th className="text-darker text-size-xs font-weight-600">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr key="product_1">
            <td
              className={`text-darker text-size-xs text-font ${`font-weight-500 text-secondary`}`}
            >
              {state?.data?.product}
            </td>
            <td
              className={`text-darker text-size-xs text-font ${`font-weight-500 text-secondary`}`}
            >
              {state?.data?.total}
            </td>
          </tr>
          <tr key="product_2">
            <td className={`text-darker text-size-xs text-font ${`font-weight-600 `}`}>
              Subtotal:
            </td>
            <td className={`text-darker text-size-xs text-font ${`font-weight-600`}`}>
              {state?.data?.subtotal}
            </td>
          </tr>
          <tr key="product_3">
            <td className={`text-darker text-size-xs text-font ${`font-weight-600 `}`}>
              Payment Method:
            </td>
            <td className={`text-darker text-size-xs text-font ${`font-weight-600`}`}>
              {state?.data?.payment_method}
            </td>
          </tr>
          <tr key="product_4">
            <td className={`text-darker text-size-xs text-font ${`font-weight-600 `}`}>Total:</td>
            <td className={`text-darker text-size-xs text-font ${`font-weight-600`}`}>
              {state?.data?.total}
            </td>
          </tr>
        </tbody>
      </Table>

      <h5 className="text-size-sm my-4 font-weight-600 text-font">Invoices</h5>
      <Table responsive bordered>
        <thead>
          <tr className="text-size-xs text-font">
            <th className="text-darker font-weight-600">Product</th>
            <th className="text-darker font-weight-600">Date</th>
            <th className="text-darker font-weight-600">Status</th>
            <th className="text-darker font-weight-600">Total</th>
            <th role="row" />
          </tr>
        </thead>
        <tbody>
          <tr key={state?.data?.id} className="text-size-xs text-font text-secondary">
            <td className="font-weight-500">#{state?.data?.subscription_id}</td>
            <td className="font-weight-500">
              {moment(state?.data?.created_at).format('MMM DD, YYYY')}
            </td>
            <td className="font-weight-500">{state?.data?.status}</td>
            <td className="font-weight-500">USD ${state?.data?.total}</td>
            <td>
              <Button
                className="text-size-xs text-font font-weight-500"
                onClick={() => {
                  window.open(
                    'https://kimp360wp.codebuddy.codes/my-account/subscriptions/',
                    '_blank',
                  );
                }}
              >
                Invoice
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default SubscriptionDetails;
