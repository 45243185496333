import { useQuery } from 'react-query';
import { getSubscriptions } from '../requests/subscriptions.requests';

export const useSubscriptions = userId =>
  useQuery(
    ['subscription', userId],
    async () => {
      const res = await getSubscriptions(userId);
      return res.data;
    },
    {
      initialData: [],
    },
  );

export const dummy = () => {};
