import http from '../utils/http';

export const createTasks = data => http.post(`/tasks`, data);

export const fetchTasks = (projectId, search) =>
  http.get(`/tasks?project_id=${projectId}${search !== '' ? `&search=${search}` : ''}`);

export const fetchArchivedTasks = projectId =>
  http.get(`/tasks?archived=1&project_id=${projectId}`);

export const getTasksDetails = id => http.get(`/tasks/${id}`);

export const updateTasksDetails = (id, data) => http.put(`/tasks/${id}`, data);

export const createTaskFile = (id, info) => http.post(`/tasks/${id}/files`, info);

export const getTaskFile = id => http.get(`/tasks/${id}/files`);
