import { useMutation } from 'react-query';
import {
  forgotPassword,
  login,
  logout,
  registerUser,
  resetPassword,
} from '../requests/auth.requests';

export const useLogin = () =>
  useMutation(async data => {
    const res = await login(data);
    return res;
  });

export const useLogout = () =>
  useMutation(async () => {
    const res = await logout();
    return res;
  });

export const useRegisterUser = () =>
  useMutation(async data => {
    const res = await registerUser(data);
    return res;
  });

export const useForgotPassword = () =>
  useMutation(async data => {
    const res = await forgotPassword(data);
    return res;
  });

export const useResetPassword = () =>
  useMutation(async data => {
    const res = await resetPassword(data);
    return res;
  });
