import React from 'react';

const Footer = () => {
  return (
    <footer className="text-size-xs text-font text-center text-md-right py-3 mx-3">
      {'COPYRIGHT @ 2021 '}
      <strong className="text-primary font-weight-600">Kimp360.io</strong>, All rights Reserved
    </footer>
  );
};

export default Footer;
