import React from 'react';
import { Redirect, Route } from 'react-router';
import UserContext from '../contexts/UserContext';

function RoleBaseRouter({ sectionKey, acceptedRoles, children, ...rest }) {
  const { getRole } = React.useContext(UserContext);

  return (
    <Route
      {...rest}
      render={() => {
        for (let i = 0; i < sectionKey?.length; i += 1) {
          if (getRole()?.permissions.includes(sectionKey[i])) {
            return children;
          }
        }

        return (
          <Redirect
            to={{
              pathname: '/dashboard',
            }}
          />
        );
      }}
    />
  );
}

export default RoleBaseRouter;
