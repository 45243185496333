import React from 'react';
import Card from 'react-bootstrap/Card';
import classNames from 'classnames';
import { Spinner } from 'react-bootstrap';
import styles from '../../styles/modules/AddAssets.module.scss';
import PlusIcon from '../../assets/icons/final-plus-icon.svg';

const AddAssetButton = ({ onAdd, title, isLoading }) => {
  const inputRef = React.useRef();

  const handleUpload = async event => {
    const { files } = event.target;

    await onAdd(Array.from(files));

    if (inputRef.current) {
      inputRef.current.value = null;
    }
  };

  return (
    <Card className={classNames('h-100 rounded-lg')}>
      <Card.Body
        className={classNames(
          'd-flex flex-column align-items-center justify-content-center',
          'w-100 shadow border-0 rounded-lg mb-0 cursor-pointer',
          styles.btn_add_brand,
        )}
        as="label"
        id="new-media"
      >
        <input
          multiple
          type="file"
          name="new-media"
          id="new-media"
          className="d-none"
          ref={inputRef}
          onChange={handleUpload}
          disabled={isLoading}
        />
        {isLoading ? (
          <Spinner animation="border" variant="primary" />
        ) : (
          <img src={PlusIcon} alt="add-icon" className={styles.plus_icon_size} />
        )}
        <div className="text-size-sm mt-1 font-weight-500">{title}</div>
      </Card.Body>
    </Card>
  );
};

AddAssetButton.defaultProps = {
  title: 'Add Asset',
  isLoading: false,
};

export default AddAssetButton;
