import classnames from 'classnames';
import React, { useState } from 'react';
import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import styles from '../../styles/modules/ForgotPassword.module.scss';
import FlyKimpBanner from '../../assets/images/fly-monkey.png';
import mainIcon from '../../assets/icons/final-main-logo.svg';
import BackIcon from '../../assets/icons/BackIcon';

import ForgotPasswordScreen from './ForgotPasswordScreen';
import ConfirmPassword from './ConfirmPasswordScreen';

const ForgotPassord = () => {
  const [currentScreen, setCurrentScreen] = useState('forgot-password');
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');

  return (
    <main className={classnames('text-font h-vh-100', styles.background_image)}>
      <Row className="mx-0 justify-content-between pt-3 pt-md-0">
        <div className="align-self-center pl-3">
          <Image src={FlyKimpBanner} className={classnames('img-fluid', styles.login_kimp_icon)} />
        </div>
        <div className="pb-4 pt-4 pr-4 pr-md-5 text-right">
          <img src={mainIcon} alt="logo" className="main-logo-icon-size" />
        </div>
      </Row>

      <Container className="text-font pt-md-2 pb-0">
        <Row className="mt-4 mt-md-0 pb-md-0">
          <Col xs={12} md={6} lg={6} className="pl-md-0 pr-5 mt-5 mt-md-0 d-none d-md-block">
            <Image src={FlyKimpBanner} className="img-fluid pr-5" />
          </Col>
          <Col xs={12} md={6} lg={6} className=" d-flex flex-column justify-content-center px-xl-5">
            <div className="px-xl-5">
              <Card
                body
                className={classnames(
                  'px-4 px-lg-5 py-lg-2',
                  styles.card_border_radius,
                  styles.card_shadow,
                )}
              >
                <div className="text-center pb-3 mt-3 mb-3">
                  {currentScreen === 'forgot-password' && (
                    <h3 className="font-weight-bold">Forgot Password</h3>
                  )}
                  {currentScreen === 'confirm-password' && (
                    <h3 className="font-weight-bold">Confirm Password</h3>
                  )}
                </div>

                {currentScreen === 'forgot-password' && (
                  <ForgotPasswordScreen
                    setCurrentScreen={setCurrentScreen}
                    setToken={setToken}
                    setEmail={setEmail}
                  />
                )}
                {currentScreen === 'confirm-password' && (
                  <ConfirmPassword token={token} email={email} />
                )}

                <Link
                  className="text-primary font-weight-600 d-flex align-items-center justify-content-center"
                  to="/"
                >
                  <BackIcon />
                  <p className="mb-0 text-center pl-2">Go back</p>
                </Link>
              </Card>
            </div>
          </Col>
        </Row>
        <p className={classnames('text-size-sm mt-5 text-center', styles.custom_white_color)}>
          COPYRIGHT @ 2021 <strong>Kimp360.io</strong>, All rights Reserved
        </p>
      </Container>
    </main>
  );
};

export default ForgotPassord;
