import { useQuery, useMutation } from 'react-query';
import {
  createAssetMedia,
  deleteAssetMedia,
  fetchAssetMedias,
} from '../requests/asset-medias.requests';

export const useAssetMedias = assetId =>
  useQuery(
    ['asset-media', assetId],
    async () => {
      const res = await fetchAssetMedias(assetId);
      return res.data;
    },
    {
      enabled: !!assetId,
      initialData: [],
    },
  );

export const useCreateAssetMedia = () =>
  useMutation(async ({ assetId, mediaId }) => {
    const res = await createAssetMedia(assetId, { media_id: mediaId });
    return res.data;
  });

export const useRemoveAssetMedia = () =>
  useMutation(async ({ assetId, mediaId }) => {
    const res = await deleteAssetMedia(assetId, mediaId);
    return res.data;
  });
