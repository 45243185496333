import * as React from 'react';

function PreDimensionIcon(props) {
  const { active } = props;

  return (
    <svg
      data-name="Group 360"
      xmlns="http://www.w3.org/2000/svg"
      width={117.877}
      height={154.226}
      viewBox="0 0 117.877 154.226"
      {...props}
    >
      <g data-name="Group 353">
        <path
          data-name="Path 553"
          d="M117.877 154.226H0V-.004h117.877zm-114.2-3.678h110.52V3.678H3.678z"
          fill={active ? '#219186' : '#969696'}
        />
      </g>
      <g data-name="Group 354">
        <path
          data-name="Rectangle 41"
          fill={active ? '#219186' : '#969696'}
          d="M21.388 97.042h75.1v3.678h-75.1z"
        />
      </g>
      <g data-name="Group 355">
        <path
          data-name="Rectangle 42"
          fill={active ? '#219186' : '#969696'}
          d="M21.388 113.595h75.1v3.678h-75.1z"
        />
      </g>
      <g data-name="Group 356">
        <path
          data-name="Rectangle 43"
          fill={active ? '#219186' : '#969696'}
          d="M21.388 130.148h75.1v3.678h-75.1z"
        />
      </g>
      <g data-name="Group 357">
        <path
          data-name="Path 554"
          d="M98.449 81.715H19.428V21.197h79.021zm-75.343-3.678H94.77V24.875H23.106z"
          fill={active ? '#219186' : '#969696'}
        />
      </g>
      <g data-name="Group 358">
        <path
          data-name="Path 555"
          d="M96.61 56.077v23.8H21.267V58.9L34.61 46.1l15.431 12.985 24.25-24.029z"
          fill={active ? '#219186' : '#969696'}
        />
      </g>
      <g data-name="Group 359">
        <circle
          data-name="Ellipse 119"
          cx={3.868}
          cy={3.868}
          r={3.868}
          transform="rotate(-80.783 35.733 1.733)"
          fill={active ? '#219186' : '#969696'}
        />
      </g>
    </svg>
  );
}

export default PreDimensionIcon;
