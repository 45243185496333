import classnames from 'classnames';
import React from 'react';
import * as yup from 'yup';
import { Form, Button, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import styles from '../../styles/modules/ForgotPassword.module.scss';
import { useForgotPassword } from '../../hooks/auth.hooks';

const ForgotPasswordScreen = ({ setCurrentScreen, setToken, setEmail }) => {
  const schema = yup.object({
    email: yup.string().required('Required').email('Must be a valid email'),
  });

  const { mutateAsync: forgotPassword, isLoading } = useForgotPassword();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async data => {
    try {
      const res = await forgotPassword(data);
      toast.success(res?.message);
      setToken(res?.remember_token);
      setEmail(getValues('email'));
      setCurrentScreen('confirm-password');
    } catch (error) {
      if (error?.error) {
        toast.error(error?.error);
      }

      if (error?.message) {
        toast.error('Oops!! Something went wrong!');
      }
    }
  };

  return (
    <Form className="mb-3" onSubmit={handleSubmit(onSubmit)}>
      <Form.Group controlId="email" className="mb-0 mb-4">
        <Form.Label className="font-weight-bold text-secondary mb-0">
          Email <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          {...register('email')}
          type="email"
          name="email"
          isInvalid={errors?.email}
          className={classnames(
            'px-0 text-size-sm font-weight-500 remove-focus',
            styles.custom_bottom_border,
            styles.active_fields,
          )}
        />
        <Form.Control.Feedback type="invalid">{errors?.email?.message}</Form.Control.Feedback>
      </Form.Group>

      <Button
        type="submit"
        variant="warning"
        className={classnames('font-weight-bold', styles.custom_btn)}
        size="lg"
        block
        disabled={isLoading}
      >
        {isLoading ? (
          <Spinner size="sm" variant="light" animation="border" className="" />
        ) : (
          <p className={classnames('mb-0', styles.login_text)}>Submit</p>
        )}
      </Button>
    </Form>
  );
};

export default ForgotPasswordScreen;
