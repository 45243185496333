import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { Col, Row, Spinner } from 'react-bootstrap';
import validator from 'validator';
import UserContext from '../../contexts/UserContext';
import { useUpdateUser } from '../../hooks/user.hooks';

const schema = yup.object({
  first_name: yup.string().required('Required'),
  last_name: yup.string().required('Required'),
  email: yup.string(),
  phone: yup
    .string()
    .required('Required')
    .test('isPhone', 'Provide a valid phone number', value => validator.isMobilePhone(value)),
  username: yup.string(),
});

function EditProfile() {
  const userContext = React.useContext(UserContext);

  const { mutateAsync: updateUser, isLoading } = useUpdateUser();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  React.useEffect(() => {
    setValue('first_name', userContext.user?.first_name);
    setValue('last_name', userContext.user?.last_name);
    setValue('email', userContext.user?.email);
    setValue('phone', userContext.user?.phone ? userContext.user?.phone : '');
    setValue(
      'username',
      userContext.user?.username ? userContext.user?.username : userContext.user?.name,
    );
  }, [userContext]);

  const onSubmit = async values => {
    try {
      const data = {
        ...values,
        name: `${values.first_name} ${values.last_name}`,
      };
      const res = await updateUser({ userId: userContext.user?.id, data });
      if (res.status) {
        userContext.updateUser(res.data);
        toast.success('User data updated successfully');
      } else toast.error('User update failed. Please try again.');
    } catch (err) {
      toast.error(err);
    }
  };

  return (
    <Card className="text-font">
      <Card.Header className="bg-white py-4">
        <Card.Title className="text-dark text-center font-weight-600">Account Details</Card.Title>
        <Card.Subtitle className="text-secondary text-center">
          Choose to change plans and/or add additional subscription as per your requirements
        </Card.Subtitle>
      </Card.Header>
      <Card.Body className="">
        <Row>
          <Col sm={12} lg={{ span: 6, offset: 3 }}>
            <Form className="my-5">
              <Form.Group>
                <Form.Control
                  size="lg"
                  placeholder="First Name *"
                  {...register('first_name')}
                  isInvalid={errors?.first_name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.first_name?.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Control
                  size="lg"
                  placeholder="Last Name *"
                  {...register('last_name')}
                  isInvalid={errors?.last_name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.last_name?.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Control
                  size="lg"
                  placeholder="Email Address"
                  {...register('email')}
                  readOnly
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.email?.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Control
                  size="lg"
                  {...register('phone')}
                  placeholder="Phone Number *"
                  isInvalid={errors?.phone}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.phone?.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Control
                  size="lg"
                  placeholder="Display Name"
                  {...register('username')}
                  readOnly
                />
                <Form.Text className="text-secondary mb-3">
                  This will be how your name will be displayed in the account section.
                </Form.Text>
              </Form.Group>

              {/* Button */}
              <Button block size="lg" disabled={isLoading} onClick={handleSubmit(onSubmit)}>
                {isLoading ? (
                  <Spinner size="sm" variant="light" animation="border" />
                ) : (
                  'Update Details'
                )}
              </Button>
            </Form>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default EditProfile;
