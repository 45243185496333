import React, { useState } from 'react';
import { Col, Image, Spinner } from 'react-bootstrap';
import { useFetchMediaById } from '../../../hooks/media.hooks';
import ImagePlaceHolder from '../../../assets/icons/image-placeholder.svg';
import ImageDefault from '../../../assets/icons/file-type-default.svg';
import ImageIllustrator from '../../../assets/icons/file-type-illustrator.svg';
import ImagePdf from '../../../assets/icons/file-type-pdf.svg';
import ImagePhotoshop from '../../../assets/icons/file-type-photoshop.svg';

const MediaView = ({ id, assetId, onSelectMedia, onRemove }) => {
  const { data: media, isFetching } = useFetchMediaById(id);
  const [selected, setSelected] = useState(false);

  const returnMediaDisplay = () => {
    switch (media.file_type) {
      case 'pdf':
        return ImagePdf;
      case 'psd':
        return ImagePhotoshop;
      case 'ai':
        return ImageIllustrator;
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'svg':
        return media.file_path;
      default:
        return ImageDefault;
    }
  };

  return (
    <>
      {isFetching && (
        <Col xs="3" align="center">
          <Spinner size="sm" variant="primary" animation="border" />
        </Col>
      )}
      {!isFetching && (
        <Col
          className={`cursor-pointer mb-2 ${selected ? 'asset-selected' : 'asset-unselected'}`}
          xs="3"
          align="center"
          onClick={() => {
            if (selected) {
              setSelected(false);
              onRemove(id, assetId);
            } else {
              setSelected(true);
              onSelectMedia(id, assetId, media);
            }
          }}
          onDoubleClick={e => {
            e.preventDefault();
          }}
        >
          <Image src={media ? returnMediaDisplay() : ImagePlaceHolder} width="100%" height="100%" />
        </Col>
      )}
    </>
  );
};

export default MediaView;
