import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import moment from 'moment';
import classnames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styles from '../../styles/modules/RequestBoard.module.scss';
import { useUpdateTask } from '../../hooks/requestHistory.hooks';
import RoleBased from '../../components/RoleBased';
import { ROLES, SECTION_KEY } from '../../utils/enums';
import { reorderTasks } from '../../requests/tasks.request';

function InProgressSection({
  onHandleScreen,
  tasks,
  handleSelectTask,
  onStatusChange = () => {},
  isTaskFetching,
}) {
  const { mutateAsync: updateTask, isLoading } = useUpdateTask();
  const { id: boardId } = useParams();
  const queryClient = useQueryClient();

  const [processingId, setProcessingId] = useState();

  const handleStatus = async id => {
    try {
      setProcessingId(id);
      const data = { 'status': 'in-review', order: 0 };
      await updateTask({ id, data });
      queryClient.invalidateQueries(['boardTasks', boardId]);
      onHandleScreen('in-review');
      onStatusChange();
      setProcessingId(undefined);
    } catch (error) {
      toast.error(error?.message);
      setProcessingId(undefined);
    }
  };

  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    if (!isTaskFetching) setItems(tasks.sort((a, b) => a.order - b.order));
  }, [tasks, isTaskFetching]);

  const handleOnDragEnd = async result => {
    const arr = Array.from(items);
    const [reorderedItem] = arr.splice(result.source.index, 1);
    arr.splice(result.destination.index, 0, reorderedItem);

    setItems(arr);

    try {
      await reorderTasks({
        orders: arr.map(({ id }, index) => ({ task_id: id, order: index })),
      });
      onStatusChange();
    } catch (err) {
      //
    }
  };

  return (
    <>
      {items.filter(task => task.status === 'in-progress').length === 0 && (
        <div className="text-center">No task is in Progress.</div>
      )}

      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="tasks">
          {provided => (
            <ul className="list-unstyled" {...provided.droppableProps} ref={provided.innerRef}>
              {items.map((item, index) => {
                const { id, title, due_date, status, label: taskLabel } = item;
                return (
                  <Draggable key={item.id} draggableId={String(item.id)} index={index}>
                    {prov => (
                      <li ref={prov.innerRef} {...prov.draggableProps} {...prov.dragHandleProps}>
                        <Card
                          className="shadow mb-3 text-font px-4 mx-lg-5 mouse-hover"
                          key={id}
                          onClick={e => {
                            const element_list = [
                              ...document.getElementsByClassName('details'),
                            ].filter(element => element.contains(e.target));
                            if (
                              !e.target.classList.contains('details') &&
                              element_list.length === 0
                            ) {
                              handleSelectTask(id);
                            }
                          }}
                        >
                          <Card.Body className="px-0 px-xl-3">
                            <Row className="mx-0">
                              <Col xs={12} sm={12} md={6} lg={8} xl={7} className="px-0">
                                <div className="d-flex flex-row align-items-center justify-content-center justify-content-lg-start flex-wrap flex-md-no-wrap">
                                  <button
                                    type="button"
                                    className="bg-transparent border-0 text-left"
                                  >
                                    <h5 className="text-size-sm font-weight-700 mb-0">{title}</h5>
                                  </button>
                                  <h5
                                    className={`text-size-xs mb-xl-0 d-flex text-center flex-row justify-content-center align-items-center mx-lg-4 px-3 px-md-2 px-lg-3 py-2 text-primary font-weight-600 ${styles.time_box}`}
                                  >
                                    {moment(due_date).format('DD MMM')}
                                  </h5>
                                  <h6
                                    className={`text-size-xs mb-xl-0 font-weight-600 text-capitalize ${classnames(
                                      (!taskLabel?.label && styles.stat_success) ||
                                        (taskLabel?.label?.label_name === 'Ready to Progress' &&
                                          styles.stat_success) ||
                                        (taskLabel?.label?.label_name === 'Detail Missing' &&
                                          styles.stat_missing) ||
                                        (taskLabel?.label?.label_name === 'On Hold' &&
                                          styles.stat_onHold) ||
                                        (taskLabel?.label?.label_name === 'Payment issues' &&
                                          styles.stat_issues),
                                      styles.btn_structure,
                                    )}`}
                                  >
                                    {taskLabel && taskLabel.label
                                      ? taskLabel.label.label_name
                                      : status}
                                  </h6>
                                </div>
                              </Col>
                              <RoleBased
                                sectionKey={[SECTION_KEY.task_status_change_edit]}
                                acceptedRoles={[
                                  ROLES.SUPER_ADMIN,
                                  ROLES.ADMIN,
                                  ROLES.MANAGERS,
                                  ROLES.SUBSCRIBERS,
                                  ROLES.VIEWERS,
                                ]}
                              >
                                <Col
                                  xs={12}
                                  sm={12}
                                  md={6}
                                  lg={4}
                                  xl={5}
                                  className="px-0 d-flex flex-column  align-items-center align-items-lg-end"
                                >
                                  <Button
                                    className={`text-size-xs py-0 py-1 font-weight-600 details ${styles.btn_structure}`}
                                    onClick={() => {
                                      handleStatus(id);
                                    }}
                                    disabled={isLoading && processingId === id}
                                  >
                                    Move to In Review
                                  </Button>
                                </Col>
                              </RoleBased>
                            </Row>
                          </Card.Body>
                        </Card>
                      </li>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

export default InProgressSection;
