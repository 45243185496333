import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import classnames from 'classnames';
// import Media from 'react-bootstrap/Media';
// import { v4 as uuid } from 'uuid';
import { useParams } from 'react-router';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import styles from '../../styles/modules/RequestMenu.module.scss';
// import tracyBotIcon from '../../assets/icons/tracy-bot-icon.svg';
import { useFetchArchivedTasks } from '../../hooks/task.hooks';
import { useUpdateTask } from '../../hooks/requestHistory.hooks';

function ArchivedItems({ reload }) {
  const { id } = useParams();
  const [updatingId, setUpdatingId] = useState();
  const [currentIndex, setCurrentIndex] = useState(-1);
  const { data: archivedTasks, isFetching, refetch } = useFetchArchivedTasks(id);
  const { mutateAsync: updateArchive, isLoading } = useUpdateTask();

  const onHandleCurrentIndex = index => {
    setCurrentIndex(index);
  };

  const unArchived = async task_id => {
    try {
      setUpdatingId(task_id);
      const data = { 'is_archived': 0 };
      await updateArchive({ id: task_id, data });
      toast.success('Successfully moved to board.');
      setCurrentIndex(-1);
      reload();
      refetch();
    } catch (error) {
      toast.error(error?.message);
    }
  };

  return (
    <>
      <Card.Header className="text-font no-border font-weight-600">Archived Items</Card.Header>
      <Card.Body className={classnames(`d-flex flex-column`, styles.overflow_activity_box)}>
        {isFetching && (
          <Col align="center">
            <Spinner animation="border" variant="primary" size="sm" />
          </Col>
        )}

        {!isFetching &&
          archivedTasks?.map((item, index) => {
            return (
              <Card key={item.id} body className="mb-2 shadow p-0">
                <Row className="mx-0 p-0 justify-content-center">
                  <Col sm="6" className="m-0 p-0" align="left">
                    <p className="text-size-xs text-darker mb-0 single_line">{item.title}</p>
                    <p className="text-size-xxs text-darker mb-0">{item.status}</p>
                  </Col>
                  <Col sm="4" className="m-0 p-0 align-self-center">
                    <Button
                      className={classnames(
                        'text-size-xxs m-0 py-0 py-1 font-weight-600 text-capitalize justify-content-center',
                        styles.achive_btn_size,
                      )}
                      onClick={() => {
                        onHandleCurrentIndex(index);
                        unArchived(item.id);
                      }}
                      disabled={currentIndex === index}
                    >
                      {currentIndex === index && isLoading && updatingId === item.id && (
                        <Spinner animation="border" variant="light" size="sm" />
                      )}
                      {!(currentIndex === index) && 'Move to Board'}
                    </Button>
                  </Col>
                </Row>
              </Card>
            );
          })}

        {!isFetching && archivedTasks?.length === 0 && (
          <Col align="center">
            <span>No Archived Items Found</span>
          </Col>
        )}
      </Card.Body>
    </>
  );
}

export default ArchivedItems;
