import React from 'react';
import { Card, Image } from 'react-bootstrap';
import classnames from 'classnames';
import plusIcon from '../../assets/icons/plus.svg';
import styles from '../../styles/modules/PaymentMethod.module.scss';

const AddCardButton = () => {
  return (
    <Card body as="button" className={classnames(styles.add_card, 'mb-3 mr-3')}>
      <div>
        <Image src={plusIcon} alt="" width="48" height="48" />
        <h6 className="mb-0 text-secondary">Add Card</h6>
      </div>
    </Card>
  );
};

export default AddCardButton;
