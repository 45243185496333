import { useQuery, useMutation } from 'react-query';
import { updateBoardRequest } from '../requests/boards.requests';
import {
  deleteTaskRequest,
  fetchTask,
  fetchTasks,
  updateTaskRequest,
} from '../requests/tasks.request';

export const useRequestHistory = userId =>
  useQuery(
    ['requests', userId],
    async () => {
      const res = await fetchTasks(userId);
      return res.data;
    },
    {
      enabled: !!userId,
      initialData: [],
    },
  );

export const useTaskDetails = taskId =>
  useQuery(
    ['taskDetails', taskId],
    async () => {
      const res = await fetchTask(taskId);
      return res.data;
    },
    {
      enabled: !!taskId,
      initialData: {},
    },
  );

export const useUpdateTask = () =>
  useMutation(async ({ id, data }) => {
    const res = await updateTaskRequest(id, data);
    return res;
  });

export const useUpdatePrivacy = () =>
  useMutation(async ({ boardId, data }) => {
    const res = await updateBoardRequest(boardId, data);
    return res;
  });

export const useDeleteTask = () =>
  useMutation(async id => {
    const res = await deleteTaskRequest(id);
    return res;
  });
