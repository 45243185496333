import React from 'react';

function Icon({ color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="7" viewBox="0 0 15 8">
      <path fill={color} d="M7.5 0L15 8H0z" data-name="Path 3135" transform="rotate(360 7.5 4)" />
    </svg>
  );
}

export default Icon;
