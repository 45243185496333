import React, { useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import classnames from 'classnames';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import styles from '../../styles/modules/RequestMenu.module.scss';
import menuDashIcon from '../../assets/icons/menu-dash-icon.svg';
import menuSearchIcon from '../../assets/icons/menu-search-icon.svg';
import menuActivityIcon from '../../assets/icons/menu-activity-icon.svg';
import menuSettingsIcon from '../../assets/icons/menu-settings-icon.svg';
import closeIcon from '../../assets/icons/close-icon.svg';
import ActivityScreen from './ActivityScreen';
import backIcon from '../../assets/icons/back-icon.svg';
import useFetchLabels from '../../hooks/label.hooks';
import {
  useFetchProjectDetails,
  useProjectCollections,
  useUpdateProjectDetails,
} from '../../hooks/project.hooks';
import ArchivedItems from './ArchivedItems';
import UserContext from '../../contexts/UserContext';
import { useGetUserWatchList, usePostUserWatchList } from '../../hooks/user.hooks';
import RoleBased from '../../components/RoleBased';
import { ROLES, SECTION_KEY } from '../../utils/enums';

function RequestMenu({ displayMenu, onHideMenu, hideMenu, onSearch, onLabelFilter, reload }) {
  const { id } = useParams();
  const [currentScreen, setCurrentScreen] = React.useState('MenuScreen');
  const [about, setAbout] = React.useState('');
  const [search, setSearch] = React.useState('');
  const [watch, setWatch] = React.useState();
  const [selectedLabel, setSelectedLabel] = React.useState('');
  const { user } = React.useContext(UserContext);

  const { data: labels, isFetching: isLabelsFetching } = useFetchLabels();
  const { data: collections, isFetching: isCollectionsFetching } = useProjectCollections(id);
  const { data: details, isFetching: isDetailsFetching } = useFetchProjectDetails(id);
  const { mutateAsync: updateProjectDetails, isLoading } = useUpdateProjectDetails();
  const { data: watchList, isFetching: isWatchListFetching } = useGetUserWatchList(user?.id);
  const { mutateAsync: postUserWatchList, isLoading: isWatchListLoading } = usePostUserWatchList();

  useEffect(() => {
    if (currentScreen === '') setCurrentScreen('MenuScreen');
  });

  useEffect(() => {
    if (!isDetailsFetching) {
      setAbout(details?.comment);
    }
  }, [isDetailsFetching]);

  useEffect(() => {
    onSearch(search);
  }, [search]);

  useEffect(() => {
    setWatch(watchList?.filter(watchData => watchData.project_id === Number.parseInt(id, 10))[0]);
  }, [id, watchList]);

  const onWatchClicked = async () => {
    try {
      const res = await postUserWatchList({
        userId: user?.id,
        data: {
          is_watching: watch ? 0 : 1,
          project_id: id,
        },
      });
      if (res.status) {
        toast.success(res.data === null ? 'Successfully Unwatched.' : 'Successfully Watched.');
        setWatch(res.data === null ? undefined : res.data);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const menuList = [
    {
      name: 'About This board',
      icon: menuDashIcon,
    },
    {
      name: 'Search Cards',
      icon: menuSearchIcon,
    },
    {
      name: 'Activity',
      icon: menuActivityIcon,
    },
    {
      name: 'Settings',
      icon: menuSettingsIcon,
    },
    {
      name: 'Labels',
      icon: menuDashIcon,
    },
    {
      name: 'Collections',
      icon: menuSearchIcon,
    },
    {
      name: 'Archived Items',
      icon: menuActivityIcon,
    },
    {
      name: 'Watch',
      icon: menuSettingsIcon,
    },
  ];

  return (
    <>
      <Card
        className={`text-font shadow-lg ${classnames(
          styles.menu_hover,
          displayMenu && `${styles.show_menu}`,
          hideMenu && `${styles.hideMenu}`,
        )}`}
      >
        <Button
          className={`p-0 text-size-xs font-weight-600 position-absolute ${classnames(
            styles.hover_bck_btn,
            currentScreen === 'MenuScreen' && 'd-none',
          )}`}
          type="button"
          variant="light"
          onClick={() => {
            setCurrentScreen('MenuScreen');
          }}
        >
          <img src={backIcon} width="14" alt="back" />
        </Button>

        <Button
          className={`p-0 text-size-xs font-weight-600 position-absolute ${classnames(
            styles.hover_btn,
          )}`}
          type="button"
          variant="light"
          onClick={() => {
            onHideMenu();
            setCurrentScreen('');
          }}
        >
          <img src={closeIcon} alt="cancel" className={classnames(styles.close_icon_size)} />
        </Button>
        {currentScreen === 'MenuScreen' && (
          <>
            <Card.Header className="no-border font-weight-600">Menu</Card.Header>
            <Card.Body className="d-flex flex-column pt-0">
              {menuList.map(items => {
                const { name, icon } = items;
                return (
                  <RoleBased
                    key={name}
                    sectionKey={
                      name === 'Activity'
                        ? [SECTION_KEY.task_description_history_of_changes_full_view]
                        : [SECTION_KEY.all]
                    }
                  >
                    <Card.Link
                      className={classnames(
                        `mouse-hover py-2 text-left  mx-0 mx-3`,
                        styles.border_bottom,
                        name === 'About This board' && `d-none`,
                        name === 'Search Cards' && `d-none`,
                        name === 'Collections' && `d-none`,
                        name === 'Labels' && `d-none`,
                      )}
                      onClick={() => {
                        return name === 'Activity'
                          ? setCurrentScreen('ActivityScreen')
                          : name === 'Archived Items'
                          ? setCurrentScreen('ArchivedItems')
                          : name === 'Watch'
                          ? onWatchClicked()
                          : setCurrentScreen('MenuScreen');
                      }}
                    >
                      <img
                        src={icon}
                        alt="search"
                        className={classnames(
                          name === 'About This board' && `d-none`,
                          name === 'Search Cards' && `d-none`,
                          name === 'Collections' && 'd-none',
                          name === 'Labels' && `d-none`,
                        )}
                      />
                      {name === 'Watch' && (isWatchListFetching || isWatchListLoading) && (
                        <span className="pl-3">
                          <Spinner size="sm" variant="primary" animation="border" />
                        </span>
                      )}
                      {name === 'Watch' && !(isWatchListFetching || isWatchListLoading) && (
                        <span
                          className={classnames(
                            'text-size-xs pl-3 text-darker',
                            name === 'About This board' && `d-none`,
                            name === 'Search Cards' && `d-none`,
                            name === 'Collections' && 'd-none',
                            name === 'Labels' && `d-none`,
                          )}
                        >
                          {watch ? 'Unwatch' : 'Watch'}
                        </span>
                      )}
                      {name !== 'Watch' && (
                        <span
                          className={classnames(
                            'text-size-xs pl-3 text-darker',
                            name === 'About This board' && `d-none`,
                            name === 'Search Cards' && `d-none`,
                            name === 'Collections' && 'd-none',
                            name === 'Labels' && `d-none`,
                          )}
                        >
                          {name}
                        </span>
                      )}
                    </Card.Link>
                    {name === 'About This board' && (
                      <Accordion
                        defaultActiveKey=""
                        className={`text-left py-2 mx-0 mx-3 ${styles.border_bottom}`}
                      >
                        <Accordion.Toggle
                          as="button"
                          eventKey="0"
                          className="no-border px-0 bg-white"
                        >
                          <img src={icon} alt="search" />
                          <span className="text-size-xs pl-3 text-darker">{name}</span>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            {(isDetailsFetching || isLoading) && (
                              <Row>
                                <Col align="center">
                                  <Spinner size="sm" variant="primary" animation="border" />
                                </Col>
                              </Row>
                            )}
                            {!(isDetailsFetching || isLoading) && (
                              <>
                                <Form.Control
                                  placeholder="Add Notes"
                                  as="textarea"
                                  className="text-size-xxs font-weight-500"
                                  value={about}
                                  onChange={e => {
                                    setAbout(e.target.value);
                                  }}
                                  onKeyPress={async e => {
                                    if (e.key === 'Enter' || e.keyCode === 13) {
                                      try {
                                        const res = await updateProjectDetails({
                                          projectId: id,
                                          details: {
                                            comment: about,
                                          },
                                        });
                                        setAbout(res.data?.comment);
                                        toast.success('Updated successfully.');
                                      } catch (err) {
                                        toast.error(err.message);
                                      }
                                    }
                                  }}
                                />
                                <span className="text-size-xxs font-weight-400">
                                  Press enter to save
                                </span>
                              </>
                            )}
                          </Card.Body>
                        </Accordion.Collapse>
                      </Accordion>
                    )}
                    {name === 'Search Cards' && (
                      <>
                        <Accordion
                          defaultActiveKey=""
                          className={`text-left py-2 mx-0 mx-3 ${styles.border_bottom}`}
                        >
                          <Accordion.Toggle
                            as="button"
                            eventKey="1"
                            className="no-border px-0 bg-white"
                          >
                            <img src={icon} alt="search" />
                            <span className="text-size-xs pl-3 text-darker">{name}</span>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="1">
                            <Card.Body>
                              <Form.Control
                                placeholder="Search Cards"
                                className="text-size-xxs font-weight-500"
                                value={search}
                                onChange={e => {
                                  setSearch(e.target.value);
                                }}
                              />
                            </Card.Body>
                          </Accordion.Collapse>
                        </Accordion>
                      </>
                    )}
                    {name === 'Collections' && (
                      <>
                        <Accordion
                          defaultActiveKey=""
                          className={`text-left py-2 mx-0 mx-3 ${styles.border_bottom}`}
                        >
                          <Accordion.Toggle
                            as="button"
                            eventKey="1"
                            className="no-border px-0 bg-white"
                          >
                            <img src={icon} alt="search" />
                            <span className="text-size-xs pl-3 text-darker">{name}</span>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="1">
                            <Card.Body>
                              {isCollectionsFetching && (
                                <Col align="center">
                                  <Spinner animation="border" variant="primary" size="sm" />
                                </Col>
                              )}
                              {!isCollectionsFetching &&
                                collections?.map(collection => (
                                  <Row key={collection.id}>
                                    <Card.Link
                                      style={{
                                        width: '100%',
                                      }}
                                      className={classnames(`mouse-hover py-2 text-left mx-0`)}
                                    >
                                      <span className="text-size-xs pl-3 text-darker">
                                        {collection.collection.title}
                                      </span>
                                    </Card.Link>
                                  </Row>
                                ))}
                              {!isCollectionsFetching && collections?.length === 0 && (
                                <Col align="center">
                                  <span className="text-size-xs pl-3 text-darker">
                                    No Collection Found
                                  </span>
                                </Col>
                              )}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Accordion>
                      </>
                    )}
                    {name === 'Labels' && (
                      <>
                        <Accordion
                          defaultActiveKey=""
                          className={`text-left py-2 mx-0 mx-3 ${styles.border_bottom}`}
                        >
                          <Accordion.Toggle
                            as="button"
                            eventKey="1"
                            className="no-border px-0 bg-white"
                          >
                            <img src={icon} alt="search" />
                            <span className="text-size-xs pl-3 text-darker">{name}</span>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="1">
                            <Card.Body>
                              {isLabelsFetching && (
                                <Col align="center">
                                  <Spinner animation="border" variant="primary" size="sm" />
                                </Col>
                              )}
                              {!isLabelsFetching &&
                                labels?.map(label => (
                                  <Row key={label.id}>
                                    <Card.Link
                                      style={{
                                        width: '100%',
                                      }}
                                      className={classnames(
                                        `mouse-hover py-2 text-left mx-0 ${
                                          selectedLabel === label.label_name
                                            ? 'font-weight-600'
                                            : ''
                                        }`,
                                      )}
                                      onClick={() => {
                                        if (
                                          selectedLabel === '' ||
                                          selectedLabel !== label.label_name
                                        ) {
                                          setSelectedLabel(label.label_name);
                                          onLabelFilter(label.label_name);
                                        } else {
                                          setSelectedLabel('');
                                          onLabelFilter(undefined);
                                        }
                                      }}
                                    >
                                      <span className="text-size-xs pl-3 text-darker">
                                        {label.label_name}
                                      </span>
                                    </Card.Link>
                                  </Row>
                                ))}
                              {!isLabelsFetching && labels?.length === 0 && (
                                <Col align="center">
                                  <span className="text-size-xs pl-3 text-darker">
                                    No Collection Found
                                  </span>
                                </Col>
                              )}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Accordion>
                      </>
                    )}
                  </RoleBased>
                );
              })}
              <Card.Text className="text-size-xs text-secondary text-left p-4">
                All members of the team can see and edit this board.
              </Card.Text>
            </Card.Body>
          </>
        )}
        <RoleBased
          sectionKey={[SECTION_KEY.task_description_history_of_changes_full_view]}
          acceptedRoles={[
            ROLES.SUPER_ADMIN,
            ROLES.ADMIN,
            ROLES.MANAGERS,
            ROLES.SUBSCRIBERS,
            ROLES.VIEWERS,
          ]}
        >
          {currentScreen === 'ActivityScreen' && <ActivityScreen />}
        </RoleBased>
        {currentScreen === 'ArchivedItems' && <ArchivedItems reload={reload} />}
      </Card>
    </>
  );
}

export default RequestMenu;
