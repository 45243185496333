import React, { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import classnames from 'classnames';
import RoleBased from './RoleBased';
import { ROLES, SECTION_KEY } from '../utils/enums';
import InProgressButton from './InProgressButton';

const RequestItemCard = ({
  id,
  title,
  status,
  role,
  label: taskLabel,
  handleStatus,
  isLoading,
  handleSelectTask,
  styles,
  processingId,
}) => {
  const [code, setCode] = React.useState(status);

  useEffect(() => {
    if (taskLabel && taskLabel.label) {
      setCode(taskLabel.label.label_name);
    } else {
      setCode(status);
    }
  }, [taskLabel]);

  return (
    <Card
      className="shadow mb-3 text-font px-4 mx-lg-5 mouse-hover"
      key={id}
      onClick={e => {
        const element_list = [
          ...document.getElementsByClassName('details'),
          ...document.getElementsByClassName('ant-picker-dropdown'),
        ].filter(element => element.contains(e.target));
        if (!e.target.classList.contains('details') && element_list.length === 0) {
          handleSelectTask(id);
        }
      }}
    >
      <Card.Body className="px-0 px-xl-3">
        <Row className="mx-0">
          <Col
            className="px-0 pb-3 pb-lg-0 d-flex flex-column justify-content-center text-center text-lg-left"
            xs={12}
            sm={12}
            md={12}
            lg={5}
            xl={6}
          >
            {/* <button type="button" className="bg-transparent border-0 text-left"> */}
            <h5 className="text-size-sm font-weight-700 mb-0">{title}</h5>
            {/* </button> */}
          </Col>
          <Col className="px-0" xs={12} sm={12} md={12} lg={7} xl={6}>
            <Row
              className={`mx-0 d-flex justify-content-center justify-content-lg-end align-items-center ${classnames(
                role === 'client' && '',
              )}`}
            >
              <p
                className={`text-size-xs underline-text m-0 py-0 py-1 font-weight-600 text-capitalize ${classnames(
                  (code === 'request' && styles.stat_success) ||
                    (code === 'Ready to Progress' && styles.stat_success) ||
                    (code === 'Detail Missing' && styles.stat_missing) ||
                    (code === 'On Hold' && styles.stat_onHold) ||
                    (code === 'Payment issues' && styles.stat_issues),
                  styles.btn_structure,
                )}`}
              >
                {code}
              </p>
              <RoleBased
                sectionKey={[SECTION_KEY.task_status_change_edit]}
                acceptedRoles={[
                  ROLES.SUPER_ADMIN,
                  ROLES.ADMIN,
                  ROLES.MANAGERS,
                  ROLES.SUBSCRIBERS,
                  ROLES.VIEWERS,
                ]}
              >
                {role !== 'client' ? (
                  <InProgressButton
                    id={id}
                    className="details"
                    isLoading={isLoading && processingId === id}
                    styles={styles}
                    onSave={dueDate => {
                      handleStatus(id, dueDate);
                    }}
                  />
                ) : null}
              </RoleBased>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default RequestItemCard;
