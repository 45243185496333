import React from 'react';
import { Card, Col, Row, ProgressBar } from 'react-bootstrap';
import { useParams } from 'react-router';
import { useMedia } from '../../hooks/medias.hooks';
import AssetBreadCrumbs from './AssetBreadCrumbs';
import MediaDetails from './MediaDetails';

function AssetMedia() {
  const { mediaId } = useParams();

  const { data: media, isFetching } = useMedia(mediaId);

  return (
    <>
      <Card.Header className="my-4 bg-white px-0 px-md-3">
        <Row className="mx-0 pb-4">
          <Col xs={12} sm={12} md={12} xl={8} lg={{ order: 2, span: 8 }} className="py-2 py-lg-0">
            <Card.Title className="text-darker text-center font-weight-600">
              {/* {asset.folder_name} */}
            </Card.Title>
          </Col>
          <Col
            xs={6}
            sm={6}
            md={6}
            xl={2}
            lg={{ order: 1, span: 2 }}
            className=" d-flex flex-column align-items-center justify-content-center"
          />
          <Col
            xs={6}
            sm={6}
            md={6}
            xl={2}
            lg={{ order: 3, span: 2 }}
            className="d-flex flex-column align-items-center justify-content-center"
          />
        </Row>
      </Card.Header>

      <Card.Body>
        <AssetBreadCrumbs isInMedia />

        {isFetching && <ProgressBar animated variant="primary" now={100} className="mb-3" />}

        {!isFetching && <MediaDetails media={media} />}
      </Card.Body>
    </>
  );
}

export default AssetMedia;
