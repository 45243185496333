import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { Button, Card, Col, Form, ProgressBar, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import RoleBased from '../../components/RoleBased';
import { useSubAssets } from '../../hooks/assets.hooks';
import { ROLES, SECTION_KEY } from '../../utils/enums';
import CreateAsset from './CreateAsset';
import SubAsset from './SubAsset';
import AddAssetButton from '../../components/Assets/AddAssetButton';
import { useAssetMedias, useCreateAssetMedia } from '../../hooks/asset-medias.hooks';
import { useCreateMedia } from '../../hooks/medias.hooks';
import PhotoCard from '../../components/Assets/PhotoCard';
import AssetBreadCrumbs from './AssetBreadCrumbs';
import AssetContext from '../../contexts/AssetContext';

function AssetDetails() {
  const { path, push } = React.useContext(AssetContext);
  const [assetId, setAssetId] = React.useState(null);

  const history = useHistory();

  React.useEffect(() => {
    if (Array.isArray(path) && path.length > 0) {
      setAssetId(path[path.length - 1].id);
    } else if (path.length === 0) {
      history.push('/dashboard/assets');
    }
  }, [path]);

  const { data: subassets, isFetching } = useSubAssets(assetId);
  const { data: medias, isFetching: isFetchingMedias } = useAssetMedias(assetId);

  const [show, setShow] = React.useState(false);
  const toggle = () => setShow(!show);

  const [sortKey, setSortKey] = React.useState('unsorted');
  const handleChangeSortKey = event => setSortKey(event.target.value);

  const sortFn = (a, b) => {
    if (sortKey === 'sort_by_name') {
      return String(a.folder_name).localeCompare(String(b.folder_name));
    }

    if (sortKey === 'sort_by_created') {
      return moment(a.created_at).diff(moment(b.created_at));
    }

    if (sortKey === 'sort_by_modified') {
      return moment(a.updated_at).diff(moment(b.updated_at));
    }

    return a.id - b.id;
  };

  const handleClick = asset => {
    push({
      name: asset.folder_name,
      id: asset.id,
    });
  };

  const refs = subassets.sort(sortFn).reduce((acc, value) => {
    acc[value.id] = React.createRef();
    return acc;
  }, {});

  const [scrollToAsset, setScrollToAsset] = React.useState(null);
  const handleOnAdd = id => setScrollToAsset(id);

  React.useEffect(() => {
    if (scrollToAsset && refs?.[scrollToAsset] !== undefined) {
      refs[scrollToAsset].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      setScrollToAsset(null);
    }
  }, [refs, scrollToAsset]);

  const queryClient = useQueryClient();

  const { mutateAsync: createMedia, isLoading: isCreatingMedia } = useCreateMedia();
  const { mutateAsync: createAssetMedia, isLoading: isCreatingAssetMedia } = useCreateAssetMedia();

  const handleOnAddAsset = async files => {
    files.forEach(async file => {
      try {
        const formData = new FormData();
        formData.append('file', file);

        const res = await createMedia(formData);

        await createAssetMedia({
          assetId,
          mediaId: res.data.id,
        });

        queryClient.invalidateQueries(['asset-media', assetId]);
      } catch (err) {
        toast.error(err?.message);
      }
    });
  };

  const handleMediaClick = (id, media) => {
    push({
      id: media?.id,
      name: media?.file_name,
      type: 'media',
      assetMediaId: id,
    });
    history.push(`/dashboard/assets/media/${media?.id}`);
  };

  return (
    <>
      <Card.Header className="my-4 bg-white px-0 px-md-3">
        <Row className="mx-0 pb-4">
          <Col xs={12} lg={{ order: 2, span: 8 }} className="py-2 py-lg-0">
            <Card.Title className="text-size-md text-darker text-center font-weight-600">
              My Assets
            </Card.Title>
            <Card.Subtitle className="text-size-xs text-secondary text-center font-weight-400">
              <span className="font-weight-600">*Note</span>
              {': Include any '}
              <span className="font-weight-600">Logo, Branding guidelines</span>
              {' for repeating requests.'}
            </Card.Subtitle>
          </Col>
          <Col
            xs={6}
            lg={{ order: 1, span: 2 }}
            className=" d-flex flex-column align-items-center justify-content-center"
          >
            <RoleBased
              sectionKey={[SECTION_KEY.board_assets_add]}
              acceptedRoles={[ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.SUBSCRIBERS, ROLES.VIEWERS]}
            >
              <Button
                className="font-weight-bold d-flex flex-row align-items-center px-4"
                onClick={toggle}
              >
                <div className="text-size-xs pr-2 font-weight-600">New</div>
                <div className="">
                  <FontAwesomeIcon icon={faPlusCircle} color="white" size="md" />
                </div>
              </Button>
            </RoleBased>
          </Col>
          <Col
            xs={6}
            lg={{ order: 3, span: 2 }}
            className="d-flex flex-column align-items-center justify-content-center"
          >
            <Form.Control custom as="select" value={sortKey} onChange={handleChangeSortKey}>
              <option value="unsorted">Unsorted</option>
              <option value="sort_by_name">Sort by Name</option>
              <option value="sort_by_modified">Sort by Modified</option>
              <option value="sort_by_created">Sort by Created</option>
            </Form.Control>
          </Col>
        </Row>
      </Card.Header>

      <Card.Body>
        <AssetBreadCrumbs />

        {(isFetching || isFetchingMedias) && (
          <ProgressBar animated variant="primary" now={100} className="mb-3" />
        )}

        <Row className="mb-3">
          <Col sm={12} md={6} lg={6} xl={3} className="mb-3">
            <AddAssetButton
              onAdd={handleOnAddAsset}
              isLoading={isCreatingMedia || isCreatingAssetMedia}
            />
          </Col>

          {medias.map(({ id, media }) => (
            <Col sm={12} md={6} lg={6} xl={3} key={id} className="mb-3">
              <PhotoCard
                title={media?.file_name}
                url={media?.file_path}
                onClick={() => handleMediaClick(id, media)}
              />
            </Col>
          ))}
        </Row>

        <Row>
          {subassets.sort(sortFn).map(asset => (
            <Col sm={12} lg={6} xl={4} key={asset.id} ref={refs[asset.id]} className="mb-3">
              <SubAsset
                name={asset.folder_name}
                parentId={assetId}
                subassets={asset.sub_assets}
                subAssetId={asset.id}
                onClick={() => handleClick(asset)}
              />
            </Col>
          ))}
        </Row>

        <CreateAsset
          show={show}
          onClose={toggle}
          isSubFolder
          assetParentId={assetId}
          onAdd={handleOnAdd}
        />
      </Card.Body>
    </>
  );
}

export default AssetDetails;
