import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from '../styles/modules/UnsortedMemberListButton.module.scss';
import TickIcon from '../assets/icons/TickIcon';
import StatusDropdownIcon from '../assets/icons/StatusdropdownIcon';

function UnsortedMemberListButton({ setSortBy, setSortOrder, sortOrder }) {
  const [categories, setCategories] = React.useState('Unsorted');
  const handleCategory = key => setCategories(key);

  return (
    <Dropdown className="">
      <Dropdown.Toggle
        className={` ${styles.unsorted_dropdown_toggle} d-flex flex-row align-items-center px-2 py-1 mb-2 mx-2 justify-content-between`}
      >
        <div className="text-size-xs pr-5 font-weight-600">{categories}</div>
        {/* <img src={unsortedIcon} alt="unsorted-arrow" /> */}
        <StatusDropdownIcon />
      </Dropdown.Toggle>
      <Dropdown.Menu className={`mt-1 text-font ${styles.unsorted_menu}`}>
        <Dropdown.Item
          className={`${styles.unsorted_item} px-0 px-2`}
          onSelect={() => {
            setSortBy('');
            setSortOrder(undefined);
            return handleCategory('Unsorted');
          }}
        >
          <Row className="mx-0 align-items-center">
            <Col className="px-0 pl-1" xs={2} sm={2} md={2} lg={2} xl={2}>
              {categories === 'Unsorted' ? <TickIcon color="" /> : null}
            </Col>
            <Col className="px-0 ">
              <span className={` ${styles.custom_text_size_09}`}>Unsorted</span>
            </Col>
          </Row>
        </Dropdown.Item>
        <Dropdown.Item
          className={`${styles.unsorted_item} px-0 px-2`}
          onSelect={() => {
            setSortBy('name');
            setSortOrder(
              categories !== 'Sort by Name' ? 'ASC' : sortOrder === 'DESC' ? 'ASC' : 'DESC',
            );
            return handleCategory('Sort by Name');
          }}
        >
          <Row className="mx-0 align-items-center">
            <Col className="px-0 pl-1" xs={2} sm={2} md={2} lg={2} xl={2}>
              {categories === 'Sort by Name' ? <TickIcon color="" /> : null}
            </Col>
            <Col className="px-0">
              <span className={` ${styles.custom_text_size_09}`}>Sort by Name</span>
            </Col>
          </Row>
        </Dropdown.Item>
        <Dropdown.Item
          className={`${styles.unsorted_item} px-0 px-2`}
          onSelect={() => {
            setSortBy('updated');
            setSortOrder(
              categories !== 'Sort by Modified' ? 'ASC' : sortOrder === 'DESC' ? 'ASC' : 'DESC',
            );
            return handleCategory('Sort by Modified');
          }}
        >
          <Row className="mx-0 align-items-center">
            <Col className="px-0 pl-1" xs={2} sm={2} md={2} lg={2} xl={2}>
              {categories === 'Sort by Modified' ? <TickIcon color="" /> : null}
            </Col>
            <Col className="px-0">
              <span className={` ${styles.custom_text_size_09}`}>Sort by Modified</span>
            </Col>
          </Row>
        </Dropdown.Item>
        <Dropdown.Item
          className={`${styles.unsorted_item} px-0 px-2`}
          onSelect={() => {
            setSortBy('created');
            setSortOrder(
              categories !== 'Sort by Created' ? 'ASC' : sortOrder === 'DESC' ? 'ASC' : 'DESC',
            );
            return handleCategory('Sort by Created');
          }}
        >
          <Row className="mx-0 align-items-center ">
            <Col className="px-0 pl-1" xs={2} sm={2} md={2} lg={2} xl={2}>
              {categories === 'Sort by Created' ? <TickIcon color="" /> : null}
            </Col>
            <Col className="px-0">
              <span className={` ${styles.custom_text_size_09}`}>Sort By Created</span>
            </Col>
          </Row>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default UnsortedMemberListButton;
