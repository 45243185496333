import { useQuery, useMutation } from 'react-query';
import { fetchFeeds, markAsReadFeeds } from '../requests/feeds.requests';

export const useFeeds = currentPage =>
  useQuery(
    ['feeds', currentPage],
    async () => {
      const res = await fetchFeeds(currentPage);
      return res.data;
    },
    {
      initialData: [],
    },
  );

export const useOnMarkFeeds = () =>
  useMutation(async ({ id, data }) => {
    const res = await markAsReadFeeds(id, data);
    return res;
  });
