import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Form, Image, Spinner, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { Link, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import UserContext from '../contexts/UserContext';
import { useLogin } from '../hooks/auth.hooks';
import { fetchUserProfile } from '../requests/auth.requests';
import styles from '../styles/modules/Login.module.scss';
import FlyKimpBanner from '../assets/images/fly-monkey.png';
import mainIcon from '../assets/icons/final-main-logo.svg';

const schema = yup.object({
  email: yup.string().required('Required').email('Must be a valid email'),
  password: yup.string().required('Required').min(6, 'Must be minimum of 6 characters'),
});

function Login() {
  const { updateUser } = React.useContext(UserContext);
  const location = useLocation();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(function prefillEmail() {
    document.onreadystatechange = () => {
      if (document.readyState === 'complete') {
        setLoader(false);
      }

      if (document.readyState === 'interactive') {
        setLoader(true);
      }
    };

    if (location?.query?.token_id) {
      setValue('email', location.query.email);
    }
  }, []);

  const { mutateAsync: login, isLoading } = useLogin();

  const onSubmit = async data => {
    try {
      const { message, access_token } = await login(data);
      localStorage.setItem('kimp-authtoken', access_token);
      const res = await fetchUserProfile();

      toast.success(message);
      updateUser(res.data);

      const query = new URLSearchParams();
      query.append('login', res.data?.username);
      query.append('redirect_url', process.env.REACT_APP_SITE_URL);
      window.open(`${process.env.REACT_APP_WP_URL}?${query.toString()}`, '_self');
    } catch (err) {
      localStorage.clear();
      toast.error(err?.message);
    }
  };

  return (
    <main className={classnames('text-font h-vh-100', styles.background_image)}>
      {loader && (
        <Row className="justify-content-center align-item-center h-vh-100">
          <Col align="center" className="my-auto">
            <Spinner variant="light" animation="border" />
          </Col>
        </Row>
      )}
      {!loader && (
        <>
          <Row className="mx-0 justify-content-between pt-3 pt-md-0">
            <div className="align-self-center pl-3">
              <Image
                src={FlyKimpBanner}
                className={classnames('img-fluid ', styles.login_kimp_icon)}
              />
            </div>
            <div className="pb-4 pt-4 pr-4 pr-md-5 text-right">
              <img src={mainIcon} alt="logo" className="main-logo-icon-size" />
            </div>
          </Row>

          <Container className="text-font pt-md-2 pb-0">
            <Row className="mt-4 mt-md-0 pb-md-0">
              <Col xs={12} md={6} lg={6} className="pl-md-0 pr-5 mt-5 mt-md-0 d-none d-md-block">
                <Image src={FlyKimpBanner} className="img-fluid pr-5" />
              </Col>
              <Col
                xs={12}
                md={6}
                lg={6}
                className=" d-flex flex-column justify-content-center px-xl-5"
              >
                <div className="px-xl-5">
                  <Card
                    body
                    className={classnames(
                      'px-4 px-lg-5 py-lg-2',
                      styles.card_border_radius,
                      styles.card_shadow,
                    )}
                  >
                    <div className="text-center pb-3 mt-3 mb-3">
                      <h3 className="font-weight-bold">Login</h3>
                    </div>

                    <Form className="mb-3" onSubmit={handleSubmit(onSubmit)}>
                      <Form.Group controlId="email" className="mb-0 mb-4">
                        <Form.Label className="font-weight-bold text-secondary mb-0">
                          Email <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          {...register('email')}
                          type="email"
                          name="email"
                          isInvalid={errors?.email}
                          className={classnames(
                            'px-0 text-size-sm font-weight-500 remove-focus',
                            styles.custom_bottom_border,
                            styles.active_fields,
                          )}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors?.email?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="password" className="mb-0 mb-4">
                        <Form.Label className="font-weight-bold text-secondary mb-0">
                          Password <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          {...register('password')}
                          type="password"
                          name="password"
                          isInvalid={errors?.password}
                          className={classnames(
                            'px-0 text-size-sm font-weight-500 remove-focus',
                            styles.custom_bottom_border,
                            styles.active_fields,
                          )}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors?.password?.message}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <div className="mb-4 text-center">
                        <Link
                          to="/forgot-password"
                          className="text-primary font-weight-bold pl-2 pl-md-0 mb-3"
                        >
                          Forgot Password?
                        </Link>
                      </div>

                      <Button
                        type="submit"
                        variant="warning"
                        className={classnames('font-weight-bold', styles.custom_btn)}
                        size="lg"
                        block
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <Spinner size="sm" variant="light" animation="border" className="" />
                        ) : (
                          <p className={classnames('mb-0', styles.login_text)}>Login</p>
                        )}
                      </Button>
                    </Form>
                  </Card>
                </div>
              </Col>
            </Row>
            <p
              className={classnames(
                'text-size-sm mt-5 text-center mb-0',
                styles.custom_white_color,
              )}
            >
              COPYRIGHT @ 2021 <strong>Kimp360.io</strong>, All rights Reserved
            </p>
          </Container>
        </>
      )}
    </main>
  );
}

export default Login;
