import React from 'react';

function Icon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="-2 0 30 26"
      className={className}
    >
      <g data-name="Group 754">
        <g data-name="Group 753">
          <path
            fill="#969696"
            d="M13 0a13 13 0 1013 13A12.993 12.993 0 0013 0zm0 23.969A10.969 10.969 0 1123.969 13 10.963 10.963 0 0113 23.969z"
            data-name="Path 1122"
          />
        </g>
      </g>
      <g data-name="Group 756" transform="translate(8.069 8.069)">
        <g data-name="Group 755">
          <path
            fill="#969696"
            d="M172.109 170.617l-3.13-3.13 3.13-3.13a1.055 1.055 0 10-1.493-1.493l-3.13 3.13-3.13-3.13a1.055 1.055 0 10-1.493 1.493l3.13 3.13-3.13 3.13a1.055 1.055 0 101.493 1.493l3.13-3.13 3.13 3.13a1.055 1.055 0 101.493-1.493z"
            data-name="Path 1123"
            transform="translate(-162.555 -162.556)"
          />
        </g>
      </g>
    </svg>
  );
}

export default Icon;
