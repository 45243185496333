import React from 'react';

const defaultValue = {
  path: [],
  push: () => {},
  pop: () => {},
  goto: () => {},
  reset: () => {},
  replace: () => {},
};

const AssetContext = React.createContext(defaultValue);

export default AssetContext;
