import React from 'react';
import { Form, Row, Col, ProgressBar, Spinner, Card } from 'react-bootstrap';
import classnames from 'classnames';
import { useHistory } from 'react-router';
import moment from 'moment';
import styles from '../../styles/modules/ServiceBoards.module.scss';
import { useBoards } from '../../hooks/boards.hooks';
import { useCollections } from '../../hooks/collection.hooks';
import UserContext from '../../contexts/UserContext';
import BoardsCard from '../Support/BoardsCard';
import { useGetUserFavorites } from '../../hooks/user.hooks';

function FavoriteBoards() {
  const history = useHistory();

  const { user } = React.useContext(UserContext);

  const { data: boards, isFetching } = useBoards(user?.id);
  const { data: favorites, isFetching: isFetchingFavorites } = useGetUserFavorites(user?.id);

  const { data: collections, isLoading: isFetchingCollections } = useCollections();

  const [search, setSearch] = React.useState('');
  const handleSearchChange = e => setSearch(e.target.value);

  const [sortKey, setSortKey] = React.useState('unsorted');
  const [filterCollection, setFilterCollection] = React.useState(null);
  const toggleSortKey = key => (key === sortKey ? setSortKey(null) : setSortKey(key));
  const toggleFilterCollection = id =>
    id === filterCollection ? setFilterCollection(null) : setFilterCollection(id);

  const filterFavorite = ({ id }) => {
    let isFavorite = false;
    for (let i = 0; i < favorites.length; i += 1) {
      const favorite = favorites[i];
      if (favorite.project_id === id) {
        isFavorite = true;
        break;
      }
    }

    return isFavorite;
  };

  const filterFn = ({ title }) => {
    if (search === '') {
      return true;
    }

    return String(title).toLowerCase().includes(search.toLowerCase());
  };

  const filterByCollection = ({ collections: clns }) => {
    if (filterCollection) {
      return clns.map(({ collection_id }) => collection_id).includes(filterCollection);
    }

    return true;
  };

  const sortFn = (a, b) => {
    if (sortKey === 'alphabetically') {
      return String(a.title).localeCompare(String(b.title));
    }

    if (sortKey === 'sort_by_last_active') {
      return moment(b.updated_at).diff(moment(a.updated_at));
    }

    if (sortKey === 'sort_by_members') {
      return (b?.team?.memberCount ?? 0) - (a?.team?.memberCount ?? 0);
    }

    if (sortKey === 'sort_by_stars') {
      return Number.parseInt(a.totalStars, 10) - Number.parseInt(b.totalStars, 10);
    }

    return 0;
  };

  return (
    <Card>
      <Card.Header className="bg-white py-4">
        <Card.Title className="text-dark text-center font-weight-600">Favorite Boards</Card.Title>
      </Card.Header>
      <Card.Body className="">
        <Row className="mt-3">
          <Col sm={12} md={4} lg={3} className="border-right">
            <Form>
              <Form.Group controlId="searchForBoards">
                <Col xs="auto" className="mx-0 px-0">
                  <Form.Control
                    size="sm"
                    type="email"
                    placeholder="Search for boards"
                    value={search}
                    onChange={handleSearchChange}
                  />
                </Col>
              </Form.Group>
            </Form>
            <div className={styles.boards_sidebar}>
              <h5 className={`${styles.section_heading} text-size-xs`}>SORT</h5>
              <button
                type="button"
                className={classnames(
                  'd-block bg-transparent border-0 text-size-xxs',
                  sortKey === 'sort_by_last_active' && 'font-weight-bold',
                )}
                onClick={() => toggleSortKey('sort_by_last_active')}
              >
                Most Recently Active
              </button>
              <button
                type="button"
                className={classnames(
                  'd-block bg-transparent border-0 text-size-xxs',
                  sortKey === 'sort_by_members' && 'font-weight-bold',
                )}
                onClick={() => toggleSortKey('sort_by_members')}
              >
                Number of Members
              </button>
              <button
                type="button"
                className={classnames(
                  'd-block bg-transparent border-0 text-size-xxs',
                  sortKey === 'sort_by_stars' && 'font-weight-bold',
                )}
                onClick={() => toggleSortKey('sort_by_stars')}
              >
                Number of Stars
              </button>
              <button
                type="button"
                className={classnames(
                  'd-block bg-transparent border-0 text-size-xxs',
                  sortKey === 'alphabetically' && 'font-weight-bold',
                )}
                onClick={() => toggleSortKey('alphabetically')}
              >
                Alphabetical
              </button>

              <h5 className={`${styles.section_heading} text-size-xxs mt-3`}>
                <span className="mr-2">FILTER BY COLLECTION</span>
                {isFetchingCollections && (
                  <Spinner animation="border" variant="primary" size="sm" />
                )}
              </h5>
              {collections.map(({ id, title }) => (
                <button
                  key={id}
                  type="button"
                  className={classnames(
                    'd-block bg-transparent border-0 text-size-xxs',
                    filterCollection === id && 'font-weight-bold',
                  )}
                  onClick={() => toggleFilterCollection(id)}
                >
                  {title}
                </button>
              ))}
            </div>
          </Col>

          <Col sm={12} md={8} lg={9}>
            {(isFetching || isFetchingFavorites) && (
              <ProgressBar animated variant="primary" now={100} className="mb-3" />
            )}
            <Row>
              {boards?.filter(filterFavorite)?.filter(filterFn)?.filter(filterByCollection)
                ?.length === 0 ? (
                <Col sm={12}>
                  <p className="text-muted text-center">No boards found!</p>
                </Col>
              ) : null}
              {boards
                ?.filter(filterFavorite)
                ?.filter(filterFn)
                ?.filter(filterByCollection)
                ?.sort(sortFn)
                ?.map(({ id, title }) => (
                  <Col key={id} sm={12} md={12} lg={4} xl={3} className="mb-3">
                    <BoardsCard
                      noAddCollection
                      noEditBoard
                      onClick={() => history.push(`/dashboard/request-history/${id}`)}
                      boardId={id}
                      heading={title}
                      user={[]}
                    />
                  </Col>
                ))}
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default FavoriteBoards;
