import { useMutation, useQuery } from 'react-query';
import {
  getRolePermission,
  getRoles,
  getRolesByUserId,
  postRoles,
  putRolesByUserId,
} from '../requests/roles.requests';

export const useRoles = () =>
  useQuery(
    ['roles'],
    async () => {
      const res = await getRoles();
      return res.data;
    },
    {
      initialData: [],
    },
  );

export const usePostRoles = () =>
  useMutation(async data => {
    const res = await postRoles(data);
    return res;
  });

export const useRolesByUserId = () =>
  useMutation(async ({ userId, data }) => {
    const res = await putRolesByUserId(userId, data);
    return res;
  });

export const useFetchRolesById = userId =>
  useQuery(
    ['roles', userId],
    async () => {
      const res = await getRolesByUserId(userId);
      return res.data;
    },
    {
      initialData: [],
      enabled: Boolean(userId),
    },
  );

export const useRolePermission = name =>
  useQuery(
    ['roles_permission', name],
    async () => {
      const res = await getRolePermission(name);
      return res;
    },
    {
      initialData: {},
      enabled: Boolean(name),
    },
  );
