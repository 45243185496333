import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import classnames from 'classnames';

const LabellessSwitch = ({ value = false, onChange, className }) => {
  const [checked, setValue] = React.useState(false);

  React.useEffect(() => {
    if (onChange) {
      onChange(checked);
    }
  }, [checked]);

  React.useEffect(() => {
    setValue(value);
  }, [value]);

  return (
    <button
      type="button"
      className={classnames(className, 'switch-labelless', checked ? 'is-checked' : '')}
      onClick={() => setValue(!checked)}
    >
      <div>
        <FontAwesomeIcon icon={faCheck} size="sm" />
      </div>
    </button>
  );
};

export default LabellessSwitch;
