import * as React from 'react';

function BackIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 473.654 473.654"
      {...props}
      width={20}
      height={20}
    >
      <circle cx={236.827} cy={236.827} r={236.827} fill="#219186" />
      <path
        d="M338.465 207.969H208.006c11.08-11.08 22.161-22.161 33.241-33.245 25.56-25.56-14.259-65.084-39.883-39.456l-81.029 81.033c-10.841 10.841-10.549 28.907.213 39.669 27.011 27.007 54.018 54.018 81.029 81.025 25.56 25.56 65.084-14.259 39.456-39.883a257240.098 257240.098 0 00-33.039-33.035H338.06c36.223 0 36.544-56.108.405-56.108z"
        fill="#fff"
      />
    </svg>
  );
}

export default BackIcon;
