import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { useBoard, useUpdateBoard } from '../../hooks/boards.hooks';

const schema = yup.object({
  title: yup.string().required('Required').min(6, 'Must be minimum of 6 characters'),
});

function EditBoard({ show, onClose, boardId }) {
  const { data: board, isFetching } = useBoard(show ? boardId : null);

  const { mutateAsync: updateBoard, isLoading } = useUpdateBoard();

  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  React.useEffect(() => {
    if (show && board?.title) {
      setValue('title', board?.title);
    }
  }, [board, show]);

  const onSubmit = async data => {
    try {
      await updateBoard({ boardId, data });
      queryClient.invalidateQueries('boards');
      reset();
      onClose();
    } catch (error) {
      toast.error(error?.message);
    }
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          Edit Board <strong>{board?.title}</strong>
          {isFetching && (
            <div className="ml-3 d-inline-block">
              <Spinner animation="border" size="sm" variant="primary" />
            </div>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)} className="mb-3">
          <Form.Group controlId="title">
            <Form.Control
              {...register('title')}
              type="text"
              placeholder="Edit Board Title"
              isInvalid={errors?.title}
            />
            <Form.Control.Feedback type="invalid">{errors?.title?.message}</Form.Control.Feedback>
          </Form.Group>
          <Button variant="primary" type="submit" disabled={isLoading}>
            {isLoading ? <Spinner size="sm" variant="light" animation="border" /> : 'Save'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default EditBoard;
