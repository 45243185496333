import React from 'react';

import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from '../../styles/modules/Dashboard.module.scss';
import teamAccessIcon from '../../assets/icons/final-team-access-icon.svg';
import supportIcon from '../../assets/icons/final-support-icon.svg';
import calendarIcon from '../../assets/icons/final-calendar-icon.svg';
import subscriptionIcon from '../../assets/icons/final-subscription-icon.svg';
import refreshIcon from '../../assets/icons/final-refresh-icon.svg';
import userIcon from '../../assets/icons/final-dashboard-user-icon.svg';
import DashboardCard from './DashboardCard';
import KimpWithBanner from '../../assets/images/welcome-kimp.png';
import UserContext from '../../contexts/UserContext';

const cardList = [
  {
    icon: userIcon,
    name: 'My Profile',
    details: 'See your profile data and manage your Account to choose what is saved in our system.',
    route: '/dashboard/edit-profile',
  },
  {
    icon: teamAccessIcon,
    name: 'Team Access',
    details:
      'You can add or remove team members from here and only member added here can view the team activity.',
    route: '/dashboard/team-access',
  },
  {
    icon: supportIcon,
    name: 'Support',
    details:
      'You can add or remove team members from here and only member added here can view the team activity.',
    route: '/dashboard/support',
  },
  {
    icon: calendarIcon,
    name: 'Team Holiday',
    details:
      'View your team holidays and plan your work accordingly,and you can get all your work done before your next vacation.',
    route: '/dashboard/team-holidays',
  },
  {
    icon: subscriptionIcon,
    name: 'Subscriptions',
    details:
      "You've got $50 on your wallet as your Joining bonus! Watch out for more bonuses to be added during promotion offered.",
    route: '/dashboard/subscriptions',
  },
  {
    icon: refreshIcon,
    name: 'Latest Updates',
    details:
      'View your team holidays and plan your work accordingly,and you can get all your work done before your next vacation.',
    route: '/dashboard/team-holidays',
  },
];

function Dashboard() {
  const { user } = React.useContext(UserContext);

  return (
    <Row>
      <Col className="px-0">
        <Card
          className={`${styles.dashboard_card_size} ${styles.background_fill_image} ${styles.border_radius_primary} mx-4 mt-4 `}
        >
          <Row className="mx-0 h-100">
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              className="d-flex flex-column justify-content-center align-items-center  pl-xl-5"
            >
              <div className="pl-xl-4">
                <Row className="text-size-lg mx-0 text-light text-font font-weight-700 py-3 py-lg-0 py-xl-3 text-center">
                  Welcome, {user?.name}
                </Row>
                <Row className={`text-size-sm ${styles.text_color_style} mx-0  text-font`}>
                  Welcome to Kimp360 Dashboard. Manage your <br /> account and your subscriptions.
                </Row>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6} className="">
              <Card.Img
                src={KimpWithBanner}
                alt=""
                className={` ml-1 ml-md-5 ml-lg-0 ml-xl-5 ${styles.kimp_banner_size}`}
              />
            </Col>
          </Row>
        </Card>
        <Row className="mx-0 my-4">
          {cardList.map(({ icon, name, details, route }) => (
            <Col key={name} xs={12} sm={12} md={12} lg={6} xl={4} className="my-3 px-4">
              <DashboardCard icon={icon} name={name} details={details} route={route} user={user} />
            </Col>
          ))}
          <hr />
        </Row>
        <Row className="mx-0">
          <Col className="px-4">
            <hr />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Dashboard;
