import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { useHistory } from 'react-router';
import classnames from 'classnames';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import styles from '../../styles/modules/NewRequest.module.scss';
import KimpMon from '../../assets/images/kimp-mon-image.svg';
import RequestContext, { initialData } from '../../contexts/RequestContext';
// import UserContext from '../../contexts/UserContext';

const schema = yup.object({
  title: yup.string().required('Required'),
  type: yup.string().required('Required'),
});

function RequestName({ changeCurrentScreen }) {
  const { request, updateRequest } = React.useContext(RequestContext);
  const form = useForm({ resolver: yupResolver(schema) });
  const [isRequestDimension, setIsRequestDimension] = React.useState(false);
  const history = useHistory();

  const onToRequestDimension = data => {
    updateRequest({
      clearedForm: [...request.clearedForm, 'name'],
      form: 'dimension',
      data: { ...request.data, ...data, updated: true },
    });
    if (isRequestDimension) {
      changeCurrentScreen('dimension');
    } else history.push('/dashboard/request-dimension', { section: 'dimension' });
  };

  React.useEffect(function setBtnRedirection() {
    let { pathname } = window.location;
    pathname = pathname.split('/');
    const url = pathname[pathname.length - 1];
    if (url === 'request-dimension') {
      setIsRequestDimension(true);
    } else {
      updateRequest(initialData);
      setIsRequestDimension(false);
    }
  }, []);

  React.useEffect(
    function setData() {
      form.setValue('title', request.data?.title);
      form.setValue('type', request.data?.type);
    },
    [request],
  );

  return (
    <Card className={classnames('px-xl-5 py-xl-4 no-border')}>
      <Card.Body className=" px-lg-5 pt-lg-5 text-font">
        <Row className=" mx-0 ">
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={7}
            className="w-50 px-0 px-md-3 pl-lg-4 pt-lg-5 d-xs-flex flex-xs-column align-items-xs-center"
          >
            <Card.Text className="text-size-xs text-secondary text-center text-md-left text-font">
              Type your <span className="font-weight-600">Task Name</span> with Your{' '}
              <span className="font-weight-600">Design Type</span> below
            </Card.Text>
            <Card className="shadow py-2 pr-md-3 d-flex flex-sm-column flex-lg-row align-items-center justify-content-center">
              <Form.Control
                {...form.register('title')}
                className={`no-border pl-3 text-size-lg text-primary text-center text-lg-left font-weight-700 ${styles.task_name_field}`}
                placeholder="Enter Request Name"
                name="title"
                isInvalid={form.formState.errors && form.formState.errors?.title}
              />
              <Form.Control
                {...form.register('type')}
                as="select"
                className={`text-size-xs text-font text-secondary font-weight-600  ${styles.dropdown_structure} `}
                name="type"
                isInvalid={form.formState.errors && form.formState.errors?.type}
                defaultValue=""
              >
                <option value="" className={styles.options}>
                  Request Type
                </option>
                <option value="flyers" className={styles.options}>
                  Flyers
                </option>
                <option value="posters" className={styles.options}>
                  Posters
                </option>
                <option value="business_cards" className={styles.options}>
                  Business Cards
                </option>
                <option value="icons" className={styles.options}>
                  Icons
                </option>
                <option value="landing_pages" className={styles.options}>
                  Landing Pages
                </option>
                <option value="logos" className={styles.options}>
                  Logos
                </option>
                <option value="custom_illustrations" className={styles.options}>
                  Custom Illustrations
                </option>
              </Form.Control>
            </Card>
            <p className="mb-4 py-2 pr-md-3 d-flex flex-sm-column flex-lg-row align-items-center justify-content-center text-font error-text">
              <span
                className={`no-border pl-3 text-size-xs ${styles.task_name_field}`}
                style={{ width: '100%' }}
              >
                {form.formState.errors && form.formState.errors?.title && 'Title is required.'}
              </span>
              <span
                className={`pl-4 text-size-xs ${styles.task_name_field} `}
                style={{ width: '235px' }}
              >
                {form.formState.errors && form.formState.errors?.type && 'Select a type.'}
              </span>
            </p>
            <Card.Text className="text-size-xs text-font text-secondary text-center text-md-left">
              <span className="font-weight-700 text-secondary">Tips:</span> Include a design type in
              your name Like Flyer, Facebook Ad, Facebook Cover etc
            </Card.Text>
            <Card.Text className="text-size-sm text-font text-secondary mt-lg-5 text-center text-md-left">
              <span className="font-weight-700">(Example:</span> Labour Day Sale Flyer)
            </Card.Text>
            <div className="d-flex justify-content-center justify-content-md-start">
              <Button
                className="text-size-xs text-font font-weight-500 px-5 mt-lg-5 "
                onClick={form.handleSubmit(onToRequestDimension)}
              >
                Continue
              </Button>
            </div>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={5}
            className="mt-2 mt-lg-3 pt-lg-3 mt-xl-5 pt-xl-5 px-0 pr-xl-5 d-flex flex-column align-items-center"
          >
            <div className="mt-5 mr-xl-5">
              <img src={KimpMon} alt="kimp-guy" className={`${styles.kimp_image_size}`} />
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default RequestName;
