import React, { useEffect, useState } from 'react';
import { Button, Col, Image, Modal, Row, Spinner } from 'react-bootstrap';
import { useAssets, useSubAssets } from '../../../hooks/assets.hooks';
import BackIcon from '../../../assets/icons/back-icon.svg';
import MediaView from './MediaView';
import { useAssetMedias } from '../../../hooks/asset-medias.hooks';
import BoardContext from '../../../contexts/BoardContext';
import folderIcon from '../../../assets/icons/folder.svg';

const AssetsViewer = ({ show, setShow, onSelectMedia }) => {
  // eslint-disable-next-line no-unused-vars
  const [parentAssetId, setParentAssetId] = useState('');
  const [breadCrumbs, setBreadCrumbs] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState([]);

  const { board } = React.useContext(BoardContext);

  const { data: parentAssets, isFetching: isParentAssetsFetching } = useAssets(board?.id);
  const { data: subAssets, isFetching: isSubAssetsFetching } = useSubAssets(parentAssetId);

  const { data: medias, isFetching: isMediaFetching } = useAssetMedias(parentAssetId);

  useEffect(() => {
    setSelectedMedia([]);
  }, [show]);

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>Select Assets</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row className="mb-2">
          {breadCrumbs.length > 0 && (
            <Col
              sm="1"
              onClick={() => {
                const tempBreadCrumbs = [...breadCrumbs];
                const lastValue = tempBreadCrumbs.pop();
                setBreadCrumbs(tempBreadCrumbs);
                setParentAssetId(lastValue);
              }}
            >
              <Image src={BackIcon} className="cursor-pointer" />
            </Col>
          )}
        </Row>
        <Row>
          {(isParentAssetsFetching || isSubAssetsFetching || isMediaFetching) && (
            <Col align="center">
              <Spinner size="lg" variant="primary" animation="border" />
            </Col>
          )}
          {!(isParentAssetsFetching || isSubAssetsFetching || isMediaFetching) && (
            <>
              <>
                {parentAssetId === ''
                  ? parentAssets
                      .filter(a => a.parent_id === null)
                      .map(asset => (
                        <Col
                          className="cursor-pointer mb-2"
                          key={asset.id}
                          xs="3"
                          align="center"
                          onClick={() => {
                            setBreadCrumbs([...breadCrumbs, parentAssetId]);
                            setParentAssetId(asset.id);
                          }}
                        >
                          <Image src={folderIcon} width="100%" />
                          <br />
                          <div className="custom-white-space details-2-line">
                            {asset.folder_name}
                          </div>
                        </Col>
                      ))
                  : subAssets.map(asset => (
                      <Col
                        className="cursor-pointer mb-2"
                        key={asset.id}
                        xs="3"
                        align="center"
                        onClick={() => {
                          setBreadCrumbs([...breadCrumbs, parentAssetId]);
                          setParentAssetId(asset.id);
                        }}
                      >
                        <Image src={folderIcon} width="100%" />
                        <br />
                        <div className="custom-white-space details-2-line">{asset.folder_name}</div>
                      </Col>
                    ))}
              </>
              {medias.map(media => (
                <MediaView
                  id={media.media_id}
                  assetId={media.asset_id}
                  onSelectMedia={(id, assetId, mediaData) => {
                    setSelectedMedia([
                      ...selectedMedia,
                      {
                        media_id: id,
                        asset_id: assetId,
                        media: mediaData,
                      },
                    ]);
                  }}
                  onRemove={id => {
                    setSelectedMedia([
                      ...selectedMedia.filter(selected => selected.media_id !== id),
                    ]);
                  }}
                />
              ))}

              {parentAssetId === ''
                ? parentAssets.length === 0 &&
                  medias.length === 0 && <Col align="center">Folder is empty</Col>
                : subAssets.length === 0 &&
                  medias.length === 0 && <Col align="center">Folder is empty</Col>}
            </>
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setBreadCrumbs([]);
            setParentAssetId('');
            setShow(false);
          }}
        >
          Close
        </Button>
        <Button
          variant="success"
          disabled={selectedMedia.length === 0}
          onClick={() => {
            setBreadCrumbs([]);
            setParentAssetId('');
            if (selectedMedia.length > 0) onSelectMedia(selectedMedia);
            setShow(false);
          }}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AssetsViewer;
