import React, { useEffect } from 'react';
import { Button, Card, Col, Container, Form, Image, Row, Spinner } from 'react-bootstrap';
import classnames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import mainIcon from '../../assets/icons/final-main-logo.svg';
import styles from '../../styles/modules/CreateAccount.module.scss';
import KimpBanner from '../../assets/images/monkey-rest.png';
import { useRegisterUser } from '../../hooks/auth.hooks';
import UserContext from '../../contexts/UserContext';
import { fetchUserProfile } from '../../requests/auth.requests';
import { useFetchSentInvites } from '../../hooks/invites.hooks';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const schema = yup.object({
  email: yup.string().required('Required').email('Must be a valid email'),
  first_name: yup
    .string()
    .required('Required')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
    .min(2, 'Must have at least 2 characters'),
  last_name: yup
    .string()
    .required('Required')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
    .min(2, 'Must have at least 2 characters'),
  phone_number: yup
    .string()
    .required('Required')
    .min(10, 'Must have at least 10 digits')
    .matches(phoneRegExp, 'Phone number is not valid'),
  user_name: yup
    .string()
    .required('Required')
    .matches(/^[0-9aA-zZ*,&@\s]+$/, 'Only alphanumeric are allowed for this field ')
    .min(2, 'Must have at least 2 characters'),
  password: yup.string().required('Required').min(6, 'Must be minimum of 6 characters'),
  confirm_password: yup
    .string()
    .required('Required')
    .min(6, 'Must be minimum of 6 characters')
    .oneOf([yup.ref('password'), null], 'Password dont match'),
});

function CreateAccount() {
  const location = useLocation();
  const { updateUser } = React.useContext(UserContext);

  useEffect(() => {
    const token = new URLSearchParams(location.search).get('token');
    if (!token) {
      window.location.href = 'https://kimp360wp.codebuddy.codes/register-now/';
    }
  }, []);

  useEffect(() => {
    // scrolls to top
    window.scrollTo(0, 0);
  }, [location]);

  const token = new URLSearchParams(location.search).get('token');
  const { data: invites } = useFetchSentInvites(token);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const { mutateAsync: registerUser, isLoading } = useRegisterUser();

  useEffect(() => {
    /* function to set prefill emailid from invites if istoken */
    setValue('email', invites?.email ? invites?.email : '');
  }, [invites]);

  const onSubmit = async user_info => {
    const data = {
      email: invites?.email ? invites.email : user_info.email,
      username: user_info.user_name,
      first_name: user_info.first_name,
      last_name: user_info.last_name,
      password: user_info.confirm_password,
      phone: user_info.phone_number,
      company: '',
      role: invites?.role ? invites.role : '',
      project_id: invites?.project_id ? invites.project_id : '',
    };
    try {
      const { message, access_token } = await registerUser(data);
      toast.success(message);
      localStorage.setItem('kimp-authtoken', access_token);
      const res = await fetchUserProfile();
      updateUser(res.data);

      const query = new URLSearchParams();
      query.append('login', res.data?.username);
      query.append('redirect_url', process.env.REACT_APP_SITE_URL);
      window.open(`${process.env.REACT_APP_WP_URL}?${query.toString()}`, '_self');
    } catch (err) {
      toast.error(err?.error);
    }
  };

  return (
    <main className="text-font bg-primary h-vh-100">
      <Row className="mx-0 justify-content-between pt-3 pt-md-0">
        <div className="align-self-center pl-3">
          <Image
            src={KimpBanner}
            alt="kimp-banner"
            className={classnames('img-fluid', styles.register_kimp_icon)}
          />
        </div>
        <div className="text-right pb-4 pt-4  pr-4 pr-md-5">
          <img src={mainIcon} alt="logo" className="main-logo-icon-size" />
        </div>
      </Row>
      <Container className="text-font pt-md-2 pb-0">
        <Row className="mt-4 mt-md-0 mb-md-3">
          <Col
            xs={12}
            md={6}
            lg={7}
            className="d-md-flex flex-md-column justify-content-md-center pr-md-0 d-none d-md-block"
          >
            <Image src={KimpBanner} alt="kimp-banner" className="mb-5 mt-5 mt-md-0" />
          </Col>
          <Col xs={12} md={6} lg={5} className="order-box">
            <Card
              body
              className={classnames(
                'px-4 px-lg-5 pt-2 pb-2',
                styles.card_shadow,
                styles.card_border_radius,
              )}
            >
              <div className="text-center pb-3">
                <h3 className="font-weight-bold">Welcome to Kimp360</h3>
              </div>
              <Form autocomplete="off" onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-0 mb-3">
                  <Form.Label className="font-weight-bold text-secondary mb-0">
                    First Name <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    {...register('first_name')}
                    type="text"
                    name="first_name"
                    autocomplete="false"
                    isInvalid={errors?.first_name}
                    className={classnames(
                      styles.custom_bottom_border,
                      styles.active_fields,
                      'px-0 text-size-sm font-weight-500 remove-focus',
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.first_name?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-0 mb-3">
                  <Form.Label className="font-weight-bold text-secondary mb-0">
                    Last Name <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    {...register('last_name')}
                    type="text"
                    name="last_name"
                    autocomplete="false"
                    isInvalid={errors?.last_name}
                    className={classnames(
                      styles.custom_bottom_border,
                      styles.active_fields,
                      'px-0 text-size-sm font-weight-500 remove-focus',
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.last_name?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-0 mb-3">
                  <Form.Label className="font-weight-bold text-secondary mb-0">
                    Email <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    {...register('email')}
                    type="email"
                    name="email"
                    autocomplete="false"
                    isInvalid={errors?.email}
                    className={classnames(
                      'px-0 text-size-sm font-weight-500 remove-focus',
                      styles.active_fields,
                      styles.custom_bottom_border,
                    )}
                    readOnly={invites?.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.email?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-0 mb-3">
                  <Form.Label className="font-weight-bold text-secondary mb-0">
                    Username <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    {...register('user_name')}
                    type="text"
                    name="user_name"
                    autocomplete="false"
                    isInvalid={errors?.user_name}
                    className={classnames(
                      'px-0 text-size-sm font-weight-500 remove-focus',
                      styles.active_fields,
                      styles.custom_bottom_border,
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.user_name?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-0 mb-3">
                  <Form.Label className="font-weight-bold text-secondary mb-0">
                    Phone Number <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    {...register('phone_number')}
                    type="number"
                    name="phone_number"
                    autocomplete="false"
                    isInvalid={errors?.phone_number}
                    className={classnames(
                      styles.custom_bottom_border,
                      styles.active_fields,
                      'px-0 text-size-sm font-weight-500 remove-focus',
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.phone_number?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-0 mb-3">
                  <Form.Label className="font-weight-bold text-secondary mb-0">
                    Password <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    {...register('password')}
                    type="password"
                    name="password"
                    autocomplete="new-password"
                    isInvalid={errors?.password}
                    className={classnames(
                      styles.custom_bottom_border,
                      styles.active_fields,
                      'px-0 text-size-sm font-weight-500 remove-focus',
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.password?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-0 mb-3">
                  <Form.Label className="font-weight-bold text-secondary mb-0 ">
                    Confirm Password <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    {...register('confirm_password')}
                    type="password"
                    name="confirm_password"
                    autocomplete="new-password"
                    isInvalid={errors?.confirm_password}
                    className={classnames(
                      styles.custom_bottom_border,
                      styles.active_fields,
                      'px-0 text-size-sm font-weight-500 remove-focus',
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.confirm_password?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Button
                  type="submit"
                  variant="warning"
                  className={classnames('font-weight-bold', styles.custom_btn)}
                  size="lg"
                  block
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner size="sm" variant="white" animation="border" />
                  ) : (
                    <p className={classnames('mb-0 my-2', styles.create_ac_text)}>
                      Create New Account
                    </p>
                  )}
                </Button>
              </Form>
              <p className="mb-0 mt-3 text-center ">
                Already have an account?{' '}
                <Link
                  className="text-primary font-weight-500"
                  to={{
                    pathname: '/',
                    query: { token_id: token, email: invites?.email },
                  }}
                >
                  <span className="underline-text">Login Here</span>
                </Link>
              </p>
            </Card>
          </Col>
        </Row>
        <p className={classnames('text-size-sm pt-2 text-center mb-0', styles.custom_white_color)}>
          COPYRIGHT @ 2021 <strong>Kimp360.io</strong>, All rights Reserved
        </p>
      </Container>
    </main>
  );
}

export default CreateAccount;
