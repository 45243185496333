import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import classnames from 'classnames';
import styles from '../../styles/modules/Dashboard.module.scss';
import playIcon from '../../assets/icons/final-play-icon.svg';

const DashboardCard = ({ icon, name, details, route, user }) => {
  return (
    <Card
      className={`px-3 py-4 ${styles.border_radius_primary} ${styles.card_shadow} ${styles.dashcard_size}`}
    >
      <Row>
        <Col>
          <Row className="mb-3 mx-0">
            <div className="d-flex flex-column justify-content-center align-items-center px-1">
              <div
                className={`${styles.background_fill_custom_green} ${styles.border_radius_primary} ${styles.icon_box} d-flex flex-row  px-2 py-2`}
              >
                <img src={icon} alt="user_profile" className={`${styles.user_icon_size}`} />
              </div>
            </div>
            <div className="text-size-sm align-self-center pl-1 text-font font-weight-600">
              {name}
            </div>
          </Row>
          <div
            className={`text-size-xs pb-2 pt-1 px-2 text-secondary ${styles.dashcard_details_size} text-font`}
          >
            {details}
          </div>
          <hr className="mx-2" />
          <Row className="underline-text pt-2 px-2 d-flex flex-row align-items-center">
            {user?.roles[0]?.name === 'Editors' && name === 'Subscriptions' ? (
              <Button
                variant="white"
                className={classnames(
                  styles.dashcard_btn_click,
                  'text-darker text-size-xs text-font font-weight-600 pl-3 pr-2 d-flex flex-row',
                )}
                onClick={() => {
                  window.open('https://kimp360wp.codebuddy.codes/register-now/');
                }}
              >
                Click Here
              </Button>
            ) : user?.roles[0]?.name === 'Viewers' && name === 'Subscriptions' ? (
              <></>
            ) : (
              <Link
                className="text-darker text-size-xs text-font font-weight-600 pl-3 pr-2 d-flex flex-row hover-mouse"
                to={route}
              >
                Click Here
              </Link>
            )}

            {!user?.roles[0]?.name === 'Viewers' && name === 'Subscriptions' && (
              <img
                src={playIcon}
                alt="arrow"
                className={`${styles.click_arrow_icon_size} hover-mouse`}
              />
            )}
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default DashboardCard;
