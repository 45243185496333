import http from '../utils/http';

export const getProfile = () => http.get(`/profile`);

export const getUsers = search => http.get(`/users${search}`);

export const updateUserDetailsById = (id, data) => http.put(`/users/${id}`, data);

export const getUserDetailsById = id => http.get(`/users/${id}`);

export const deleteUserById = id => http.delete(`/users/${id}`);

export const postUserWatchList = (id, data) => http.post(`/users/${id}/watching`, data);

export const getUserWatchList = id => http.get(`/users/${id}/watching`);

export const getUserFavorites = id => http.get(`/users/${id}/favorites`);

export const updateUserFavorites = (id, data) => http.post(`/users/${id}/favorites`, data);
