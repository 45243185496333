import * as React from 'react';

function CustomSizeIcon(props) {
  const { active } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={128.507}
      height={129.598}
      viewBox="0 0 128.507 129.598"
      {...props}
    >
      <g data-name="Group 370" fill={active ? '#219186' : '#969696'}>
        <path
          data-name="Path 556"
          d="M111.727 10.777L62.073 0l-3.686 17.052H23.873v88.672h72.575v-7.639l18.007 3.82 14.051-65.345zm-.273 9.413l8.319 12.823-10.5-2.319zm-20.463 80.078H29.33V22.646h41.471v20.19h20.19zM76.258 37.242V26.465l10.777 10.777zm33.968 58.115l-13.778-3v-10.5l5.457 1.228 1.228-5.457-6.685-1.5v-10.5l8.731 1.91 1.228-5.457-9.959-2.183v-10.1l12.141 2.592 1.228-5.457-13.233-2.87v-5.184L74.757 17.052h-10.64l2.319-10.5 40.516 8.731-4.229 19.777 19.781 4.229z"
        />
        <path data-name="Rectangle 44" d="M39.971 84.443h40.244V89.9H39.971z" />
        <path data-name="Rectangle 45" d="M39.971 68.755h40.244v5.457H39.971z" />
        <path data-name="Rectangle 46" d="M39.971 53.067h40.244v5.457H39.971z" />
        <path
          data-name="Path 557"
          d="M14.051 30.831l2.319-2.456-8.185-8.185L0 28.375l2.319 2.456 4.229-4.229v69.573l-4.229-4.092L0 94.402l8.185 8.188 8.185-8.185-2.319-2.319-4.229 4.093V26.602z"
        />
        <path
          data-name="Path 558"
          d="M82.811 115.547l4.229 4.093H33.423l4.229-4.093-2.456-2.319-8.185 8.185 8.185 8.185 2.456-2.319-4.229-4.229h53.612l-4.224 4.229 2.456 2.319 8.185-8.185-8.19-8.185z"
        />
      </g>
    </svg>
  );
}

CustomSizeIcon.defaultProps = {
  active: false,
};

export default CustomSizeIcon;
