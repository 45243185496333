import React, { useState, useRef } from 'react';
import { Button, Card, Overlay, Popover } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import bellIcon from '../assets/icons/bell-icon.svg';
import styles from '../styles/modules/NotificationPopover.module.scss';
import { ROLES } from '../utils/enums';
import NotificationContext from '../contexts/NotificationContext';
import UserContext from '../contexts/UserContext';

const NotificationPopover = ({ feeds }) => {
  const history = useHistory();
  const target = useRef(null);
  const [showPopover, setShowPopover] = useState(false);
  const { feedCount } = React.useContext(NotificationContext);
  const { getRole } = React.useContext(UserContext);

  return (
    <div className="mx-md-1 position-relative">
      <Button
        ref={target}
        onClick={() => {
          setShowPopover(true);
        }}
        className="p-0 position-relative"
        variant="white"
      >
        {feeds?.data?.filter(val => val.is_read === 0).length > 0 && (
          <div className="bg-danger notification-badge position-absolute d-flex align-items-center justify-content-center">
            <p className="mb-0">
              {feedCount?.unread_count > 99 ? <>99+</> : feedCount?.unread_count}
            </p>
          </div>
        )}
        <img src={bellIcon} alt="notification" className="tnb-icon-size mx-md-2" />{' '}
      </Button>
      <Overlay
        trigger="click"
        placement="bottom"
        show={showPopover}
        target={target.current}
        rootClose
        rootCloseEvent="click"
        onHide={() => setShowPopover(!showPopover)}
      >
        {props => (
          <Popover id="popover-basic" {...props} className="text-font px-4">
            <Popover.Title className="text-size-xs text-secondary bg-white text-center no-border">
              Notifications
            </Popover.Title>

            {feeds?.data?.filter(val => val.is_read === 0).length === 0 && (
              <Popover.Content className="px-3 mx-4 text-center">
                No new notifications available for now.
              </Popover.Content>
            )}
            <Popover.Content className={classnames(styles.pop_content, 'px-0')}>
              {feeds?.data
                ?.filter(val => val.is_read === 0)
                .filter((val, index) => index < 3)
                .map((item, index) => {
                  return (
                    <Card
                      className={classnames('pt-2 pb-2 px-3 mb-2', index === 3 && 'mb-5')}
                      key={uuid()}
                    >
                      <Card.Text className="text-size-xs mb-1 font-weight-500">
                        {item.notification.from_user.name} :
                      </Card.Text>
                      <Card.Text className="text-size-xs mb-1">{item.notification.text}</Card.Text>
                      <Card.Text className="text-size-xs">
                        {moment(item.created_at).format('MMMM Do YYYY, h:mm a')}
                      </Card.Text>
                    </Card>
                  );
                })}
            </Popover.Content>
            <Popover.Content className="text-center mb-2">
              <Button
                type="button"
                variant="white"
                className="text-primary"
                onClick={() => {
                  if (
                    [
                      ROLES.SUBSCRIBERS,
                      ROLES.VIEWERS,
                      ROLES.CONTRIBUTORS,
                      ROLES.MANAGERS,
                      ROLES.EDITORS,
                    ].includes(getRole()?.role)
                  ) {
                    history.push('/dashboard/feeds');
                  }

                  if ([ROLES.SUPER_ADMIN, ROLES.ADMIN].includes(getRole()?.role)) {
                    history.push('/dashboard/support');
                  }

                  return setShowPopover(false);
                }}
              >
                Go To Feeds
              </Button>
            </Popover.Content>
          </Popover>
        )}
      </Overlay>
    </div>
  );
};

export default NotificationPopover;
