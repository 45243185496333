import React, { useEffect, useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import RequestContext from '../../../contexts/RequestContext';

import CustomSize from './CustomSize';
import PreDimensions from './PreDimensions';

const predefined = [
  { _id: uuid(), name: 'Standard A4', width: 8.5, height: 11, resolution: 300 },
  { _id: uuid(), name: 'Half Sheet', width: 5.5, height: 8.5, resolution: 300 },
  { _id: uuid(), name: 'Standard A5', width: 5.8, height: 8.3, resolution: 300 },
  { _id: uuid(), name: 'DL', width: 3.9, height: 8.3, resolution: 300 },
];

function RequestDimension({ changeCurrentScreen }) {
  const { request, updateRequest } = React.useContext(RequestContext);
  const [selected, setSelected] = useState();
  const [showError, setShowError] = useState(false);
  const [blankError, setBlankError] = useState(false);
  const [dimensions, setDimensions] = useState('');
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    setSelected(
      request.data?.dimension ? request.data?.dimension.split(' ')[0].replace('_', ' ') : selected,
    );
    setDimensions(
      request.data.dimension
        ? `${request.data.dimension?.split(' ')[1]} x ${request.data.dimension?.split(' ')[3]} ${
            request.data.dimension?.split(' ')[4]
          } DPI`
        : dimensions,
    );
    setDisplay(true);
  }, [request]);

  return (
    <>
      {showError && !selected && (
        <Row>
          <Col align="center" className="text-font error-text mb-3">
            {' '}
            Please select a dimension.
          </Col>
        </Row>
      )}
      {blankError && dimensions === '' && (
        <Row>
          <Col align="center" className="text-font error-text mb-3">
            {' '}
            Please enter value for height, width and resolution.
          </Col>
        </Row>
      )}
      {display ? (
        <>
          <Row>
            <Col sm={12} md={6} lg={4} xl={3} className="mb-3">
              <CustomSize
                active={selected === 'Custom Size'}
                value={request.data.dimension}
                onUpdate={(name, value) => {
                  setSelected(name);
                  if (value.width > 0 && value.height > 0 && value.resolution > 0) {
                    setDimensions(`${value.width}“ x ${value.height}“ ${value.resolution} DPI`);
                  } else {
                    setDimensions('');
                  }
                }}
              />
            </Col>
            {predefined.map(item => (
              <Col sm={12} md={6} lg={4} xl={3}>
                <PreDimensions
                  key={item._id}
                  name={item.name}
                  width={item.width}
                  height={item.height}
                  resolution={item.resolution}
                  active={item.name === selected}
                  onClick={(name, value) => {
                    setSelected(name);
                    setDimensions(`${value.width}“ x ${value.height}“ ${value.resolution} DPI`);
                  }}
                />
              </Col>
            ))}
          </Row>
          <div className="text-center mt-4">
            <Button
              className="text-size-xs px-5 font-weight-500 text-font"
              onClick={() => {
                if (!selected) {
                  setShowError(true);
                  return;
                }

                if (selected === 'Custom Size' && dimensions.length === 0) {
                  setBlankError(true);
                  return;
                }

                const tempRequestData = {
                  ...request.data,
                  dimension: `${selected.replace(' ', '_')} ${dimensions}`,
                  updated: true,
                };

                let clearedForm = [];
                if (request.clearedForm.includes('dimension')) {
                  clearedForm = request.clearedForm;
                } else {
                  clearedForm = [...request.clearedForm, 'dimension'];
                }

                updateRequest({
                  clearedForm,
                  form: 'description',
                  data: tempRequestData,
                });

                changeCurrentScreen('description');
              }}
            >
              Continue
            </Button>
          </div>
        </>
      ) : null}
    </>
  );
}

export default RequestDimension;
