import { useQuery, useMutation } from 'react-query';
import {
  createBoard,
  fetchBoardCollections,
  fetchBoards,
  fetchBoard,
  fetchBoardTasks,
  updateBoardRequest,
} from '../requests/boards.requests';

export const useBoards = userId =>
  useQuery(
    ['boards', userId],
    async () => {
      const res = await fetchBoards(userId);
      return res.data;
    },
    {
      initialData: [],
    },
  );

export const useCreateBoard = () =>
  useMutation(async ({ userId, data }) => {
    const res = await createBoard(userId, data);
    return res;
  });

export const useBoardCollections = (boardId, enabled) =>
  useQuery(
    ['board-collections', boardId],
    async () => {
      const res = await fetchBoardCollections(boardId);
      return res.data;
    },
    {
      enabled,
      initialData: [],
    },
  );

export const useBoard = boardId =>
  useQuery(
    ['board-data', boardId],
    async () => {
      const res = await fetchBoard(boardId);
      return res.data;
    },
    {
      enabled: !!boardId,
      initialData: {},
    },
  );

export const useBoardTasks = boardId =>
  useQuery(
    ['boardTasks', boardId],
    async () => {
      const res = await fetchBoardTasks(boardId);
      return res.data;
    },
    {
      enabled: !!boardId,
      initialData: [],
    },
  );

export const useUpdateBoard = () =>
  useMutation(async ({ boardId, data }) => {
    const res = await updateBoardRequest(boardId, data);
    return res;
  });
