import { useQuery } from 'react-query';
import fetchUsers from '../requests/client.requests';

const useFetchClient = (type, search) =>
  useQuery(
    ['clients', type, search],
    async () => {
      const res = await fetchUsers(type, search);
      return res.data;
    },
    {
      enabled: !!type,
      initialData: [],
    },
  );

export default useFetchClient;
