import { useQuery, useMutation } from 'react-query';
import {
  createAsset,
  deleteAsset,
  fetchAsset,
  fetchAssetDownloadURL,
  fetchAssets,
  fetchBoardAssets,
} from '../requests/assets.requests';

export const useAssets = boardId =>
  useQuery(
    ['assets', boardId],
    async () => {
      const res = await fetchBoardAssets(boardId);
      return res.data;
    },
    {
      enabled: Boolean(boardId),
      initialData: [],
    },
  );

export const useCreateAsset = () =>
  useMutation(async data => {
    const res = await createAsset(data);
    return res;
  });

export const useAsset = assetId =>
  useQuery(
    ['asset', assetId],
    async () => {
      const res = await fetchAsset(assetId);
      return res.data;
    },
    {
      enabled: Boolean(assetId),
      initialData: {},
    },
  );

export const useSubAssets = parentId =>
  useQuery(
    ['sub-asset', parentId],
    async () => {
      const res = await fetchAssets(parentId);
      return res.data;
    },
    {
      // enabled: !!parentId,
      initialData: [],
    },
  );

export const useDeleteAsset = () =>
  useMutation(async assetId => {
    const res = await deleteAsset(assetId);
    return res;
  });

export const useAssetDownloadURL = assetId =>
  useQuery(
    ['asset-download-url', assetId],
    async () => {
      const res = await fetchAssetDownloadURL(assetId);
      return res;
    },
    {
      enabled: !!assetId,
      initialData: null,
    },
  );
