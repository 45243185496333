import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import Rating from 'react-rating';
import styles from '../../styles/modules/FeedBack.module.scss';
import closeIcon from '../../assets/icons/close-icon.svg';
import GoldStarIcon from '../../assets/icons/GoldStarIcon';
import GreyStarIcon from '../../assets/icons/GreyStarIcon';
import BoardContext from '../../contexts/BoardContext';

function FeedBack() {
  const [success, setSuccess] = React.useState('rating-screen');
  const history = useHistory();
  const [starRating, setStarRating] = useState(0);
  const { board } = React.useContext(BoardContext);

  return (
    <Card className="text-font px-md-4 px-lg-5 py-5">
      <Card.Body
        className={`d-flex flex-row justify-content-center align-items-center ${classnames(
          styles.background_with_banner,
        )}`}
      >
        {success === 'rating-screen' ? (
          <Card.Body
            className={`shadow bg-white d-flex flex-column justify-content-center align-items-center position-relative ${classnames(
              styles.card_size,
            )}`}
          >
            <Button
              className={`p-0 text-size-xs font-weight-600 position-absolute ${classnames(
                styles.hover_btn,
              )}`}
              type="button"
              variant="light"
              onClick={() =>
                history.push(`/dashboard/request-history/${board?.id}`, { section: 'completed' })
              }
            >
              <img src={closeIcon} alt="cancel" className={classnames(styles.close_icon_size)} />
            </Button>
            <h4 className="text-size-lg text-center font-weight-700">We Value Your FeedBack</h4>
            <p className="text-size-xs text-secondary font-weight-600">Tell us your experience</p>
            <div className="d-flex flex-row mb-4 mt-2 w-100 justify-content-center">
              <Rating
                start={0}
                stop={5}
                step={1}
                initialRating={starRating}
                emptySymbol={<GreyStarIcon />}
                fullSymbol={<GoldStarIcon />}
                className={classnames(styles.custom_star_space)}
                onClick={value => setStarRating(value)}
              />
            </div>
            <Button
              className="text-size-xs font-weight-600 px-4"
              type="button"
              onClick={() => setSuccess('comment-screen')}
            >
              Submit
            </Button>
          </Card.Body>
        ) : success === 'comment-screen' ? (
          <Card.Body
            className={`shadow bg-white d-flex flex-column justify-content-center align-items-center position-relative ${classnames(
              styles.card_size,
            )}`}
          >
            <Button
              className={`p-0 text-size-xs font-weight-600 position-absolute ${classnames(
                styles.hover_btn,
              )}`}
              type="button"
              variant="light"
              onClick={() => setSuccess('rating-screen')}
            >
              <img src={closeIcon} alt="cancel" className={classnames(styles.close_icon_size)} />
            </Button>
            <h4 className="text-size-lg text-center font-weight-700">We Value Your FeedBack</h4>
            <p className="text-size-xs text-secondary font-weight-600">Tell us your experience</p>
            <div className="d-flex flex-row mb-4 mt-2  justify-content-center">
              <Form.Control as="textarea" placeholder="Type here" />
            </div>
            <Button
              className="text-size-xs font-weight-600 px-4"
              type="button"
              onClick={() => setSuccess('thankyou-screen')}
            >
              Submit
            </Button>
          </Card.Body>
        ) : success === 'thankyou-screen' ? (
          <Card.Body
            className={`shadow d-flex flex-column justify-content-center align-items-center position-relative ${classnames(
              styles.card_size,
              styles.thankyou_with_banner,
            )}`}
          >
            <Button
              className={`p-0 text-size-xs font-weight-600 position-absolute ${classnames(
                styles.hover_btn,
              )}`}
              type="button"
              variant="light"
              onClick={() =>
                history.push(`/dashboard/request-history/${board?.id}`, { section: 'completed' })
              }
            >
              <img src={closeIcon} alt="cancel" className={classnames(styles.close_icon_size)} />
            </Button>
            <h4 className="text-size-xl font-weight-700">Thank You</h4>
            <p className="text-size-xs text-secondary font-weight-600 text-center">
              Your feedback is very helpful to us
            </p>
          </Card.Body>
        ) : null}
      </Card.Body>
    </Card>
  );
}

export default FeedBack;
