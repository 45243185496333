import classNames from 'classnames';
import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useHistory } from 'react-router';
import AssetContext from '../../contexts/AssetContext';

const AssetBreadCrumbs = ({ isInMedia }) => {
  const history = useHistory();
  const { path, reset, goto } = React.useContext(AssetContext);

  const gotoHome = () => {
    reset();
    history.push('/dashboard/assets');
  };

  const gotoToAsset = index => {
    if (isInMedia) {
      goto(index);
      history.push('/dashboard/assets/details');
    } else {
      goto(index);
    }
  };

  return (
    <Breadcrumb>
      <Breadcrumb.Item />
      <button type="button" className="bg-transparent border-0 breadcrumb-item" onClick={gotoHome}>
        Assets
      </button>
      {path.map(({ id, name }, index) => (
        <button
          key={id}
          type="button"
          onClick={() => gotoToAsset(index)}
          className={classNames(
            'bg-transparent border-0 breadcrumb-item',
            index === path.length - 1 && 'active',
          )}
        >
          {name}
        </button>
      ))}
    </Breadcrumb>
  );
};

AssetBreadCrumbs.defaultProps = {
  isInMedia: false,
};

export default AssetBreadCrumbs;
