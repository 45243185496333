import React from 'react';
import Card from 'react-bootstrap/Card';
import classNames from 'classnames';
import styles from '../../styles/modules/AddAssets.module.scss';
import PlusIcon from '../../assets/icons/final-plus-icon.svg';

const AddBrandButton = ({ onAdd, title }) => {
  return (
    <Card className={classNames('h-100 rounded-lg')}>
      <Card.Body
        className={classNames(
          'd-flex flex-column align-items-center justify-content-center',
          'w-100 shadow border-0 rounded-lg',
          styles.btn_add_brand,
        )}
        as="button"
        type="button"
        onClick={onAdd}
      >
        <div>
          <img src={PlusIcon} alt="add-icon" width="80" height="80" />
        </div>
        <div className="text-size-sm mt-1 font-weight-500">{title}</div>
      </Card.Body>
    </Card>
  );
};

AddBrandButton.defaultProps = {
  title: 'Add Brand',
};

export default AddBrandButton;
