import React from 'react';

const UserContext = React.createContext({
  user: null,
  boardRole: null,
  updateBoardRole: () => {},
  updateUser: () => {},
  getRole: () => {},
});

export default UserContext;
