import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import DueDatePicker from './DueDatePicker';

const DueDateInput = ({ styles, onChange }) => {
  const [selectedDate, setSelectedDate] = useState();
  const [clickOnButton, setClickOnButton] = useState(false);
  const ref = useRef();
  const dateRef = useRef();
  const timeRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !document.getElementsByClassName('ant-picker-dropdown')[0]?.contains(event.target) &&
        !document.getElementsByClassName('ant-picker-dropdown')[1]?.contains(event.target)
      ) {
        setClickOnButton(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return (
    <div ref={ref}>
      <Form.Control
        className="mt-3"
        placeholder="Set Due Date"
        readOnly
        value={selectedDate ? moment(selectedDate).format('MMM DD, hh:mmA') : ''}
        onClick={() => {
          setClickOnButton(!clickOnButton);
        }}
      />
      {clickOnButton && (
        <DueDatePicker
          className="ven-due-date-popup"
          dateRef={dateRef}
          timeRef={timeRef}
          styles={styles}
          saveDueDate={(date, time) => {
            if (date && time) {
              setSelectedDate(
                moment(
                  `${date.format('DD-MM-YYYY')} ${time.format('HH:mm:ss')}`,
                  'DD-MM-YYYY HH:mm:ss',
                ),
              );
              onChange(
                moment(
                  `${date.format('DD-MM-YYYY')} ${time.format('HH:mm:ss')}`,
                  'DD-MM-YYYY HH:mm:ss',
                ),
              );
              setClickOnButton(false);
            }
          }}
        />
      )}
    </div>
  );
};

export default DueDateInput;
