import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import styles from '../../styles/modules/ProgressDropdownButton.module.scss';
import DropdownIcon from '../../assets/icons/DropdownIcon';
import TickIcon from '../../assets/icons/TickIcon';
import useFetchLabels from '../../hooks/label.hooks';
import { useDeleteTaskLabel, usePostTaskLabel } from '../../hooks/task.hooks';

function ProgressDropdownButton({ taskId, onStatusChange, taskLabel }) {
  const [categories, setCategories] = React.useState('Set label');
  const [labelId, setLabelId] = React.useState();
  const { data: labels, isFetching: isLabelsFetching } = useFetchLabels();

  const { mutateAsync: postTaskLabel } = usePostTaskLabel();
  const { mutateAsync: deleteTaskLabel } = useDeleteTaskLabel();

  const queryClient = useQueryClient();

  const handleCategory = async (id, key) => {
    if (labelId) {
      const deleteRes = await deleteTaskLabel({ taskId, labelId });
      if (deleteRes.status) {
        const data = {
          label_id: id,
        };
        const res = await postTaskLabel({ taskId, data });
        if (res.status) {
          toast.success('Label updated successfully.');
          setLabelId(res.data.id);
          onStatusChange();
          queryClient.invalidateQueries(['task_label', taskId]);
        } else {
          toast.success('Label update failed.');
        }
      } else {
        toast.success('Label update failed.');
      }
    } else {
      const data = {
        label_id: id,
      };
      const res = await postTaskLabel({ taskId, data });
      if (res.status) {
        toast.success('Label added successfully.');
        setLabelId(res.data.id);
        onStatusChange();
        queryClient.invalidateQueries(['task_label', taskId]);
      } else {
        toast.success('Label add failed.');
      }
    }

    setCategories(key);
  };

  React.useEffect(() => {
    if (taskLabel) {
      setLabelId(taskLabel?.id);
      setCategories(taskLabel.label ? taskLabel.label.label_name : 'Set label');
    } else {
      setCategories('Set label');
    }
  }, [taskLabel]);

  return (
    <Dropdown>
      <Dropdown.Toggle
        className={` ${styles.progress_dropdown_toggle} text-font d-flex flex-row align-items-center mt-3 w-100 justify-content-between`}
      >
        <div className="text-size-xs pr-4 text-darker">{categories}</div>
        <DropdownIcon color="#969696" />
      </Dropdown.Toggle>
      <Dropdown.Menu className={`mt-1 text-font ${styles.progress_menu}`}>
        {isLabelsFetching && (
          <Row>
            <Col align="center">
              <Spinner size="sm" variant="primary" animation="border" />
            </Col>
          </Row>
        )}
        {!isLabelsFetching && (
          <>
            {labels.map(label => (
              <Dropdown.Item
                className={`${styles.progress_item} px-0 px-2`}
                onSelect={() => {
                  return handleCategory(label.id, label.label_name);
                }}
              >
                <Row className="mx-0 align-items-center">
                  <Col className="px-0" xs={2} sm={2} md={2} lg={2} xl={2}>
                    {categories === label.label_name ? <TickIcon color="" /> : null}
                  </Col>
                  <Col className="px-0">
                    <span className={` ${styles.custom_text_size}`}>{label.label_name}</span>
                  </Col>
                </Row>
              </Dropdown.Item>
            ))}
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default ProgressDropdownButton;
