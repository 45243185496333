import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { Button, Card, Col, Form, ProgressBar, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import AddBrandButton from '../../components/Assets/AddBrandButton';
import RoleBased from '../../components/RoleBased';
import { useAssets } from '../../hooks/assets.hooks';
import { ROLES, SECTION_KEY } from '../../utils/enums';
import CreateAsset from './CreateAsset';
import ParentAsset from './ParentAsset';
import BoardContext from '../../contexts/BoardContext';
import AssetContext from '../../contexts/AssetContext';

function AssetsList() {
  const history = useHistory();

  const { board } = React.useContext(BoardContext);
  const assetContext = React.useContext(AssetContext);

  const { data: assets, isFetching } = useAssets(board?.id);

  const handleAssetClick = asset => {
    assetContext.push({
      name: asset?.folder_name,
      id: asset?.id,
    });
    history.push(`/dashboard/assets/details`);
  };

  const [show, setShow] = React.useState(false);
  const toggle = () => setShow(!show);

  const [sortKey, setSortKey] = React.useState('unsorted');
  const handleChangeSortKey = event => setSortKey(event.target.value);

  const sortFn = (a, b) => {
    if (sortKey === 'sort_by_name') {
      return String(a.folder_name).localeCompare(String(b.folder_name));
    }

    if (sortKey === 'sort_by_created') {
      return moment(a.created_at).diff(moment(b.created_at));
    }

    if (sortKey === 'sort_by_modified') {
      return moment(a.updated_at).diff(moment(b.updated_at));
    }

    return 0;
  };

  const refs = assets
    .filter(a => a.parent_id === null)
    .sort(sortFn)
    .reduce((acc, value) => {
      acc[value.id] = React.createRef();
      return acc;
    }, {});

  const [scrollToAsset, setScrollToAsset] = React.useState(null);
  const handleOnAdd = id => setScrollToAsset(id);

  React.useEffect(() => {
    if (scrollToAsset && refs?.[scrollToAsset] !== undefined) {
      refs[scrollToAsset].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      setScrollToAsset(null);
    }
  }, [refs, scrollToAsset]);

  return (
    <>
      <Card.Header className="my-4 bg-white px-0 px-md-3">
        <Row className="mx-0 pb-4">
          <Col xs={12} lg={{ order: 2, span: 8 }} className="py-2 py-lg-0">
            <Card.Title className="text-size-md text-darker text-center font-weight-600">
              My Assets
            </Card.Title>
            <Card.Subtitle className="text-size-xs text-secondary text-center font-weight-400">
              <span className="font-weight-600">*Note</span>: Create new company to include any
              <span className="font-weight-600"> Logo, Branding guidelines</span> for repeating
              requests.
            </Card.Subtitle>
          </Col>
          <Col
            xs={6}
            lg={{ order: 1, span: 2 }}
            className="d-flex flex-column align-items-center justify-content-center"
          >
            <RoleBased
              sectionKey={[SECTION_KEY.board_assets_add]}
              acceptedRoles={[ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.SUBSCRIBERS, ROLES.VIEWERS]}
            >
              <Button
                className="font-weight-bold d-flex flex-row align-items-center px-4"
                onClick={toggle}
              >
                <div className="text-size-xs pr-2 font-weight-600">New</div>
                <div className="">
                  <FontAwesomeIcon icon={faPlusCircle} color="white" size="md" />
                </div>
              </Button>
            </RoleBased>
          </Col>
          <Col
            xs={6}
            lg={{ order: 3, span: 2 }}
            className="d-flex flex-column align-items-center justify-content-center"
          >
            <Form.Control custom as="select" value={sortKey} onChange={handleChangeSortKey}>
              <option value="unsorted">Unsorted</option>
              <option value="sort_by_name">Sort by Name</option>
              <option value="sort_by_modified">Sort by Modified</option>
              <option value="sort_by_created">Sort by Created</option>
            </Form.Control>
          </Col>
        </Row>
      </Card.Header>

      <Card.Body>
        {isFetching && (
          <Col sm={12}>
            <ProgressBar animated variant="primary" now={100} className="mb-3" />
          </Col>
        )}

        <Row>
          <Col sm={12} lg={6} xl={4} className="mb-3">
            <AddBrandButton onAdd={toggle} />
          </Col>
          {assets
            .filter(a => a.parent_id === null)
            .sort(sortFn)
            .map(asset => (
              <Col sm={12} lg={6} xl={4} key={asset.id} className="mb-3" ref={refs[asset.id]}>
                <ParentAsset
                  parentId={asset.id}
                  name={asset.folder_name}
                  subassets={asset.sub_assets}
                  onClick={() => handleAssetClick(asset)}
                />
              </Col>
            ))}
        </Row>

        <CreateAsset show={show} onClose={toggle} onAdd={handleOnAdd} />
      </Card.Body>
    </>
  );
}

export default AssetsList;
