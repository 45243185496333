import React from 'react';

function Icon({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="23.113"
      // height="13.175"
      width={8}
      height={8}
      viewBox="0 0 23.113 13.175"
    >
      <g transform="rotate(90 60.125 -37.013)">
        <path
          fill={color}
          d="M109.84 12.7l-9.94 9.938a1.618 1.618 0 01-2.289-2.289l8.794-8.793-8.792-8.793A1.619 1.619 0 0199.9.474l9.938 9.938a1.618 1.618 0 010 2.288z"
          data-name="Path 594"
        />
      </g>
    </svg>
  );
}

export default Icon;
