import React from 'react';
import { Button, Card, Image, Row, Spinner, Table } from 'react-bootstrap';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import styles from '../../styles/modules/Subscriptions.module.scss';
import icon from '../../assets/icons/add-user.svg';
import { useSubscriptions } from '../../hooks/subscriptions.hooks';
import UserContext from '../../contexts/UserContext';
import RoleBased from '../../components/RoleBased';
import { ROLES, SECTION_KEY } from '../../utils/enums';

const SubscriptionsTable = () => {
  const history = useHistory();
  const { user } = React.useContext(UserContext);
  const { data: subscriptions, isFetching } = useSubscriptions(user?.id);
  return (
    <>
      <div className={`px-5 ${classnames(styles.subscription_table)}`}>
        <Table responsive>
          <thead>
            <tr className="text-size-xs text-font">
              <th className="font-weight-600">Subscriptions</th>
              <th className="font-weight-600">Status</th>
              <th className="font-weight-600">Next Payment</th>
              <th className="font-weight-600">Total</th>
              <th role="row" />
              <th role="row" />
            </tr>
          </thead>
          {!isFetching && (
            <tbody>
              {subscriptions.map(subscription => {
                const days = moment(subscription?.end_date).diff(moment(), 'days');
                let next_in = '';
                if (days < 0) {
                  next_in = 'Expired';
                } else if (days === 0) {
                  next_in = 'Today';
                } else if (days > 0 && days <= 5) {
                  next_in = `In ${days} days`;
                } else {
                  next_in = moment(subscription?.end_date).format('MMM DD, YYYY');
                }

                return (
                  <tr key={subscription?.id}>
                    <td className="text-darker text-font font-weight-600">
                      {subscription?.subscription_id}
                    </td>
                    <td>
                      <strong className="text-darker text-size-xs text-font">
                        {subscription?.status}
                      </strong>
                    </td>
                    <td>
                      <strong className="text-darker text-size-xs d-block text-font">
                        {next_in}
                      </strong>
                      <small className="text-darker text-size-xxs d-block text-font font-weight-500">
                        Via {subscription.payment_method}
                      </small>
                    </td>
                    <td>
                      <strong className="text-darker text-size-xs text-font">
                        USD ${subscription.total} / mo
                      </strong>
                    </td>
                    <td>
                      <RoleBased
                        sectionKey={[SECTION_KEY.subscription_view_page_full_view]}
                        acceptedRoles={[ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.SUBSCRIBERS]}
                      >
                        <Button
                          type="button"
                          className="text-size-xs btn btn-primary text-font font-weight-500 px-5"
                          onClick={() => {
                            history.push(`/dashboard/subscriptions/${subscription?.id}`, {
                              data: subscription,
                            });
                          }}
                        >
                          View
                        </Button>
                      </RoleBased>
                    </td>
                    <td>
                      <RoleBased
                        sectionKey={[SECTION_KEY.subscription_view_page_edit]}
                        acceptedRoles={[ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.SUBSCRIBERS]}
                      >
                        <Button
                          type="button"
                          variant="outline-dark"
                          className="text-size-xs text-font font-weight-500"
                          onClick={() => {
                            window.open(
                              'https://kimp360wp.codebuddy.codes/my-account/payment-methods/',
                              '_blank',
                            );
                          }}
                        >
                          Change Payment Method
                        </Button>
                      </RoleBased>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </Table>
        {!isFetching && subscriptions.length === 0 && (
          <span className="text-darker text-size-xs text-font pl-5">Active</span>
        )}
        {isFetching && (
          <Row>
            <Col align="center">
              <Spinner variant="primary" animation="border" />
            </Col>
          </Row>
        )}
      </div>

      <Card className={`mx-4 mx-md-5 mt-3 border-0 ${styles.change_payment_card}`}>
        <Card.Body className="d-flex flex-column flex-lg-row align-items-center">
          <Col xs={12} sm={12} md={12} lg={7} xl={9} className="d-flex align-items-center pl-0">
            <Image src={icon} alt="" />
            <p className="text-size-xs mb-0 ml-3 text-font font-weight-500">
              Supercharge your turnaround times by adding more Subscriptions. You&apos;ll get more
              designs completed, At a faster pace.
            </p>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={5}
            xl={3}
            className="mt-0 mt-md-2 mt-lg-0 d-flex justify-content-center"
          >
            <Button
              type="button"
              variant="outline-dark"
              className="text-size-xs text-font font-weight-500 mt-3 mt-md-0 text-font"
              onClick={() => {
                window.open(
                  'https://kimp360wp.codebuddy.codes/my-account/payment-methods/',
                  '_blank',
                );
              }}
            >
              Change Payment Method
            </Button>
          </Col>
        </Card.Body>
      </Card>
    </>
  );
};

export default SubscriptionsTable;
