import React from 'react';
import Card from 'react-bootstrap/Card';

import Feeds from '../Support/Feeds';

function NotificationFeeds() {
  return (
    <>
      <Card className="text-font no-border">
        <Card.Header className="mb-3 no-border ">
          <h3 className="text-font text-center mt-3">Notification Feeds</h3>
        </Card.Header>
        <Card.Body>
          <Feeds />
        </Card.Body>
      </Card>
    </>
  );
}

export default NotificationFeeds;
