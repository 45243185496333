import React, { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import classnames from 'classnames';
import Badge from 'react-bootstrap/Badge';
import { useHistory, useLocation, useParams } from 'react-router';
import { Overlay, Popover, Spinner } from 'react-bootstrap';
import moment from 'moment';
import RequestSection from './RequestSection';
import InProgressSection from './InProgressSection';
import InReviewSection from './InReviewSection';
import CompletedSection from './CompletedSection';
import styles from '../../styles/modules/RequestBoard.module.scss';
import arrowPointerIcon from '../../assets/icons/arrow-pointer-icon.svg';
import ChangeVisibility from './ChangeVisibility';
import RequestMenu from './RequestMenu';
import finalIcon from '../../assets/icons/final-star-icon.svg';
import starIcon from '../../assets/icons/star.svg';
import { useBoards } from '../../hooks/boards.hooks';
import { useFetchTasks } from '../../hooks/task.hooks';
import TaskDetails from './TaskDetails';
import UserContext from '../../contexts/UserContext';
import DraftSection from './DraftSection';
import { useGetUserFavorites, useUpdateUserFavorites } from '../../hooks/user.hooks';
import BoardContext from '../../contexts/BoardContext';
import { useTeams } from '../../hooks/teams.hooks';
import RoleBased from '../../components/RoleBased';
import { ROLES, SECTION_KEY } from '../../utils/enums';
import InviteButton from '../../components/InviteButton';

function RequestBoard() {
  const { id } = useParams();
  const history = useHistory();
  const { state } = useLocation();
  const { user, getRole } = React.useContext(UserContext);
  const { board: currentBoard, updateBoard } = React.useContext(BoardContext);
  const [key, setKey] = React.useState('request');
  const [displayMenu, setDisplayMenu] = React.useState(false);
  const [hideMenu, setHideMenu] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [labelFilter, setLabelFilter] = React.useState();
  const [showTeamMembers, setShowTeamMembers] = React.useState(false);

  const teamRef = React.useRef();

  const {
    data: boards,
    isFetching: isBoardFetching,
    refetch: refetchBoard,
  } = useBoards(getRole()?.role !== ROLES.SUPER_ADMIN ? user?.id : '');
  const {
    data: favorites,
    isFetching: isFavoritesFetching,
    refetch: refetchFavorites,
  } = useGetUserFavorites(user?.id);
  const { data: fetchTasks, isFetching: isTaskFetching, refetch } = useFetchTasks(id, search);
  const { data: fetchTeam, isFetching: isTeamFetching } = useTeams(`?project_id=${id}`);

  const { mutateAsync: updateFavorites, isFetching } = useUpdateUserFavorites();

  const onShowMenu = () => {
    setDisplayMenu(!displayMenu);
  };

  const onHideMenu = () => {
    setHideMenu(!hideMenu);
    setDisplayMenu(!displayMenu);
  };

  const handleSelectTask = task_id => {
    setKey('task-details');
    history.push(`/dashboard/request-history/${id}`, { taskId: task_id });
  };

  const handleClose = status => {
    history.push(`/dashboard/request-history/${id}`, { section: status });
  };

  useEffect(() => {
    if (state) {
      if (state.taskId) {
        setKey('task-details');
      } else if (state.section) {
        setKey(state.section);
      }
    } else {
      setKey('request');
    }
  }, [state]);

  useEffect(() => {
    if (!isBoardFetching && !currentBoard) {
      if (boards.filter(board => board.id === Number.parseInt(id, 10)).length === 0) {
        history.push('/dashboard/request-history');
        localStorage.setItem(`kimp-user-default-board`, undefined);
        return;
      }

      updateBoard(boards.filter(board => board.id === Number.parseInt(id, 10))[0]);
      localStorage.setItem(`kimp-user-default-board`, id);
    }
  }, [isBoardFetching]);

  const onClickFavorites = async () => {
    const data = {
      is_favorite:
        favorites?.filter(favorite => favorite.project_id === Number.parseInt(id, 10)).length > 0
          ? 0
          : 1,
      project_id: id,
    };
    const res = await updateFavorites({ userId: user?.id, data });
    if (res.status) {
      refetchFavorites();
    }
  };

  const filterLabel = data => {
    if (labelFilter) {
      return data?.label?.label?.label_name === labelFilter;
    }

    return true;
  };

  return (
    <Card className={`text-font position-relative ${styles.overflow_box} mt-4`}>
      <Card.Header
        className={`d-flex flex-row align-items-center justify-content-around shadow mb-4 flex-wrap ${styles.overflow_header}`}
      >
        <RequestMenu
          displayMenu={displayMenu}
          onHideMenu={onHideMenu}
          hideMenu={hideMenu}
          onSearch={setSearch}
          onLabelFilter={setLabelFilter}
          reload={refetch}
        />

        <div className="d-flex flex-row align-items-center justify-content-center flex-wrap mb-2  mb-xl-0">
          <Card.Title className="text-size-sm font-weight-700 m-0 mr-2 mt-3 mb-2 mb-lg-0">
            <Form.Group>
              <Form.Control
                as="select"
                placeholder="Select your Board"
                className={classnames(boards.length === 1 && 'board-arrw')}
                disabled={boards.length === 1}
                value={id}
                onChange={async e => {
                  localStorage.setItem(`kimp-user-default-board`, e.target.value);
                  updateBoard(
                    boards.filter(board => board.id === Number.parseInt(e.target.value, 10))[0],
                  );
                  window.location.href = '/dashboard/request-history';
                }}
              >
                {boards.map(project => (
                  <option value={project.id} key={project.id}>
                    {project.title}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Card.Title>
          <Button
            type="button"
            variant="white"
            className={`text-secondary mx-3 mb-2 mb-lg-0 p-0 d-flex flex-row justify-content-center align-items-center ${styles.final_star_icon_size}`}
            onClick={onClickFavorites}
          >
            {(isFavoritesFetching || isFetching) && (
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            )}
            {!(isFavoritesFetching || isFetching) && (
              <>
                {favorites?.filter(favorite => favorite.project_id === Number.parseInt(id, 10))
                  .length > 0 ? (
                  <img src={starIcon} alt="star" width="15" />
                ) : (
                  <img src={finalIcon} alt="star" width="15" />
                )}
              </>
            )}
          </Button>
          <RoleBased
            sectionKey={[SECTION_KEY.board_edit]}
            acceptedRoles={[
              ROLES.SUPER_ADMIN,
              ROLES.ADMIN,
              ROLES.CONTRIBUTORS,
              ROLES.EDITORS,
              ROLES.MANAGERS,
            ]}
          >
            <ChangeVisibility refetch={refetchBoard} />
          </RoleBased>
          <Row
            ref={teamRef}
            className="d-flex align-items-center mx-3 mb-2 mt-0 mt-md-2 mt-lg-0 mb-md-0 cursor-pointer"
            onClick={() => {
              setShowTeamMembers(true);
            }}
          >
            {isTeamFetching && <Spinner size="sm" variant="primary" animation="border" />}
            {!isTeamFetching && fetchTeam[0]?.members.filter(member => member?.user).length > 0 && (
              <>
                {fetchTeam[0]?.members.filter(member => member?.user).length <= 3 ? (
                  fetchTeam[0]?.members
                    .filter(member => member?.user)
                    .map(member => (
                      <img
                        key={member?.id}
                        src={`https://ui-avatars.com/api/?name=${member?.user?.first_name}+${member?.user?.last_name}&background=219186&color=fff`}
                        alt="user badge"
                        className={styles.circle_badge}
                      />
                    ))
                ) : (
                  <>
                    {fetchTeam[0]?.members
                      .filter(member => member?.user)
                      .slice(0, 3)
                      .map(member => (
                        <img
                          key={member?.id}
                          src={`https://ui-avatars.com/api/?name=${member?.user?.first_name}+${member?.user?.last_name}&background=219186&color=fff`}
                          alt="user badge"
                          className={styles.circle_badge}
                        />
                      ))}
                    <Badge pill variant="secondary" className={styles.circle_badge}>
                      +{fetchTeam[0]?.members.filter(member => member?.user).length - 3}
                    </Badge>
                  </>
                )}
              </>
            )}
          </Row>
          <Overlay
            target={teamRef.current}
            show={showTeamMembers}
            placement="bottom-end"
            flip
            rootClose
            rootCloseEvent="click"
            onHide={() => setShowTeamMembers(false)}
          >
            {props => (
              <Popover {...props} className="text-font px-2 py-2 custom-popover w-100">
                <Popover.Content>
                  {fetchTeam[0]?.members
                    .filter(member => member?.user)
                    .map(member => (
                      <Row
                        key={member}
                        className="text-size-xs align-items-center font-weight-500 px-3 py-2"
                      >
                        <img
                          src={`https://ui-avatars.com/api/?name=${member?.user?.first_name}+${member?.user?.last_name}&background=219186&color=fff`}
                          alt="user badge"
                          className={`mr-2 ${styles.circle_badge}`}
                        />
                        {member?.user?.username
                          ? member?.user?.username
                          : `${member?.user?.first_name} ${member?.user?.last_name}`}
                      </Row>
                    ))}
                </Popover.Content>
              </Popover>
            )}
          </Overlay>
          <RoleBased
            sectionKey={[
              SECTION_KEY.invite_client_associates_to_board_and_view_them_add,
              SECTION_KEY.invite_clients_associates_to_board_add,
              SECTION_KEY.invite_clients_to_board_add,
              SECTION_KEY.invite_kimp_members_to_board_add,
            ]}
            acceptedRoles={[ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.SUBSCRIBERS]}
          >
            <InviteButton
              className={`text-size-xs text-secondary font-weight-600 ml-2 px-4 py-0 py-1 mt-lg-2 mt-xl-0 ${styles.header_btn}`}
              from="board"
              buttonLabel="Invite"
              variant="light"
            />
          </RoleBased>
        </div>

        <RoleBased
          sectionKey={[
            SECTION_KEY.task_show_menu_options_add,
            SECTION_KEY.task_show_menu_options_edit,
            SECTION_KEY.task_show_menu_options_delete,
            SECTION_KEY.task_show_menu_options_full_view,
          ]}
          acceptedRoles={[ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.MANAGERS, ROLES.EDITORS]}
        >
          <Button
            type="button"
            variant="light"
            className={`text-size-xs text-secondary font-weight-600 px-3 py-0 py-1 ${styles.header_btn}`}
            onClick={() => onShowMenu()}
          >
            Show Menu
          </Button>
        </RoleBased>
      </Card.Header>

      <Tab.Container activeKey={key}>
        <Card.Header className={classnames(styles.history_tab, 'no-border pb-0 mt-3')}>
          <Row className="mx-0">
            <Col
              className="text-size-xs d-flex flex-column align-items-center"
              xs={key === 'draft' && { order: 1 }}
              sm={key === 'draft' && { order: 1 }}
              lg={{ span: 3, order: 1 }}
              xl={{ order: 1 }}
            >
              <button
                type="button"
                onClick={() => setKey('draft')}
                className={`mb-1 ${classnames(key === 'draft' && styles.active)}`}
              >
                Drafts
              </button>
              <img
                src={arrowPointerIcon}
                alt="arrow"
                className={`mt-2 ${classnames(key === 'draft' && styles.show)}`}
              />
            </Col>
            <Col
              className="text-size-xs d-flex flex-column align-items-center"
              xs={key === 'request' && { order: 1 }}
              sm={key === 'request' && { order: 1 }}
              lg={{ span: 3, order: 1 }}
              xl={{ order: 1 }}
            >
              <button
                type="button"
                onClick={() => setKey('request')}
                className={`mb-1 ${classnames(key === 'request' && styles.active)}`}
              >
                Requests
              </button>
              <img
                src={arrowPointerIcon}
                alt="arrow"
                className={`mt-2 ${classnames(key === 'request' && styles.show)}`}
              />
            </Col>
            <Col
              className="text-size-xs d-flex flex-column align-items-center "
              xs={key === 'in-progress' && { order: 1 }}
              sm={key === 'in-progress' && { order: 1 }}
              lg={{ span: 3, order: 2 }}
              xl={{ order: 2 }}
            >
              <button
                type="button"
                onClick={() => setKey('in-progress')}
                className={`mb-1 ${classnames(key === 'in-progress' && styles.active)}`}
              >
                In Progress
              </button>
              <img
                src={arrowPointerIcon}
                alt="arrow"
                className={`mt-2 ${classnames(key === 'in-progress' && styles.show)}`}
              />
            </Col>
            <Col
              className="text-size-xs d-flex flex-column align-items-center "
              xs={key === 'in-review' && { order: 1 }}
              sm={key === 'in-review' && { order: 1 }}
              lg={{ span: 3, order: 3 }}
              xl={{ order: 3 }}
            >
              <button
                type="button"
                onClick={() => setKey('in-review')}
                className={`mb-1 ${classnames(key === 'in-review' && styles.active)}`}
              >
                In Review
              </button>
              <img
                src={arrowPointerIcon}
                alt="arrow"
                className={`mt-2 ${classnames(key === 'in-review' && styles.show)}`}
              />
            </Col>
            <Col
              className="text-size-xs d-flex flex-column align-items-center"
              xs={key === 'completed' && { order: 1 }}
              sm={key === 'completed' && { order: 1 }}
              lg={{ span: 3, order: 4 }}
              xl={{ order: 4 }}
            >
              <button
                type="button"
                onClick={() => setKey('completed')}
                className={`mb-1 ${classnames(key === 'completed' && styles.active)}`}
              >
                Completed
              </button>
              <img
                src={arrowPointerIcon}
                alt="arrow"
                className={`mt-2 ${classnames(key === 'completed' && styles.show)}`}
              />
            </Col>
          </Row>
        </Card.Header>

        <Card.Body className={classnames('px-xl-5 pt-0 mt-3', styles.card_body_request_history)}>
          {isTaskFetching && (
            <div className="mb-3 text-center">
              <Spinner animation="border" variant="primary" size="sm" />
            </div>
          )}

          <Tab.Content>
            <Tab.Pane eventKey="draft">
              <DraftSection
                onHandleScreen={setKey}
                tasks={fetchTasks
                  .filter(filterLabel)
                  .filter(task => task.is_publish === 0 && task.is_archived === 0)
                  .sort((a, b) => moment(b.updated_at).diff(moment(a.updated_at)))}
                handleSelectTask={handleSelectTask}
                onStatusChange={refetch}
                isTaskFetching={isTaskFetching}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="request">
              <RequestSection
                onHandleScreen={setKey}
                tasks={fetchTasks
                  .filter(filterLabel)
                  .filter(
                    task =>
                      task.is_publish === 1 && task.is_archived === 0 && task.status === 'request',
                  )
                  .sort((a, b) => moment(b.updated_at).diff(moment(a.updated_at)))}
                handleSelectTask={handleSelectTask}
                onStatusChange={refetch}
                isTaskFetching={isTaskFetching}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="in-progress">
              <InProgressSection
                onHandleScreen={setKey}
                tasks={fetchTasks
                  .filter(filterLabel)
                  .filter(
                    task =>
                      task.is_publish === 1 &&
                      task.is_archived === 0 &&
                      task.status === 'in-progress',
                  )
                  .sort((a, b) => moment(b.updated_at).diff(moment(a.updated_at)))}
                handleSelectTask={handleSelectTask}
                onStatusChange={refetch}
                isTaskFetching={isTaskFetching}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="in-review">
              <InReviewSection
                onHandleScreen={setKey}
                tasks={fetchTasks
                  .filter(filterLabel)
                  .filter(
                    task =>
                      task.is_publish === 1 &&
                      task.is_archived === 0 &&
                      task.status === 'in-review',
                  )
                  .sort((a, b) => moment(b.updated_at).diff(moment(a.updated_at)))}
                handleSelectTask={handleSelectTask}
                onStatusChange={refetch}
                isTaskFetching={isTaskFetching}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="completed">
              <CompletedSection
                onHandleScreen={setKey}
                tasks={fetchTasks
                  .filter(filterLabel)
                  .filter(
                    task =>
                      task.is_publish === 1 &&
                      task.is_archived === 0 &&
                      task.status === 'completed',
                  )
                  .sort((a, b) => moment(b.updated_at).diff(moment(a.updated_at)))}
                handleSelectTask={handleSelectTask}
                onStatusChange={refetch}
                isTaskFetching={isTaskFetching}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="task-details">
              {state?.taskId && (
                <TaskDetails
                  onHandleScreen={setKey}
                  taskId={state?.taskId}
                  onClose={handleClose}
                  onStatusChange={refetch}
                />
              )}
            </Tab.Pane>
          </Tab.Content>
        </Card.Body>
      </Tab.Container>
    </Card>
  );
}

export default RequestBoard;
