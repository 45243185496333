import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';
import ProgressBar from 'react-bootstrap/ProgressBar';
import InputGroup from 'react-bootstrap/InputGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { Col, Pagination, Row, Spinner } from 'react-bootstrap';
import { useLocation } from 'react-router';
import styles from '../../styles/modules/Support.module.scss';
import searchFeedIcon from '../../assets/icons/search-feed-icon.svg';
import popoverIcon from '../../assets/icons/popover-downarrow-icon.svg';
import AddMembersPopover from '../../components/AddMembersPopover';
import { useFeeds, useOnMarkFeeds } from '../../hooks/feeds.hooks';
import useFetchClient from '../../hooks/client.hooks';
import RoleBased from '../../components/RoleBased';
import { ROLES, SECTION_KEY } from '../../utils/enums';
import PaginationView from '../../components/PaginationView';

function Feeds() {
  const { search } = useLocation();
  const queryClient = useQueryClient();
  // const [currentPage, setCurrentPage] = useState(1);
  const { data: feeds, isFetching } = useFeeds(
    new URLSearchParams(search).get('page') !== null ? new URLSearchParams(search).get('page') : 1,
  );
  const [searchTerm, setSearchTerm] = useState('');
  const { data: managers, isFetching: isManagersLoading } = useFetchClient('managers', '');
  const { mutateAsync: onMarkFeeds } = useOnMarkFeeds();
  const TIME_FORMAT = 'MMMM Do YYYY, h:mm a';

  const onMarkNotification = async (id, value, e) => {
    if (e.detail === 1) {
      const data = {
        is_read: value,
      };
      try {
        await onMarkFeeds({ id, data });
        toast.success('Marked as read');
        queryClient.invalidateQueries('feeds');
      } catch (err) {
        toast.error(err?.message);
      }
    }
  };

  const onUndoNotification = async (id, value, e) => {
    if (e.detail === 1) {
      const data = {
        is_read: value,
      };
      try {
        await onMarkFeeds({ id, data });
        toast.success('Marked as unread');
        queryClient.invalidateQueries('feeds');
      } catch (err) {
        toast.error(err?.message);
      }
    }
  };

  const pageItem = [];
  pageItem.push(
    <Pagination.Item active={1} key={1}>
      {isFetching && <Spinner animation="grow" size="sm" />}
      {!isFetching && feeds?.current_page}
    </Pagination.Item>,
  );

  const unReadFeedsCount = () => {
    return feeds?.data?.filter(val => val.is_read === 0).length;
  };

  const readFeedsCount = () => {
    return feeds?.data?.filter(val => val.is_read === 1).length;
  };

  const filterByUnread = val => {
    if (val?.is_read === 0) {
      if (searchTerm === '') {
        return val;
      }

      return true;
    }

    return false;
  };

  const filterByRead = val => {
    if (val?.is_read === 1) {
      if (searchTerm === '') {
        return val;
      }

      return true;
    }

    return false;
  };

  const filterByName = item => {
    return item.notification?.from_user?.name.toLowerCase().includes(searchTerm.toLowerCase());
  };

  return (
    <Card className="text-font no-border">
      <Card.Header className="mb-3 no-border d-flex flex-row justify-content-center justify-content-lg-between align-items-center flex-wrap">
        <h4 className="text-size-sm font-weight-600 mb-0 text-center mb-2 mb-lg-0">
          New Notifications
        </h4>
        <RoleBased
          sectionKey={[SECTION_KEY.notification_filters_full_view]}
          acceptedRoles={[ROLES.SUPER_ADMIN, ROLES.ADMIN]}
        >
          <div className="d-flex flex-row align-items-center justify-content-center flex-wrap flex-lg-nowrap">
            <Button className="p-0" variant="white" onClick={() => setSearchTerm('Super Admin')}>
              <p className={`text-size-xs mb-0 font-weight-600 px-3 ${styles.border_right}`}>
                Mine
              </p>
            </Button>
            <Button className="p-0" variant="white" onClick={() => setSearchTerm('')}>
              <p className={`text-size-xs mb-0 font-weight-600 px-3 ${styles.border_right}`}>
                Everyones
              </p>
            </Button>
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={AddMembersPopover}
              rootClose
              popperConfig={{
                name: 'feeds',
                list: managers.data,
                isLoading: isManagersLoading,
                onChange: setSearchTerm,
              }}
            >
              <Button
                variant="white"
                className={`text-size-xs d-flex font-weight-600 px-3 justify-content-between align-items-center py-0 `}
              >
                <span className="pr-1">PM</span>
                <img src={popoverIcon} alt="downarrow" width="10" />
              </Button>
            </OverlayTrigger>

            <InputGroup className={classnames(`d-flex justify-content-center my-2 my-lg-0 `)}>
              <div className={classnames(`d-flex ${styles.input_group_border_color}`)}>
                <Form.Control
                  custom
                  value={searchTerm}
                  placeholder="Type your client name"
                  onChange={e => setSearchTerm(e.target.value)}
                  className={`text-size-xxs  pl-2 font-weight-500 ${classnames(
                    styles.custom_right_border,
                    styles.custom_search_bar,
                  )}`}
                />
                <InputGroup.Append>
                  <InputGroup.Text
                    className={`bg-white ${classnames(
                      styles.no_left_border,
                      styles.custom_search_icon_bar,
                    )} `}
                  >
                    <img
                      src={searchFeedIcon}
                      alt="search"
                      className={classnames(styles.custom_search_icon)}
                    />
                  </InputGroup.Text>
                </InputGroup.Append>
              </div>
            </InputGroup>
          </div>
        </RoleBased>
      </Card.Header>
      {isFetching && <ProgressBar animated variant="primary" now={100} className="mb-3" />}

      {unReadFeedsCount() === 0 && (
        <div className="text-center">There are no new notifications.</div>
      )}

      {feeds?.data?.filter(filterByName).length === 0 && (
        <div className="text-font text-center">No records found</div>
      )}

      {feeds?.data
        ?.filter(filterByUnread)
        .filter(filterByName)
        .map(item => {
          return (
            <Card
              className="mb-3 mx-md-3 shadow cursor-pointer"
              key={item.id}
              onClick={e => onMarkNotification(item?.id, 1, e)}
            >
              <Card.Body className="d-flex flex-row justify-content-between">
                <div className="d-flex align-items-center">
                  <Card.Img
                    src={`https://ui-avatars.com/api/?name=${item?.notification?.from_user?.first_name}+${item?.notification?.from_user?.last_name}&background=219186&color=fff`}
                    alt="user"
                    className="user-avatar-icon"
                  />
                  <Card.Text className="text-size-xs mb-0 pl-3">
                    <span className=" font-weight-500">{item.notification.from_user?.name}</span>:{' '}
                    {item.notification.text}
                  </Card.Text>
                </div>
                <div className="d-flex align-items-center">
                  <Card.Text className="text-size-xs  mb-0 mr-md-4">
                    {moment(item.created_at).format(TIME_FORMAT)}
                  </Card.Text>
                  <Form.Check type="checkbox" custom id={uuid()} className="circle" disabled />
                </div>
              </Card.Body>
            </Card>
          );
        })}

      <Card.Header className="mb-3 mt-3 mt-md-4 mt-lg-4 mt-xl-4 no-border text-left">
        <span className="text-size-sm font-weight-600">Read Notifications</span>
      </Card.Header>

      {isFetching && <ProgressBar animated variant="primary" now={100} className="mb-3" />}

      {readFeedsCount() === 0 && <div className="text-center">There are no new notifications.</div>}

      {feeds?.data
        ?.filter(filterByRead)
        .filter(filterByName)
        .map(item => {
          return (
            <Card
              key={item.id}
              className="mx-md-3 no-border cursor-pointer"
              onClick={e => onUndoNotification(item?.id, 0, e)}
            >
              <Card.Body
                className={`d-flex flex-row justify-content-between ${styles.border_bottom}`}
              >
                <div className="d-flex align-items-center">
                  <Card.Img
                    src={`https://ui-avatars.com/api/?name=${item?.notification?.from_user?.first_name}+${item?.notification?.from_user?.last_name}&background=219186&color=fff`}
                    alt="user"
                    className="user-avatar-icon"
                  />
                  <Card.Text className="text-size-xs mb-0 pl-3">
                    <span className=" font-weight-500">{item.notification.from_user?.name}</span>
                    <span className="text-secondary">: {item.notification.text}</span>
                  </Card.Text>
                </div>
                <div className={`d-flex align-items-center ${styles.custom_div}`}>
                  <Card.Text className="text-size-xs text-secondary mb-0 mr-md-4">
                    {moment(item.created_at).format(TIME_FORMAT)}
                  </Card.Text>
                  <Form.Check
                    type="checkbox"
                    custom
                    id={uuid()}
                    className="circle greencircle"
                    defaultChecked
                    disabled
                  />
                </div>
              </Card.Body>
            </Card>
          );
        })}

      {!isFetching && feeds.data.length > 0 && (
        <Row>
          <Col align="right" className="mx-lg-5">
            <PaginationView
              pageCount={feeds.last_page}
              currentPage={Number.parseInt(feeds.current_page, 10)}
            />
          </Col>
        </Row>
      )}
    </Card>
  );
}

export default Feeds;
