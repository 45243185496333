import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import classNames from 'classnames';
import DropDownDeleteDowload from '../../components/DropDownDeleteDowload';
import { useDeleteAsset } from '../../hooks/assets.hooks';
import AssetImage from './AssetImage';
import { useConfirmation } from '../../components/Confirmation/ConfirmationService';
import { fetchAssetDownloadURL } from '../../requests/assets.requests';
import styles from '../../styles/modules/AddAssets.module.scss';

const ParentAsset = ({ onClick, name, parentId, subassets }) => {
  const confirm = useConfirmation();
  const queryClient = useQueryClient();
  const { mutateAsync: deleteAsset } = useDeleteAsset();

  const handleDelete = async () => {
    await confirm({
      title: 'Confirm',
      description: 'Are you sure you want to delete this asset?',
    });

    try {
      await deleteAsset(parentId);
      toast.success(`Asset ${name} deleted successfully!`);
      queryClient.invalidateQueries('assets');
    } catch (err) {
      toast.error(err?.message);
    }
  };

  const handleDownload = async () => {
    try {
      const res = await fetchAssetDownloadURL(parentId);
      window.open(res.download_url, '_blank');
    } catch (err) {
      toast.error(err?.message);
    }
  };

  return (
    <Card className="rounded-lg shadow border d-flex flex-column h-100">
      <Card.Header className="bg-white text-center mx-3">
        <Card.Title className={classNames('btn btn-link d-block mb-0')}>
          <button
            type="button"
            onClick={onClick}
            className={classNames(
              styles.asset_name,
              'bg-transparent border-0 text-size-sm font-weight-600',
            )}
          >
            {name}
          </button>
        </Card.Title>
      </Card.Header>
      <DropDownDeleteDowload onDelete={handleDelete} onDownload={handleDownload} />

      <Card.Body>
        <Row>
          {subassets.length === 0 && (
            <Col sm={12} className="text-center mb-2">
              <p className="text-muted">No sub assets present</p>
            </Col>
          )}

          {subassets
            .filter((_, i) => i < 4)
            .map(asset => (
              <Col xs={6} xl={4} key={asset.id} className="mb-3">
                <AssetImage name={asset.folder_name} onClick={onClick} />
              </Col>
            ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ParentAsset;
