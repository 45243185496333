import React, { useContext, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import classnames from 'classnames';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styles from '../../styles/modules/RequestBoard.module.scss';
import { useUpdateTask } from '../../hooks/requestHistory.hooks';
import RoleBased from '../../components/RoleBased';
import { ROLES, SECTION_KEY } from '../../utils/enums';
import UserContext from '../../contexts/UserContext';
import { reorderTasks } from '../../requests/tasks.request';

function InReviewSection({
  onHandleScreen,
  tasks,
  handleSelectTask,
  onStatusChange = () => {},
  isTaskFetching,
}) {
  const { mutateAsync: updateTask, isLoading } = useUpdateTask();
  const [activeButtonState, setActiveButtonState] = useState(null);

  const userContext = useContext(UserContext);

  const { id: boardId } = useParams();
  const queryClient = useQueryClient();

  const [processingId, setProcessingId] = useState();

  const history = useHistory();

  const navigateTo = () => {
    history.push('/dashboard/feedback');
  };

  const handleMoveComplete = async id => {
    try {
      setProcessingId(id);
      setActiveButtonState('completed');
      const data = { 'status': 'completed', order: 0 };
      await updateTask({ id, data });
      queryClient.invalidateQueries(['boardTasks', boardId]);
      if (
        userContext.getRole()?.permissions.includes(SECTION_KEY.task_completion_feedback_full_view)
      ) {
        navigateTo();
      } else {
        onHandleScreen('completed');
      }

      onStatusChange();
      setProcessingId(undefined);
    } catch (error) {
      toast.error(error?.message);
      setProcessingId(undefined);
    }
  };

  const handleMoveRequest = async id => {
    try {
      setProcessingId(id);
      setActiveButtonState('request');
      const data = { 'status': 'request', order: 0 };
      await updateTask({ id, data });
      queryClient.invalidateQueries(['boardTasks', boardId]);
      onHandleScreen('request');
      onStatusChange();
      setProcessingId(undefined);
    } catch (error) {
      toast.error(error?.message);
      setProcessingId(undefined);
    }
  };

  const [items, setItems] = React.useState([]);
  // React.useEffect(() => console.log(items.map(({ title, order }) => ({ title, order }))), [items]);

  React.useEffect(() => {
    if (!isTaskFetching) setItems(tasks.sort((a, b) => a.order - b.order));
  }, [tasks, isTaskFetching]);

  const handleOnDragEnd = async result => {
    const arr = Array.from(items);
    const [reorderedItem] = arr.splice(result.source.index, 1);
    arr.splice(result.destination.index, 0, reorderedItem);

    setItems(arr.map((i, index) => ({ ...i, order: index })).sort((a, b) => a.order - b.order));

    try {
      await reorderTasks({
        orders: arr.map(({ id }, index) => ({ task_id: id, order: index })),
      });
      onStatusChange();
    } catch (err) {
      //
    }
  };

  return (
    <>
      {items.length === 0 && <div className="text-center">No task is in Review.</div>}
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="tasks">
          {provided => (
            <ul className="list-unstyled" {...provided.droppableProps} ref={provided.innerRef}>
              {items.map((item, index) => {
                const { id, title } = item;
                return (
                  <Draggable key={item.id} draggableId={String(item.id)} index={index}>
                    {prov => (
                      <li ref={prov.innerRef} {...prov.draggableProps} {...prov.dragHandleProps}>
                        <Card
                          className="shadow mb-3 text-font px-4 mx-lg-5 mouse-hover"
                          key={id}
                          onClick={e => {
                            const element_list = [
                              ...document.getElementsByClassName('details'),
                            ].filter(element => element.contains(e.target));
                            if (
                              !e.target.classList.contains('details') &&
                              element_list.length === 0
                            ) {
                              handleSelectTask(id);
                            }
                          }}
                        >
                          <Card.Body className="d-flex flex-row justify-content-center justify-content-lg-between align-items-center flex-wrap px-0 px-xl-3">
                            <Col className="px-0" xs={12} sm={12} md={12} lg={5} xl={6}>
                              <button type="button" className="bg-transparent border-0 text-left">
                                <h5 className="text-size-sm font-weight-700 mb-0">{title}</h5>
                              </button>
                            </Col>
                            <RoleBased
                              sectionKey={[SECTION_KEY.task_status_change_edit]}
                              acceptedRoles={[
                                ROLES.SUPER_ADMIN,
                                ROLES.ADMIN,
                                ROLES.MANAGERS,
                                ROLES.SUBSCRIBERS,
                                ROLES.VIEWERS,
                              ]}
                            >
                              <Col className=" px-0" xs={12} sm={12} md={12} lg={7} xl={6}>
                                <Row className="mx-0 justify-content-center justify-content-lg-between justify-content-xl-end align-items-center">
                                  <Button
                                    variant="warning"
                                    className={`text-size-xs text-light  mr-md-3 mx-lg-0 mr-xl-3 mb-2 mb-md-0 py-0 py-1 mx-1 font-weight-600 details ${classnames(
                                      styles.revision_btn,
                                      styles.btn_structure,
                                    )}`}
                                    onClick={e => {
                                      handleMoveRequest(id, e);
                                    }}
                                    disabled={
                                      activeButtonState === 'request' &&
                                      isLoading &&
                                      processingId === id
                                    }
                                  >
                                    Revision Needed
                                  </Button>
                                  <Button
                                    className={`text-size-xs py-0 py-1 ml-md-3 mx-lg-0 ml-xl-3 mb-2 mb-md-0 font-weight-600 mx-1 details ${styles.btn_structure}`}
                                    onClick={e => {
                                      handleMoveComplete(id, e);
                                    }}
                                    disabled={
                                      activeButtonState === 'completed' &&
                                      isLoading &&
                                      processingId === id
                                    }
                                  >
                                    Mark as Completed
                                  </Button>
                                </Row>
                              </Col>
                            </RoleBased>
                          </Card.Body>
                        </Card>
                      </li>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

export default InReviewSection;
