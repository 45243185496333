import * as React from 'react';

function DownloadIcon({ props, color }) {
  return (
    <svg
      data-name="download (1)"
      xmlns="http://www.w3.org/2000/svg"
      width={10.74}
      height={10.74}
      viewBox="0 0 10.74 10.74"
      {...props}
    >
      <g data-name="Group 717">
        <g data-name="Group 716">
          <path
            data-name="Path 1104"
            d="M8.538 3.818H6.965V.315A.315.315 0 006.65 0H4.091a.315.315 0 00-.315.315v3.5H2.203a.315.315 0 00-.242.516L5.15 8.11a.315.315 0 00.483 0l3.146-3.776a.315.315 0 00-.241-.516z"
            // fill="#969696"
            fill={color}
          />
        </g>
      </g>
      <g data-name="Group 719">
        <g data-name="Group 718">
          <path
            data-name="Path 1105"
            d="M10.426 6.335H9.167a.314.314 0 00-.315.315v2.2H1.888v-2.2a.314.314 0 00-.315-.315H.315A.314.314 0 000 6.65v3.147a.945.945 0 00.944.944H9.8a.945.945 0 00.944-.944V6.65a.314.314 0 00-.318-.315z"
            // fill="#969696"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}

export default DownloadIcon;
