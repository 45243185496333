import React from 'react';
import { Card, Dropdown, Image } from 'react-bootstrap';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import visa from '../../assets/icons/visa.svg';
import check from '../../assets/icons/check.svg';
import styles from '../../styles/modules/PaymentMethod.module.scss';

const PaymentCard = ({ isDefault }) => {
  return (
    <Card
      className={classnames(
        isDefault ? styles.default_payment_card : styles.payment_card,
        'shadow mb-3 mr-0 mr-md-3',
      )}
    >
      <Card.Body>
        <div className="d-flex justify-content-between">
          <Image src={visa} alt="" width="94" height="30" />
          {isDefault ? (
            <Image src={check} alt="" width="24" height="24" />
          ) : (
            <Dropdown>
              <Dropdown.Toggle variant="secondary" className={styles.card_dropdown_toggle}>
                <FontAwesomeIcon icon={faEllipsisH} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>Set As Default</Dropdown.Item>
                <Dropdown.Item>Delete this Card</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
        <h6 className="my-4 text-secondary">**** **** **** 1234</h6>

        <div className="d-flex justify-content-between align-items-center">
          <h6 className="text-secondary mb-0">YOUR NAME</h6>
          <h6 className="text-secondary mb-0">
            <small>Expires 10-2025</small>
          </h6>
        </div>
      </Card.Body>
    </Card>
  );
};

PaymentCard.defaultProps = {
  isDefault: false,
};

export default PaymentCard;
