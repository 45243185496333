import React, { useContext, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { v4 as uuid } from 'uuid';
import { Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import styles from '../../styles/modules/TeamAccess.module.scss';
import TeamAccessRoleDropdown from '../../components/TeamAccessRoleDropdown';
import { useDeleteMembers, useMembers } from '../../hooks/teams.hooks';
import UserContext from '../../contexts/UserContext';
import { ROLES } from '../../utils/enums';

function TeamAccess() {
  const userContext = useContext(UserContext);
  const { data: members, isFetching, refetch } = useMembers();
  const { mutateAsync: deleteMember, isLoading } = useDeleteMembers();

  const [currentIndex, setCurrentIndex] = useState(-1);
  const onHandleCurrentIndex = index => {
    setCurrentIndex(index);
  };

  const removeUserFromTeam = async item => {
    try {
      const res = await deleteMember({ teamId: item.team_id, userId: item.user_id });
      if (res.status) toast.success('User removed successfully.');
      setCurrentIndex(-1);
      refetch();
    } catch (err) {
      toast.error(err);
    }
  };

  return (
    <Card className="mt-4">
      <Card.Header className="bg-white py-4">
        <Card.Title className="text-size-md text-center text-font font-weight-600">
          Team Access
        </Card.Title>
        <Card.Subtitle className="text-size-xs text-secondary text-font text-center font-weight-500">
          Here is the list of team members where you can make changes as per your requirements.
        </Card.Subtitle>
      </Card.Header>
      <Card.Body className="w-75 align-self-center my-4">
        <Table responsive="xl" bordered>
          <thead>
            <tr className={`text-size-xs ${styles.header}`}>
              <th className="font-weight-600 text-font text-darker">Team Members</th>
              <th className="font-weight-600 text-font text-darker">Role</th>
              <th className="font-weight-600 text-font text-darker text-center">Action</th>
            </tr>
          </thead>
          {!isFetching && members.filter(member => member.user).length > 0 && (
            <tbody>
              {members
                .filter(member => member.user)
                .map((item, index) => {
                  const { user } = item;

                  return (
                    <tr key={uuid()}>
                      <td className="no-wrap text-font">
                        <div className="d-flex flex-row align-items-center ">
                          <div className="px-3">
                            <img
                              src={`https://ui-avatars.com/api/?name=${user?.first_name}+${user?.last_name}&background=219186&color=fff`}
                              alt="user"
                              className={`rounded-circle ${styles.icon_size}`}
                            />
                          </div>
                          <div>
                            <div className="text-darker text-size-xs font-weight-600">
                              {user?.name}
                            </div>
                            <div className="text-darker text-size-xxs">{user?.email}</div>
                          </div>
                        </div>
                      </td>
                      <td className="text-size-xs no-wrap text-font">
                        <div className=" d-flex flex-row align-items-center justify-content-start">
                          <TeamAccessRoleDropdown
                            selected={user?.roles[0]?.name}
                            userId={user?.id}
                            disabled={
                              userContext.user?.id === user?.id ||
                              [ROLES.SUPER_ADMIN].includes(user?.roles[0]?.name)
                            }
                          />
                        </div>
                      </td>
                      <td className="text-size-xs no-wrap text-font text-left text-center">
                        <div className="d-flex flex-row">
                          <Col className="pl-0 underline-text font-weight-500 pl-4">
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <Button
                              type="button"
                              variant="white"
                              className={classnames(
                                `text-danger font-weight-500 underline-text  `,
                                styles.on_focus_btn,
                                styles.btn_structure,
                              )}
                              onClick={() => {
                                onHandleCurrentIndex(index);
                                removeUserFromTeam(item);
                              }}
                              disabled={
                                userContext.user?.id === user?.id ||
                                [ROLES.SUPER_ADMIN].includes(user?.roles[0]?.name)
                              }
                            >
                              {currentIndex === index && (
                                <Spinner
                                  variant="primary"
                                  animation="border"
                                  size="sm"
                                  className=""
                                />
                              )}
                              {!(currentIndex === index) && <>Remove</>}
                            </Button>
                          </Col>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          )}
        </Table>
        {!(isFetching || isLoading) && members.filter(member => member.user).length === 0 && (
          <div className="text-darker text-size-xs font-weight-600">No team member found.</div>
        )}
        {isFetching && (
          <Row>
            <Col align="center">
              <Spinner variant="primary" animation="border" />
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
}

export default TeamAccess;
