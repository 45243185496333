import React, { useContext, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Media from 'react-bootstrap/Media';
import Form from 'react-bootstrap/Form';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { Collapse, OverlayTrigger, Spinner, Tooltip, Modal } from 'react-bootstrap';
import moment from 'moment';
import CloseIcon from '../../assets/icons/CloseIcon';
import descriptionIcon from '../../assets/icons/description-icon.svg';
import styles from '../../styles/modules/RequestBoard.module.scss';
import HistoryOfChangesSection from './HistoryOfChangesSection';
import smileyIcon from '../../assets/icons/smiley-icon.svg';
import DropdownIcon from '../../assets/icons/DropdownIcon';
import { useDeleteTask, useTaskDetails, useUpdateTask } from '../../hooks/requestHistory.hooks';
import DropdownUpIcon from '../../assets/icons/DropdownUpIcon';
import DueDateInput from '../../components/DueDateInput';
import { useGetTaskComment, useGetTaskFile, usePostTaskComment } from '../../hooks/task.hooks';
import { useConfirmation } from '../../components/Confirmation/ConfirmationService';
import RoleBased from '../../components/RoleBased';
import { ROLES, SECTION_KEY } from '../../utils/enums';
import UserContext from '../../contexts/UserContext';
import AssetImage from '../../components/AssetImage';
import ProgressDropdownButton from './ProgressDropdownButton';
import CommentContext from '../../contexts/CommentContext';

function TaskDetails({ onClose, taskId, onHandleScreen, onStatusChange = () => {} }) {
  const history = useHistory();
  const confirm = useConfirmation();
  const [showHistory, setShowHistory] = React.useState('hide');
  const { id: boardId } = useParams();
  const userContext = useContext(UserContext);
  const { comments, updatedComments } = React.useContext(CommentContext);

  const { data: taskDetails, isFetching } = useTaskDetails(taskId);
  const { data: taskComments, isFetching: isTaskCommentLoading } = useGetTaskComment(taskId);
  const { data: taskFiles, isFetching: isTaskFilesLoading } = useGetTaskFile(taskId);

  const { mutateAsync: updateArchive, isLoading } = useUpdateTask();
  const { mutateAsync: deleteTask, isLoading: isLoadingDeleteTask } = useDeleteTask();
  const { mutateAsync: addTaskComment, isLoading: isCommentPosting } = usePostTaskComment();

  const queryClient = useQueryClient();

  const [dueDate, setDueDate] = useState();
  const [open, setOpen] = useState();
  const [updating, setUpdating] = useState('');
  const [comment, setComment] = useState('');

  const [show, setShow] = useState(false);
  const [media, setMedia] = useState();

  const navigateTo = () => {
    history.push('/dashboard/feedback');
  };

  const handleArchive = async id => {
    try {
      setUpdating('archived');
      const data = { 'is_archived': 1 };
      await updateArchive({ id, data });
      toast.success('Successfully archived.');
      onHandleScreen(taskDetails?.status);
      onStatusChange();
      setUpdating('');
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleStatus = async newStatus => {
    try {
      setUpdating(newStatus);
      const data = { status: newStatus, due_date: dueDate?.format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]') };
      await updateArchive({ id: taskId, data });
      if (
        userContext
          .getRole()
          .permissions.includes(SECTION_KEY.task_completion_feedback_full_view) &&
        newStatus === 'completed'
      ) {
        navigateTo();
      }

      queryClient.invalidateQueries(['taskDetails', taskId]);
      queryClient.invalidateQueries(['boardTasks', boardId]);
      onHandleScreen(newStatus);
      onStatusChange();
      setUpdating('');
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handlePublish = async newStatus => {
    try {
      setUpdating(newStatus);
      const data = { 'is_publish': 1 };
      await updateArchive({ id: taskId, data });
      queryClient.invalidateQueries(['taskDetails', taskId]);
      queryClient.invalidateQueries(['boardTasks', boardId]);
      onHandleScreen(newStatus);
      onStatusChange();
      setUpdating('');
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const deleteTasks = async () => {
    await confirm({
      title: 'Delete',
      description: 'Are you sure you want to delete this task?',
    });

    try {
      await deleteTask(taskDetails?.id);
      queryClient.invalidateQueries(['boardTasks', boardId]);
      onStatusChange();
      onClose('draft');
    } catch (error) {
      toast.error(error?.message);
    }
  };

  React.useEffect(() => {
    if (!isTaskCommentLoading) {
      updatedComments({ current_task: taskId, data: taskComments });
    }
  }, [isTaskCommentLoading]);

  const getActionView = () => {
    if (taskDetails?.is_publish === 1)
      switch (taskDetails?.status) {
        case 'request':
          return (
            <RoleBased
              sectionKey={[SECTION_KEY.task_status_change_edit]}
              acceptedRoles={[
                ROLES.SUPER_ADMIN,
                ROLES.ADMIN,
                ROLES.MANAGERS,
                ROLES.SUBSCRIBERS,
                ROLES.VIEWERS,
              ]}
            >
              <OverlayTrigger
                trigger="hover"
                overlay={!dueDate ? <Tooltip>Set a due date before progress.</Tooltip> : <div />}
                placement="top"
              >
                <Button
                  variant="primary"
                  className={`font-weight-500 mt-2 ${styles.process_btn}`}
                  onClick={() => {
                    if (dueDate) {
                      handleStatus('in-progress');
                    }
                  }}
                >
                  {isLoading && updating === 'in-progress' ? (
                    <Spinner size="sm" variant="light" animation="border" />
                  ) : (
                    'Move to In Progress'
                  )}
                </Button>
              </OverlayTrigger>
              <DueDateInput
                styles={styles}
                onChange={date => {
                  setDueDate(date);
                }}
              />
            </RoleBased>
          );
        case 'in-progress':
          return (
            <>
              <RoleBased
                sectionKey={[SECTION_KEY.task_status_change_edit]}
                acceptedRoles={[
                  ROLES.SUPER_ADMIN,
                  ROLES.ADMIN,
                  ROLES.MANAGERS,
                  ROLES.SUBSCRIBERS,
                  ROLES.VIEWERS,
                ]}
              >
                <Button
                  variant="primary"
                  className={`font-weight-500 mt-2 ${styles.process_btn}`}
                  onClick={() => {
                    handleStatus('in-review');
                  }}
                >
                  {isLoading && updating === 'in-review' ? (
                    <Spinner size="sm" variant="light" animation="border" />
                  ) : (
                    'Move to In Review'
                  )}
                </Button>
              </RoleBased>
              <h5
                className={`mt-3 text-size-xs d-flex text-primary font-weight-600 ${styles.time_box}`}
              >
                <strong className="mr-1">Due:</strong>
                {moment(taskDetails?.due_date).format('DD MMM [at] hh:mm A')}
              </h5>
            </>
          );
        case 'in-review':
          return (
            <>
              <RoleBased
                sectionKey={[SECTION_KEY.task_status_change_edit]}
                acceptedRoles={[
                  ROLES.SUPER_ADMIN,
                  ROLES.ADMIN,
                  ROLES.MANAGERS,
                  ROLES.SUBSCRIBERS,
                  ROLES.VIEWERS,
                ]}
              >
                <Button
                  variant="primary"
                  className={`font-weight-500 mt-2 ${styles.process_btn}`}
                  onClick={() => {
                    handleStatus('completed');
                  }}
                >
                  {isLoading && updating === 'completed' ? (
                    <Spinner size="sm" variant="light" animation="border" />
                  ) : (
                    'Mark as Completed'
                  )}
                </Button>
                <Button
                  variant="warning"
                  className={`text-size-xs text-light mt-3 font-weight-600 ${classnames(
                    styles.revision_btn,
                    styles.btn_structure,
                  )}`}
                  onClick={() => {
                    handleStatus('request');
                  }}
                >
                  {isLoading && updating === 'request' ? (
                    <Spinner size="sm" variant="light" animation="border" />
                  ) : (
                    'Revision Needed'
                  )}
                </Button>
              </RoleBased>
              <h5
                className={`mt-3 text-size-xs d-flex text-primary font-weight-600 ${styles.time_box}`}
              >
                <strong className="mr-1">Due:</strong>
                {moment(taskDetails?.due_date).format('DD MMM [at] hh:mm A')}
              </h5>
            </>
          );
        case 'completed':
          return (
            <>
              <RoleBased
                sectionKey={[SECTION_KEY.task_status_change_edit]}
                acceptedRoles={[
                  ROLES.SUPER_ADMIN,
                  ROLES.ADMIN,
                  ROLES.MANAGERS,
                  ROLES.SUBSCRIBERS,
                  ROLES.VIEWERS,
                ]}
              >
                <Button
                  variant="primary"
                  className={`font-weight-500 mt-2 ${styles.process_btn}`}
                  onClick={() => {
                    handleStatus('request');
                  }}
                >
                  {isLoading && updating === 'request' ? (
                    <Spinner size="sm" variant="light" animation="border" />
                  ) : (
                    'Reopen'
                  )}
                </Button>
              </RoleBased>
              <h5 className={`mt-3 text-size-xs text-primary font-weight-600 ${styles.time_box}`}>
                <strong className="mr-1">Due:</strong>
                {moment(taskDetails?.due_date).format('DD MMM [at] hh:mm A')}
              </h5>
              <h5 className={`mt-3 text-size-xs text-primary font-weight-600 ${styles.time_box}`}>
                <strong className="mr-0">{'Done: '}</strong>
                {moment(taskDetails?.updated_at).format(' DD MMM [at] hh:mm A')}
              </h5>
            </>
          );
        default:
          return null;
      }
    else
      return (
        <>
          <RoleBased
            sectionKey={[SECTION_KEY.task_status_change_edit]}
            acceptedRoles={[
              ROLES.SUPER_ADMIN,
              ROLES.ADMIN,
              ROLES.MANAGERS,
              ROLES.SUBSCRIBERS,
              ROLES.VIEWERS,
            ]}
          >
            <Button
              variant="primary"
              className={`font-weight-500 mt-2 ${styles.process_btn}`}
              onClick={() => {
                handlePublish('request');
              }}
              disabled={isLoading && updating === 'request'}
            >
              {isLoading && updating === 'request' ? (
                <Spinner size="sm" variant="light" animation="border" />
              ) : (
                'Publish'
              )}
            </Button>
          </RoleBased>
          <RoleBased
            sectionKey={[SECTION_KEY.task_request_edit]}
            acceptedRoles={[
              ROLES.SUPER_ADMIN,
              ROLES.ADMIN,
              ROLES.MANAGERS,
              ROLES.EDITORS,
              ROLES.SUBSCRIBERS,
              ROLES.VIEWERS,
            ]}
          >
            <Button
              variant="warning"
              className={`text-size-xs text-light mt-3 font-weight-600 ${classnames(
                styles.revision_btn,
                styles.btn_structure,
              )}`}
              onClick={() => {
                history.push('/dashboard/request-dimension', {
                  taskId: taskDetails?.id,
                  section: 'name',
                });
              }}
            >
              Edit
            </Button>
          </RoleBased>

          <Button
            variant="warning"
            className={`text-size-xs text-light mt-3 font-weight-600 ${classnames(
              styles.delete_btn,
              styles.btn_structure,
            )}`}
            onClick={() => {
              deleteTasks();
            }}
            disabled={isLoadingDeleteTask}
          >
            {isLoadingDeleteTask ? (
              <Spinner size="sm" variant="light" animation="border" />
            ) : (
              'Delete'
            )}
          </Button>
        </>
      );
  };

  return (
    <>
      <Card className="text-font position-relative mb-4">
        {isFetching && (
          <Row className="p-3">
            <Col align="center">
              <Spinner variant="primary" animation="border" />
            </Col>
          </Row>
        )}
        {!isFetching && (
          <>
            <RoleBased
              sectionKey={[SECTION_KEY.task_description_history_of_changes_full_view]}
              acceptedRoles={[
                ROLES.SUPER_ADMIN,
                ROLES.ADMIN,
                ROLES.MANAGERS,
                ROLES.EDITORS,
                ROLES.SUBSCRIBERS,
                ROLES.VIEWERS,
              ]}
            >
              {showHistory === 'show' ? (
                <HistoryOfChangesSection status={setShowHistory} taskId={taskId} />
              ) : null}
            </RoleBased>
            <Card.Body className={`py-5 px-lg-5 position-relative ${styles.card_bg_grey}`}>
              <Button
                variant="light"
                className={`text-size-xs font-weight-600 position-absolute ${styles.close_details_icon}`}
                onClick={() => {
                  onClose(taskDetails?.is_publish === 1 ? taskDetails?.status : 'draft');
                }}
              >
                <CloseIcon className={classnames(styles.close_details_icon_size)} />
              </Button>
              <Card.Header
                className={` mb-lg-5 text-md-left font-weight-600 no-border ${styles.card_bg_grey}`}
              >
                {taskDetails?.title}
              </Card.Header>
              <Row className="mx-0">
                <Col
                  xs={{ order: 2 }}
                  sm={{ order: 2 }}
                  md={{ order: 2 }}
                  lg={{ order: 1 }}
                  xl={9}
                  className="px-0 px-md-3"
                >
                  <div className="d-flex flex-row mb-3 justify-content-between align-items-center">
                    <h5 className="text-size-sm mb-0 text-darker font-weight-600">
                      <Image src={descriptionIcon} alt="" className="mr-2" width="24" height="24" />{' '}
                      Description
                    </h5>
                    <RoleBased
                      sectionKey={[SECTION_KEY.task_description_history_of_changes_full_view]}
                      acceptedRoles={[
                        ROLES.SUPER_ADMIN,
                        ROLES.ADMIN,
                        ROLES.MANAGERS,
                        ROLES.EDITORS,
                      ]}
                    >
                      <Button
                        className="mb-0 text-size-xs text-darker underline-text"
                        variant="link"
                        onClick={() => setShowHistory('show')}
                      >
                        View change
                      </Button>
                    </RoleBased>
                  </div>
                  <Card className="mb-5 ">
                    <Card.Body className="px-4">
                      <h5 className="text-size-sm font-weight-600 text-primary py-3">
                        Request Type: {taskDetails?.type}
                      </h5>
                      <h5 className="text-size-sm text-darker font-weight-600 py-1">Dimensions</h5>

                      <p className="text-size-xs text-darker">
                        <span className="font-weight-600">Custom: </span>
                        <span>{taskDetails?.dimension}</span>
                      </p>
                      <h5 className="text-size-sm text-darker font-weight-600 py-2">Description</h5>
                      <p className="text-size-xs text-secondary">{taskDetails?.text}</p>
                      <Collapse in={open}>
                        <Row className="mb-2">
                          <Col>
                            <h5 className="text-size-sm text-darker font-weight-600 py-2">
                              Instructions
                            </h5>
                            <ol>
                              {taskDetails?.steps?.map(step => (
                                <li>{step.step}</li>
                              ))}
                            </ol>
                            {taskDetails?.is_include_text === 1 && (
                              <>
                                <h5 className="text-size-sm text-darker font-weight-600 py-2">
                                  Include Text
                                </h5>
                                <p className="text-size-xs text-secondary">{taskDetails?.text}</p>
                              </>
                            )}
                            {taskDetails?.type_of_images &&
                              taskDetails?.type_of_images !== null &&
                              taskDetails?.type_of_images !== '' && (
                                <>
                                  <h5 className="text-size-sm text-darker font-weight-600 py-2">
                                    Image type
                                  </h5>
                                  <p className="text-size-xs text-secondary">
                                    {taskDetails?.type_of_images}
                                  </p>
                                </>
                              )}
                          </Col>
                        </Row>
                      </Collapse>
                      <Button
                        variant="link"
                        className="text-size-xs px-0 text-darker underline-text"
                        onClick={() => {
                          setOpen(!open);
                        }}
                      >
                        <span className="mr-1">{open ? 'View less' : 'View more'}&#160;</span>
                        {open ? (
                          <DropdownUpIcon color="#323232" />
                        ) : (
                          <DropdownIcon color="#323232" />
                        )}
                      </Button>
                    </Card.Body>
                  </Card>

                  {isTaskFilesLoading && (
                    <Row>
                      <Col>
                        <Spinner variant="primary" animation="border" />
                      </Col>
                    </Row>
                  )}
                  {!isTaskFilesLoading &&
                    taskFiles.map(files => (
                      <AssetImage
                        asset={files}
                        className="cursor-pointer"
                        onDisplay={display_media => {
                          setMedia(display_media);
                          setShow(true);
                        }}
                      />
                    ))}

                  <div className="d-flex flex-row mb-3 mt-4 pt-4">
                    <Image src={descriptionIcon} alt="" className="mr-2" width="24" height="24" />
                    <h5 className="text-size-sm text-darker font-weight-600">Comments</h5>
                  </div>

                  <Card className={`no-border ${styles.card_bg_grey}`}>
                    <Card.Body className="d-flex flex-column pl-0">
                      <RoleBased
                        sectionKey={[
                          SECTION_KEY.task_comments_add,
                          SECTION_KEY.task_comments_edit,
                          SECTION_KEY.task_comments_delete,
                        ]}
                        acceptedRoles={[
                          ROLES.SUPER_ADMIN,
                          ROLES.ADMIN,
                          ROLES.MANAGERS,
                          ROLES.SUBSCRIBERS,
                          ROLES.VIEWERS,
                        ]}
                      >
                        <Media className="mb-3">
                          <img
                            src={`https://ui-avatars.com/api/?name=${userContext.user?.first_name}+${userContext.user?.last_name}&background=219186&color=fff`}
                            alt="bot"
                            className={`mr-2 rounded-circle ${styles.bot_icon_size}`}
                          />
                          <Media.Body>
                            {isCommentPosting && (
                              <Row>
                                <Col align="center">
                                  <Spinner size="sm" variant="primary" animation="border" />
                                </Col>
                              </Row>
                            )}
                            {!isCommentPosting && (
                              <Form.Control
                                className={`${styles.input_box_structure} shadow px-0 px-3`}
                                placeholder="Type your comment..."
                                value={comment}
                                onChange={e => {
                                  setComment(e.target.value);
                                }}
                                onKeyPress={async e => {
                                  if (e.key === 'Enter' || e.keyCode === 13) {
                                    try {
                                      const res = await addTaskComment({
                                        taskId,
                                        data: {
                                          comment,
                                        },
                                      });
                                      if (res.status) toast.success('Comment saved successfully.');
                                      setComment('');
                                    } catch (err) {
                                      toast.error(err);
                                    }
                                  }
                                }}
                              />
                            )}
                          </Media.Body>
                        </Media>
                      </RoleBased>
                      <RoleBased
                        sectionKey={[SECTION_KEY.task_comments_full_view]}
                        acceptedRoles={[
                          ROLES.SUPER_ADMIN,
                          ROLES.ADMIN,
                          ROLES.MANAGERS,
                          ROLES.EDITORS,
                          ROLES.SUBSCRIBERS,
                          ROLES.VIEWERS,
                        ]}
                      >
                        {isTaskCommentLoading && (
                          <Row>
                            <Col align="center">
                              <Spinner variant="primary" animation="border" />
                            </Col>
                          </Row>
                        )}
                        {!isTaskCommentLoading && comments?.data.length === 0 && (
                          <Row>
                            <Col align="center">
                              <span className="text-size-xs text-darker font-weight-600">
                                No comment available.
                              </span>
                            </Col>
                          </Row>
                        )}
                        {comments?.data.length > 0 && (
                          <>
                            {comments?.data
                              ?.sort((x, y) => moment(y.updated_at).diff(moment(x.updated_at)))
                              ?.map(item => {
                                const {
                                  img,
                                  name,
                                  updated_at,
                                  comment: message,
                                  reference,
                                  more_details,
                                  user,
                                } = item;
                                return (
                                  <Media className="mt-4 mb-3" key={uuid()}>
                                    <img
                                      src={`https://ui-avatars.com/api/?name=${user?.first_name}+${user?.last_name}&background=219186&color=fff`}
                                      alt="bot"
                                      className="mr-2 rounded-circle"
                                      height="25"
                                    />
                                    <Media.Body className="mx-1">
                                      <div className="d-flex">
                                        <h5 className="text-size-xs text-darker font-weight-600 pr-2 pb-2">
                                          {user?.username ? user?.username : user?.name}
                                        </h5>

                                        <h5 className="text-size-xs text-darker">
                                          {moment(updated_at).format('MMM DD [at] hh.mm A')}
                                        </h5>
                                      </div>
                                      <Card
                                        className={`text-size-xs d-inline-block px-3 shadow ${styles.chat_box_structure}`}
                                      >
                                        <p className="text-size-xs text-darker mb-0 py-3">
                                          {message}
                                          <span className="text-size-xs text-darker font-weight-600">
                                            &#160;{reference}
                                            {name === 'Dominic Osborne' && (
                                              <img src={smileyIcon} alt="smiley" />
                                            )}
                                          </span>
                                        </p>
                                        <div
                                          className={`text-size-xs text-darker ${classnames(
                                            more_details && 'pb-3',
                                          )}`}
                                        >
                                          {more_details}
                                        </div>
                                      </Card>
                                    </Media.Body>
                                  </Media>
                                );
                              })}
                          </>
                        )}
                      </RoleBased>
                    </Card.Body>
                  </Card>
                </Col>
                <Col
                  xs={{ order: 1 }}
                  sm={{ order: 1 }}
                  md={{ order: 1 }}
                  lg={{ order: 2 }}
                  xl={3}
                  className="px-0 px-md-3"
                >
                  <div className="mt-lg-5 mb-4 d-flex flex-column px-2 px-xl-2">
                    {getActionView()}
                    <RoleBased
                      sectionKey={[
                        SECTION_KEY.task_labeling_add,
                        SECTION_KEY.task_labeling_edit,
                        SECTION_KEY.task_labeling_delete,
                        SECTION_KEY.task_labeling_full_view,
                      ]}
                      acceptedRoles={[ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.MANAGERS]}
                    >
                      <ProgressDropdownButton
                        taskId={taskId}
                        onStatusChange={onStatusChange}
                        taskLabel={taskDetails?.label}
                      />
                    </RoleBased>
                    <Button
                      variant="primary"
                      onClick={() => handleArchive(taskId)}
                      className={`font-weight-500 mt-3 ${styles.process_btn}`}
                    >
                      {isLoading && updating === 'archive' && (
                        <Spinner size="sm" variant="light" animation="border" />
                      )}
                      Archive
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </>
        )}
      </Card>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        centered
        size="xl"
      >
        <Modal.Header className="p-0 m-0 display-image" closeButton>
          <Image src={media?.file_path} className="w-vw-80" width="100%" />
        </Modal.Header>
      </Modal>
    </>
  );
}

export default TaskDetails;
