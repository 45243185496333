import React from 'react';
import { Card, Image, Table } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import classnames from 'classnames';
import pdfIcon from '../../assets/icons/acrobat.svg';

const data = [
  { _id: uuid(), orderId: 123456, amount: 389.0, paidOn: new Date(), status: 'Complete' },
  { _id: uuid(), orderId: 123456, amount: 389.0, paidOn: new Date(), status: 'Pending' },
  { _id: uuid(), orderId: 123456, amount: 389.0, paidOn: new Date(), status: 'Complete' },
  { _id: uuid(), orderId: 123456, amount: 389.0, paidOn: new Date(), status: 'Complete' },
  { _id: uuid(), orderId: 123456, amount: 389.0, paidOn: new Date(), status: 'Pending' },
  { _id: uuid(), orderId: 123456, amount: 389.0, paidOn: new Date(), status: 'Complete' },
  { _id: uuid(), orderId: 123456, amount: 389.0, paidOn: new Date(), status: 'Cancel' },
];

const PaymentHistory = () => {
  const getTextColor = status => {
    switch (status) {
      case 'Completed':
        return 'text-primary';
      case 'Pending':
        return 'text-warning';
      case 'Cancel':
        return 'text-danger';
      default:
        return 'text-primary';
    }
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title className="font-weight-600">Payment History</Card.Title>
        <Card.Subtitle>
          Your order history is given below and you can choose to download your invoices.
        </Card.Subtitle>
      </Card.Header>
      <Card.Body>
        <div className="px-0 px-lg-5">
          <Table responsive="lg" bordered>
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Paid Amount</th>
                <th>Paid On</th>
                <th>Status</th>
                <th className="text-center">Download</th>
              </tr>
            </thead>
            <tbody>
              {data.map(({ _id, orderId, amount, paidOn, status }) => (
                <tr key={_id}>
                  <td>{orderId}</td>
                  <td>${amount}</td>
                  <td>{moment(paidOn).format('DD-MMM-YYYY')}</td>
                  <td className={classnames(getTextColor(status), 'font-weight-bold')}>{status}</td>
                  <td className="text-center">
                    <a href="/">
                      <Image src={pdfIcon} alt="" />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Card.Body>
    </Card>
  );
};

export default PaymentHistory;
