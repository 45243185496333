import React from 'react';
import UserContext from '../contexts/UserContext';
import { SECTION_KEY } from '../utils/enums';

function RoleBased({ sectionKey, children }) {
  const { getRole } = React.useContext(UserContext);

  if (sectionKey?.[0] === SECTION_KEY.all) {
    return <>{children}</>;
  }

  for (let i = 0; i < sectionKey?.length; i += 1) {
    if (getRole()?.permissions.includes(sectionKey[i])) {
      return <>{children}</>;
    }
  }

  return <></>;
}

export default RoleBased;
