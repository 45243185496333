import http from '../utils/http';

export const fetchMembers = () => http.get(`/members`);

export const createTeam = data => http.post('/teams', data);

export const getTeams = query => http.get(`/teams${query}`);

export const addMembers = (teamid, data) => http.post(`/teams/${teamid}/members`, data);

export const deleteMembers = (teamid, memberid) =>
  http.delete(`/teams/${teamid}/members/${memberid}`);
