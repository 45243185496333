import React from 'react';
import { Card, Image } from 'react-bootstrap';
import classnames from 'classnames';
import styles from '../../../styles/modules/NewRequest.module.scss';
import PreDimensionIcon from './PreDimensionIcon';
import check from '../../../assets/icons/check-white.svg';

const PreDimensions = ({ name, width, height, resolution, active, onClick }) => {
  return (
    <button
      type="button"
      className={classnames('mb-3', styles.pedefined_size)}
      onClick={() => {
        onClick(name, { height, width, resolution });
      }}
    >
      {active && <Image src={check} alt="" className={styles.img_check} />}
      <Card className="text-font ">
        <Card.Body>
          <h6
            className={`text-size-xs ${classnames(
              active ? 'text-primary font-weight-600' : 'text-muted',
            )}`}
          >
            {name}
          </h6>

          <PreDimensionIcon className="my-3" active={active} />

          <p
            className={`text-size-xxs ${classnames(
              'mb-0',
              active ? 'text-primary font-weight-600' : 'text-muted',
            )}`}
          >
            {`${width}" × ${height}" (${resolution} DPI)`}
          </p>
        </Card.Body>
      </Card>
    </button>
  );
};

PreDimensions.defaultProps = {
  name: '',
  width: 0,
  height: 0,
  resolution: 0,
  active: false,
};

export default PreDimensions;
