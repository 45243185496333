import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import styles from '../styles/modules/AddAssets.module.scss';
import DeleteIcon from '../assets/icons/DeleteIcon';
import DownloadIcon from '../assets/icons/DownloadIcon';
import RoleBased from './RoleBased';
import { ROLES, SECTION_KEY } from '../utils/enums';

function DropDownDeleteDowload({ onDelete, onDownload }) {
  const [deleteIconColor, setDeleteIconColor] = React.useState('#969696');
  const [downloadIconColor, setDownloadIconColor] = React.useState('#969696');

  return (
    <Dropdown className={classNames(styles.card_dropdown_toggle)}>
      <Dropdown.Toggle
        variant="light"
        className={classNames(
          styles.card_dropdown_toggle,
          'dropdown-hide-caret border-0 float-right',
        )}
      >
        <FontAwesomeIcon icon={faEllipsisV} />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          as="button"
          className={classNames(
            styles.selected_item,
            'd-flex flex-row align-items-center justify-content-between',
            'px-0 px-3',
          )}
          onMouseOver={() => setDownloadIconColor('white')}
          onMouseLeave={() => setDownloadIconColor('#969696')}
          onClick={onDownload}
        >
          <div className="text-size-xxs pr-2 ">Download this folder</div>
          <DownloadIcon color={downloadIconColor} />
        </Dropdown.Item>

        <RoleBased
          sectionKey={[SECTION_KEY.board_assets_delete]}
          acceptedRoles={[ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.SUBSCRIBERS, ROLES.VIEWERS]}
        >
          <Dropdown.Item
            as="button"
            className={classNames(
              styles.selected_item,
              'd-flex flex-row align-items-center justify-content-between',
              'px-0 px-3',
            )}
            onMouseOver={() => setDeleteIconColor('white')}
            onMouseLeave={() => setDeleteIconColor('#969696')}
            onClick={onDelete}
          >
            <div className="text-size-xxs pr-2">Delete this folder</div>
            <DeleteIcon color={deleteIconColor} />
          </Dropdown.Item>
        </RoleBased>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DropDownDeleteDowload;
