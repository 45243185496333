import React from 'react';
import styles from '../../styles/modules/FeedBack.module.scss';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="72.714"
      // height="69.749"
      //   width={55}
      //   height={55}
      viewBox="0 0 72.714 69.749"
      className={styles.star_size}
    >
      <path
        fill="none"
        stroke="#969696"
        strokeWidth="2"
        d="M71.481 26.573a3.746 3.746 0 00-3.232-2.581l-20.381-1.85-8.059-18.864a3.753 3.753 0 00-6.9 0l-8.06 18.864-20.387 1.85a3.757 3.757 0 00-2.13 6.568l15.405 13.511-4.542 20.012a3.747 3.747 0 005.582 4.055l17.58-10.508 17.575 10.508a3.753 3.753 0 005.585-4.055l-4.543-20.012L70.38 30.563a3.755 3.755 0 001.101-3.99zm0 0"
      />
    </svg>
  );
}

export default Icon;
