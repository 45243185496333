import React, { useEffect } from 'react';
import { Card, Col, Image, Row, Spinner, Tab } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import brchoreIcon from '../../assets/icons/brochure.svg';
import check from '../../assets/icons/check-white.svg';
import uncheck from '../../assets/icons/check-none.svg';
import styles from '../../styles/modules/NewRequest.module.scss';
import RequestDimension from './RequestDimension/RequestDimension';
import RequestDescription from './RequestionDescription/RequestDescription';
import RequestFileTypes from './RequestFileTypes';
import RequestSummary from './RequestSummary';
import RequestName from './RequestName';
import RequestContext, { initialData } from '../../contexts/RequestContext';
import UserContext from '../../contexts/UserContext';
import BoardContext from '../../contexts/BoardContext';
import {
  useCreateTaskFile,
  useCreateTasks,
  useGetTasksDetails,
  useUpdateTasks,
  useGetTaskFile,
} from '../../hooks/task.hooks';
import { useBoards } from '../../hooks/boards.hooks';
import { ROLES } from '../../utils/enums';

const NewRequestTabs = () => {
  const history = useHistory();
  const { state } = useLocation();
  const { request, updateRequest } = React.useContext(RequestContext);
  const { board } = React.useContext(BoardContext);
  const { user, getRole } = React.useContext(UserContext);
  const [key, setKey] = React.useState(state?.section ? state.section : 'name');
  const [taskFor, setTaskFor] = React.useState();
  const { data: projects, isFetching } = useBoards(
    getRole()?.role !== ROLES.SUPER_ADMIN ? user?.id : '',
  );
  const { data: taskDetails, isFetching: isTaskDetailsFetching } = useGetTasksDetails(
    state?.taskId,
  );
  const { data: taskFiles, isFetching: isTaskFilesFetching } = useGetTaskFile(state?.taskId);

  const { mutateAsync: create, isLoading: isLoadingCreate } = useCreateTasks();
  const { mutateAsync: update, isLoading: isLoadingUpdate } = useUpdateTasks();
  const { mutateAsync: createTaskFile, isLoading: isLoadingTaskFile } = useCreateTaskFile();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [key]);

  useEffect(() => {
    if (!(isFetching || isTaskDetailsFetching || isTaskFilesFetching)) {
      if (state && state.taskId) {
        const clearedForm = [];
        if (taskDetails.title && taskDetails.type) {
          clearedForm.push('name');
          if (taskDetails.dimension && taskDetails.dimension !== '') {
            clearedForm.push('dimension');
            if (taskDetails.steps && taskDetails.steps.length !== 0) {
              let valid_description = false;
              if (taskDetails.is_include_text && taskDetails.text) {
                valid_description = true;
              } else if (!taskDetails.is_include_text && !taskDetails.text) {
                valid_description = true;
              } else {
                valid_description = false;
              }

              if (valid_description) {
                clearedForm.push('description');

                if (taskDetails.file_formats) {
                  clearedForm.push('filetypes');
                }
              }
            }
          }
        }

        if (!request.data.updated) {
          const tempRequest = {
            ...request,
            assets: taskFiles.filter(file => file.media_id),
            clearedForm,
            data: {
              ...taskDetails,
              steps: taskDetails.steps?.map((step, index) => ({
                id: index,
                text: step.step,
              })),
              file_formats: taskDetails.file_formats
                ? taskDetails.file_formats.split(',').map(format => `.${format}`)
                : [],
              is_include_text: taskDetails.is_include_text === 1,
              is_share_asset: taskDetails.is_share_asset === 1,
            },
          };

          updateRequest(tempRequest);
        }
      } else if (board !== null) {
        const tempRequest = {
          ...request,
          data: {
            ...request.data,
            project_id: board?.id,
          },
        };

        updateRequest(tempRequest);
      } else if (!isFetching && !request.data.project_id) {
        const tempRequest = {
          ...request,
          data: {
            ...request.data,
            project_id: projects[0]?.id,
          },
        };

        updateRequest(tempRequest);
      }
    }
  }, [isFetching, isTaskDetailsFetching, isTaskFilesFetching]);

  useEffect(
    function storeProjectId() {
      if (request.clearedForm.length === 0) {
        setKey('name');
      }

      setTaskFor(request.data.project_id);
    },
    [request],
  );

  const saveToDesign = async is_publish => {
    if (is_publish === 1 && request.clearedForm.length < 4) {
      toast.error('All forms are not filled properly.');
      return;
    }

    if (is_publish === 1 && key === 'summary') {
      if (
        request.data.file_formats?.filter(format => format !== '' && format !== undefined)
          .length === 0
      ) {
        toast.error('Please provide the type of image you need.');
        return;
      }
    }

    try {
      const data = {
        ...request.data,
        file_formats: request.data.file_formats
          ?.filter(format => format !== '' && format !== undefined)
          .map(format => format.replace('.', ''))
          .join(),
        steps:
          request.data.steps.length > 0
            ? request.data.steps?.filter(step => step.text !== '').map(step => step.text)
            : [],
        is_include_text: request.data.is_include_text ? 1 : 0,
        is_share_asset: request.data.is_share_asset ? 1 : 0,
        is_publish,
      };

      let res;
      if (state && state.taskId) {
        res = await update({ id: state.taskId, data });
      } else {
        res = await create(data);
      }

      if (res.status) {
        if (request.files.length > 0) {
          request.files.forEach(async file => {
            const info = {
              media_id: file.media_id,
            };
            await createTaskFile({ id: res.data.id, data: info });
          });
        }

        if (request.assets.length > 0) {
          request.assets.forEach(async asset => {
            if (!asset.id) {
              const info = {
                media_id: asset.media_id,
                asset_id: asset.asset_id,
              };
              await createTaskFile({ id: res.data.id, data: info });
            }
          });
        }

        toast.success(
          is_publish === 1 ? 'Request created successfully.' : 'Request draft saved successfully.',
        );
        updateRequest(initialData);
        history.push(
          board ? `/dashboard/request-history/${taskFor}` : `/dashboard/request-history`,
          {
            section: is_publish === 1 ? 'request' : 'draft',
          },
        );
      } else {
        toast.error(res.message);
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <div>
      <div className="d-flex flex-column flex-md-row align-items-center mb-3">
        <h4 className="mb-0 d-flex flex-row align-items-center">
          <Image src={brchoreIcon} alt="" className="mr-3" width="32" height="34" />
          <div className="text-size-md text-font font-weight-600">
            {key === 'name' && <>Please provide your task name with your design type:</>}
            {key === 'dimension' && <>Select the size(S) of your design request:</>}
            {key === 'description' && <>Tell us more about what you&apos;re looking for?</>}
            {key === 'filetypes' && <>What are the file formats do you need?</>}
            {key === 'summary' && (
              <>
                {request.data?.type
                  ? request.data?.type[0]?.toUpperCase() +
                    request.data?.type.slice(1).replace('_', ' ')
                  : ''}
              </>
            )}
          </div>
        </h4>
        <div className="text-size-xs text-font ml-auto text-md-right mt-2 mt-md-0 d-flex flex-column align-items-end align-items-md-start ">
          {request.data?.type && (
            <div className="mb-0">
              <span className="font-weight-600 text-dark">Type:</span>{' '}
              {request.data?.type[0]?.toUpperCase() + request.data?.type.slice(1).replace('_', ' ')}
            </div>
          )}
          <Row className="mb-0 mx-0">
            <Col className="px-0 d-flex flex-row justify-content-end justify-content-lg-start">
              <span className="pr-1">Not Right?</span>
              <button
                type="button"
                className="text-size-xs text-primary text-underline px-0 cursor-pointer py-0 no-border bg-transparent"
                onClick={() => {
                  setKey('name');
                }}
              >
                Change Request Type
              </button>
            </Col>
          </Row>
        </div>
      </div>
      <Tab.Container id="new-request-tab" activeKey={key}>
        <Card className="mb-3">
          <Card.Body className={classnames(styles.new_request_tabs, 'py-0 px-0')}>
            <button
              type="button"
              onClick={() => setKey('name')}
              className={`mb-2 mb-md-0 ${classnames(key === 'name' && styles.active)}`}
            >
              <span className="text-darker text-size-xs mr-2 text-font ">Name</span>
              {key === 'name' || request.clearedForm.includes('name') ? (
                <Image src={check} alt="" width="16" height="16" />
              ) : (
                <Image src={uncheck} alt="" width="16" height="16" />
              )}
            </button>
            <button
              type="button"
              onClick={() => {
                if (request.clearedForm.includes('name')) setKey('dimension');
                else
                  toast.error('Please fill the current form and click continue to move forward.');
              }}
              className={`mb-2 mb-md-0 ${classnames(key === 'dimension' && styles.active)}`}
            >
              <span className="text-darker text-size-xs mr-2 text-font">Dimensions</span>
              {key === 'dimension' || request.clearedForm.includes('dimension') ? (
                <Image src={check} alt="" width="16" height="16" />
              ) : (
                <Image src={uncheck} alt="" width="16" height="16" />
              )}
            </button>
            <button
              type="button"
              onClick={() => {
                if (request.clearedForm.includes('dimension')) setKey('description');
                else
                  toast.error('Please fill the current form and click continue to move forward.');
              }}
              className={`mb-2 mb-md-0 ${classnames(key === 'description' && styles.active)}`}
            >
              <span className="text-darker text-size-xs mr-2 text-font">Description</span>
              {key === 'description' || request.clearedForm.includes('description') ? (
                <Image src={check} alt="" width="16" height="16" />
              ) : (
                <Image src={uncheck} alt="" width="16" height="16" />
              )}
            </button>
            <button
              type="button"
              onClick={() => {
                if (request.clearedForm.includes('description')) setKey('filetypes');
                else
                  toast.error('Please fill the current form and click continue to move forward.');
              }}
              className={`mb-2 mb-md-0 ${classnames(key === 'filetypes' && styles.active)}`}
            >
              <span className={`text-darker text-size-xs mr-2 text-font ${styles.set_white_space}`}>
                File Types
              </span>
              {key === 'filetypes' || request.clearedForm.includes('filetypes') ? (
                <Image src={check} alt="" width="16" height="16" />
              ) : (
                <Image src={uncheck} alt="" width="16" height="16" />
              )}
            </button>
            <button
              type="button"
              onClick={() => {
                if (request.clearedForm.includes('filetypes')) setKey('summary');
                else
                  toast.error('Please fill the current form and click continue to move forward.');
              }}
              className={`mb-2 mb-md-0 ${classnames(key === 'summary' && styles.active)}`}
            >
              <span className="text-darker text-size-xs mr-2 text-font">Summary</span>
              {key === 'summary' || request.clearedForm.includes('summary') ? (
                <Image src={check} alt="" width="16" height="16" />
              ) : (
                <Image src={uncheck} alt="" width="16" height="16" />
              )}
            </button>
          </Card.Body>
        </Card>

        <Card>
          <Card.Body>
            {(isLoadingUpdate ||
              isLoadingCreate ||
              // isLoadingMediaInAssets ||
              isLoadingTaskFile) && (
              <Row>
                <Col align="center">
                  <Spinner animation="border" variant="primary" size="md" />
                </Col>
              </Row>
            )}
            {!(
              isLoadingUpdate ||
              isLoadingCreate ||
              // isLoadingMediaInAssets ||
              isLoadingTaskFile
            ) && (
              <Tab.Content>
                <Tab.Pane eventKey="name">
                  <RequestName changeCurrentScreen={setKey} />
                </Tab.Pane>
                <Tab.Pane eventKey="dimension">
                  <RequestDimension changeCurrentScreen={setKey} />
                </Tab.Pane>
                <Tab.Pane eventKey="description">
                  <RequestDescription changeCurrentScreen={setKey} saveDraft={saveToDesign} />
                </Tab.Pane>
                <Tab.Pane eventKey="filetypes">
                  <RequestFileTypes changeCurrentScreen={setKey} saveDraft={saveToDesign} />
                </Tab.Pane>
                <Tab.Pane eventKey="summary">
                  <RequestSummary changeCurrentScreen={setKey} publishTask={saveToDesign} />
                </Tab.Pane>
              </Tab.Content>
            )}
          </Card.Body>
        </Card>
      </Tab.Container>
    </div>
  );
};

export default NewRequestTabs;
