import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
import { useHistory, useLocation } from 'react-router';

const PaginationView = ({ pageCount, currentPage }) => {
  const [items, setItems] = React.useState([]);
  const history = useHistory();
  const { pathname, search } = useLocation();

  React.useEffect(
    function loadPageItem() {
      const tempItems = [];
      if (pageCount <= 7) {
        for (let i = 1; i <= pageCount; i += 1) {
          tempItems.push(
            <Pagination.Item
              key={i}
              active={currentPage === i}
              onClick={() => {
                const searchParam = new URLSearchParams(search);
                searchParam.set('page', i);
                history.push(`${pathname}?${searchParam.toString()}`);
              }}
            >
              {i}
            </Pagination.Item>,
          );
        }
      } else if (currentPage <= 4) {
        for (let i = 1; i <= 7; i += 1) {
          if (i <= 5) {
            tempItems.push(
              <Pagination.Item
                key={i}
                active={currentPage === i}
                onClick={() => {
                  const searchParam = new URLSearchParams(search);
                  searchParam.set('page', i);
                  history.push(`${pathname}?${searchParam.toString()}`);
                }}
              >
                {i}
              </Pagination.Item>,
            );
          } else if (i === 6) {
            tempItems.push(<Pagination.Ellipsis />);
          } else if (i === 7) {
            tempItems.push(
              <Pagination.Item
                key={i}
                active={currentPage === pageCount}
                onClick={() => {
                  const searchParam = new URLSearchParams(search);
                  searchParam.set('page', pageCount);
                  history.push(`${pathname}?${searchParam.toString()}`);
                }}
              >
                {pageCount}
              </Pagination.Item>,
            );
          }
        }
      } else if (currentPage > pageCount - 4) {
        for (let i = 1; i <= 7; i += 1) {
          if (i > 2) {
            tempItems.push(
              <Pagination.Item
                key={i}
                active={currentPage === pageCount - (7 - i)}
                onClick={() => {
                  const searchParam = new URLSearchParams(search);
                  searchParam.set('page', pageCount - (7 - i));
                  history.push(`${pathname}?${searchParam.toString()}`);
                }}
              >
                {pageCount - (7 - i)}
              </Pagination.Item>,
            );
          } else if (i === 2) {
            tempItems.push(<Pagination.Ellipsis />);
          } else if (i === 1) {
            tempItems.push(
              <Pagination.Item
                key={i}
                active={currentPage === 1}
                onClick={() => {
                  const searchParam = new URLSearchParams(search);
                  searchParam.set('page', 1);
                  history.push(`${pathname}?${searchParam.toString()}`);
                }}
              >
                1
              </Pagination.Item>,
            );
          }
        }
      } else {
        for (let i = 1; i <= 7; i += 1) {
          if (i === 1) {
            tempItems.push(
              <Pagination.Item
                key={i}
                active={currentPage === 1}
                onClick={() => {
                  const searchParam = new URLSearchParams(search);
                  searchParam.set('page', 1);
                  history.push(`${pathname}?${searchParam.toString()}`);
                }}
              >
                1
              </Pagination.Item>,
            );
          } else if (i === 2 || i === 6) {
            tempItems.push(<Pagination.Ellipsis />);
          } else if (i === 7) {
            tempItems.push(
              <Pagination.Item
                key={i}
                active={currentPage === pageCount}
                onClick={() => {
                  const searchParam = new URLSearchParams(search);
                  searchParam.set('page', pageCount);
                  history.push(`${pathname}?${searchParam.toString()}`);
                }}
              >
                {pageCount}
              </Pagination.Item>,
            );
          } else if (i === 3) {
            tempItems.push(
              <Pagination.Item
                key={i}
                active={currentPage === currentPage - 1}
                onClick={() => {
                  const searchParam = new URLSearchParams(search);
                  searchParam.set('page', currentPage - 1);
                  history.push(`${pathname}?${searchParam.toString()}`);
                }}
              >
                {currentPage - 1}
              </Pagination.Item>,
            );
          } else if (i === 4) {
            tempItems.push(
              <Pagination.Item
                key={i}
                active
                onClick={() => {
                  const searchParam = new URLSearchParams(search);
                  searchParam.set('page', currentPage);
                  history.push(`${pathname}?${searchParam.toString()}`);
                }}
              >
                {currentPage}
              </Pagination.Item>,
            );
          } else if (i === 5) {
            tempItems.push(
              <Pagination.Item
                key={i}
                active={currentPage === currentPage + 1}
                onClick={() => {
                  const searchParam = new URLSearchParams(search);
                  searchParam.set('page', currentPage + 1);
                  history.push(`${pathname}?${searchParam.toString()}`);
                }}
              >
                {currentPage + 1}
              </Pagination.Item>,
            );
          }
        }
      }

      setItems(tempItems);
    },
    [currentPage],
  );

  return (
    <div
      className="mt-4"
      style={{
        width: 'fit-content',
      }}
    >
      <Pagination>
        <Pagination.First
          disabled={currentPage === 1}
          onClick={() => {
            const searchParam = new URLSearchParams(search);
            searchParam.set('page', 1);
            history.push(`${pathname}?${searchParam.toString()}`);
          }}
        />
        <Pagination.Prev
          disabled={currentPage === 1}
          onClick={() => {
            const searchParam = new URLSearchParams(search);
            searchParam.set('page', currentPage - 1);
            history.push(`${pathname}?${searchParam.toString()}`);
          }}
        />
        {items}
        <Pagination.Next
          disabled={currentPage === pageCount}
          onClick={() => {
            const searchParam = new URLSearchParams(search);
            searchParam.set('page', currentPage + 1);
            history.push(`${pathname}?${searchParam.toString()}`);
          }}
        />
        <Pagination.Last
          disabled={currentPage === pageCount}
          onClick={() => {
            const searchParam = new URLSearchParams(search);
            searchParam.set('page', pageCount);
            history.push(`${pathname}?${searchParam.toString()}`);
          }}
        />
      </Pagination>
    </div>
  );
};

export default PaginationView;
