import { useMutation, useQuery } from 'react-query';
import {
  fetchProjectDetails,
  fetchProjects,
  getProjectCollections,
  getProjectLogs,
  updateProjectDetails,
} from '../requests/project.request';

export const useProject = userId =>
  useQuery(
    ['projects', userId],
    async () => {
      const res = await fetchProjects(userId);
      return res.data;
    },
    {
      enabled: !!userId,
      initialData: [],
    },
  );

export const useProjectLogs = projectId =>
  useQuery(
    ['projectsLogs', projectId],
    async () => {
      const res = await getProjectLogs(projectId);
      return res.data;
    },
    {
      enabled: !!projectId,
      initialData: [],
    },
  );

export const useProjectCollections = projectId =>
  useQuery(
    ['projectCollections', projectId],
    async () => {
      const res = await getProjectCollections(projectId);
      return res.data;
    },
    {
      enabled: !!projectId,
      initialData: [],
    },
  );

export const useFetchProjectDetails = projectId =>
  useQuery(
    ['projectDetails', projectId],
    async () => {
      const res = await fetchProjectDetails(projectId);
      return res.data;
    },
    {
      enabled: !!projectId,
      initialData: {},
    },
  );

export const useUpdateProjectDetails = () =>
  useMutation(async ({ projectId, details }) => {
    const res = await updateProjectDetails(projectId, details);
    return res;
  });
