import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Overlay, Popover, Button, Form, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import closeIcon from '../../assets/icons/close-icon.svg';
import { useCreateCollection } from '../../hooks/collection.hooks';
import styles from '../../styles/modules/ServiceBoards.module.scss';

const schema = yup.object({
  title: yup.string().required('Required').min(2, 'Minimum 2 characters'),
});

const CreateCollection = ({ children }) => {
  const [showPop, setShowPop] = React.useState(false);
  const target = React.useRef(null);

  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const { mutateAsync: createCollection, isLoading } = useCreateCollection();

  const onSubmit = async data => {
    try {
      await createCollection(data);
      toast.success(`Collection ${data.title} created!`);
      setShowPop(false);
      reset();
      queryClient.invalidateQueries('collections');
    } catch (error) {
      toast.error(error?.message);
    }
  };

  return (
    <>
      <button
        type="button"
        ref={target}
        onClick={() => setShowPop(!showPop)}
        className={`${styles.button_nobg}`}
      >
        {children}
      </button>

      <Overlay target={target.current} show={showPop} placement="auto" flip>
        {props => (
          <Popover id="popover-collection" className={styles.boards_popover_small} {...props}>
            <Popover.Content className={`${styles.section_heading} text-size-xs`}>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.row_end}>
                  <button
                    type="button"
                    onClick={() => setShowPop(!showPop)}
                    className={styles.button_cross}
                  >
                    <img src={closeIcon} alt="close" className={styles.cross_img} />
                  </button>
                </div>
                <h6 className="text-center">Create new collection</h6>
                <Form.Group controlId="title">
                  <Form.Label>Name</Form.Label>
                  <Form.Control {...register('title')} type="text" isInvalid={errors?.title} />
                  <Form.Control.Feedback type="invalid">
                    {errors?.title?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <div className="">
                  <Button type="submit" disabled={isLoading}>
                    {isLoading ? <Spinner size="sm" variant="light" animation="border" /> : 'Save'}
                  </Button>
                </div>
              </Form>
            </Popover.Content>
          </Popover>
        )}
      </Overlay>
    </>
  );
};

export default CreateCollection;
