import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import BoardSelection from './BoardSelection';
import RequestBoard from './RequestBoard';

function RequestHistory() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <BoardSelection />
      </Route>
      <Route exact path={`${match.path}/:id`}>
        <RequestBoard />
      </Route>
    </Switch>
  );
}

export default RequestHistory;
