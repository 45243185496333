import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { useDeleteCollection, useUpdateCollection } from '../../hooks/collection.hooks';

const schema = yup.object({
  title: yup.string().required('Required').min(2, 'Minimum 2 characters'),
});

const EditCollection = ({ show, onClose, collection }) => {
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  React.useEffect(() => {
    if (collection) {
      setValue('title', collection?.title);
    }
  }, [collection]);

  const { mutateAsync: updateCollection, isLoading: isUpdating } = useUpdateCollection();

  const { mutateAsync: deleteCollection, isLoading: isDeleting } = useDeleteCollection();

  const onSubmit = async data => {
    try {
      await updateCollection({ id: collection?.id, data });
      toast.success('Collection updated!');
      onClose();
      queryClient.invalidateQueries('collections');
    } catch (err) {
      toast.error(err?.message);
    }
  };

  const onDelete = async () => {
    try {
      await deleteCollection(collection?.id);
      toast.success('Collection deleted!');
      onClose();
      queryClient.invalidateQueries('collections');
    } catch (err) {
      toast.error(err?.message);
    }
  };

  return (
    <Modal show={show} onHide={onClose} size="sm" centered className="z-1070">
      <Modal.Header closeButton>
        <Modal.Title>Edit Collection</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group controlId="title">
            <Form.Label>Name</Form.Label>
            <Form.Control {...register('title')} type="text" isInvalid={errors?.title} />
            <Form.Control.Feedback type="invalid">{errors?.title?.message}</Form.Control.Feedback>
          </Form.Group>
          <div className="d-flex justify-content-between">
            <Button type="submit" disabled={isUpdating}>
              {isUpdating ? <Spinner size="sm" variant="light" animation="border" /> : 'Save'}
            </Button>
            <Button type="button" variant="danger" disabled={isDeleting} onClick={onDelete}>
              {isDeleting ? <Spinner size="sm" variant="light" animation="border" /> : 'Delete'}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditCollection;
