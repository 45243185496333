import { useQuery, useMutation } from 'react-query';
import {
  addMembers,
  createTeam,
  deleteMembers,
  fetchMembers,
  getTeams,
} from '../requests/teams.requests';

export const useMembers = () =>
  useQuery(
    ['members'],
    async () => {
      const res = await fetchMembers();
      return res.data;
    },
    {
      initialData: [],
    },
  );

export const useCreateTeam = () =>
  useMutation(async data => {
    const res = await createTeam(data);
    return res;
  });

export const useTeams = query =>
  useQuery(
    ['teams', query],
    async () => {
      const res = await getTeams(query);
      return res.data;
    },
    {
      enabled: query !== undefined,
      initialData: [],
    },
  );

export const useAddMembers = () =>
  useMutation(async ({ teamid, data }) => {
    const res = await addMembers(teamid, data);
    return res;
  });

export const useDeleteMembers = () =>
  useMutation(async ({ teamId, userId }) => {
    const res = await deleteMembers(teamId, userId);
    return res;
  });
