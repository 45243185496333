import http from '../utils/http';

/**
 * Get all Collections
 * @returns {Promise}
 */
export const fetchCollections = () => http.get(`/collections`);

/**
 * Create Collection
 * @param {Object} data Collection data
 * @returns {Promise}
 */
export const createCollection = data => http.post(`/collections`, data);

/**
 * Update Collection using ID
 * @param {String} id Collection ID
 * @param {Object} data Collection data
 * @returns {Promise}
 */
export const updateCollection = (id, data) => http.put(`/collections/${id}`, data);

/**
 * Delete Collection using ID
 * @param {String} id Collection ID
 * @returns {Promise}
 */
export const deleteCollecton = id => http.delete(`/collections/${id}`);

/**
 * Add Collection to a Board
 * @param {String} id Collection ID
 * @param {String} boardId Board ID
 * @returns {Promise}
 */
export const addCollectionToBoard = (id, boardId) =>
  http.post(`/collections/${id}/projects`, { project_id: boardId });

/**
 * Delete Collection from a Board
 * @param {String} id Collection Id
 * @param {String} boardId Board ID
 * @returns {Promise}
 */
export const removeCollectionFromBoard = (id, boardId) =>
  http.delete(`/collections/${id}/projects/${boardId}`);
