import React from 'react';
import Button from 'react-bootstrap/Button';
import { Form, Overlay, Popover, Spinner } from 'react-bootstrap';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import CloseIcon from '../assets/icons/CloseIcon';
import styles from '../styles/modules/RequestBoard.module.scss';
import { useRolesByUserId } from '../hooks/roles.hooks';

function ButtonWithPopover({
  buttonLabel = ' ',
  buttonCssClasses,
  buttonVariant,
  userId,
  onUpdateComplete,
  buttonDisable,
}) {
  const [showPop, setShowPop] = React.useState(false);
  const [role, setRole] = React.useState('');
  const target = React.useRef();

  const { mutateAsync: updateRolesByUserId, isLoading } = useRolesByUserId();

  /* ["Super Admin", "Admin", "Managers", "Editors", "Contributors", "Subscribers", "Viewers"]   */
  return (
    <>
      <Button
        disabled={buttonDisable}
        type="button"
        variant={buttonVariant}
        className={buttonCssClasses}
        ref={target}
        onClick={() => {
          setShowPop(!showPop);
        }}
      >
        {buttonLabel}
      </Button>
      <Overlay
        target={target.current}
        show={showPop}
        placement="left-start"
        flip
        rootClose
        rootCloseEvent="click"
        onHide={() => setShowPop(!showPop)}
      >
        {props => (
          <Popover {...props} className="py-2 px-2 text-font custom-popover">
            <div className="position-relative ">
              <Button
                onClick={() => setShowPop(!showPop)}
                variant="light"
                className={`text-size-xs font-weight-600 position-absolute ${styles.close_icon}`}
              >
                <CloseIcon className={`${classnames(styles.close_icon_size)}`} />
              </Button>
            </div>
            <Popover.Title className="text-size-xs text-secondary bg-white text-center no-border">
              Change Permissions
            </Popover.Title>
            <Popover.Content>
              <div className="text-size-xs d-flex flex-row align-items-center font-weight-500">
                <Form.Check
                  disabled={buttonLabel.toString().normalize() === 'Super Admin'}
                  custom
                  type="radio"
                  value="Super Admin"
                  id="super_admin"
                  className=" visibility_label"
                  label="Super Admin"
                  onChange={() => {
                    setRole('Super Admin');
                  }}
                  checked={role === 'Super Admin'}
                />
              </div>
              <p className="text-size-xxs text-secondary mb-0 pt-1">
                Can view, create and edit team boards, and change settings for the team. Will Have
                admin rights on all boards in the Team.
              </p>
            </Popover.Content>
            <Popover.Content>
              <div className="text-size-xs d-flex flex-row align-items-center font-weight-500">
                <Form.Check
                  disabled={buttonLabel.toString().normalize() === 'Admin'}
                  custom
                  type="radio"
                  value="Admin"
                  id="admin"
                  label="Admin"
                  className=" visibility_label"
                  onChange={() => {
                    setRole('Admin');
                  }}
                  checked={role === 'Admin'}
                />
              </div>
              <p className="text-size-xxs text-secondary mb-0 pt-1">
                Can view, create, edit team boards, But change the settings.
              </p>
            </Popover.Content>
            <Popover.Content>
              <div className="text-size-xs d-flex flex-row align-items-center font-weight-500">
                <Form.Check
                  disabled={buttonLabel.toString().normalize() === 'Managers'}
                  custom
                  type="radio"
                  value="Managers"
                  id="team_lead"
                  label="Managers"
                  className=" visibility_label"
                  onChange={() => {
                    setRole('Managers');
                  }}
                  checked={role === 'Managers'}
                />
              </div>
              <p className="text-size-xxs text-secondary mb-0 pt-1">
                Can view, create, edit and invite members, But change the settings.
              </p>
            </Popover.Content>
            <Popover.Content>
              <div className="text-size-xs d-flex flex-row align-items-center font-weight-500">
                <Form.Check
                  disabled={buttonLabel.toString().normalize() === 'Editors'}
                  custom
                  type="radio"
                  value="Editors"
                  id="project_manager"
                  label="Editors"
                  className=" visibility_label"
                  onChange={() => {
                    setRole('Editors');
                  }}
                  checked={role === 'Editors'}
                />
              </div>
              <p className="text-size-xxs text-secondary mb-0 pt-1">
                Can view, create, edit team boards, But change the settings.
              </p>
            </Popover.Content>
            <Popover.Content>
              <div className="text-size-xs d-flex flex-row align-items-center font-weight-500">
                <Form.Check
                  disabled={buttonLabel.toString().normalize() === 'Contributors'}
                  custom
                  type="radio"
                  value="Contributors"
                  id="designer"
                  label="Contributors"
                  className=" visibility_label"
                  onChange={() => {
                    setRole('Contributors');
                  }}
                  checked={role === 'Contributors'}
                />
              </div>
              <p className="text-size-xxs text-secondary mb-0 pt-1">
                Can view, create, edit team boards, But change the settings.
              </p>
            </Popover.Content>
            <Popover.Content>
              <div className="text-size-xs d-flex flex-row align-items-center font-weight-500">
                <Form.Check
                  disabled={buttonLabel.toString().normalize() === 'Subscribers'}
                  custom
                  type="radio"
                  value="Subscribers"
                  id="subscribers"
                  label="Subscribers"
                  className=" visibility_label"
                  onChange={() => {
                    setRole('Subscribers');
                  }}
                  checked={role === 'Subscribers'}
                />
              </div>
              <p className="text-size-xxs text-secondary mb-0 pt-1">
                Can view, create, edit team boards, But change the settings.
              </p>
            </Popover.Content>
            <Popover.Content>
              <div className="text-size-xs d-flex flex-row align-items-center font-weight-500">
                <Form.Check
                  disabled={buttonLabel.toString().normalize() === 'Viewers'}
                  custom
                  type="radio"
                  value="Viewers"
                  id="viewer"
                  label="Viewers"
                  className=" visibility_label"
                  onChange={() => {
                    setRole('Viewers');
                  }}
                  checked={role === 'Viewers'}
                />
              </div>
              <p className="text-size-xxs text-secondary mb-0 pt-1">
                Can view, create, edit team boards, But change the settings.
              </p>
            </Popover.Content>
            <Popover.Content>
              <Button
                type="button"
                className="text-size-xxs font-weight-600 px-3"
                onClick={async () => {
                  try {
                    const res = await updateRolesByUserId({
                      userId,
                      data: {
                        name: role,
                      },
                    });
                    if (res.status) toast.success('Role updated successfully.');
                    else toast.success(res.error);
                    onUpdateComplete();
                  } catch (err) {
                    toast.error(err);
                  }
                }}
              >
                {isLoading && <Spinner size="sm" variant="light" animation="border" />}
                {!isLoading && 'Save'}
              </Button>
            </Popover.Content>
          </Popover>
        )}
      </Overlay>
    </>
  );
  // kasjhdkajshdjksahd
}

export default ButtonWithPopover;
