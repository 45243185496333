import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import moment from 'moment';
import { useParams } from 'react-router';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import styles from '../../styles/modules/AddAssets.module.scss';
import DownloadBlackIcon from '../../assets/icons/download-black-icon.svg';
import ExchangeIcon from '../../assets/icons/exchange-icon.svg';
import RoleBased from '../../components/RoleBased';
import { ROLES, SECTION_KEY } from '../../utils/enums';
import { useUpdateMedia } from '../../hooks/medias.hooks';
import AssetContext from '../../contexts/AssetContext';

const MediaDetails = ({ media }) => {
  const { mediaId } = useParams();
  const queryClient = useQueryClient();
  const { path, replace } = React.useContext(AssetContext);

  const { mutateAsync: updateMedia, isLoading } = useUpdateMedia(mediaId);

  const handleUpload = async event => {
    const file = event.target.files[0];

    try {
      const formData = new FormData();
      formData.append('file', file);

      const res = await updateMedia(formData);

      queryClient.invalidateQueries(['media', mediaId]);

      const last = path[path.length - 1];
      replace(path.length - 1, {
        ...last,
        name: res.data.file_name,
      });
    } catch (err) {
      toast.error(err?.message);
    }
  };

  return (
    <Col className="d-flex flex-column align-items-center px-0">
      <Card body className={`no-border ${styles.card_height_60vh} `}>
        <Card.Header className="text-center bg-white no-border">
          <Card.Title>
            <span className="text-size-md font-weight-600">{media?.file_name}</span>
          </Card.Title>
          <Card.Subtitle className="">
            <span className="text-size-xxs">
              {moment(media?.created_at).format('MMMM DD, YYYY')}
            </span>
          </Card.Subtitle>
        </Card.Header>

        <Card.Body className={`shadow mt-3 p-5 ${styles.border_all}`}>
          {isLoading && (
            <div className="text-center mb-4">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
          <Card.Img src={media?.file_path} />
        </Card.Body>
        <Row className="mx-0 mt-5 justify-content-center">
          <a
            href={media?.file_path}
            target="_blank"
            rel="noreferrer"
            download
            className="btn text-darker text-size-xs d-flex flex-row align-items-center bg-white no-border mr-2 text-underline"
          >
            <img src={DownloadBlackIcon} alt="save-icon" className="pr-3" />
            Download
          </a>

          <RoleBased
            sectionKey={[SECTION_KEY.board_assets_edit]}
            acceptedRoles={[ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.SUBSCRIBERS, ROLES.VIEWERS]}
          >
            <label
              className="btn text-darker text-size-xs d-flex flex-row align-items-center bg-white no-border mb-0 ml-2 text-underline"
              id="new-media"
            >
              <input
                type="file"
                name="new-media"
                id="new-media"
                className="d-none"
                onChange={handleUpload}
                disabled={isLoading}
              />
              <img src={ExchangeIcon} alt="save-icon " className="pr-3" />
              Replace with a new version
            </label>
          </RoleBased>
        </Row>
      </Card>
    </Col>
  );
};

export default MediaDetails;
