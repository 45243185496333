import React from 'react';
import { Alert, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router';
import BoardContext from '../../contexts/BoardContext';
import UserContext from '../../contexts/UserContext';
import { useBoards } from '../../hooks/boards.hooks';

const BoardSelection = () => {
  const { user } = React.useContext(UserContext);
  const { board, updateBoard } = React.useContext(BoardContext);
  const history = useHistory();

  const { data: boards, isFetching } = useBoards(user?.id);

  React.useEffect(async () => {
    if (boards.length > 0) {
      if (board !== null && board?.id) {
        history.push(`/dashboard/request-history/${board.id}`);
      } else {
        localStorage.setItem('kimp-user-default-board', boards[0].id);
        updateBoard(boards[0]);
        history.push(`/dashboard/request-history/${boards[0].id}`);
      }
    }
  }, [boards]);

  return (
    <>
      {isFetching && (
        <div className="text-center my-3">
          <Spinner animation="border" variant="success" />
        </div>
      )}
      {!isFetching && boards.length === 0 && <Alert variant="warning">No Boards present </Alert>}
    </>
  );
};

export default BoardSelection;
