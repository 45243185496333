import React from 'react';
import { Image, Spinner } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import cx from 'classnames';
import addFileIcon from '../assets/icons/add-file.svg';

const FileButton = ({ children, className, onFileUploaded, disable, isLoading }) => {
  const [id, setId] = React.useState();

  React.useEffect(() => {
    setId(uuid());
  }, []);

  return (
    <label
      htmlFor={id}
      className={cx(className, 'btn btn-outline-secondary d-inline-flex align-items-center')}
    >
      {isLoading && <Spinner size="sm" variant="primary" animation="border" />}
      {!isLoading && (
        <>
          {children}
          <Image src={addFileIcon} alt="" className="ml-2" height="16" />
          <input
            multiple
            disabled={disable}
            type="file"
            id={id}
            className="d-none"
            onChange={e => {
              onFileUploaded(e.target.files);
            }}
          />
        </>
      )}
    </label>
  );
};

export default FileButton;
