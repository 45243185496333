import React from 'react';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import { Col } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import styles from '../../styles/modules/AddAssets.module.scss';
import DropDownDeleteDowload from '../../components/DropDownDeleteDowload';
import AssetImage from './AssetImage';
import { useConfirmation } from '../../components/Confirmation/ConfirmationService';
import { useDeleteAsset } from '../../hooks/assets.hooks';
import { fetchAssetDownloadURL } from '../../requests/assets.requests';

const SubAsset = ({ name, parentId, subAssetId, onClick, subassets }) => {
  const confirm = useConfirmation();
  const queryClient = useQueryClient();
  const { mutateAsync: deleteAsset } = useDeleteAsset();

  const handleDelete = async () => {
    await confirm({
      title: 'Confirm',
      description: 'Are you sure you want to delete this asset?',
    });

    try {
      await deleteAsset(subAssetId);
      toast.success(`Sub Asset ${name} deleted successfully!`);
      queryClient.invalidateQueries(['sub-asset', parentId]);
    } catch (err) {
      toast.error(err?.message);
    }
  };

  const handleDownload = async () => {
    try {
      const res = await fetchAssetDownloadURL(subAssetId);
      window.open(res.download_url, '_blank');
    } catch (err) {
      toast.error(err?.message);
    }
  };

  return (
    <Card className="rounded-lg shadow border d-flex flex-column h-100">
      <Card.Header className="bg-white text-center mx-3">
        <Card.Title className={classNames('btn btn-link d-block mb-0')}>
          <button
            type="button"
            onClick={onClick}
            className={classNames(
              styles.asset_name,
              'bg-transparent border-0 text-size-sm font-weight-600',
            )}
          >
            {name}
          </button>
        </Card.Title>
      </Card.Header>
      <DropDownDeleteDowload onDelete={handleDelete} onDownload={handleDownload} />

      <Card.Body>
        <Row className="mx-0 pt-4">
          {subassets.length === 0 && (
            <Col sm={12} className="text-center mb-2">
              <p className="text-muted">No sub assets present</p>
            </Col>
          )}
          {subassets.map(asset => (
            <Col sm={12} md={6} key={asset.id} className="mb-3">
              <AssetImage key={asset.id} name={asset?.folder_name} onClick={onClick} />
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default SubAsset;
