import React, { useState } from 'react';
import { Form, Row, Col, ProgressBar, Spinner } from 'react-bootstrap';
import classnames from 'classnames';
import { useHistory } from 'react-router';
import moment from 'moment';
import BoardsCard from './BoardsCard';
import styles from '../../styles/modules/ServiceBoards.module.scss';
import plusIconGray from '../../assets/icons/plus-gray.svg';
import { useBoards } from '../../hooks/boards.hooks';
import CreateBoard from './CreateBoard';
import CreateCollection from './CreateCollection';
import { useCollections } from '../../hooks/collection.hooks';
import UserContext from '../../contexts/UserContext';
import plusIcon from '../../assets/icons/plus.svg';
import { ROLES, SECTION_KEY } from '../../utils/enums';
import RoleBased from '../../components/RoleBased';

function Boards() {
  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);

  const history = useHistory();

  const { user, getRole } = React.useContext(UserContext);

  const {
    data: boards,
    isFetching,
    refetch,
  } = useBoards(getRole()?.role !== ROLES.SUPER_ADMIN ? user?.id : '');

  const { data: collections, isLoading: isFetchingCollections } = useCollections();

  const [search, setSearch] = React.useState('');
  const handleSearchChange = e => setSearch(e.target.value);

  const [sortKey, setSortKey] = React.useState('unsorted');
  const [filterCollection, setFilterCollection] = React.useState(null);
  const toggleSortKey = key => (key === sortKey ? setSortKey(null) : setSortKey(key));
  const toggleFilterCollection = id =>
    id === filterCollection ? setFilterCollection(null) : setFilterCollection(id);

  const filterCreatedBy = (a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  };

  const filterFn = ({ title }) => {
    if (search === '') {
      return true;
    }

    return String(title).toLowerCase().includes(search.toLowerCase());
  };

  const filterByCollection = ({ collections: clns }) => {
    if (filterCollection) {
      return clns.map(({ collection_id }) => collection_id).includes(filterCollection);
    }

    return true;
  };

  const sortFn = (a, b) => {
    if (sortKey === 'alphabetically') {
      return String(a.title).localeCompare(String(b.title));
    }

    if (sortKey === 'sort_by_last_active') {
      return moment(b.updated_at).diff(moment(a.updated_at));
    }

    if (sortKey === 'sort_by_members') {
      return (b?.team?.memberCount ?? 0) - (a?.team?.memberCount ?? 0);
    }

    if (sortKey === 'sort_by_stars') {
      return Number.parseInt(a.totalStars, 10) - Number.parseInt(b.totalStars, 10);
    }

    return 0;
  };

  return (
    <Row className="mt-3">
      <RoleBased
        sectionKey={[SECTION_KEY.subscription_view_page_full_view]}
        acceptedRoles={[ROLES.SUPER_ADMIN, ROLES.MANAGERS]}
      >
        <Col sm={12} md={4} lg={3} className="border-right">
          <Form>
            <Form.Group controlId="searchForBoards">
              <Col xs="auto" className="mx-0 px-0">
                <Form.Control
                  size="sm"
                  type="email"
                  placeholder="Search for boards"
                  value={search}
                  onChange={handleSearchChange}
                />
              </Col>
            </Form.Group>
          </Form>
          <div className={styles.boards_sidebar}>
            <h5 className={`${styles.section_heading} text-size-xs`}>SORT</h5>
            <button
              type="button"
              className={classnames(
                'd-block bg-transparent border-0 text-size-xxs',
                sortKey === 'sort_by_last_active' && 'font-weight-bold',
              )}
              onClick={() => toggleSortKey('sort_by_last_active')}
            >
              Most Recently Active
            </button>
            <button
              type="button"
              className={classnames(
                'd-block bg-transparent border-0 text-size-xxs',
                sortKey === 'sort_by_members' && 'font-weight-bold',
              )}
              onClick={() => toggleSortKey('sort_by_members')}
            >
              Number of Members
            </button>
            <button
              type="button"
              className={classnames(
                'd-block bg-transparent border-0 text-size-xxs',
                sortKey === 'sort_by_stars' && 'font-weight-bold',
              )}
              onClick={() => toggleSortKey('sort_by_stars')}
            >
              Number of Stars
            </button>
            <button
              type="button"
              className={classnames(
                'd-block bg-transparent border-0 text-size-xxs',
                sortKey === 'alphabetically' && 'font-weight-bold',
              )}
              onClick={() => toggleSortKey('alphabetically')}
            >
              Alphabetical
            </button>

            <h5 className={`${styles.section_heading} text-size-xxs mt-3`}>
              <span className="mr-2">FILTER BY COLLECTION</span>
              {isFetchingCollections && <Spinner animation="border" variant="primary" size="sm" />}
            </h5>

            {collections.map(({ id, title }) => (
              <button
                key={id}
                type="button"
                className={classnames(
                  'd-block bg-transparent border-0 text-size-xxs',
                  filterCollection === id && 'font-weight-bold',
                )}
                onClick={() => toggleFilterCollection(id)}
              >
                {title}
              </button>
            ))}

            <RoleBased
              sectionKey={[SECTION_KEY.collection_and_collection_filter_view_add]}
              acceptedRoles={[ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.MANAGERS]}
            >
              <CreateCollection>
                <div>
                  <Row
                    className={`${styles.section_heading} px-0 d-flex align-items-center justify-content-between text-size-xxs`}
                  >
                    <Col
                      xs={7}
                      sm={7}
                      md={9}
                      lg={9}
                      className={`${styles.section_heading_bottom} text-sixe-xxs px-2`}
                    >
                      Create a collection &nbsp;
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1} className={`${styles.row_end_icon}`}>
                      <img
                        src={plusIcon}
                        className={`${styles.sidesection_icon_img} py-2`}
                        alt="plus sign"
                      />
                    </Col>
                  </Row>
                </div>
              </CreateCollection>
            </RoleBased>
          </div>
        </Col>
      </RoleBased>

      <Col sm={12} md={8} lg={9}>
        {isFetching && <ProgressBar animated variant="primary" now={100} className="mb-3" />}
        <Row>
          <RoleBased
            sectionKey={[SECTION_KEY.board_add]}
            acceptedRoles={[ROLES.SUPER_ADMIN, ROLES.ADMIN]}
          >
            <Col sm={12} md={12} lg={4} xl={3} className="mb-3 ">
              <button
                type="button"
                onClick={toggleModal}
                className={classnames(
                  'card card-body d-block w-100',
                  styles.border_radius_primary,
                  styles.card_shadow,
                )}
              >
                <h6 className="mb-0">
                  {'Create new Board '}
                  <img
                    src={plusIconGray}
                    className={styles.sidesection_icon_img_gray}
                    alt="plus sign"
                  />
                </h6>
              </button>

              <CreateBoard
                show={show}
                onClose={() => {
                  refetch();
                  toggleModal();
                }}
              />
            </Col>
          </RoleBased>
          {boards
            ?.sort(filterCreatedBy)
            ?.filter(filterFn)
            ?.filter(filterByCollection)
            ?.sort(sortFn)
            ?.map(board => (
              <Col key={board.id} sm={12} md={12} lg={4} xl={3} className="mb-3">
                <BoardsCard
                  onClick={() => {
                    localStorage.setItem(`kimp-user-default-board`, board.id);
                    history.push(`/dashboard/request-history/${board.id}`);
                  }}
                  boardId={board.id}
                  heading={board.title}
                  collections={board.collections}
                  user={[]}
                />
              </Col>
            ))}
        </Row>
      </Col>
    </Row>
  );
}

export default Boards;
