import React, { useState } from 'react';
import { Tab } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styles from '../../styles/modules/RequestBoard.module.scss';
import { useUpdateTask } from '../../hooks/requestHistory.hooks';
import RequestItemCard from '../../components/RequestItemCard';
import { reorderTasks } from '../../requests/tasks.request';

function RequestSection({
  onHandleScreen,
  tasks,
  onStatusChange = () => {},
  handleSelectTask,
  isTaskFetching,
}) {
  const [key, setKey] = React.useState('tasks');
  const { id: boardId } = useParams();

  const { mutateAsync: updateTask, isLoading } = useUpdateTask();

  const queryClient = useQueryClient();

  const [processingId, setProcessingId] = useState();

  const handleStatus = async (id, dueDate) => {
    try {
      setProcessingId(id);
      const data = {
        status: 'in-progress',
        due_date: dueDate.format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
        order: 0,
      };
      await updateTask({ id, data });
      queryClient.invalidateQueries(['boardTasks', boardId]);
      onHandleScreen('in-progress');
      onStatusChange();
      setProcessingId(undefined);
    } catch (error) {
      toast.error(error?.message);
      setProcessingId(undefined);
    }
  };

  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    if (!isTaskFetching) setItems(tasks.sort((a, b) => a.order - b.order));
  }, [tasks, isTaskFetching]);

  const handleOnDragEnd = async result => {
    const arr = Array.from(items);
    const [reorderedItem] = arr.splice(result.source.index, 1);
    arr.splice(result.destination.index, 0, reorderedItem);

    setItems(arr.map((i, index) => ({ ...i, order: index })).sort((a, b) => a.order - b.order));

    try {
      await reorderTasks({
        orders: arr.map(({ id }, index) => ({ task_id: id, order: index })),
      });
      onStatusChange();
    } catch (err) {
      //
    }
  };

  return (
    <>
      <Tab.Container id="requests-list-tab" activeKey={key} onSelect={k => setKey(k)}>
        <Tab.Content>
          <Tab.Pane eventKey="tasks">
            {items.length === 0 && <div className="text-center">No new task is requested.</div>}

            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="tasks">
                {provided => (
                  <ul
                    className="list-unstyled"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {items.map((item, index) => (
                      <Draggable key={item.id} draggableId={String(item.id)} index={index}>
                        {prov => (
                          <li
                            ref={prov.innerRef}
                            {...prov.draggableProps}
                            {...prov.dragHandleProps}
                          >
                            <RequestItemCard
                              {...item}
                              handleStatus={handleStatus}
                              isLoading={isLoading}
                              handleSelectTask={handleSelectTask}
                              styles={styles}
                              processingId={processingId}
                              onStatusChange={onStatusChange}
                            />
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
}

export default RequestSection;
