import React from 'react';

export const initialData = {
  clearedForm: [],
  form: 'name',
  files: [],
  assets: [],
  data: {
    file_formats: [],
    steps: [],
  },
};

const RequestContext = React.createContext({
  request: initialData,
  updateRequest: () => {},
});

export default RequestContext;
