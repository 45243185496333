import { useQuery } from 'react-query';
import isEmpty from 'lodash/isEmpty';
import search from '../requests/search.requests';

const useSearch = keyword =>
  useQuery(
    ['search', String(keyword).trim()],
    async () => {
      const res = await search(String(keyword).trim());
      return res.data;
    },
    {
      enabled: !isEmpty(String(keyword).trim()),
      initialData: {},
    },
  );

export default useSearch;
