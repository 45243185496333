import React from 'react';
import Card from 'react-bootstrap/Card';
import classnames from 'classnames';
import Media from 'react-bootstrap/Media';
import { v4 as uuid } from 'uuid';
import { useParams } from 'react-router';
import { Col, Spinner } from 'react-bootstrap';
import moment from 'moment';
import styles from '../../styles/modules/RequestMenu.module.scss';
import { useProjectLogs } from '../../hooks/project.hooks';

function ActivityScreen() {
  const { id } = useParams();
  const { data: logs, isFetching } = useProjectLogs(id);

  const taskName = log => {
    switch (log) {
      case 'task_created':
        return 'Created';
      case 'status_change_to_in-progress':
        return 'Status change to in-progress';
      case 'status_change_to_in-review':
        return 'Status change to in-review';
      case 'status_change_to_completed':
        return 'Status change to completed';
      case 'status_change_to_request':
        return 'Status change to request';
      case 'task_modified':
        return 'Modified';
      default:
        return log;
    }
  };

  return (
    <>
      <Card.Header className="text-font no-border font-weight-600">Activity</Card.Header>
      <Card.Body className={classnames(`d-flex flex-column`, styles.overflow_activity_box)}>
        {isFetching && (
          <Col align="center">
            <Spinner animation="border" variant="primary" size="sm" />
          </Col>
        )}

        {!isFetching &&
          logs?.map(item => {
            const { log, task, user, updated_at } = item;
            return (
              <Media className={`mt-4 `} key={uuid()}>
                {/* <img src={tracyBotIcon} alt="bot" className={`mx-2 ${styles.bot_icon_size}`} /> */}
                <Media.Body className="mx-1">
                  <div className="d-flex py-1">
                    <h5 className="text-size-xxs pr-2 font-weight-600 ">{user?.name}</h5>
                    <h5 className="text-size-xxs ">
                      {moment(updated_at).format('DD MMM [at] hh:mm A')}
                    </h5>
                  </div>
                  <Card body className={`text-size-xxs shadow ${styles.custom_padding_details}`}>
                    {`${taskName(log)}: ${task?.title ? task?.title : ''}`}
                  </Card>
                </Media.Body>
              </Media>
            );
          })}

        {!isFetching && logs?.length === 0 && (
          <Col align="center">
            <span>No Activity Found</span>
          </Col>
        )}
      </Card.Body>
    </>
  );
}

export default ActivityScreen;
