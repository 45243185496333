import React from 'react';
import { Card } from 'react-bootstrap';
import classNames from 'classnames';
import PhotoGalleryIcon from '../../assets/icons/photo-gallery-icon.svg';
import defaultIcon from '../../assets/icons/file-type-default.svg';
import photoshopIcon from '../../assets/icons/file-type-photoshop.svg';
import illustratorIcon from '../../assets/icons/file-type-illustrator.svg';
import pdfIcon from '../../assets/icons/file-type-pdf.svg';
import pngIcon from '../../assets/icons/file-type-png.svg';
import svgIcon from '../../assets/icons/file-type-svg.svg';
import jpgIcon from '../../assets/icons/file-type-jpg.svg';
import styles from '../../styles/modules/AddAssets.module.scss';

const imageExtensions = ['jpeg', 'jpg', 'gif', 'png'];

const PhotoCard = ({ onClick, title, url }) => {
  const getThumbnail = () => {
    const extension = String(title).split('.').pop().toLowerCase();

    if (imageExtensions.includes(extension)) {
      return url;
    }

    return PhotoGalleryIcon;
  };

  const getFileType = () => {
    const extension = String(title).split('.').pop().toLowerCase();

    switch (extension) {
      case 'jpeg':
        return jpgIcon;
      case 'jpg':
        return jpgIcon;
      case 'png':
        return pngIcon;
      case 'svg':
        return svgIcon;
      case 'pdf':
        return pdfIcon;
      case 'ai':
        return illustratorIcon;
      case 'psd':
        return photoshopIcon;

      default:
        return defaultIcon;
    }
  };

  return (
    <Card
      className={classNames(
        'shadow-sm position-relative p-0 w-100',
        'd-flex flex-column justify-content-center align-items-center',
      )}
      as="button"
      title={title}
      onClick={onClick}
    >
      <img src={getFileType()} alt="" width="30" height="30" className={styles.img_file_type} />
      <Card.Body>
        <Card.Img
          src={getThumbnail()}
          alt=""
          width="200"
          height="200"
          className={classNames('p-4', styles.asset_image_item)}
        />
      </Card.Body>
    </Card>
  );
};

export default PhotoCard;
