import React from 'react';
import { DatePicker, Space, TimePicker } from 'antd';
import { Button, Card, Col, Row } from 'react-bootstrap';
// import moment from 'moment';

const DueDatePicker = ({ style, styles, saveDueDate, className }) => {
  const [date, setDate] = React.useState();
  const [time, setTime] = React.useState();
  return (
    <Card style={style} className={className}>
      <Space direction="vertical">
        <Row>
          <Col xs="4" className="align-self-center">
            <span className="text-size-xs">Date:</span>
          </Col>
          <Col className="pl-0">
            <DatePicker
              className="date-picker details"
              // disabledDate={currentDate => moment(currentDate).diff(moment(), 'days') < 0}
              allowClear
              onChange={selectedDate => {
                setDate(selectedDate);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="4" className="align-self-center">
            <span className="text-size-xs">Time:</span>
          </Col>
          <Col className="pl-0">
            <TimePicker
              className="ven-due-date-time-picker time-picker details"
              allowClear
              onChange={setTime}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col align="center">
            <Button
              disabled={!date || !time}
              className={`text-size-xs py-0 py-1 font-weight-600 details ${styles.btn_structure}`}
              onClick={() => {
                saveDueDate(date, time);
              }}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Space>
    </Card>
  );
};

export default DueDatePicker;
