import React from 'react';

function Icon({ color, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={className}
    >
      <path
        //   fill="#f44336"
        // fill="#969696"
        fill={color}
        d="M8 0a8 8 0 108 8 8.009 8.009 0 00-8-8zm0 0"
        data-name="Path 3164"
      />
      <path
        fill="#fafafa"
        d="M161.679 160.718a.68.68 0 11-.961.961l-2.043-2.043-2.043 2.043a.68.68 0 11-.961-.961l2.043-2.043-2.043-2.043a.68.68 0 11.961-.961l2.043 2.043 2.043-2.043a.68.68 0 11.961.961l-2.043 2.043zm0 0"
        data-name="Path 3165"
        transform="translate(-150.675 -150.675)"
      />
    </svg>
  );
}

export default Icon;
