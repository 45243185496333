import http from '../utils/http';

export const createMedia = data => http.post('/medias', data, { hasFiles: true });

export const updateMedia = (id, data) =>
  http.post(`/medias/${id}?_method=PUT`, data, { hasFiles: true });

export const fetchMedia = id => http.get(`/medias/${id}`);

export const deleteMedia = id => http.delete(`/medias/${id}`);
