import { useMutation, useQuery } from 'react-query';
import {
  deleteUserById,
  getProfile,
  getUserDetailsById,
  getUserFavorites,
  getUsers,
  getUserWatchList,
  postUserWatchList,
  updateUserDetailsById,
  updateUserFavorites,
} from '../requests/user.requests';

export const useGetProfile = () =>
  useQuery(
    ['profile'],
    async () => {
      const res = await getProfile();
      return res.data;
    },
    {
      initialData: [],
    },
  );

export const useGetUsers = search =>
  useQuery(
    ['users', search],
    async () => {
      const res = await getUsers(search);
      return res.data;
    },
    {
      initialData: [],
    },
  );

export const useGetUserDetailsById = id =>
  useQuery(
    ['profile', id],
    async () => {
      const res = await getUserDetailsById(id);
      return res.data;
    },
    {
      enabled: !!id,
      initialData: [],
    },
  );

export const usePostUserWatchList = () =>
  useMutation(async ({ userId, data }) => {
    const res = await postUserWatchList(userId, data);
    return res;
  });

export const useGetUserWatchList = userId =>
  useQuery(
    ['watch_list', userId],
    async () => {
      const res = await getUserWatchList(userId);
      return res.data;
    },
    {
      enabled: !!userId,
      initialData: [],
    },
  );

export const useUpdateUserFavorites = () =>
  useMutation(async ({ userId, data }) => {
    const res = await updateUserFavorites(userId, data);
    return res;
  });

export const useGetUserFavorites = userId =>
  useQuery(
    ['favorites', userId],
    async () => {
      const res = await getUserFavorites(userId);
      return res.data;
    },
    {
      enabled: !!userId,
      initialData: [],
    },
  );

export const useDeleteUser = () =>
  useMutation(async userId => {
    const res = await deleteUserById(userId);
    return res;
  });

export const useUpdateUser = () =>
  useMutation(async ({ userId, data }) => {
    const res = await updateUserDetailsById(userId, data);
    return res;
  });
