import classnames from 'classnames';
import React from 'react';
import * as yup from 'yup';
import { Form, Button, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { toast } from 'react-toastify';
// import { useHistory } from 'react-router-dom';
import styles from '../../styles/modules/ForgotPassword.module.scss';
import { useLogin, useResetPassword } from '../../hooks/auth.hooks';
import { fetchUserProfile } from '../../requests/auth.requests';
import UserContext from '../../contexts/UserContext';

const ConfirmPasswordScreen = ({ token, email }) => {
  // const history = useHistory();
  const { updateUser } = React.useContext(UserContext);
  const schema = yup.object({
    otp: yup.string().required('Required').min(6, 'Must be  6 digits').max(6, 'Must be  6 digits'),
    password: yup.string().required('Required').min(6, 'Must be minimum of 6 characters'),
    confirm_password: yup
      .string()
      .required('Required')
      .min(6, 'Must be minimum of 6 characters')
      .oneOf([yup.ref('password'), null], 'Password dont match'),
  });

  const { mutateAsync: resetPassword, isLoading } = useResetPassword();
  const { mutateAsync: login } = useLogin();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async () => {
    const data = {
      otp: getValues('otp'),
      password: getValues('confirm_password'),
      remember_token: token,
    };
    try {
      const res = await resetPassword(data);
      toast.success(res.message);
      if (res.status) {
        const user_data = {
          email,
          password: getValues('confirm_password'),
        };
        try {
          const { message, access_token } = await login(user_data);
          localStorage.setItem('kimp-authtoken', access_token);
          const response = await fetchUserProfile();
          toast.success(message);
          updateUser(response.data);

          const query = new URLSearchParams();
          query.append('login', response.data?.username);
          query.append('redirect_url', process.env.REACT_APP_SITE_URL);
          window.open(`${process.env.REACT_APP_WP_URL}?${query.toString()}`, '_self');
        } catch (err) {
          localStorage.clear();
          toast.error(err?.message);
        }
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  return (
    <Form className="mb-3" onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-0 mb-3">
        <Form.Label className="font-weight-bold text-secondary mb-0">
          OTP <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          {...register('otp')}
          type="number"
          name="otp"
          isInvalid={errors?.otp}
          className={classnames(
            styles.custom_bottom_border,
            styles.active_fields,
            'px-0 text-size-sm font-weight-500 remove-focus',
          )}
        />
        <Form.Control.Feedback type="invalid">{errors?.otp?.message}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-0 mb-3">
        <Form.Label className="font-weight-bold text-secondary mb-0">
          Password <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          {...register('password')}
          type="password"
          name="password"
          isInvalid={errors?.password}
          className={classnames(
            styles.custom_bottom_border,
            styles.active_fields,
            'px-0 text-size-sm font-weight-500 remove-focus',
          )}
        />
        <Form.Control.Feedback type="invalid">{errors?.password?.message}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-0 mb-3">
        <Form.Label className="font-weight-bold text-secondary mb-0 ">
          Confirm Password <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          {...register('confirm_password')}
          type="password"
          name="confirm_password"
          isInvalid={errors?.confirm_password}
          className={classnames(
            styles.custom_bottom_border,
            styles.active_fields,
            'px-0 text-size-sm font-weight-500 remove-focus',
          )}
        />
        <Form.Control.Feedback type="invalid">
          {errors?.confirm_password?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Button
        type="submit"
        variant="warning"
        className={classnames('font-weight-bold', styles.custom_btn)}
        size="lg"
        block
        disabled={isLoading}
      >
        {isLoading ? (
          <Spinner size="sm" variant="light" animation="border" className="" />
        ) : (
          <p className={classnames('mb-0', styles.login_text)}>Submit</p>
        )}
      </Button>
    </Form>
  );
};

export default ConfirmPasswordScreen;
