import React from 'react';
import { Card } from 'react-bootstrap';
import { Route, Switch } from 'react-router';
import SubscriptionDetails from './SubscriptionDetails';
import SubscriptionsTable from './SubscriptionsTable';

const MySubscriptions = () => {
  return (
    <Card className="mt-4">
      <Card.Header className="py-4">
        <Card.Title className="text-size-md font-weight-600 text-font">My Subscriptions</Card.Title>
        <Card.Subtitle className="text-size-xs text-font font-weight-500 ">
          Choose to change plans and/or add additional subscriptions as per your requirements.
        </Card.Subtitle>
      </Card.Header>
      <Card.Body className="px-0 px-xl-5">
        <Switch>
          <Route exact path="/dashboard/subscriptions">
            <SubscriptionsTable />
          </Route>
          <Route exact path="/dashboard/subscriptions/:id">
            <SubscriptionDetails />
          </Route>
        </Switch>
      </Card.Body>
    </Card>
  );
};

export default MySubscriptions;
