import '../styles/modules/TopNavBar.scss';
import React, {
  useState,
  // useEffect,
  useRef,
} from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Nav,
  Navbar,
  Overlay,
  Popover,
  Row,
  Spinner,
} from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import mailIcon from '../assets/icons/mail-icon.svg';
import chatIcon from '../assets/icons/chat-icon.svg';
import helpIcon from '../assets/icons/help-icon.svg';
import userIcon from '../assets/icons/user-icon.svg';
import starIcon from '../assets/icons/star-icon.svg';
import searchIcon from '../assets/icons/search-icon.svg';
import UserContext from '../contexts/UserContext';
import useSearch from '../hooks/search.hooks';
import { useFeeds } from '../hooks/feeds.hooks';
import NotificationPopover from './NotificationPopver';
import RoleBased from './RoleBased';
import { ROLES, SECTION_KEY } from '../utils/enums';
import { useFetchRolesById } from '../hooks/roles.hooks';
import NotificationContext from '../contexts/NotificationContext';

function TopNavBar({ toggleSideNav }) {
  const location = useLocation();
  const history = useHistory();
  const [showSearch, setShowSearch] = useState(false);
  const [search, setSearch] = useState('');
  const [showSearchDrop, setShowSearchDrop] = useState(false);
  const target = useRef(null);

  const { data: searchData, isFetching } = useSearch(search);
  const { data: feeds } = useFeeds('');
  const { user, getRole } = React.useContext(UserContext);
  const { data: userRole, isLoading: isRoleLoading } = useFetchRolesById(user?.id);
  const { updatedFeeds } = React.useContext(NotificationContext);

  React.useEffect(() => {
    updatedFeeds({ unread_count: feeds?.total_unread });
  }, [feeds?.total_unread]);

  return (
    <Navbar fixed="top" expand="md" className="navbar-shadow py-3">
      <Button className="d-inline-block d-md-none" type="button" onClick={toggleSideNav}>
        <FontAwesomeIcon icon={faBars} className="icon-size" />
      </Button>
      <Navbar.Toggle className="ml-auto" />

      <Navbar.Collapse className="">
        {location.pathname === '/' ||
        location.pathname === '/assets' ||
        location.pathname === '/new-request-name' ||
        location.pathname === '/support' ||
        location.pathname === '/request-dimension' ? (
          <Nav className="mr-auto align-items-start align-items-md-center collapsed-navbar">
            <Navbar.Text className="text-darker text-size-sm text-font font-weight-600 ">
              Monday, 27 Apr 2020{' '}
            </Navbar.Text>
          </Nav>
        ) : (
          <Nav className="mr-auto d-flex flex-row align-items-center collapsed-navbar py-2">
            <Link to="/dashboard/request-history" className="mx-md-1">
              <img src={mailIcon} alt="mail" className=" ml-md-2 mr-md-2" />
            </Link>
            <Link
              to={
                [
                  ROLES.SUBSCRIBERS,
                  ROLES.VIEWERS,
                  ROLES.CONTRIBUTORS,
                  ROLES.MANAGERS,
                  ROLES.EDITORS,
                ].includes(getRole()?.role)
                  ? '/dashboard/feeds'
                  : [ROLES.SUPER_ADMIN, ROLES.ADMIN].includes(getRole()?.role)
                  ? '/dashboard/support'
                  : ''
              }
              className="mx-md-1"
            >
              <img src={chatIcon} alt="message" className="mx-md-2" />
            </Link>
            <RoleBased sectionKey={[SECTION_KEY.settings_full_view]}>
              <Link to="/dashboard/support" className="mx-md-1">
                <img src={helpIcon} alt="support" className="mx-md-2" />
              </Link>
            </RoleBased>
            <Link to="/dashboard/edit-profile" className="mx-md-1">
              <img src={userIcon} alt="user" className="mx-md-2" />
            </Link>
            <RoleBased
              sectionKey={[
                SECTION_KEY.subscription_view_page_add,
                SECTION_KEY.subscription_view_page_edit,
                SECTION_KEY.subscription_view_page_delete,
                SECTION_KEY.subscription_view_page_full_view,
              ]}
              acceptedRoles={[
                ROLES.SUPER_ADMIN,
                ROLES.ADMIN,
                ROLES.MANAGERS,
                ROLES.EDITORS,
                ROLES.CONTRIBUTORS,
              ]}
            >
              <Link to="/dashboard/favorite-board" className="mx-md-1">
                <img src={starIcon} alt="bookmark" className="mx-md-2" />
              </Link>
            </RoleBased>
          </Nav>
        )}
        <Nav className="align-items-center collapsed-navbar py-2">
          <NotificationPopover feeds={feeds} />

          <Nav>
            <Nav.Link
              className={`mx-md-1 ${showSearch ? 'd-none' : 'd-block'}`}
              onClick={() => {
                document.getElementsByClassName('search-input')[0].focus();
                setShowSearch(true);
                setShowSearchDrop(true);
              }}
            >
              <img src={searchIcon} alt="search" className="tnb-icon-size mx-md-2" />
            </Nav.Link>
            <Overlay
              target={target.current}
              show={showSearchDrop}
              placement="bottom-end"
              rootClose
              rootCloseEvent="click"
              onHide={e => {
                if (e.target !== target.current) {
                  setShowSearch(false);
                  setShowSearchDrop(false);
                }
              }}
            >
              {props => (
                <Popover {...props} id="popover-positioned" className="search_popover">
                  <Popover.Title>Tasks</Popover.Title>
                  <Popover.Content>
                    {isFetching && (
                      <Row>
                        <Col align="center">
                          <Spinner size="md" variant="primary" animation="border" />
                        </Col>
                      </Row>
                    )}
                    {!isFetching &&
                      searchData?.tasks?.length > 0 &&
                      searchData?.tasks?.map(task => (
                        <Row>
                          <Col>
                            <Card
                              key={task.id}
                              body
                              className="mb-2 shadow cursor-pointer"
                              onClick={() => {
                                history.push(`/dashboard/request-history/${task.project_id}`, {
                                  taskId: task.id,
                                });
                                setShowSearch(false);
                                setShowSearchDrop(false);
                              }}
                            >
                              <Row>
                                <span className="text-size-xs pl-4 text-darker mb-0 search-task-title">
                                  {task.title}
                                </span>
                                <span className="text-size-xxs ml-2 text-darker mb-0 align-self-center search-task-project">
                                  {task.project?.title}
                                </span>
                              </Row>
                              <Row>
                                <p className="text-size-xxs px-4 text-darker mb-0">{task.status}</p>
                              </Row>
                            </Card>
                          </Col>
                        </Row>
                      ))}
                    {!isFetching && searchData?.tasks?.length === 0 && (
                      <Row>
                        <Col align="center">
                          <p className="text-size-xs px-4 text-darker mb-0">No data found</p>
                        </Col>
                      </Row>
                    )}
                  </Popover.Content>
                  <Popover.Title>Projects</Popover.Title>
                  <Popover.Content>
                    {isFetching && (
                      <Row>
                        <Col align="center">
                          <Spinner size="md" variant="primary" animation="border" />
                        </Col>
                      </Row>
                    )}
                    {!isFetching &&
                      searchData?.projects?.length > 0 &&
                      searchData?.projects?.map(project => (
                        <Row>
                          <Col>
                            <Nav.Link
                              onClick={() => {
                                setShowSearch(false);
                                setShowSearchDrop(false);
                                history.push(`/dashboard/request-history/${project.id}`);
                              }}
                            >
                              <Card key={project.id} body className="mb-2 shadow">
                                <p className="text-size-xs px-4 text-darker mb-0">
                                  {project.title}
                                </p>
                              </Card>
                            </Nav.Link>
                          </Col>
                        </Row>
                      ))}
                    {!isFetching && searchData?.projects?.length === 0 && (
                      <Row>
                        <Col align="center">
                          <p className="text-size-xs px-4 text-darker mb-0">No data found</p>
                        </Col>
                      </Row>
                    )}
                  </Popover.Content>
                </Popover>
              )}
            </Overlay>
            <Form.Control
              ref={target}
              placeholder="Search"
              autoFocus
              type="text"
              disabled={!showSearch}
              className={`search-input${showSearch ? ' show ml-4' : ''}`}
              value={search}
              onChange={e => {
                setSearch(e.target.value);
              }}
              onClick={() => {
                setShowSearch(true);
                setShowSearchDrop(true);
              }}
            />
          </Nav>
          <Link to="/dashboard/edit-profile" className="mr-md-2 ml-4 text-font">
            <div className="username text-secondary">{user?.name}</div>
            {isRoleLoading && <div className="availibility text-secondary">Available</div>}
            <div className="availibility text-secondary">
              {!isRoleLoading && <>{userRole[0] === 'Viewers' && 'Viewer'}</>}
              {!isRoleLoading && <>{userRole[0] === 'Subscribers' && 'Subscriber'}</>}
              {!isRoleLoading && <>{userRole[0] === 'Contributors' && 'Contributor'}</>}
              {!isRoleLoading && <>{userRole[0] === 'Editors' && 'Editor'}</>}
              {!isRoleLoading && <>{userRole[0] === 'Managers' && 'Manager'}</>}
              {!isRoleLoading && <>{userRole[0] === 'Admin' && 'Admin'}</>}
              {!isRoleLoading && <>{userRole[0] === 'Super Admin' && 'Super Admin'}</>}
            </div>
          </Link>
          <Link to="/dashboard/edit-profile" className="">
            <div className="mr-md-2 ml-1">
              <img
                src={`https://ui-avatars.com/api/?name=${user?.first_name}+${user?.last_name}&background=219186&color=fff`}
                alt="profile"
                className="rounded-circle active-user-icon-size"
              />
            </div>
          </Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default TopNavBar;
