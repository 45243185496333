import { useMutation, useQuery } from 'react-query';
import { createMedias, fetchMediaById, fetchMedias } from '../requests/media.requests';

export const useFetchMedias = (userId, orderBy) =>
  useQuery(
    ['medias', userId, orderBy],
    async () => {
      const res = await fetchMedias(userId, orderBy);
      return res.data;
    },
    {
      enabled: !!userId,
      initialData: [],
    },
  );

export const useCreateMedias = () =>
  useMutation(async media => {
    const res = await createMedias(media);
    return res;
  });

export const useFetchMediaById = id =>
  useQuery(
    ['media_id', id],
    async () => {
      const res = await fetchMediaById(id);
      return res.data;
    },
    {
      enabled: !!id,
      initialData: undefined,
    },
  );
