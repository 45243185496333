import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Card from 'react-bootstrap/Card';
import classnames from 'classnames';
import { useHistory, useLocation } from 'react-router';
import styles from '../../styles/modules/Support.module.scss';
import Feeds from './Feeds';
import Boards from './Boards';
import Members from './Members';
import RoleBased from '../../components/RoleBased';
import { ROLES, SECTION_KEY } from '../../utils/enums';

function SupportTabs() {
  const [key, setKey] = React.useState('feeds');
  const history = useHistory();
  const { pathname, search } = useLocation();

  React.useEffect(
    function loadViewFromPathParam() {
      if (search) {
        setKey(new URLSearchParams(search).get('key'));
      } else {
        history.push(`${pathname}?key=feeds`);
      }
    },
    [search],
  );

  return (
    <>
      <Tab.Container activeKey={key}>
        <Card className="text-font mt-4">
          <Card.Header
            className={`pb-md-0 mx-4 pl-0 px-md-3 mx-md-0 d-flex flex-row justify-content-md-center ${classnames(
              styles.support_tabs,
            )}`}
          >
            <button
              type="button"
              onClick={() => history.push(`${pathname}?key=feeds`)}
              className={`text-size-sm ${classnames(key === 'feeds' && styles.active)}`}
            >
              <span>Feeds</span>
            </button>
            <RoleBased
              sectionKey={[
                SECTION_KEY.board_full_view,
                SECTION_KEY.board_add,
                SECTION_KEY.board_edit,
                SECTION_KEY.board_delete,
              ]}
              acceptedRoles={[
                ROLES.SUPER_ADMIN,
                ROLES.ADMIN,
                ROLES.MANAGERS,
                ROLES.EDITORS,
                ROLES.SUBSCRIBERS,
              ]}
            >
              <button
                type="button"
                onClick={() => history.push(`${pathname}?key=boards`)}
                className={`text-size-sm ${classnames(key === 'boards' && styles.active)}`}
              >
                <span>Boards</span>
              </button>
            </RoleBased>
            <button
              type="button"
              onClick={() => history.push(`${pathname}?key=members`)}
              className={`text-size-sm ${classnames(key === 'members' && styles.active)}`}
            >
              <span>Members</span>
            </button>
          </Card.Header>

          <Card.Body>
            <Tab.Content>
              <Tab.Pane eventKey="feeds">
                <Feeds />
              </Tab.Pane>
              <RoleBased
                sectionKey={[
                  SECTION_KEY.board_full_view,
                  SECTION_KEY.board_add,
                  SECTION_KEY.board_edit,
                  SECTION_KEY.board_delete,
                ]}
                acceptedRoles={[
                  ROLES.SUPER_ADMIN,
                  ROLES.ADMIN,
                  ROLES.MANAGERS,
                  ROLES.EDITORS,
                  ROLES.SUBSCRIBERS,
                ]}
              >
                <Tab.Pane eventKey="boards">
                  <Boards />
                </Tab.Pane>
              </RoleBased>
              <Tab.Pane eventKey="members">
                <Members />
              </Tab.Pane>
            </Tab.Content>
          </Card.Body>
        </Card>
      </Tab.Container>
    </>
  );
}

export default SupportTabs;
