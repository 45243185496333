import React from 'react';
import { Button, Card, Image, Table } from 'react-bootstrap';
import classnames from 'classnames';
import cardIcon from '../../assets/icons/choose-card.svg';
import PaymentCard from './PaymentCard';
import styles from '../../styles/modules/PaymentMethod.module.scss';
import AddCardButton from './AddCardButton';

const ChangePaymentMethod = () => {
  return (
    <Card>
      <Card.Header>
        <Card.Title className="font-weight-600">Change Payment Method</Card.Title>
        <Card.Subtitle>
          Choose to change plans and/or add additional subscriptions as per your requirements.
        </Card.Subtitle>
      </Card.Header>
      <Card.Body>
        <div className="px-0 px-lg-5">
          <Card className="bg-white border-0 shadow mb-5">
            <Card.Body className="d-flex align-items-center justify-content-center">
              <Image src={cardIcon} alt="" className="mr-3" />
              <p className="mb-0">
                {'Choose a new payment method. '}
                <strong>Next payment is due June 9, 2020</strong>
              </p>
            </Card.Body>
          </Card>

          <Table responsive bordered className="mb-5">
            <thead>
              <tr>
                <th>Product</th>
                <th>Quantity</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Kimp Standard Plan $389</td>
                <td>1</td>
                <td>USD $389.00 / month</td>
              </tr>
            </tbody>
          </Table>

          <h5 className="mb-4">Subscription Totals</h5>
          <div className={classnames(styles.payment_cards, 'mb-5')}>
            <PaymentCard />
            <AddCardButton />
          </div>

          <Button className={`px-5 font-weight-500 py-2 ${styles.save_button}`}>Save</Button>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ChangePaymentMethod;
