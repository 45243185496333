import React from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import styles from '../../styles/modules/HistoryOfChanges.module.scss';
import CloseIcon from '../../assets/icons/CloseIcon';
import { useGetTaskLog } from '../../hooks/task.hooks';

function HistoryOfChangesSection({ status, taskId }) {
  const { data: taskLog, isFetching } = useGetTaskLog(taskId);
  const taskName = log => {
    switch (log) {
      case 'task_created':
        return 'Created';
      case 'status_change_to_in-progress':
        return 'Status change to in-progress';
      case 'status_change_to_in-review':
        return 'Status change to in-review';
      case 'status_change_to_completed':
        return 'Status change to completed';
      case 'status_change_to_request':
        return 'Status change to request';
      default:
        return '';
    }
  };

  return (
    <Card className={`text-font ${styles.history_card} pt-3`}>
      <Button
        variant="light"
        className={`text-size-xs font-weight-600 position-absolute ${styles.close_details_icon}`}
        onClick={() => status('hide')}
      >
        <CloseIcon className={`${classnames(styles.close_details_icon_size)}`} />
      </Button>
      <Card.Header className="text-size-sm font-weight-700 no-border pb-0">
        History of changes
      </Card.Header>
      <Card.Body className="px-lg-5 text-center">
        {isFetching && (
          <Row>
            <Col>
              <Spinner size="lg" variant="primary" animation="border" />
            </Col>
          </Row>
        )}
        {!isFetching &&
          taskLog.map(item => {
            const { user, log, created_at } = item;

            return (
              <div
                key={uuid()}
                className={`mx-md-4 pt-4 d-flex flex-row justify-content-between ${styles.border_bottom}`}
              >
                <Card.Text className="text-size-xs text-darker">
                  {user.name}: <span className="text-secondary">{taskName(log)}</span>
                </Card.Text>
                <Card.Text className="text-size-xs text-secondary">
                  {moment(created_at).format('MMM DD [at] HH:mmA')}
                </Card.Text>
              </div>
            );
          })}
      </Card.Body>
    </Card>
  );
}

export default HistoryOfChangesSection;
