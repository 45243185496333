import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import classnames from 'classnames';

const Switch = ({ value, onChange, className }) => {
  const [checked, setValue] = React.useState(value || false);

  React.useEffect(() => {
    if (onChange) {
      onChange(checked);
    }
  }, [checked]);

  React.useEffect(() => {
    setValue(value);
  }, [value]);

  return (
    <button
      type="button"
      className={`text-font ${classnames(className, 'switch', checked ? 'is-checked' : '')}`}
      onClick={() => setValue(!checked)}
    >
      <div>
        <FontAwesomeIcon icon={faCheck} size="sm" />
      </div>
      <p className=" text-darker mb-0 text-font font-weight-500">{checked ? 'YES' : 'NO'}</p>
    </button>
  );
};

export default Switch;
