import React, { useEffect, useState } from 'react';
import { Card, Image } from 'react-bootstrap';
import classnames from 'classnames';
import CustomSizeIcon from './CustomSizeIcon';
import styles from '../../../styles/modules/NewRequest.module.scss';
import check from '../../../assets/icons/check-white.svg';

const CustomSize = ({ active, value, onUpdate = () => {} }) => {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [resolution, setResolution] = useState(0);

  useEffect(() => {
    if (value?.includes('Custom_Size')) {
      const dimensions = value?.split(' ');
      setResolution(dimensions[4]);
      setHeight(Number.parseInt(dimensions[3].replace('”', ''), 10));
      setWidth(Number.parseInt(dimensions[1].replace('”', ''), 10));
    }
  }, [value]);

  return (
    <button
      type="button"
      className={styles.custom_size}
      onClick={() => {
        onUpdate('Custom Size', { width, height, resolution });
      }}
    >
      {active && <Image src={check} alt="" className={styles.img_check} />}
      <Card>
        <Card.Body className="text-size-xxs text-font">
          <h6 className={`text-size-xs ${classnames(active ? 'text-primary' : 'text-muted')}`}>
            Custom Size
          </h6>

          <CustomSizeIcon className="my-3" active={active} />

          <div>
            {'W '}
            <input
              type="number"
              min="0"
              value={width}
              onChange={e => {
                onUpdate('Custom Size', { width: e.target.value, height, resolution });
                setWidth(e.target.value);
              }}
            />
            {' H '}
            <input
              type="number"
              min="0"
              value={height}
              onChange={e => {
                onUpdate('Custom Size', { width, height: e.target.value, resolution });
                setHeight(e.target.value);
              }}
            />
            {' DPI '}
            <input
              type="number"
              min="0"
              value={resolution}
              onChange={e => {
                onUpdate('Custom Size', { width, height, resolution: e.target.value });
                setResolution(e.target.value);
              }}
            />
          </div>
        </Card.Body>
      </Card>
    </button>
  );
};

export default CustomSize;
