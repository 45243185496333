import React from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';
import Login from './pages/Login';
import DashboardRouter from './pages/Dashboard.Router';
import UserContext from './contexts/UserContext';
import RequestContext, { initialData } from './contexts/RequestContext';
import { fetchUserProfile } from './requests/auth.requests';
import BoardContext from './contexts/BoardContext';
import { fetchProjectDetails } from './requests/project.request';
import { ROLES } from './utils/enums';
import CreateAccount from './pages/CreateAccount/CreateAccount';
import ForgotPassord from './pages/ForgotPassword/ForgotPassword';
import { fetchBoards } from './requests/boards.requests';
import NotificationContext from './contexts/NotificationContext';
import CommentContext from './contexts/CommentContext';

function Router() {
  const history = useHistory();
  const location = useLocation();
  const [user, setUser] = React.useState(null);
  const [boardRole, setBoardRole] = React.useState(null);
  const [request, setRequest] = React.useState(initialData);
  const [board, setBoard] = React.useState(null);
  const [feedCount, setFeedCount] = React.useState(null);
  const [comments, setComments] = React.useState(null);

  function updateUser(value) {
    setUser({ ...user, ...value });
  }

  function updateBoardRole(value) {
    setBoardRole({ ...boardRole, ...value });
  }

  function updatedFeeds(value) {
    setFeedCount({ ...feedCount, ...value });
  }

  function updatedComments(value) {
    setComments({ ...feedCount, ...value });
  }

  function getRole() {
    let user_role = '';
    if (user) {
      const roles = user?.roles?.map(({ name }) => name);

      if (roles?.includes(ROLES.SUPER_ADMIN)) user_role = ROLES.SUPER_ADMIN;
      if (roles?.includes(ROLES.ADMIN)) user_role = ROLES.ADMIN;
      if (roles?.includes(ROLES.MANAGERS)) user_role = ROLES.MANAGERS;
      if (roles?.includes(ROLES.EDITORS)) user_role = ROLES.EDITORS;
      if (roles?.includes(ROLES.CONTRIBUTORS)) user_role = ROLES.CONTRIBUTORS;
      if (roles?.includes(ROLES.SUBSCRIBERS)) user_role = ROLES.SUBSCRIBERS;
      if (roles?.includes(ROLES.VIEWERS)) user_role = ROLES.VIEWERS;

      return {
        role: user_role,
        permissions: boardRole?.permissions
          ? (boardRole?.permissions ?? []).map(item => item.name)
          : (user?.roles?.[0]?.permissions ?? []).map(item => item.name),
      };
    }

    return null;
  }

  function updateRequest(value) {
    setRequest({ ...request, ...value });
  }

  function updateBoard(value) {
    setBoard({ ...board, ...value });
  }

  async function checkIfUserAuthenticated(token = null) {
    try {
      if (!isEmpty(token)) {
        const res = await fetchUserProfile();
        updateUser(res.data);
        const defaultBoard = localStorage.getItem('kimp-user-default-board');
        if (defaultBoard) {
          const boardDetails = await fetchProjectDetails(defaultBoard);
          updateBoard(boardDetails.data);
        } else {
          const batchRes = await fetchBoards(
            res.data?.roles[0]?.name !== ROLES.SUPER_ADMIN ? res.data?.id : '',
          );
          if (batchRes.status) {
            localStorage.setItem('kimp-user-default-board', batchRes.data[0]?.id);
            updateBoard(batchRes.data[0]);
          }
        }

        if (location.pathname.includes('/dashboard')) {
          history.push(location.pathname + location.search, location.state);
        } else {
          history.push('/dashboard');
        }
      } else if (
        location.pathname.includes('/register') &&
        location.search &&
        location.search !== ''
      ) {
        history.push(location.pathname + location.search);
      } else if (location.pathname.includes('/register')) {
        window.location.href = 'https://kimp360wp.codebuddy.codes/register-now/';
      } else {
        history.push('/');
      }
    } catch (err) {
      toast.error(err?.message);
    }
  }

  React.useEffect(() => {
    const token = localStorage.getItem('kimp-authtoken');

    if (token) {
      checkIfUserAuthenticated(token);
    } else if (location.pathname !== '/') {
      history.push(location.pathname + location.search);
    }

    window.addEventListener(
      'message',
      function (event) {
        if (event.origin === 'https://kimp360wp.codebuddy.codes') {
          // eslint-disable-next-line no-console
          // console.log('DATA TOKEN', event.origin, event.data, typeof event.data);
          if (
            typeof event.data === 'string' &&
            !isEmpty(event.data) &&
            isEmpty(localStorage.getItem('kimp-authtoken'))
          ) {
            localStorage.setItem('kimp-authtoken', event.data);
            checkIfUserAuthenticated(event.data);
          }
        }
      },
      false,
    );
  }, []);

  return (
    <UserContext.Provider value={{ user, updateBoardRole, updateUser, getRole }}>
      <NotificationContext.Provider value={{ feedCount, updatedFeeds }}>
        <CommentContext.Provider value={{ comments, updatedComments }}>
          <RequestContext.Provider value={{ request, updateRequest }}>
            <BoardContext.Provider value={{ board, updateBoard }}>
              <Switch>
                <Route exact path="/">
                  <Login />
                </Route>
                <Route path="/register">
                  <CreateAccount />
                </Route>
                <Route path="/forgot-password">
                  <ForgotPassord />
                </Route>
                <Route path="/dashboard">
                  <DashboardRouter />
                </Route>
              </Switch>
            </BoardContext.Provider>
          </RequestContext.Provider>
        </CommentContext.Provider>
      </NotificationContext.Provider>
    </UserContext.Provider>
  );
}

export default Router;
