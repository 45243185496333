import http from '../utils/http';

export const fetchTasks = userId => http.get(`/tasks/${userId}`);

export const sendTaskRequest = userId => http.post(`/tasks/${userId}`);

export const fetchTask = taskId => http.get(`/tasks/${taskId}`);

export const updateTaskRequest = (id, data) => http.put(`/tasks/${id}`, data);

export const deleteTaskRequest = id => http.delete(`/tasks/${id}`);

export const getTaskLog = id => http.get(`/tasks/${id}/logs`);

export const getTaskComment = taskId => http.get(`/tasks/${taskId}/comments`);

export const postTaskComment = (taskId, data) => http.post(`/tasks/${taskId}/comments`, data);

export const updateTaskComment = (taskId, commentId, data) =>
  http.put(`/tasks/${taskId}/comments/${commentId}`, data);

export const deleteTaskComment = (taskId, commentId) =>
  http.delete(`/tasks/${taskId}/comments/${commentId}`);

export const getTaskLabel = taskId => http.get(`/tasks/${taskId}/labels`);

export const postTaskLabel = (taskId, data) => http.post(`/tasks/${taskId}/labels`, data);

export const deleteTaskLabel = (taskId, labelId) =>
  http.delete(`/tasks/${taskId}/labels/${labelId}`);

export const reorderTasks = data => http.post('/tasks-order', data);
