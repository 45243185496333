import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ConfirmationServiceProvider } from './components/Confirmation/ConfirmationService';
import Router from './Router';

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <ConfirmationServiceProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <Router />
        </BrowserRouter>
        <ToastContainer />
        <ReactQueryDevtools initialIsOpen={false} />
        <iframe
          title="session_share"
          src="https://kimp360wp.codebuddy.codes"
          id="ifr"
          className="d-none"
        />
      </QueryClientProvider>
    </ConfirmationServiceProvider>
  );
}

export default App;
