import classNames from 'classnames';
import React from 'react';
import { Button, Card, Col, Collapse, Form, Image, Row, Spinner } from 'react-bootstrap';
import { usePostRoles, useRoles } from '../../hooks/roles.hooks';
import styles from '../../styles/modules/RoleAndPermission.module.scss';
import PermissionList from './PermissionList';
import DropDownArrow from '../../assets/images/icons8-expand-arrow-100.png';

const RoleAndPermission = () => {
  const [open, setOpen] = React.useState();
  const [addRole, setAddRole] = React.useState(false);
  const [title, setTitle] = React.useState();

  const { data: roles, isFetching, refetch } = useRoles();
  const { mutateAsync: postRoles, isLoading } = usePostRoles();

  const toggle = role => setOpen(role);

  const onSave = async () => {
    if (title && title !== '') {
      await postRoles({
        title,
      });
      refetch();
      setAddRole(false);
    }
  };

  return (
    <Card className="text-font">
      <Card.Header className="bg-white py-4">
        <Card.Title className="text-dark text-center font-weight-600">
          Roles and Permissions
        </Card.Title>
        <Card.Subtitle className="text-secondary text-center">
          Enable and disable permissions of a role
        </Card.Subtitle>
      </Card.Header>
      <Card.Body className="">
        <Row className="mb-3">
          {addRole ? (
            <Col sm={12} lg={{ span: 6, offset: 3 }} align="right">
              <Row className="mx-0">
                <Form.Control
                  placeholder="Role name"
                  style={{ width: '60%' }}
                  onChange={e => {
                    setTitle(e.target.value);
                  }}
                />
                <Button
                  className="text-size-xs text-font font-weight-500"
                  style={{ width: '19.5%', marginLeft: '0.5%' }}
                  disabled={!(title && title !== '')}
                  onClick={onSave}
                >
                  {isLoading ? <Spinner size="sm" variant="primary" animation="border" /> : 'Save'}
                </Button>
                <Button
                  className="text-size-xs text-font font-weight-500"
                  style={{ width: '19.5%', marginLeft: '0.5%' }}
                  onClick={async () => {
                    setAddRole(false);
                  }}
                >
                  Cancel
                </Button>
              </Row>
            </Col>
          ) : (
            <Col sm={12} lg={{ span: 6, offset: 3 }} align="right">
              <Button
                className="text-size-xs text-font font-weight-500"
                style={{ width: '19.5%', marginLeft: '0.5%' }}
                onClick={() => {
                  setAddRole(true);
                }}
              >
                Add Role
              </Button>
            </Col>
          )}
        </Row>
        {isFetching ? (
          <Row>
            <Col align="center">
              <Spinner size="lg" variant="primary" animation="border" />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col sm={12} lg={{ span: 6, offset: 3 }} align="center">
              {roles?.map(role => (
                <>
                  <button
                    type="button"
                    onClick={() => {
                      if (open === role) {
                        toggle('');
                      } else {
                        toggle(role);
                      }
                    }}
                    className={`d-flex flex-row align-items-center justify-content-between ${classNames(
                      styles.btn_collapse,
                      open === role && 'is-open',
                    )}`}
                  >
                    <div className="text-size-sm text-font">{role}</div>
                    <Image
                      src={DropDownArrow}
                      height={20}
                      style={{ transform: open === role ? `rotate(0deg)` : `rotate(180deg)` }}
                    />
                  </button>
                  <Collapse in={open === role}>
                    <div className={styles.permission_list}>
                      <PermissionList name={role} />
                    </div>
                  </Collapse>
                </>
              ))}
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};

export default RoleAndPermission;
