import { useMutation, useQuery } from 'react-query';
import {
  createTaskFile,
  createTasks,
  fetchArchivedTasks,
  fetchTasks,
  getTaskFile,
  getTasksDetails,
} from '../requests/task.requests';
import {
  deleteTaskComment,
  deleteTaskLabel,
  getTaskComment,
  getTaskLabel,
  getTaskLog,
  postTaskComment,
  postTaskLabel,
  updateTaskComment,
  updateTaskRequest,
} from '../requests/tasks.request';

export const useCreateTasks = () =>
  useMutation(async data => {
    const res = await createTasks(data);
    return res;
  });

export const useUpdateTasks = () =>
  useMutation(async ({ id, data }) => {
    const res = await updateTaskRequest(id, data);
    return res;
  });

export const useGetTasksDetails = id =>
  useQuery(
    ['tasks', id],
    async () => {
      const res = await getTasksDetails(id);
      return res.data;
    },
    {
      enabled: !!id,
      initialData: [],
    },
  );

export const useCreateTaskFile = () =>
  useMutation(async ({ id, data }) => {
    const res = await createTaskFile(id, data);
    return res;
  });

export const useGetTaskFile = taskId =>
  useQuery(
    ['task_file', taskId],
    async () => {
      const res = await getTaskFile(taskId);
      return res.data;
    },
    {
      enabled: !!taskId,
      initialData: [],
    },
  );

export const useFetchTasks = (projectId, search) =>
  useQuery(
    ['tasks', projectId, search],
    async () => {
      const res = await fetchTasks(projectId, search);
      return res.data;
    },
    {
      enabled: !!projectId,
      initialData: [],
    },
  );

export const useFetchArchivedTasks = projectId =>
  useQuery(
    ['tasks', projectId],
    async () => {
      const res = await fetchArchivedTasks(projectId);
      return res.data;
    },
    {
      enabled: !!projectId,
      initialData: [],
    },
  );

export const useGetTaskLog = projectId =>
  useQuery(
    ['task_log', projectId],
    async () => {
      const res = await getTaskLog(projectId);
      return res.data;
    },
    {
      enabled: !!projectId,
      initialData: [],
    },
  );

export const useGetTaskComment = taskId =>
  useQuery(
    ['task_comment', taskId],
    async () => {
      const res = await getTaskComment(taskId);
      return res.data;
    },
    {
      enabled: !!taskId,
      initialData: [],
    },
  );

export const usePostTaskComment = () =>
  useMutation(async ({ taskId, data }) => {
    const res = await postTaskComment(taskId, data);
    return res;
  });

export const useUpdateTaskComment = () =>
  useMutation(async ({ taskId, commentId, data }) => {
    const res = await updateTaskComment(taskId, commentId, data);
    return res;
  });

export const useDeleteTaskComment = () =>
  useMutation(async ({ taskId, commentId }) => {
    const res = await deleteTaskComment(taskId, commentId);
    return res;
  });

export const useGetTaskLabel = taskId =>
  useQuery(
    ['task_label', taskId],
    async () => {
      const res = await getTaskLabel(taskId);
      return res.data;
    },
    {
      enabled: !!taskId,
      initialData: [],
    },
  );

export const usePostTaskLabel = () =>
  useMutation(async ({ taskId, data }) => {
    const res = await postTaskLabel(taskId, data);
    return res;
  });

export const useDeleteTaskLabel = () =>
  useMutation(async ({ taskId, labelId }) => {
    const res = await deleteTaskLabel(taskId, labelId);
    return res;
  });
