import http from '../utils/http';

export const login = data => http.post('/login', data);

export const logout = () => http.post('/logout');

export const fetchUserProfile = () => http.get('/profile');

export const registerUser = data => http.post('/register', data);

export const forgotPassword = data => http.post('/password/email', data);

export const resetPassword = data => http.post('/password/reset', data);
