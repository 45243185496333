import { useQuery } from 'react-query';
import fetchHolidays from '../requests/holiday.requests';

const useHolidays = () =>
  useQuery(
    'holidays',
    async () => {
      const res = await fetchHolidays();
      return res.data;
    },
    {
      initialData: [],
    },
  );

export default useHolidays;
