import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const Confirmation = ({ open, title, description, onSubmit, onClose, size }) => (
  <Modal show={open} onHide={onClose} centered size={size || 'sm'}>
    <Modal.Header closeButton>
      <Modal.Title className="font-size-lg">{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body className="text-center">
      <p className="m-2">{description}</p>
    </Modal.Body>
    <Modal.Footer className="d-flex justify-content-center">
      <Button variant="danger" onClick={onClose}>
        Cancel
      </Button>
      <Button variant="success" onClick={onSubmit}>
        Confirm
      </Button>
    </Modal.Footer>
  </Modal>
);

export default Confirmation;
