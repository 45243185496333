import React from 'react';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import Select from 'react-select';

const InvoiceAddress = () => {
  return (
    <Card>
      <Card.Header>
        <Card.Title className="font-weight-600">Invoice Address</Card.Title>
        <Card.Subtitle>
          Choose to change plans and/or add additional subscriptions as per your requirements.
        </Card.Subtitle>
      </Card.Header>
      <Card.Body>
        <div className="px-0 px-lg-5">
          <Form>
            <Row>
              <Col sm={12} md={6}>
                <Form.Group controlId="firstName">
                  <Form.Control type="text" name="firstName" placeholder="First Name *" />
                </Form.Group>
              </Col>
              <Col sm={12} md={6}>
                <Form.Group controlId="lastName">
                  <Form.Control type="text" name="lastName" placeholder="Last Name *" />
                </Form.Group>
              </Col>
              <Col sm={12} md={6}>
                <Form.Group controlId="company">
                  <Form.Control type="text" name="company" placeholder="Company" />
                </Form.Group>
              </Col>
              <Col sm={12} md={6}>
                <Form.Group controlId="streetAddress">
                  <Form.Control type="text" name="streetAddress" placeholder="Street address *" />
                </Form.Group>
              </Col>
              <Col sm={12} md={6}>
                <Form.Group controlId="city">
                  <Form.Control type="text" name="city" placeholder="Town / City *" />
                </Form.Group>
              </Col>
              <Col sm={12} md={6}>
                <Form.Group controlId="city">
                  <Form.Control type="text" name="city" placeholder="Town / City *" />
                </Form.Group>
              </Col>
              <Col sm={12} md={6}>
                <Form.Group controlId="state">
                  <Form.Control type="text" name="state" placeholder="Province / State *" />
                </Form.Group>
              </Col>
              <Col sm={12} md={6}>
                <Form.Group controlId="state">
                  <Select options={[]} placeholder="Country *" />
                </Form.Group>
              </Col>
            </Row>

            <Form.Check
              type="checkbox"
              custom
              id="customSelect"
              label="This will be how your name will be displayed in the account section."
            />
            <div className="d-flex flex-row justify-content-center justify-content-md-start">
              <Button type="submit" className="px-5 mt-5 ">
                Save Address
              </Button>
            </div>
          </Form>
        </div>
      </Card.Body>
    </Card>
  );
};

export default InvoiceAddress;
