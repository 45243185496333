import React, { useEffect } from 'react';
import Popover from 'react-bootstrap/Popover';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import classnames from 'classnames';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { Overlay, Spinner } from 'react-bootstrap';
import styles from '../../styles/modules/RequestBoard.module.scss';
import CloseIcon from '../../assets/icons/CloseIcon';
import { useUpdatePrivacy } from '../../hooks/requestHistory.hooks';
import BoardContext from '../../contexts/BoardContext';

const ChangeVisibility = props => {
  const { register, handleSubmit, setValue } = useForm();
  const [showPopOverBox, setShowPopOverBox] = React.useState(false);
  const target = React.useRef(null);

  const boardContext = React.useContext(BoardContext);

  const { mutateAsync: updatePrivacy, isLoading } = useUpdatePrivacy();

  const { id: boardId } = useParams();
  const queryClient = useQueryClient();

  const onHandlePopover = () => {
    setShowPopOverBox(false);
  };

  const handleStatus = async data => {
    if (data.visibility === 'private') {
      try {
        await updatePrivacy({ boardId, data: { 'is_private': 1 } });
        boardContext.updateBoard({
          ...boardContext.board,
          is_private: 1,
        });
        props.refetch();
        queryClient.invalidateQueries(['boardTasks', boardId]);
        toast.success('Privacy updated successfully.');
        onHandlePopover();
      } catch (error) {
        toast.error(error?.message);
      }
    } else if (data.visibility === 'team') {
      try {
        await updatePrivacy({ boardId, data: { 'is_private': 0 } });
        boardContext.updateBoard({
          ...boardContext.board,
          is_private: 0,
        });
        props.refetch();
        queryClient.invalidateQueries(['boardTasks', boardId]);
        toast.success('Privacy updated successfully.');
        onHandlePopover();
      } catch (error) {
        toast.error(error?.message);
      }
    }
  };

  useEffect(
    function privacyUpdate() {
      setValue('visibility', boardContext.board?.is_private === 0 ? 'team' : 'private');
    },
    [boardContext],
  );

  return (
    <>
      <Button
        type="button"
        variant="light"
        className={`text-size-xs text-secondary font-weight-600 mx-2 mb-2 mb-lg-0 px-3 py-0 py-1 ${styles.header_btn}`}
        ref={target}
        onClick={() => setShowPopOverBox(true)}
      >
        Team Visible
      </Button>
      <Overlay target={target.current} show={showPopOverBox} placement="bottom">
        <Popover {...props} className="text-font px-2 py-2 custom-popover">
          <div className="position-relative ">
            <Button
              onClick={() => onHandlePopover()}
              variant="light"
              className={`text-size-xs font-weight-600 position-absolute ${styles.close_icon}`}
            >
              <CloseIcon className={`${classnames(styles.close_icon_size)}`} />
            </Button>
          </div>
          <Popover.Title className="text-size-xs text-secondary bg-white text-center no-border">
            Change Visibility
          </Popover.Title>
          <Form>
            <Popover.Content>
              <div className="text-size-xs d-flex flex-row align-items-center font-weight-500">
                <Form.Check
                  custom
                  name="visibility"
                  type="radio"
                  value="private"
                  id="private"
                  label="Private"
                  className=" visibility_label"
                  {...register('visibility')}
                />
              </div>
              <p className="text-size-xxs text-secondary mb-0 pt-1">
                Board members and Kimp team admins can and edit this board
              </p>
            </Popover.Content>
            <Popover.Content>
              <div className="text-size-xs d-flex flex-row align-items-center font-weight-500">
                <Form.Check
                  name="visibility"
                  custom
                  type="radio"
                  value="team"
                  label="Team"
                  className=" visibility_label"
                  id="team"
                  {...register('visibility')}
                />
              </div>
              <p className="text-size-xxs text-secondary mb-0 pt-1">
                All members and Kimp team admins can and edit this board
              </p>
            </Popover.Content>
            <Popover.Content>
              <Button
                type="submit"
                onClick={handleSubmit(handleStatus)}
                className="text-size-xxs font-weight-600 px-3"
                disabled={isLoading}
              >
                {isLoading && <Spinner size="sm" variant="light" animation="border" />}
                {!isLoading && 'Save'}
              </Button>
            </Popover.Content>
          </Form>
        </Popover>
      </Overlay>
    </>
  );
};

export default ChangeVisibility;
