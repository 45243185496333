import http from '../utils/http';

export const fetchBoards = userId =>
  http.get(`/projects${userId !== '' ? `?user_id=${userId}` : ''}`);

export const fetchBoard = boardId => http.get(`/projects/${boardId}`);

export const createBoard = (userId, data) => http.post(`/projects`, { ...data, user_id: userId });

export const fetchBoardCollections = boardId => http.get(`/projects/${boardId}/collections`);

export const fetchBoardTasks = boardId => http.get(`/projects/${boardId}/tasks`);

export const updateBoardRequest = (boardId, data) => http.put(`/projects/${boardId}`, data);
