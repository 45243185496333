import { useQuery } from 'react-query';
import fetchLabels from '../requests/label.request';

const useFetchLabels = () =>
  useQuery(
    ['labels'],
    async () => {
      const res = await fetchLabels();
      return res.data;
    },
    {
      initialData: [],
    },
  );

export default useFetchLabels;
