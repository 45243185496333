import React from 'react';
import { Row, Col, ProgressBar, Spinner, Card, Overlay, Popover } from 'react-bootstrap';
import classnames from 'classnames';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import styles from '../../styles/modules/ServiceBoards.module.scss';
import { useBoards } from '../../hooks/boards.hooks';
// import { useCollections } from '../../hooks/collection.hooks';
import BoardsCard from '../Support/BoardsCard';
import { useGetUserDetailsById } from '../../hooks/user.hooks';
import RoleBased from '../../components/RoleBased';
import plusIconGray from '../../assets/icons/plus-gray.svg';
import { ROLES, SECTION_KEY } from '../../utils/enums';
import closeIcon from '../../assets/icons/close-icon.svg';
import customStyles from '../../styles/modules/Boards.module.scss';
import tickIcon from '../../assets/icons/tick-icon.svg';
import { useAddMembers, useDeleteMembers } from '../../hooks/teams.hooks';
import { useConfirmation } from '../../components/Confirmation/ConfirmationService';

function MemberBoard() {
  const history = useHistory();
  const { user_id } = useParams();
  const check = useConfirmation();

  const target = React.useRef();
  const [showPopover, setShowPopover] = React.useState(false);

  const { data: boards, isFetching, refetch: refetchBoards } = useBoards(user_id);
  const { data: allBoards, isFetching: isAllBoardFetching } = useBoards('');
  const { data: userData } = useGetUserDetailsById(user_id);

  const { mutateAsync: addMember, isLoading: isAddLoading } = useAddMembers();
  const { mutateAsync: deleteMember, isLoading: isDeleteLoading } = useDeleteMembers();

  // const { data: collections, isLoading: isFetchingCollections } = useCollections();

  // const [search, setSearch] = React.useState('');
  // const handleSearchChange = e => setSearch(e.target.value);

  // const [sortKey, setSortKey] = React.useState('unsorted');
  // const [filterCollection, setFilterCollection] = React.useState(null);
  // const toggleSortKey = key => (key === sortKey ? setSortKey(null) : setSortKey(key));
  // const toggleFilterCollection = id =>
  //   id === filterCollection ? setFilterCollection(null) : setFilterCollection(id);

  // const filterFn = ({ title }) => {
  //   if (search === '') {
  //     return true;
  //   }

  //   return String(title).toLowerCase().includes(search.toLowerCase());
  // };

  // const filterByCollection = ({ collections: clns }) => {
  //   if (filterCollection) {
  //     return clns.map(({ collection_id }) => collection_id).includes(filterCollection);
  //   }

  //   return true;
  // };

  // const sortFn = (a, b) => {
  //   if (sortKey === 'alphabetically') {
  //     return String(a.title).localeCompare(String(b.title));
  //   }

  //   return 0;
  // };

  const toggleBoard = async board => {
    try {
      if (!board.team) {
        toast.error(`No team found for this board.`);
        return;
      }

      if (boards.map(({ id }) => id).includes(board.id)) {
        setShowPopover(false);
        await check({
          title: 'Confirm',
          description: `Are you sure you want to remove ${userData.name} from ${board.title}?`,
        });
        setShowPopover(true);
        await deleteMember({
          teamId: board.team?.id,
          userId: userData.id,
        });
        toast.success(`${userData.name} removed from ${board.title} successfully!`);
      } else {
        setShowPopover(false);
        await check({
          title: 'Confirm',
          description: `Are you sure you want to add ${userData.name} to ${board.title}?`,
        });
        setShowPopover(true);
        await addMember({
          teamid: board.team?.id,
          data: {
            user_id: userData.id,
          },
        });
        toast.success(`${userData.name} added to ${board.title} successfully!`);
      }

      refetchBoards();
    } catch (err) {
      toast.error(err?.message);
    }
  };

  return (
    <Card>
      <Card.Header className="bg-white py-4">
        <Card.Title className="text-dark text-center font-weight-600">
          {userData?.name}`s Boards
        </Card.Title>
      </Card.Header>
      <Card.Body className="">
        <Row className="mt-3">
          {/* <Col sm={12} md={4} lg={3} className="border-right">
            <Form>
              <Form.Group controlId="searchForBoards">
                <Col xs="auto" className="mx-0 px-0">
                  <Form.Control
                    size="sm"
                    type="email"
                    placeholder="Search for boards"
                    value={search}
                    onChange={handleSearchChange}
                  />
                </Col>
              </Form.Group>
            </Form>
            <div className={styles.boards_sidebar}>
              <h5 className={`${styles.section_heading} text-size-xs`}>SORT</h5>
              <button
                type="button"
                className={classnames('d-block bg-transparent border-0 text-size-xxs')}
                onClick={() => toggleSortKey('')}
              >
                Most Recently Active
              </button>
              <button
                type="button"
                className={classnames('d-block bg-transparent border-0 text-size-xxs')}
                onClick={() => toggleSortKey('')}
              >
                Number of Members
              </button>
              <button
                type="button"
                className={classnames('d-block bg-transparent border-0 text-size-xxs')}
                onClick={() => toggleSortKey('')}
              >
                Number of Stars
              </button>
              <button
                type="button"
                className={classnames(
                  'd-block bg-transparent border-0 text-size-xxs',
                  sortKey === 'alphabetically' && 'font-weight-bold',
                )}
                onClick={() => toggleSortKey('alphabetically')}
              >
                Alphabetical
              </button>

              <h5 className={`${styles.section_heading} text-size-xxs mt-3`}>
                <span className="mr-2">FILTER BY COLLECTION</span>
                {isFetchingCollections && (
                  <Spinner animation="border" variant="primary" size="sm" />
                )}
              </h5>
              {collections.map(({ id, title }) => (
                <button
                  key={id}
                  type="button"
                  className={classnames(
                    'd-block bg-transparent border-0 text-size-xxs',
                    filterCollection === id && 'font-weight-bold',
                  )}
                  onClick={() => toggleFilterCollection(id)}
                >
                  {title}
                </button>
              ))}
            </div>
          </Col> */}

          <Col sm={12} md={12} lg={12}>
            {isFetching && <ProgressBar animated variant="primary" now={100} className="mb-3" />}
            <Row>
              <RoleBased
                sectionKey={[SECTION_KEY.settings_edit]}
                acceptedRoles={[ROLES.SUPER_ADMIN, ROLES.ADMIN]}
              >
                <Col sm={12} md={12} lg={4} xl={3} className="mb-3 ">
                  <button
                    type="button"
                    ref={target}
                    onClick={() => setShowPopover(!showPopover)}
                    className={classnames(
                      'card card-body d-block w-100',
                      styles.border_radius_primary,
                      styles.card_shadow,
                    )}
                  >
                    <h6 className="mb-0">
                      {'Add to Board '}
                      <img
                        src={plusIconGray}
                        className={styles.sidesection_icon_img_gray}
                        alt="plus sign"
                      />
                    </h6>
                  </button>
                  <Overlay target={target.current} show={showPopover} placement="auto" flip>
                    {props => (
                      <Popover
                        {...props}
                        id="popover-names"
                        className={classnames(
                          'text-font custom-board-popover',
                          customStyles.popover_collection_card,
                        )}
                      >
                        <Popover.Content className="py-4">
                          <div className="text-right">
                            <button
                              type="button"
                              onClick={() => setShowPopover(!showPopover)}
                              className={styles.button_cross}
                            >
                              <img src={closeIcon} alt="close" className={styles.cross_img} />
                            </button>
                          </div>

                          <h6 className="text-center text-secondary">
                            <span className="mr-2">Add {userData?.name} to Board</span>
                            {(isAllBoardFetching || isAddLoading || isDeleteLoading) && (
                              <Spinner animation="border" variant="primary" size="sm" />
                            )}
                          </h6>
                          <ul className={classnames('pl-0', styles.scrollable_collection_tag_box)}>
                            {!(isAddLoading && isDeleteLoading) &&
                              allBoards.map(board => (
                                <li
                                  key={board.id}
                                  className={classnames(
                                    'd-flex align-items-center p-2',
                                    styles.collection_edit_list_item,
                                  )}
                                >
                                  <button
                                    type="button"
                                    className="bg-transparent border-0 p-0 d-flex align-items-center flex-grow-1"
                                    onClick={() => toggleBoard(board)}
                                  >
                                    <div className={styles.collection_checked}>
                                      {boards.map(({ id }) => id).includes(board.id) && (
                                        <img src={tickIcon} alt="tick" className="" width="8" />
                                      )}
                                    </div>
                                    <div htmlFor="name" className="mb-0">
                                      {board.title}
                                    </div>
                                  </button>
                                </li>
                              ))}
                          </ul>
                        </Popover.Content>
                      </Popover>
                    )}
                  </Overlay>
                </Col>
              </RoleBased>
              {boards
                // ?.filter(filterFn)
                // ?.filter(filterByCollection)
                // ?.sort(sortFn)
                ?.map(({ id, title }) => (
                  <Col key={id} sm={12} md={12} lg={4} xl={3} className="mb-3">
                    <BoardsCard
                      noAddCollection
                      noEditBoard
                      onClick={() => history.push(`/dashboard/request-history/${id}`)}
                      boardId={id}
                      heading={title}
                      user={[]}
                    />
                  </Col>
                ))}
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default MemberBoard;
