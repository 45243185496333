import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Switch, Route, useRouteMatch, useLocation, Redirect } from 'react-router-dom';
import Pusher from 'pusher-js';
import { toast } from 'react-toastify';
import Sidebar from '../components/Sidebar';
import TopNavBar from '../components/TopNavBar';
import Dashboard from './Dashboard/Dashboard';
import EditProfile from './Dashboard/EditProfile';
import TeamAccess from './Dashboard/TeamAccess';
import TeamHolidays from './Dashboard/TeamHolidays';
import MyAssets from './MyAssets/MyAssets';
import Footer from '../components/Footer';
import MySubscriptions from './Subscriptions/MySubscriptions';
import PaymentHistory from './PaymentHistory/PaymentHistory';
import ChangePaymentMethod from './PaymentMethod/ChangePaymentMethod';
import InvoiceAddress from './Invoice/InvoiceAddress';
import PaymentMethod from './PaymentMethod/PaymentMethod';
import RequestName from './NewRequest/RequestName';
import NewRequestTabs from './NewRequest/NewRequestTabs';
import FeedBack from './RequestHistory/FeedBack';
import SupportTabs from './Support/SupportTabs';
import RequestHistory from './RequestHistory/RequestHistory';
import { useGetProfile } from '../hooks/user.hooks';
import UserContext from '../contexts/UserContext';
import FavoriteBoards from './Dashboard/FavoriteBoards';
import { ROLES, SECTION_KEY } from '../utils/enums';
import RoleBaseRouter from '../components/RoleBaseRouter';
import NotificationFeeds from './NotificationFeeds/Feeds';
import MemberBoard from './Dashboard/MemberBoard';
import NotificationContext from '../contexts/NotificationContext';
import CommentContext from '../contexts/CommentContext';
import RoleAndPermission from './RolesAndPermission';
import { getProjectPermission } from '../requests/project.request';

function DashboardRouter() {
  const { user, updateUser, getRole, updateBoardRole } = React.useContext(UserContext);
  const { path } = useRouteMatch();
  const [open, setOpen] = React.useState(false);
  const toggle = () => setOpen(!open);
  const [pusherConnected, setPusherConnected] = useState(false);
  const { feedCount, updatedFeeds } = React.useContext(NotificationContext);
  const { comments, updatedComments } = React.useContext(CommentContext);
  const { data: profile, isFetching } = useGetProfile();
  const [updateCount, setupdateCount] = useState(false);
  const [updateComment, setUpdateComment] = useState();

  const location = useLocation();

  useEffect(async () => {
    // scrolls to top
    window.scrollTo(0, 0);
    const boardPermission = await getProjectPermission(
      Number.parseInt(localStorage.getItem('kimp-user-default-board'), 10),
    );
    updateBoardRole(boardPermission.data);
  }, [location]);

  useEffect(() => {
    if (!pusherConnected) {
      const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
        cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      });
      pusher.connection.bind('error', function (err) {
        toast.error(err?.message);
      });
      pusher.connection.bind('connected', function () {
        setPusherConnected(true);
        if (!('Notification' in window)) {
          // console.log('This browser does not support desktop notification');
        } else {
          Notification.requestPermission();
        }
      });
      const channel = pusher.subscribe(process.env.REACT_APP_PUSHER_CHANNEL);
      channel.bind('task-event', function (data) {
        if (data.comment) {
          setUpdateComment(data);
        }

        if (data.text) {
          const options = {
            body: data.text,
          };
          // eslint-disable-next-line no-unused-vars
          const notification = new Notification('Kimp360', options);
          setupdateCount(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!isFetching && user === null) {
      updateUser(profile);
    }
  }, [isFetching]);

  useEffect(() => {
    if (updateCount) {
      updatedFeeds({ unread_count: feedCount?.unread_count ? feedCount?.unread_count + 1 : 1 });
      setupdateCount(false);
    }
  }, [updateCount]);

  useEffect(() => {
    if (updateComment) {
      if (comments?.current_task === updateComment.data.task_id) {
        updatedComments({ ...comments, data: [...comments.data, updateComment.data] });
      }

      setUpdateComment(undefined);
    }
  }, [updateComment]);

  return (
    <>
      <TopNavBar toggleSideNav={toggle} />
      <main className="dashboard">
        <Sidebar isOpen={open} toggleSidebar={toggle} />
        <Container
          fluid
          style={{
            height: '100%',
            overflow: 'hidden',
          }}
        >
          <Switch>
            <Route exact path={`${path}`}>
              <Dashboard />
            </Route>
            <Route path={`${path}/request-history`}>
              <RequestHistory />
            </Route>
            <Route path={`${path}/feedback`}>
              <FeedBack />
            </Route>

            <RoleBaseRouter
              sectionKey={[
                SECTION_KEY.board_assets_add,
                SECTION_KEY.board_assets_edit,
                SECTION_KEY.board_assets_delete,
                SECTION_KEY.board_assets_full_view,
              ]}
              acceptedRoles={[ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.SUBSCRIBERS, ROLES.VIEWERS]}
              path={`${path}/assets`}
            >
              <MyAssets />
            </RoleBaseRouter>
            <Route path={`${path}/edit-profile`}>
              <EditProfile />
            </Route>
            <Route path={`${path}/team-access`}>
              <TeamAccess />
            </Route>
            <Route path={`${path}/team-holidays`}>
              <TeamHolidays />
            </Route>
            <Route path={`${path}/history`}>
              <PaymentHistory />
            </Route>
            <Route path={`${path}/change-payment-method`}>
              <ChangePaymentMethod />
            </Route>
            <Route path={`${path}/payment-method`}>
              <PaymentMethod />
            </Route>
            <Route path={`${path}/invoice-address`}>
              <InvoiceAddress />
            </Route>
            <RoleBaseRouter
              sectionKey={[
                SECTION_KEY.subscription_view_page_add,
                SECTION_KEY.subscription_view_page_edit,
                SECTION_KEY.subscription_view_page_delete,
                SECTION_KEY.subscription_view_page_full_view,
              ]}
              acceptedRoles={[
                ROLES.SUPER_ADMIN,
                ROLES.ADMIN,
                ROLES.MANAGERS,
                ROLES.EDITORS,
                ROLES.CONTRIBUTORS,
              ]}
              path={`${path}/favorite-board`}
            >
              <FavoriteBoards />
            </RoleBaseRouter>
            <Route path={`${path}/feeds`}>
              <NotificationFeeds />
            </Route>
            <RoleBaseRouter
              sectionKey={[
                SECTION_KEY.subscription_view_page_add,
                SECTION_KEY.subscription_view_page_edit,
                SECTION_KEY.subscription_view_page_delete,
                SECTION_KEY.subscription_view_page_full_view,
              ]}
              acceptedRoles={[ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.MANAGERS, ROLES.SUBSCRIBERS]}
              path={`${path}/subscriptions`}
            >
              <MySubscriptions />
            </RoleBaseRouter>
            <RoleBaseRouter
              sectionKey={[SECTION_KEY.task_request_add]}
              acceptedRoles={[
                ROLES.SUPER_ADMIN,
                ROLES.ADMIN,
                ROLES.MANAGERS,
                ROLES.EDITORS,
                ROLES.SUBSCRIBERS,
                ROLES.VIEWERS,
              ]}
              path={`${path}/new-request-name`}
            >
              <RequestName />
            </RoleBaseRouter>
            <RoleBaseRouter
              sectionKey={[SECTION_KEY.task_request_add, SECTION_KEY.task_request_edit]}
              acceptedRoles={[
                ROLES.SUPER_ADMIN,
                ROLES.ADMIN,
                ROLES.MANAGERS,
                ROLES.EDITORS,
                ROLES.SUBSCRIBERS,
                ROLES.VIEWERS,
              ]}
              path={`${path}/request-dimension`}
            >
              <NewRequestTabs />
            </RoleBaseRouter>
            <RoleBaseRouter
              sectionKey={[SECTION_KEY.settings_full_view]}
              acceptedRoles={[ROLES.SUPER_ADMIN, ROLES.ADMIN]}
              path={`${path}/support`}
            >
              <SupportTabs />
            </RoleBaseRouter>
            <RoleBaseRouter
              sectionKey={[SECTION_KEY.board_full_view]}
              acceptedRoles={[ROLES.SUPER_ADMIN, ROLES.ADMIN]}
              path={`${path}/member/:user_id/board`}
            >
              <MemberBoard />
            </RoleBaseRouter>
            {getRole()?.role === ROLES.SUPER_ADMIN ? (
              <Route path={`${path}/roles`}>
                <RoleAndPermission />
              </Route>
            ) : null}
            <Route render={() => <Redirect to={{ pathname: '/' }} />}>
              <Dashboard />
            </Route>
          </Switch>
          <Footer />
        </Container>
      </main>
    </>
  );
}

export default DashboardRouter;
