import React, { useContext } from 'react';
import classnames from 'classnames';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import { v4 as uuid } from 'uuid';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router';
import styles from '../../styles/modules/Support.module.scss';
import UnsortedMemberListButton from '../../components/UnsortedMemberListButton';
import addIcon from '../../assets/icons/add-icon.svg';
import RemoveIcon from '../../assets/icons/RemoveIcon';
import ButtonWithPopover from '../../components/ButtonWithPopover';
import RoleBased from '../../components/RoleBased';
import { ROLES, SECTION_KEY } from '../../utils/enums';
import { useDeleteUser, useGetUsers } from '../../hooks/user.hooks';
import InviteButton from '../../components/InviteButton';
import UserContext from '../../contexts/UserContext';
import PaginationView from '../../components/PaginationView';

function Members() {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const userContext = useContext(UserContext);
  const queryClient = useQueryClient();
  const [key, setKey] = React.useState('team-members');

  const [sortBy, setSortBy] = React.useState();
  const [sortOrder, setSortOrder] = React.useState();

  const [currentIndex, setCurrentIndex] = React.useState(-1);
  const onHandleCurrentIndex = (index = -1) => {
    setCurrentIndex(index);
  };

  const {
    data: teams,
    isFetching: isTeamFetching,
    refetch: teamRefetch,
  } = useGetUsers(
    `?role[]=${ROLES.SUPER_ADMIN}&role[]=${ROLES.ADMIN}&role[]=${ROLES.CONTRIBUTORS}&role[]=${
      ROLES.EDITORS
    }&role[]=${ROLES.MANAGERS}${
      new URLSearchParams(search).get('type') === 'team-members' &&
      new URLSearchParams(search).get('page') !== null
        ? `&page=${new URLSearchParams(search).get('page')}`
        : ''
    }`,
  );

  const { data: guests, isFetching: isGuestsFetching } = useGetUsers(
    `?role[]=${ROLES.VIEWERS}${
      new URLSearchParams(search).get('type') === 'guests' &&
      new URLSearchParams(search).get('page') !== null
        ? `&page=${new URLSearchParams(search).get('page')}`
        : ''
    }`,
  );
  const { mutateAsync: deleteUser } = useDeleteUser();

  const removeUser = async id => {
    try {
      const res = await deleteUser(id);
      if (res.status) {
        toast.success('User deleted successfully.');
        setCurrentIndex(-1);
        queryClient.invalidateQueries('teams');
      }
    } catch (err) {
      toast.success(err);
    }
  };

  function sortMember(a, b) {
    switch (sortBy) {
      case 'name':
        return sortOrder === 'ASC'
          ? a.first_name < b.first_name
            ? -1
            : 1
          : b.first_name < a.first_name
          ? -1
          : 1;
      case 'created':
        return sortOrder === 'ASC'
          ? new Date(a.created_at) - new Date(b.created_at)
          : new Date(b.created_at) - new Date(a.created_at);
      case 'updated':
        return sortOrder === 'ASC'
          ? new Date(a.updated_at) - new Date(b.updated_at)
          : new Date(b.updated_at) - new Date(a.updated_at);
      case '':
      default:
        return 1;
    }
  }

  React.useEffect(
    function loadViewFromPathParam() {
      if (search) {
        const searchParam = new URLSearchParams(search);
        if (searchParam.get('type') !== null) {
          setKey(new URLSearchParams(search).get('type'));
        }
      }
    },
    [search],
  );

  return (
    <>
      <Card className="no-border">
        <Card.Header className="text-size-sm font-weight-600 no-border mb-3 ">
          Members of Team Boards
        </Card.Header>
        <Row className="mx-0 justify-content-center align-items-center justify-content-xl-between px-xl-4">
          <div
            className={classnames(
              `d-flex flex-wrap justify-content-center align-items-center`,
              styles.switch_lists,
            )}
          >
            <button
              type="button"
              onClick={() => {
                // setKey('team-members');
                const searchParam = new URLSearchParams(search);
                searchParam.set('type', 'team-members');
                searchParam.set('page', '1');
                history.push(`${pathname}?${searchParam.toString()}`);
              }}
              className={`py-1 text-size-xs mx-2 mb-2 ${classnames(
                key === 'team-members' && styles.active,
              )}`}
            >
              Team Members ({teams.total})
            </button>
            <button
              type="button"
              onClick={() => {
                // setKey('guests');
                const searchParam = new URLSearchParams(search);
                searchParam.set('type', 'guests');
                searchParam.set('page', '1');
                history.push(`${pathname}?${searchParam.toString()}`);
              }}
              className={`py-1 text-size-xs mx-2 mb-2 ${classnames(
                key === 'guests' && styles.active,
              )}`}
            >
              Guests ({guests.total})
            </button>
          </div>
          <div className={classnames(`d-flex flex-wrap justify-content-center align-items-center`)}>
            <UnsortedMemberListButton
              setSortBy={setSortBy}
              setSortOrder={setSortOrder}
              sortOrder={sortOrder}
            />
            <RoleBased
              sectionKey={[
                SECTION_KEY.invite_clients_to_360_and_view_clients_add,
                SECTION_KEY.invite_kimp_members_to_360_and_view_members_add,
              ]}
              acceptedRoles={[ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.MANAGERS]}
            >
              <InviteButton
                className={`text-size-xs mx-2 mb-2 py-1 d-flex justify-content-center align-items-center font-weight-600 ${classnames(
                  key === 'guests' && 'd-none',
                  styles.invite_btn,
                )}`}
                from="member"
                buttonLabel={
                  <>
                    Invite Team Members
                    <img src={addIcon} alt="add" className={styles.add_icon} />
                  </>
                }
              />
            </RoleBased>
          </div>
        </Row>

        <div className={classnames(styles.member_table)}>
          <Tab.Container activeKey={key}>
            <Tab.Content>
              <Tab.Pane eventKey="team-members">
                {isTeamFetching && (
                  <Row className="mt-5">
                    <Col align="center">
                      <Spinner variant="primary" animation="border" />
                    </Col>
                  </Row>
                )}
                {!isTeamFetching && teams.data.length === 0 && (
                  <Row className="mt-5">
                    <Col align="center">
                      <span className="text-size-xs font-weight-600 mb-0">
                        No team member found.
                      </span>
                    </Col>
                  </Row>
                )}
                {!isTeamFetching &&
                  teams.data.sort(sortMember).map((item, index) => {
                    return (
                      <Card.Body
                        key={uuid()}
                        className={`d-flex flex-column flex-md-row mt-3 px-0 mx-lg-5 ${styles.border_btm}`}
                      >
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={3}
                          xl={6}
                          className="px-0 mb-2 mb-lg-0 d-flex justify-content-center justify-content-md-start align-items-center"
                        >
                          <div className="d-flex">
                            <img
                              src={`https://ui-avatars.com/api/?name=${item?.first_name}+${item?.last_name}&background=219186&color=fff`}
                              alt="user"
                              className={`rounded-circle ${styles.member_icon_size}`}
                            />
                            <div className="pl-3">
                              <h4 className="text-size-xs font-weight-600 mb-0">{item?.name}</h4>
                              <h6 className="text-size-xxs mb-0">{item?.email}</h6>
                            </div>
                          </div>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={9} xl={6} className="px-0 ">
                          <Row className="mx-0">
                            <Col
                              className="px-0 mb-2 d-flex flex-column align-items-center align-items-lg-end pr-lg-2 pr-xl-5 justify-content-center cursor-pointer"
                              xs={12}
                              lg={4}
                              xl={4}
                              onClick={() => {
                                history.push(`/dashboard/member/${item?.id}/board`);
                              }}
                            >
                              <h4
                                className={`text-size-xs underline-text mb-0 font-weight-600  ${classnames(
                                  ` text-primary`,
                                )}`}
                              >
                                On {item?.no_of_board} boards
                              </h4>
                            </Col>
                            <Col
                              className="px-0 mb-2 d-flex flex-column align-items-center justify-content-center"
                              xs={12}
                              lg={4}
                              xl={4}
                            >
                              <ButtonWithPopover
                                buttonDisable={
                                  userContext.user?.id === item?.id ||
                                  [ROLES.SUPER_ADMIN].includes(item.roles[0]?.name)
                                }
                                buttonVariant="white"
                                buttonLabel={item?.roles?.map(i => i.name)}
                                buttonCssClasses={`py-0 py-1 font-weight-600 ${classnames(
                                  !(
                                    userContext.user?.id === item?.id ||
                                    [ROLES.SUPER_ADMIN].includes(item.roles[0]?.name)
                                  )
                                    ? `${styles.border_all} ${styles.role_btn_size} text-primary`
                                    : `${styles.border_all} ${styles.role_btn_size} text-secondary`,
                                  `${styles.btn_structure}`,
                                )}`}
                                userId={item?.id}
                                onUpdateComplete={() => {
                                  teamRefetch();
                                }}
                              />
                            </Col>
                            <Col
                              className="px-0 mb-2 d-flex flex-column align-items-center justify-content-center"
                              xs={12}
                              lg={4}
                              xl={4}
                            >
                              <Button
                                type="button"
                                variant="white"
                                className={`py-0 py-1 d-flex flex-row align-items-center justify-content-center font-weight-600 ${classnames(
                                  !(
                                    userContext.user?.id === item?.id ||
                                    [ROLES.SUPER_ADMIN].includes(item.roles[0]?.name)
                                  )
                                    ? `${styles.border_all}  text-primary`
                                    : `${styles.border_all}  text-secondary`,
                                  `${styles.btn_structure}`,
                                )}`}
                                onClick={() => {
                                  onHandleCurrentIndex(index);
                                  removeUser(item?.id);
                                }}
                                disabled={
                                  userContext.user?.id === item?.id ||
                                  [ROLES.SUPER_ADMIN].includes(item.roles[0]?.name)
                                }
                              >
                                {currentIndex === index && (
                                  <Spinner
                                    variant="primary"
                                    animation="border"
                                    size="sm"
                                    className="my-1"
                                  />
                                )}
                                {!(currentIndex === index) && (
                                  <>
                                    Remove
                                    <RemoveIcon
                                      color={
                                        !(
                                          userContext.user?.id === item?.id ||
                                          [ROLES.SUPER_ADMIN].includes(item.roles[0]?.name)
                                        )
                                          ? '#f44336'
                                          : '#969696'
                                      }
                                      className={styles.add_icon}
                                    />
                                  </>
                                )}
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Card.Body>
                    );
                  })}
                {!isTeamFetching && teams.data.length > 0 && (
                  <Row>
                    <Col align="right" className="mx-lg-5">
                      <PaginationView
                        pageCount={teams.last_page}
                        currentPage={Number.parseInt(new URLSearchParams(search).get('page'), 10)}
                      />
                    </Col>
                  </Row>
                )}
              </Tab.Pane>

              <Tab.Pane eventKey="guests">
                {isGuestsFetching && (
                  <Row className="mt-5">
                    <Col align="center">
                      <Spinner variant="primary" animation="border" />
                    </Col>
                  </Row>
                )}
                {!isGuestsFetching && guests.data.length === 0 && (
                  <Row className="mt-5">
                    <Col align="center">
                      <span className="text-size-xs font-weight-600 mb-0">
                        No guest member found.
                      </span>
                    </Col>
                  </Row>
                )}
                {!isGuestsFetching &&
                  guests.data.sort(sortMember).map(item => {
                    return (
                      <Card.Body
                        key={uuid()}
                        className={`d-flex flex-row px-0 mx-md-5 mt-4 justify-content-between ${styles.border_btm}`}
                      >
                        <Col xl={4} className="px-0">
                          <div className="d-flex">
                            <img
                              src={`https://ui-avatars.com/api/?name=${item?.first_name}+${item?.last_name}&background=219186&color=fff`}
                              alt="user"
                              className={`rounded-circle ${styles.member_icon_size}`}
                            />
                            <div className="pl-3">
                              <h4 className="text-size-xs font-weight-600 mb-0">{item.name}</h4>
                              <h6 className="text-size-xxs mb-0">{item.email}</h6>
                            </div>
                          </div>
                        </Col>
                        <Col
                          xl={2}
                          className="px-0 d-flex flex-column align-items-end cursor-pointer"
                          onClick={() => {
                            history.push(`/dashboard/member/${item?.id}/board`);
                          }}
                        >
                          <h4
                            className={`text-size-xs underline-text mb-0 font-weight-600 ${classnames(
                              ` text-primary`,
                            )}`}
                          >
                            On boards
                          </h4>
                        </Col>
                      </Card.Body>
                    );
                  })}
                {!isGuestsFetching && guests.data.length > 0 && (
                  <Row>
                    <Col align="right" className="mx-lg-5">
                      <PaginationView
                        pageCount={guests.last_page}
                        currentPage={Number.parseInt(guests.current_page, 10)}
                      />
                    </Col>
                  </Row>
                )}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </Card>
    </>
  );
}

export default Members;
