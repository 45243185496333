import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styles from '../../styles/modules/RequestBoard.module.scss';
import { useUpdateTask } from '../../hooks/requestHistory.hooks';
import RoleBased from '../../components/RoleBased';
import { ROLES, SECTION_KEY } from '../../utils/enums';
import { reorderTasks } from '../../requests/tasks.request';

function CompletedSection({
  onHandleScreen,
  tasks,
  handleSelectTask,
  onStatusChange = () => {},
  isTaskFetching,
}) {
  const { mutateAsync: updateTask } = useUpdateTask();
  const { id: boardId } = useParams();
  const queryClient = useQueryClient();

  const handleStatus = async id => {
    try {
      const data = { 'status': 'request', order: 0 };
      await updateTask({ id, data });
      queryClient.invalidateQueries(['boardTasks', boardId]);
      onHandleScreen('request');
      onStatusChange();
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    if (!isTaskFetching) setItems(tasks.sort((a, b) => a.order - b.order));
  }, [tasks, isTaskFetching]);

  const handleOnDragEnd = async result => {
    const arr = Array.from(items);
    const [reorderedItem] = arr.splice(result.source.index, 1);
    arr.splice(result.destination.index, 0, reorderedItem);

    setItems(arr.map((i, index) => ({ ...i, order: index })).sort((a, b) => a.order - b.order));

    try {
      await reorderTasks({
        orders: arr.map(({ id }, index) => ({ task_id: id, order: index })),
      });
      onStatusChange();
    } catch (err) {
      //
    }
  };

  return (
    <>
      {tasks.filter(task => task.status === 'completed').length === 0 && (
        <div className="text-center">No task is completed.</div>
      )}
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="tasks">
          {provided => (
            <ul className="list-unstyled" {...provided.droppableProps} ref={provided.innerRef}>
              {items.map((item, index) => {
                const { id, title } = item;
                return (
                  <Draggable key={item.id} draggableId={String(item.id)} index={index}>
                    {prov => (
                      <li ref={prov.innerRef} {...prov.draggableProps} {...prov.dragHandleProps}>
                        <Card
                          className="shadow mb-3 px-4 mx-lg-5 text-font text-font mouse-hover"
                          key={id}
                          onClick={e => {
                            const element_list = [
                              ...document.getElementsByClassName('details'),
                            ].filter(element => element.contains(e.target));
                            if (
                              !e.target.classList.contains('details') &&
                              element_list.length === 0
                            ) {
                              handleSelectTask(id);
                            }
                          }}
                        >
                          <Card.Body className="px-0 d-flex px-xl-3 flex-row justify-content-center justify-content-md-between align-items-center flex-wrap">
                            <Col
                              className="px-0 align-items-lg-start"
                              xs={12}
                              sm={12}
                              md={6}
                              lg={6}
                            >
                              <button type="button" className="bg-transparent border-0 text-left">
                                <h5 className="text-size-sm font-weight-700 mb-0">{title}</h5>
                              </button>
                            </Col>
                            <RoleBased
                              sectionKey={[SECTION_KEY.task_status_change_edit]}
                              acceptedRoles={[
                                ROLES.SUPER_ADMIN,
                                ROLES.ADMIN,
                                ROLES.MANAGERS,
                                ROLES.SUBSCRIBERS,
                                ROLES.VIEWERS,
                              ]}
                            >
                              <Col
                                className="px-0 d-flex flex-column align-items-center align-items-md-end"
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                              >
                                <Button
                                  onClick={() => {
                                    handleStatus(id);
                                    onHandleScreen('request');
                                  }}
                                  className={`text-size-xs py-0 py-1 font-weight-600 details ${styles.btn_structure}`}
                                >
                                  Reopen
                                </Button>
                              </Col>
                            </RoleBased>
                          </Card.Body>
                        </Card>
                      </li>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

export default CompletedSection;
