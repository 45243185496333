import React, { useEffect, useState } from 'react';
import { Image, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useCreateMedias } from '../hooks/media.hooks';

const MediaImage = ({ media, onLoadComplete }) => {
  const [data, setData] = useState();
  const { mutateAsync: createMedias, isLoading } = useCreateMedias();

  const uploadMedia = async () => {
    try {
      const formData = new FormData();
      formData.append('file', media, media.name);
      const res = await createMedias(formData);
      setData({
        media_id: res.data.id,
        media: res.data,
      });
      onLoadComplete(res.data);
    } catch (err) {
      toast.error(err.message);
    }
  };

  useEffect(() => {
    if (!media.media_id) {
      uploadMedia();
    } else {
      setData(media);
    }
  }, [media]);

  return (
    <>
      {isLoading && <Spinner size="sm" variant="primary" animation="border" className="m-3" />}
      {!isLoading && data && (
        <Image src={data.media ? data.media.file_path : data} className="mr-2 my-1" height="36px" />
      )}
    </>
  );
};

export default MediaImage;
