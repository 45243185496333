import React from 'react';
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { usePermission, useUpdatePermission } from '../../hooks/permissions.hooks';
import { useRolePermission } from '../../hooks/roles.hooks';

const PermissionList = ({ name }) => {
  const [currentKey, setCurrentKey] = React.useState();

  const queryClient = useQueryClient();
  const {
    data: rolePermission,
    isFetching: isRolePermissionFetching,
    refetch,
  } = useRolePermission(name);
  const { data: permissions, isFetching } = usePermission();
  const { mutateAsync: updatePermission, isLoading } = useUpdatePermission();

  const getPermission = () => {
    return (
      permissions?.data?.reduce((pValue, cValue) => {
        let dataName = '';

        if (cValue.name.substring(cValue.name.lastIndexOf('_'), cValue.name.length) !== '_view') {
          dataName = cValue.name.substring(0, cValue.name.lastIndexOf('_'));
        } else {
          const tempDataName = cValue.name.substring(0, cValue.name.lastIndexOf('_'));
          dataName = tempDataName.substring(0, tempDataName.lastIndexOf('_'));
        }

        if (!(`${dataName}` in pValue)) {
          // eslint-disable-next-line no-param-reassign
          pValue[dataName] = [];
        }

        pValue[dataName].push(cValue.name.substring(dataName.length, cValue.name.length));

        return pValue;
      }, {}) || {}
    );
  };

  const onChange = async (id, permission, key, status) => {
    try {
      const currentKeys = getPermission()[permission].filter(item => {
        if (key === item && !status) {
          return false;
        }

        return (
          rolePermission?.data?.filter(data => data.name === `${permission}${item}`).length > 0
        );
      });

      if (status && !currentKeys.includes(key)) {
        currentKeys.push(key);
      }

      await updatePermission({
        id,
        data: {
          title: permission,
          role_name: name,
          types: currentKeys,
        },
      });

      queryClient.invalidateQueries(['roles_permission', name]);
      refetch();
    } catch (e) {
      toast.error(e?.message);
    }

    setCurrentKey('');
  };

  const upperCaseFirstLatter = string => {
    return string.charAt(0).toUpperCase() + string.substring(1);
  };

  return (
    <>
      {isFetching && isRolePermissionFetching ? (
        <Row>
          <Col align="center">
            <Spinner size="lg" variant="primary" animation="border" />
          </Col>
        </Row>
      ) : (
        <>
          {permissions &&
            Object.keys(getPermission()).map(permission => (
              <ul>
                <li>
                  <p className="text-size-sm text-font font-weight-600">
                    {upperCaseFirstLatter(permission.replaceAll('_', ' '))}
                  </p>
                  {(isLoading || isRolePermissionFetching) && currentKey === permission ? (
                    <Row className="my-4">
                      <Col align="center">
                        <Spinner size="sm" variant="primary" animation="border" />
                      </Col>
                    </Row>
                  ) : (
                    <Row className="mt-4">
                      {getPermission()[permission].map(key => (
                        <Col>
                          <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Check
                              type="checkbox"
                              disabled={isLoading || isRolePermissionFetching}
                              id={`${permission}${key}`}
                              label={key.replaceAll('_', ' ')}
                              checked={
                                rolePermission?.data?.filter(
                                  data => data.name === `${permission}${key}`,
                                ).length > 0
                              }
                              onChange={e => {
                                setCurrentKey(permission);
                                onChange(
                                  rolePermission?.data?.filter(
                                    data => data.name === `${permission}${key}`,
                                  )?.[0]?.id,
                                  permission,
                                  key,
                                  e.target.checked,
                                );
                              }}
                            />
                          </Form.Group>
                        </Col>
                      ))}
                    </Row>
                  )}
                </li>
              </ul>
            ))}
        </>
      )}
    </>
  );
};

export default PermissionList;
