/* eslint-disable import/prefer-default-export */
export const ROLES = {
  SUPER_ADMIN: 'Super Admin',
  ADMIN: 'Admin',
  MANAGERS: 'Managers',
  EDITORS: 'Editors',
  CONTRIBUTORS: 'Contributors',
  SUBSCRIBERS: 'Subscribers',
  VIEWERS: 'Viewers',
};

export const SECTION_KEY = {
  all: 'all',
  invite_kimp_members_to_360_and_view_members_add:
    'invite_kimp_members_to_360_and_view_members_add',
  invite_kimp_members_to_360_and_view_members_edit:
    'invite_kimp_members_to_360_and_view_members_edit',
  invite_kimp_members_to_360_and_view_members_delete:
    'invite_kimp_members_to_360_and_view_members_delete',
  invite_kimp_members_to_360_and_view_members_full_view:
    'invite_kimp_members_to_360_and_view_members_full_view',
  invite_clients_to_360_and_view_clients_add: 'invite_clients_to_360_and_view_clients_add',
  invite_clients_to_360_and_view_clients_edit: 'invite_clients_to_360_and_view_clients_edit',
  invite_clients_to_360_and_view_clients_delete: 'invite_clients_to_360_and_view_clients_delete',
  invite_clients_to_360_and_view_clients_full_view:
    'invite_clients_to_360_and_view_clients_full_view',
  invite_client_associates_to_board_and_view_them_add:
    'invite_client_associates_to_board_and_view_them_add',
  invite_client_associates_to_board_and_view_them_edit:
    'invite_client_associates_to_board_and_view_them_edit',
  invite_client_associates_to_board_and_view_them_delete:
    'invite_client_associates_to_board_and_view_them_delete',
  invite_client_associates_to_board_and_view_them_full_view:
    'invite_client_associates_to_board_and_view_them_full_view',
  subscription_view_page_add: 'subscription_view_page_add',
  subscription_view_page_edit: 'subscription_view_page_edit',
  subscription_view_page_delete: 'subscription_view_page_delete',
  subscription_view_page_full_view: 'subscription_view_page_full_view',
  board_add: 'board_add',
  board_edit: 'board_edit',
  board_delete: 'board_delete',
  board_full_view: 'board_full_view',
  invite_kimp_members_to_board_add: 'invite_kimp_members_to_board_add',
  invite_kimp_members_to_board_edit: 'invite_kimp_members_to_board_edit',
  invite_kimp_members_to_board_delete: 'invite_kimp_members_to_board_delete',
  invite_kimp_members_to_board_full_view: 'invite_kimp_members_to_board_full_view',
  invite_clients_to_board_add: 'invite_clients_to_board_add',
  invite_clients_to_board_edit: 'invite_clients_to_board_edit',
  invite_clients_to_board_delete: 'invite_clients_to_board_delete',
  invite_clients_to_board_full_view: 'invite_clients_to_board_full_view',
  invite_clients_associates_to_board_add: 'invite_clients_associates_to_board_add',
  invite_clients_associates_to_board_edit: 'invite_clients_associates_to_board_edit',
  invite_clients_associates_to_board_delete: 'invite_clients_associates_to_board_delete',
  invite_clients_associates_to_board_full_view: 'invite_clients_associates_to_board_full_view',
  collection_and_collection_filter_view_add: 'collection_and_collection_filter_view_add',
  collection_and_collection_filter_view_edit: 'collection_and_collection_filter_view_edit',
  collection_and_collection_filter_view_delete: 'collection_and_collection_filter_view_delete',
  collection_and_collection_filter_view_full_view:
    'collection_and_collection_filter_view_full_view',
  task_request_add: 'task_request_add',
  task_request_edit: 'task_request_edit',
  task_request_delete: 'task_request_delete',
  task_request_full_view: 'task_request_full_view',
  task_comments_add: 'task_comments_add',
  task_comments_edit: 'task_comments_edit',
  task_comments_delete: 'task_comments_delete',
  task_comments_full_view: 'task_comments_full_view',
  board_assets_add: 'board_assets_add',
  board_assets_edit: 'board_assets_edit',
  board_assets_delete: 'board_assets_delete',
  board_assets_full_view: 'board_assets_full_view',
  task_status_change_edit: 'task_status_change_edit',
  task_status_change_full_view: 'task_status_change_full_view',
  task_labeling_add: 'task_labeling_add',
  task_labeling_edit: 'task_labeling_edit',
  task_labeling_delete: 'task_labeling_delete',
  task_labeling_full_view: 'task_labeling_full_view',
  task_show_menu_options_add: 'task_show_menu_options_add',
  task_show_menu_options_edit: 'task_show_menu_options_edit',
  task_show_menu_options_delete: 'task_show_menu_options_delete',
  task_show_menu_options_full_view: 'task_show_menu_options_full_view',
  task_description_history_of_changes_full_view: 'task_description_history_of_changes_full_view',
  task_completion_feedback_full_view: 'task_completion_feedback_full_view',
  search_boards_full_view: 'search_boards_full_view',
  notifications_full_view: 'notifications_full_view',
  notification_filters_full_view: 'notification_filters_full_view',
  settings_edit: 'settings_edit',
  settings_delete: 'settings_delete',
  settings_full_view: 'settings_full_view',
};
