/* eslint-disable react/destructuring-assignment */
import React from 'react';
import Popover from 'react-bootstrap/Popover';
import Form from 'react-bootstrap/Form';

import classnames from 'classnames';
import { Col, Row, Spinner } from 'react-bootstrap';
import styles from '../styles/modules/InviteToBoard.module.scss';

const AddMembersPopover = props => {
  return (
    <Popover
      {...props}
      className={`custom-popover text-font ${classnames(
        props?.popper?.state?.options?.name === 'feeds' && styles.feeds_popover_structure,
        props?.popper?.state?.options?.name === 'request-history' &&
          styles.member_popover_structure,
        props?.popper?.state?.options?.name === 'clients' && styles.clients_popover_structure,
      )}`}
    >
      <Popover.Content className="px-4 pt-3">
        {props?.popper?.state?.options?.isLoading && (
          <Row>
            <Col align="center">
              <Spinner size="sm" variant="primary" animation="border" />
            </Col>
          </Row>
        )}
        {!props?.popper?.state?.options?.isLoading &&
          props?.popper?.state?.options?.list?.length > 0 &&
          props?.popper?.state?.options?.list?.map(item => {
            return (
              <div
                // key={uuid()}
                className="text-size-xs d-flex mb-2 flex-row align-items-center text-darker"
              >
                <Form.Check
                  custom
                  type="radio"
                  label={item?.name}
                  id={item?.name}
                  name="popover_member"
                  className="mb-0 text-font visibility_label"
                  onClick={() => props?.popper?.state?.options?.onChange(item?.name)}
                />
              </div>
            );
          })}
        {!props?.popper?.state?.options?.isLoading &&
          props?.popper?.state?.options?.list?.length === 0 && (
            <Row>
              <Col align="center">
                <span>No data available</span>
              </Col>
            </Row>
          )}
      </Popover.Content>
    </Popover>
  );
};

export default AddMembersPopover;
