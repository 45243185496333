import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router';
import classnames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styles from '../../styles/modules/RequestBoard.module.scss';
import { useDeleteTask, useUpdateTask } from '../../hooks/requestHistory.hooks';
import { useConfirmation } from '../../components/Confirmation/ConfirmationService';
import RoleBased from '../../components/RoleBased';
import { ROLES, SECTION_KEY } from '../../utils/enums';
import { reorderTasks } from '../../requests/tasks.request';

function DraftSection({
  onHandleScreen,
  tasks,
  handleSelectTask,
  onStatusChange = () => {},
  isTaskFetching,
}) {
  const history = useHistory();
  const confirm = useConfirmation();

  const { mutateAsync: updateTask, isLoading: isLoadingUpdateTask } = useUpdateTask();
  const { mutateAsync: deleteTask, isLoading: isLoadingDeleteTask } = useDeleteTask();
  const { id: boardId } = useParams();
  const queryClient = useQueryClient();

  const [processingId, setProcessingId] = useState();

  const handleStatus = async id => {
    try {
      setProcessingId(id);
      const data = { 'is_publish': 1 };
      await updateTask({ id, data });
      onHandleScreen('request');
      queryClient.invalidateQueries(['boardTasks', boardId]);
      onStatusChange();
      setProcessingId(undefined);
    } catch (error) {
      toast.error(error?.message);
      setProcessingId(undefined);
    }
  };

  const deleteTasks = async id => {
    await confirm({
      title: 'Delete',
      description: 'Are you sure you want to delete this task?',
    });

    try {
      setProcessingId(id);
      await deleteTask(id);
      queryClient.invalidateQueries(['boardTasks', boardId]);
      onStatusChange();
      setProcessingId(undefined);
    } catch (error) {
      toast.error(error?.message);
      setProcessingId(undefined);
    }
  };

  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    if (!isTaskFetching) setItems(tasks.sort((a, b) => a.order - b.order));
  }, [tasks, isTaskFetching]);

  const handleOnDragEnd = async result => {
    const arr = Array.from(items);
    const [reorderedItem] = arr.splice(result.source.index, 1);
    arr.splice(result.destination.index, 0, reorderedItem);

    setItems(arr.map((i, index) => ({ ...i, order: index })).sort((a, b) => a.order - b.order));

    try {
      await reorderTasks({
        orders: arr.map(({ id }, index) => ({ task_id: id, order: index })),
      });
      onStatusChange();
    } catch (err) {
      //
    }
  };

  return (
    <>
      {items.length === 0 && (
        <Row>
          <Col align="center">
            <span>No draft task available.</span>
          </Col>
        </Row>
      )}
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="tasks">
          {provided => (
            <ul className="list-unstyled" {...provided.droppableProps} ref={provided.innerRef}>
              {items.map(({ id, title }, index) => (
                <Draggable key={id} draggableId={String(id)} index={index}>
                  {prov => (
                    <li ref={prov.innerRef} {...prov.draggableProps} {...prov.dragHandleProps}>
                      <Card
                        className="shadow mb-3 text-font px-4 mx-lg-5 mouse-hover"
                        key={id}
                        onClick={e => {
                          const element_list = [
                            ...document.getElementsByClassName('details'),
                          ].filter(element => element.contains(e.target));
                          if (
                            !e.target.classList.contains('details') &&
                            element_list.length === 0 &&
                            !isTaskFetching &&
                            !isLoadingDeleteTask
                          ) {
                            handleSelectTask(id);
                          }
                        }}
                      >
                        <Card.Body className="px-0 px-xl-3">
                          <Row className="mx-0">
                            <Col xs={12} sm={12} md={6} lg={8} xl={7} className="px-0">
                              <div className="d-flex flex-row align-items-center justify-content-center justify-content-lg-start flex-wrap flex-md-no-wrap">
                                <button type="button" className="bg-transparent border-0 text-left">
                                  <h5 className="text-size-sm font-weight-700 mb-0">{title}</h5>
                                </button>
                                <RoleBased
                                  sectionKey={[SECTION_KEY.task_request_edit]}
                                  acceptedRoles={[
                                    ROLES.SUPER_ADMIN,
                                    ROLES.ADMIN,
                                    ROLES.MANAGERS,
                                    ROLES.EDITORS,
                                    ROLES.SUBSCRIBERS,
                                    ROLES.VIEWERS,
                                  ]}
                                >
                                  <Button
                                    type="button"
                                    variant="link"
                                    className={`text-size-xs underline-text py-0 py-1 font-weight-600 details ${classnames(
                                      styles.btn_structure,
                                    )}`}
                                    onClick={() => {
                                      history.push('/dashboard/request-dimension', {
                                        taskId: id,
                                        section: 'name',
                                      });
                                    }}
                                  >
                                    Edit
                                  </Button>
                                </RoleBased>
                              </div>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={4} xl={5}>
                              <Row className="mx-0 d-flex justify-content-center justify-content-lg-end align-items-center">
                                <RoleBased
                                  sectionKey={[SECTION_KEY.task_request_delete]}
                                  acceptedRoles={[
                                    ROLES.SUPER_ADMIN,
                                    ROLES.ADMIN,
                                    ROLES.MANAGERS,
                                    ROLES.SUBSCRIBERS,
                                  ]}
                                >
                                  <Button
                                    type="button"
                                    variant="link"
                                    className={`text-size-xs underline-text py-0 py-1 font-weight-600 details ${classnames(
                                      styles.btn_structure,
                                    )}`}
                                    onClick={() => {
                                      deleteTasks(id);
                                    }}
                                    disabled={
                                      (isLoadingDeleteTask && processingId === id) || isTaskFetching
                                    }
                                  >
                                    Delete
                                  </Button>
                                </RoleBased>
                                <RoleBased
                                  sectionKey={[SECTION_KEY.task_status_change_edit]}
                                  acceptedRoles={[
                                    ROLES.SUPER_ADMIN,
                                    ROLES.ADMIN,
                                    ROLES.MANAGERS,
                                    ROLES.SUBSCRIBERS,
                                    ROLES.VIEWERS,
                                  ]}
                                >
                                  <Button
                                    className={`text-size-xs py-0 py-1 font-weight-600 details ${styles.btn_structure}`}
                                    onClick={() => {
                                      handleStatus(id);
                                    }}
                                    disabled={
                                      (isLoadingUpdateTask && processingId === id) || isTaskFetching
                                    }
                                  >
                                    Publish
                                  </Button>
                                </RoleBased>
                              </Row>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

export default DraftSection;
