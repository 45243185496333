import { yupResolver } from '@hookform/resolvers/yup';
import { AutoComplete } from 'antd';
import React from 'react';
import { Button, Col, Form, Nav, Overlay, Popover, Row, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import BoardContext from '../contexts/BoardContext';
import UserContext from '../contexts/UserContext';
import { useSendInvites } from '../hooks/invites.hooks';
import { useRoles } from '../hooks/roles.hooks';
// import { useAddMembers } from '../hooks/teams.hooks';
import { useGetUsers } from '../hooks/user.hooks';
import { ROLES } from '../utils/enums';

import styles from '../styles/modules/RequestBoard.module.scss';

const schema = yup.object({
  email: yup.string().required('Required').email(),
  role: yup.string().required('Required'),
});

const InviteButton = ({ className, from, buttonLabel, variant }) => {
  const queryClient = useQueryClient();

  const [showPop, setShowPop] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [openDrop, setOpenDrop] = React.useState(false);
  const [invitation, setInvitation] = React.useState('');
  // const [existingUserId, setExistingUserId] = React.useState();
  const [invitationLink, setInvitationLink] = React.useState('');
  const target = React.useRef(null);

  const board = React.useContext(BoardContext);
  const user = React.useContext(UserContext);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
    trigger,
  } = useForm({ resolver: yupResolver(schema) });

  const { data: fetchRoles, isFetching: isRolesFetching } = useRoles();
  const { data: fetchUsers } = useGetUsers(search);
  const { mutateAsync: sendInvites, isLoading: isInvitationSending } = useSendInvites();

  const onSelect = data => {
    // setExistingUserId(data.split(' ')[0]);
    setValue('email', data.split(' ')[1]);
    setValue('role', data.split(' ')[2]);
    setOpenDrop(false);
    trigger('email');
    trigger('role');
  };

  const onSearch = value => {
    // setExistingUserId(undefined);
    setValue('role', '');
    setSearch(value !== '' ? `?search=${value}` : '');
    setValue('email', value !== '' ? value : '');
    setOpenDrop(value !== '');
    trigger('email');
  };

  const getInvitationLink = async data => {
    try {
      setInvitation('Link');
      const res = await sendInvites(
        from === 'board'
          ? {
              email: data.email,
              project_id: board.board?.id,
              role: data.role,
              email_send: false,
            }
          : {
              email: data.email,
              role: data.role,
              email_send: false,
            },
      );
      if (res.status) {
        queryClient.invalidateQueries(['teams', `?project_id=${board.board?.id}`]);
        setInvitationLink(res.data.inviteLink);
        navigator.clipboard.writeText(res.data.inviteLink);
        toast.success('Invitation link generated successfully');
      } else toast.error(res.message);

      setInvitation('');
    } catch (err) {
      toast.error(err);
      setInvitation('');
    }
  };

  const inviteUser = async data => {
    try {
      setInvitation('Email');
      const res = await sendInvites(
        from === 'board'
          ? {
              email: data.email,
              project_id: board.board?.id,
              role: data.role,
              email_send: true,
            }
          : {
              email: data.email,
              role: data.role,
              email_send: true,
            },
      );
      if (res.status) {
        queryClient.invalidateQueries(['teams', `?project_id=${board.board?.id}`]);
        toast.success('Invitation sent successfully');
        setValue('email', '');
        setValue('role', '');
      } else toast.error(res.message);

      setInvitation('');
    } catch (err) {
      toast.error(err);
      setInvitation('');
    }
  };

  return (
    <>
      <Button
        type="button"
        variant={variant}
        className={className}
        ref={target}
        onClick={() => {
          setInvitationLink('');
          reset();
          setShowPop(!showPop);
        }}
      >
        <span className="px-1">{buttonLabel}</span>
      </Button>
      <Overlay
        target={target.current}
        show={showPop}
        placement="bottom-end"
        flip
        rootClose
        rootCloseEvent="click"
        onHide={e => {
          if (!document.getElementsByClassName('ant-select-dropdown')[0]?.contains(e.target)) {
            setShowPop(!showPop);
          }
        }}
      >
        {props => (
          <Popover {...props} className="text-font px-2 py-2 custom-popover w-100">
            <Popover.Title className="text-size-xs text-secondary bg-white text-center no-border">
              {from === 'board' ? `Invite To Board` : `Invite To Kimp`}
            </Popover.Title>
            <Popover.Content>
              <div className="text-size-xs align-items-center font-weight-500 ">
                <AutoComplete
                  value={getValues().email}
                  onSelect={onSelect}
                  onSearch={onSearch}
                  placeholder="Email address or Name"
                  className="text-size-xxs w-100 kimp-search-input"
                  open={openDrop && from === 'board'}
                >
                  {fetchUsers.data &&
                    fetchUsers.data.map(fetchedUser => {
                      return (
                        <AutoComplete.Option
                          key={fetchedUser.id}
                          value={`${fetchedUser.id} ${fetchedUser.email} ${fetchedUser.roles[0].name}`}
                        >
                          <Row className="text-size-xs align-items-center font-weight-500 px-3 py-2">
                            <img
                              src={`https://ui-avatars.com/api/?name=${fetchedUser.first_name}+${fetchedUser.last_name}&background=219186&color=fff`}
                              alt="user badge"
                              className={`mr-2 ${styles.circle_badge}`}
                            />
                            {fetchedUser.username
                              ? fetchedUser.username
                              : `${fetchedUser.first_name} ${fetchedUser.last_name}`}
                            <br />
                            {fetchedUser.email}
                          </Row>
                        </AutoComplete.Option>
                      );
                    })}
                </AutoComplete>
                <p className="text-size-xxs error-text m-0">{errors?.email?.message}</p>
              </div>
            </Popover.Content>
            <Popover.Content>
              <div className="text-size-xs align-items-center font-weight-500 ">
                {isRolesFetching && (
                  <Col align="center">
                    <Spinner size="sm" variant="primary" animation="border" />
                  </Col>
                )}
                {!isRolesFetching && (
                  <>
                    <Form.Control
                      as="select"
                      className="text-size-xxs text-darker invitation-role"
                      {...register('role')}
                      isInvalid={errors?.role}
                    >
                      <>
                        <option value="">Select role</option>
                        {fetchRoles
                          .filter(role => {
                            if (
                              role === ROLES.SUPER_ADMIN ||
                              role === ROLES.ADMIN ||
                              role === ROLES.MANAGERS ||
                              role === ROLES.EDITORS
                            ) {
                              return [ROLES.SUPER_ADMIN, ROLES.ADMIN].includes(user.getRole());
                            }

                            return true;
                          })
                          .filter(role => {
                            if (from === 'member') {
                              if (role === ROLES.SUBSCRIBERS || role === ROLES.VIEWERS) {
                                return false;
                              }

                              return true;
                            }

                            return true;
                          })
                          .map(role => (
                            <option value={role}>{role}</option>
                          ))}
                      </>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors?.role?.message}
                    </Form.Control.Feedback>
                  </>
                )}
              </div>
            </Popover.Content>
            {from === 'board' && (
              <Popover.Content className="">
                {invitationLink !== '' && (
                  <>
                    <div className="d-flex flex-row align-items-center justify-content-between">
                      <h6 className="text-size-xs font-weight-600">Link</h6>
                    </div>
                    <div className="d-flex flex-row align-items-center justify-content-between">
                      <h6 className="text-size-xxs text-primary underline-text">
                        {invitationLink}
                      </h6>
                    </div>
                  </>
                )}
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <h6 className="text-size-xs font-weight-600">Invite with Link</h6>
                  <h6 className="text-size-xs text-primary underline-text cursor-pointer">
                    {/* <a href="/" className="text-size-xs text-primary underline-text"> */}
                    {invitation === 'Link' && isInvitationSending && (
                      <Spinner size="sm" variant="light" animation="border" />
                    )}
                    {(invitation !== 'Link' || !isInvitationSending) && (
                      <Nav.Link
                        className="text-size-xs text-primary underline-text"
                        onClick={handleSubmit(getInvitationLink)}
                      >
                        Create Link
                      </Nav.Link>
                    )}
                    {/* </a>/ */}
                  </h6>
                </div>
                <p className="text-size-xxs text-secondary mb-0 pt-1 pb-3">
                  Guests that are already on another team board can be added via email address.
                </p>
              </Popover.Content>
            )}
            <Popover.Content>
              <Button
                type="button"
                className="text-size-xxs font-weight-600 px-3 w-100"
                onClick={handleSubmit(inviteUser)}
                disabled={isInvitationSending}
              >
                {invitation === 'Email' && isInvitationSending && (
                  <Spinner size="sm" variant="light" animation="border" />
                )}
                {(invitation !== 'Email' || !isInvitationSending) && 'Send Invitation'}
              </Button>
            </Popover.Content>
          </Popover>
        )}
      </Overlay>
    </>
  );
};

export default InviteButton;
