import * as React from 'react';
// import styles from '../../styles/modules/Icons.module.scss';

function DeleteIcon({ props, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={8.353}
      height={11.74}
      viewBox="0 0 8.353 11.74"
      {...props}
    >
      <path
        data-name="Path 1106"
        d="M.6 10.547a1.2 1.2 0 001.19 1.193h4.773a1.2 1.2 0 001.193-1.193v-7.16H.6zM8.353.6H6.265l-.6-.6h-2.98l-.6.6H0v1.19h8.353z"
        // fill="#969696"
        // fill="white"
        fill={color}
      />
    </svg>
  );
}

export default DeleteIcon;
