import http from '../utils/http';

export const fetchBoardAssets = (boardId = null) => http.get(`/assets?project_id=${boardId}`);

export const fetchAssets = (parentId = null) => {
  if (parentId) {
    const query = new URLSearchParams({ parent_id: parentId });

    return http.get(`/assets?${query}`);
  }

  return http.get(`/assets`);
};

export const createAsset = data => http.post(`/assets`, data);

export const fetchAsset = assetId => http.get(`/assets/${assetId}`);

export const fetchAssetDownloadURL = assetid => http.get(`/assets/${assetid}/downloads`);

export const deleteAsset = assetId => http.delete(`/assets/${assetId}`);
