import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Switch from '../../components/LabellessSwitch';
import RequestContext from '../../contexts/RequestContext';

const RequestFileTypes = ({ changeCurrentScreen, saveDraft }) => {
  const { request, updateRequest } = React.useContext(RequestContext);
  const [fileTypes, setFileTypes] = useState([]);
  const [showError, setShowError] = useState(false);
  const [sourceFile, setSourceFile] = useState('');
  const [saveAs, setSaveAs] = useState();

  const onSubmit = variant => {
    if (fileTypes.length === 0 && (sourceFile === '' || sourceFile === undefined)) {
      setShowError(true);
      return;
    }

    if (variant === 1) {
      setSaveAs('publish');
    } else if (variant === 0) {
      setSaveAs('draft');
    }

    fileTypes.push(sourceFile);

    const tempRequestData = {
      ...request.data,
      file_formats: fileTypes,
      updated: true,
    };

    let clearedForm = [];
    if (request.clearedForm.includes('filetypes')) {
      clearedForm = request.clearedForm;
    } else {
      clearedForm = [...request.clearedForm, 'filetypes'];
    }

    updateRequest({
      clearedForm,
      form: 'summary',
      data: tempRequestData,
    });
  };

  const selectSourceFile = file_type => {
    setSourceFile(file_type);
  };

  useEffect(() => {
    if (saveAs) {
      if (saveAs === 'draft') {
        saveDraft(0);
        setSaveAs(undefined);
      }

      if (saveAs === 'publish') {
        changeCurrentScreen('summary');
        setSaveAs(undefined);
      }
    }

    setFileTypes(
      request.data.file_formats
        ? request.data.file_formats.slice(undefined, request.data.file_formats.length - 1)
        : fileTypes,
    );
    setSourceFile(
      request.data.file_formats
        ? request.data.file_formats[request.data.file_formats.length - 1]
        : '',
    );
  }, [request]);

  return (
    <Card body className="text-font pl-md-0 pl-lg-5 no-border pt-3 text-font">
      <Col className="px-0 px-md-3">
        <p>
          <Switch
            className="mr-3"
            value={fileTypes.includes('.jpeg')}
            onChange={check => {
              let types = [...fileTypes];
              if (check) {
                types.push('.jpeg');
              } else {
                types = types.filter(type => type !== '.jpeg');
              }

              setFileTypes(types);
            }}
          />
          <span className="text-size-xs font-weight-600">{' .jpeg'}</span>
        </p>
        <p>
          <Switch
            className="mr-3"
            value={fileTypes.includes('.png')}
            onChange={check => {
              let types = [...fileTypes];
              if (check) {
                types.push('.png');
              } else {
                types = types.filter(type => type !== '.png');
              }

              setFileTypes(types);
            }}
          />
          <span className="text-size-xs font-weight-600">{' .png'}</span>
        </p>
        <p>
          <Switch
            className="mr-3"
            value={fileTypes.includes('.pdf')}
            onChange={check => {
              let types = [...fileTypes];
              if (check) {
                types.push('.pdf');
              } else {
                types = types.filter(type => type !== '.pdf');
              }

              setFileTypes(types);
            }}
          />
          <span className="text-size-xs font-weight-600">{' .pdf'}</span>
        </p>
        <p>
          <Switch
            className="mr-3"
            value={fileTypes.includes('.gif')}
            onChange={check => {
              let types = [...fileTypes];
              if (check) {
                types.push('.gif');
              } else {
                types = types.filter(type => type !== '.gif');
              }

              setFileTypes(types);
            }}
          />
          <span className="text-size-xs font-weight-600">{' .gif'}</span>
        </p>
        <p>
          <Switch
            className="mr-3"
            value={fileTypes.includes('.ppt')}
            onChange={check => {
              let types = [...fileTypes];
              if (check) {
                types.push('.ppt');
              } else {
                types = types.filter(type => type !== '.ppt');
              }

              setFileTypes(types);
            }}
          />
          <span className="text-size-xs font-weight-600">{' .ppt'}</span>
        </p>

        <p>
          <span className="text-size-xs font-weight-600">Source File</span>
          {' ( '}
          <Form.Check
            type="radio"
            inline
            custom
            id="ai"
            name="source_file"
            label="AI"
            className="text-size-xs"
            checked={sourceFile === '.AI'}
            onClick={() => {
              selectSourceFile('.AI');
            }}
          />
          <Form.Check
            inline
            custom
            id="psd"
            type="radio"
            name="source_file"
            label="PSD"
            className="text-size-xs"
            checked={sourceFile === '.PSD'}
            onClick={() => {
              selectSourceFile('.PSD');
            }}
          />
          <Form.Check
            inline
            custom
            id="indd"
            type="radio"
            name="source_file"
            label="INDD"
            className="text-size-xs"
            checked={sourceFile === '.INDD'}
            onClick={() => {
              selectSourceFile('.INDD');
            }}
          />
          <Form.Check
            inline
            custom
            id="xd"
            type="radio"
            name="source_file"
            label="XD"
            className="text-size-xs"
            checked={sourceFile === '.XD'}
            onClick={() => {
              selectSourceFile('.XD');
            }}
          />
          {' ) '}
        </p>
        {showError && fileTypes.length === 0 && (sourceFile === '' || sourceFile === undefined) && (
          <p className="text-size-xs error-text">Please provide the type of image you need.</p>
        )}
        <div className="d-flex flex-column align-items-center align-items-md-start">
          <Button
            type="button"
            className="text-size-xs mt-5 px-5 font-weight-500"
            onClick={() => onSubmit(1)}
          >
            Continue
          </Button>

          <Button
            type="button"
            variant="link"
            className="text-size-xs mt-2 px-0 d-block text-underline text-darker"
            onClick={() => {
              onSubmit(0);
            }}
          >
            Save Draft
          </Button>
        </div>
      </Col>
    </Card>
  );
};

export default RequestFileTypes;
