import * as React from 'react';

function SvgComponent({ props, color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={10} height={10} viewBox="0 0 6 5.305" {...props}>
      <path
        data-name="Tick mark"
        fill={color}
        d="M5.899.803l-3.2 4.339a.371.371 0 01-.3.163.361.361 0 01-.25-.105l-2-1.93a.552.552 0 01-.062-.678.354.354 0 01.562-.074l1.7 1.641 2.948-4a.356.356 0 01.565-.041.557.557 0 01.037.685z"
      />
    </svg>
  );
}

export default SvgComponent;
