import { useQuery, useMutation } from 'react-query';
import {
  addCollectionToBoard,
  createCollection,
  deleteCollecton,
  fetchCollections,
  removeCollectionFromBoard,
  updateCollection,
} from '../requests/collection.requests';

export const useCollections = () =>
  useQuery(
    'collections',
    async () => {
      const res = await fetchCollections();
      return res.data;
    },
    {
      initialData: [],
    },
  );

export const useCreateCollection = () =>
  useMutation(async data => {
    const res = await createCollection(data);
    return res;
  });

export const useUpdateCollection = () =>
  useMutation(async ({ id, data }) => {
    const res = await updateCollection(id, data);
    return res;
  });

export const useDeleteCollection = () =>
  useMutation(async id => {
    const res = await deleteCollecton(id);
    return res;
  });

export const useAddCollectionToBoard = () =>
  useMutation(async ({ id, boardId }) => {
    const res = await addCollectionToBoard(id, boardId);
    return res;
  });

export const useRemoveCollectionFromBoard = () =>
  useMutation(async ({ id, boardId }) => {
    const res = await removeCollectionFromBoard(id, boardId);
    return res;
  });
