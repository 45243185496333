import React from 'react';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import moment from 'moment';
import { Spinner } from 'react-bootstrap';
import useHolidays from '../../hooks/holiday.hooks';

function TeamHolidays() {
  const { data: holidays, isFetching } = useHolidays();

  return (
    <Card className="mt-4">
      <Card.Header className="bg-white py-4">
        <Card.Title className="text-size-md text-center font-weight-600 text-font">
          Team Holidays
        </Card.Title>
        <Card.Subtitle className="text-size-xs text-secondary text-center text-font font-weight-500">
          Here is the list of team holidays and plan your work accordingly.
        </Card.Subtitle>
      </Card.Header>
      <Card.Body className="w-75 align-self-center py-5">
        <Table responsive="lg" bordered>
          <thead>
            <tr className="text-size-xs">
              <th className=" no-wrap font-weight-bold pl-5 pl-lg-1 pl-xl-5">
                <div className="text-darker pl-5 pl-lg-3 pl-xl-5 text-font font-weight-600">
                  United States/Public holidays (2021)
                </div>
              </th>
              <th className="text-darker no-wrap font-weight-bold pl-5 pl-lg-0 pl-xl-5 text-font font-weight-600">
                Date
              </th>
            </tr>
          </thead>
          <tbody>
            {isFetching && (
              <tr>
                <td colSpan="2" align="center">
                  <Spinner animation="border" variant="primary" size="sm" />
                </td>
              </tr>
            )}
            {holidays.map(({ id, title, date }) => (
              <tr key={id}>
                <td className="text-size-xs no-wrap font-weight-bold w-50 pl-5 pl-lg-1 pl-xl-5">
                  <div className="text-darker pl-5 pl-lg-3 pl-xl-5 text-font font-weight-600">
                    {title}
                  </div>
                </td>
                <td className="text-size-xs no-wrap font-weight-bold w-50 pl-5 pl-lg-0 pl-xl-5">
                  <div className="text-darker text-font font-weight-600">
                    {moment(date).format('ddd, Do MMM, YYYY')}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}

export default TeamHolidays;
