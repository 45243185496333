import { useQuery, useMutation } from 'react-query';
import { createMedia, deleteMedia, fetchMedia, updateMedia } from '../requests/medias.requests';

export const useCreateMedia = () =>
  useMutation(async data => {
    const res = await createMedia(data);
    return res;
  });

export const useUpdateMedia = mediaId =>
  useMutation(async data => {
    const res = await updateMedia(mediaId, data);
    return res;
  });

export const useMedia = mediaId =>
  useQuery(
    ['media', mediaId],
    async () => {
      const res = await fetchMedia(mediaId);
      return res.data;
    },
    {
      enabled: !!mediaId,
      initialData: null,
    },
  );

export const useRemoveMedia = () =>
  useMutation(async mediaId => {
    const res = await deleteMedia(mediaId);
    return res;
  });
