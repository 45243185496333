import React from 'react';
import { Image, Spinner } from 'react-bootstrap';
import { useFetchMediaById } from '../hooks/media.hooks';
import ImageDefault from '../assets/icons/file-type-default.svg';
import ImageIllustrator from '../assets/icons/file-type-illustrator.svg';
import ImagePdf from '../assets/icons/file-type-pdf.svg';
import ImagePhotoshop from '../assets/icons/file-type-photoshop.svg';

const AssetImage = ({ asset, onDisplay, className }) => {
  const { data: media, isFetching } = useFetchMediaById(!asset.media ? asset.media_id : undefined);

  const returnMediaDisplay = selected_media => {
    switch (selected_media?.file_type) {
      case 'pdf':
        return ImagePdf;
      case 'psd':
        return ImagePhotoshop;
      case 'ai':
        return ImageIllustrator;
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'svg':
        return selected_media?.file_path;
      default:
        return ImageDefault;
    }
  };

  return (
    <>
      {isFetching && <Spinner size="sm" variant="primary" animation="border" className="m-3" />}
      {!isFetching && (
        <Image
          src={asset.media ? returnMediaDisplay(asset.media) : returnMediaDisplay(media)}
          className={`mr-2 ${className}`}
          height="36px"
          onClick={() => {
            if (onDisplay && media) {
              onDisplay(media);
            }
          }}
        />
      )}
    </>
  );
};

export default AssetImage;
