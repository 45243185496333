import { useMutation, useQuery } from 'react-query';
import { getPermission, postPermission, putPermission } from '../requests/permissions.requests';

export const usePermission = () =>
  useQuery(
    'permissions',
    async () => {
      const res = await getPermission();
      return res;
    },
    {
      initialData: {},
    },
  );

export const useCreatePermission = () =>
  useMutation(async data => {
    const res = await postPermission(data);
    return res;
  });

export const useUpdatePermission = () =>
  useMutation(async ({ id, data }) => {
    const res = await putPermission(id, data);
    return res;
  });
