import http from '../utils/http';

export const fetchProjects = userId => http.get(`/projects?user_id=${userId}`);

export const sendProjectRequest = userId => http.post(`/project/${userId}`);

export const fetchProjectDetails = projectId => http.get(`/projects/${projectId}`);

export const updateProjectDetails = (projectId, details) =>
  http.put(`/projects/${projectId}`, details);

// tasks by project_id
export const projectTasks = projectId => http.get(`/project/${projectId}/tasks`);

export const getProjectLogs = projectId => http.get(`/projects/${projectId}/logs`);

export const getProjectCollections = projectId => http.get(`/projects/${projectId}/collections`);

export const getProjectPermission = projectId => http.get(`/projects/${projectId}/permissions`);
