import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import styles from '../styles/modules/TeamAccessRoleDropdown.module.scss';
import TickIcon from '../assets/icons/TickIcon';
import { useRoles, useRolesByUserId } from '../hooks/roles.hooks';

function TeamAccessRoleDropdown({ selected, userId, onSelect, disabled }) {
  const [categories, setCategories] = useState('');
  const handleCategory = key => setCategories(key);

  const queryClient = useQueryClient();

  const { data: roles, isFetching } = useRoles();
  const { mutateAsync: updateRolesByUserId, isLoading } = useRolesByUserId();

  useEffect(() => {
    setCategories(selected);
  }, [selected]);

  useEffect(async () => {
    if (categories !== '' && categories !== selected) {
      try {
        const res = await updateRolesByUserId({
          userId,
          data: {
            name: categories,
          },
        });
        if (res.status) {
          toast.success('Role updated successfully.');
          queryClient.invalidateQueries(['members']);
        } else toast.success(res.error);
        if (onSelect) onSelect();
      } catch (err) {
        toast.error(err);
      }
    }
  }, [categories]);

  return (
    <Dropdown className="">
      <Dropdown.Toggle
        disabled={disabled}
        variant="white"
        className={` ${classnames(
          styles.team_role_dropdown_toggle,
        )} text-font d-flex flex-row align-items-center mr-2 pl-0 justify-content-between`}
      >
        {isLoading && (
          <Row>
            <Col align="center">
              <Spinner size="sm" variant="primary" animation="border" />
            </Col>
          </Row>
        )}
        {!isLoading && <span className="text-darker pr-2 font-weight-600">{categories}</span>}
        {!disabled && <FontAwesomeIcon icon={faChevronDown} color="black" />}
      </Dropdown.Toggle>
      <Dropdown.Menu className={`mt-1 text-font ${styles.team_role_menu} role-menu`}>
        {isFetching && <Spinner size="lg" variant="primary" animation="border" />}
        {!isFetching &&
          roles.map(role => {
            return (
              <Dropdown.Item
                disabled={categories.toString().normalize() === role.toString().normalize()}
                className={`${styles.team_role_item} px-0 px-2`}
                onSelect={() => {
                  return handleCategory(`${role}`);
                }}
              >
                <Row className="mx-0 align-items-center">
                  <Col className="px-0 pl-1" xs={2} sm={2} md={2} lg={2} xl={2}>
                    {categories.toString().normalize() === role.toString().normalize() ? (
                      <TickIcon color="" />
                    ) : null}
                  </Col>
                  <Col className="px-0">
                    <span className={` ${styles.custom_text_size}`}>{role}</span>
                  </Col>
                </Row>
              </Dropdown.Item>
            );
          })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default TeamAccessRoleDropdown;
