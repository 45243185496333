import { faCheck, faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import { toast } from 'react-toastify';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import FileButton from '../../../components/FileButton';
import Switch from '../../../components/Switch';
import crossIcon from '../../../assets/icons/red-cross-icon.svg';
import RequestContext from '../../../contexts/RequestContext';
import AssetsViewer from './AssetsViewer';
import AssetsButton from '../../../components/AssetsButton';
import MediaImage from '../../../components/MediaImage';
import AssetImage from '../../../components/AssetImage';

const RequestDescription = ({ changeCurrentScreen, saveDraft }) => {
  const { request, updateRequest } = React.useContext(RequestContext);
  const [show, setShow] = useState(false);
  const [showError, setShowError] = useState();
  const [saveAs, setSaveAs] = useState();
  const [inputFieldList, setInputFieldList] = useState([
    {
      id: 1,
    },
  ]);
  const [text, setText] = useState({
    yes: false,
    text: '',
  });
  const [image, setImage] = useState({
    yes: false,
    imageData: '',
  });
  const [uploadAssets, setUploadAssets] = useState(false);
  const [assets, setAssets] = useState([]);
  const [files, setFiles] = useState([]);

  const addTextField = e => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      if (e.target.value.trim() !== '') {
        const list = [...inputFieldList];
        const id = inputFieldList.length;
        list.push({
          id: id + 1,
        });
        setInputFieldList(list);
        setTimeout(() => {
          document?.getElementById(`${id + 1}`).focus();
        }, 100);
      }
    }
  };

  const deleteTextField = id => {
    const list = [];
    if (inputFieldList.length === 1) {
      list.push({
        ...inputFieldList[0],
        id: 0,
        text: '',
      });
      setInputFieldList(list);
      return;
    }

    inputFieldList.map(inputField => {
      if (inputField.id !== id) {
        list.push(inputField);
      }

      return inputField;
    });
    setInputFieldList(list);
  };

  const formValidation = () => {
    if (text.yes && (!text.text || text.text === '')) {
      setShowError(true);
      return false;
    }

    if (image.yes && (!image.imageData || image.imageData === '')) {
      setShowError(true);
      return false;
    }

    return true;
  };

  const updatedRequestContext = draft => {
    const tempRequestData = {
      ...request.data,
      text: text.text,
      is_include_text: text.yes,
      is_share_asset: uploadAssets,
      type_of_images: image.imageData,
      steps: inputFieldList.filter(data => data.text && data.text.trim() !== ''),
      updated: true,
    };

    if (draft) {
      updateRequest({
        files,
        assets,
        data: tempRequestData,
      });
    } else {
      let clearedForm = [];
      if (request.clearedForm.includes('description')) {
        clearedForm = request.clearedForm;
      } else {
        clearedForm = [...request.clearedForm, 'description'];
      }

      updateRequest({
        clearedForm,
        form: 'filetypes',
        files,
        assets,
        data: tempRequestData,
      });
    }
  };

  const onSubmitForm = () => {
    if (!formValidation()) {
      return;
    }

    setSaveAs('publish');
    updatedRequestContext();
  };

  const onSaveDraft = () => {
    if (!formValidation()) {
      return;
    }

    setSaveAs('draft');
    updatedRequestContext(true);
  };

  const onChange = (id, value) => {
    const inputData = [...inputFieldList];
    inputFieldList.forEach((inputField, index) => {
      if (inputField.id === id) {
        inputData[index].text = value;
      }
    });
    setInputFieldList(inputData);
  };

  const setMediaForRequest = selectedAssets => {
    if (selectedAssets) {
      const tempAssets = [...assets];
      selectedAssets.forEach(selectedAsset => {
        if (
          tempAssets.filter(
            asset =>
              asset.media_id === selectedAsset.media_id &&
              asset.asset_id === selectedAsset.asset_id,
          ).length === 0
        ) {
          setShow(false);
          tempAssets.push({
            media_id: selectedAsset.media_id,
            asset_id: selectedAsset.asset_id,
            media: selectedAsset.media,
          });
        }
      });
      setAssets(tempAssets);
      toast.success('Asset added successfully.');
    }
  };

  const setFileForRequest = medias => {
    const list = [...files];
    Object.keys(medias).forEach(key => list.push(medias[key]));
    setFiles(list);
  };

  useEffect(() => {
    if (saveAs) {
      if (saveAs === 'draft') {
        saveDraft(0);
        setSaveAs(undefined);
      }

      if (saveAs === 'publish') {
        changeCurrentScreen('filetypes');
        setSaveAs(undefined);
      }
    }

    setInputFieldList(
      request.data.steps && request.data.steps.length !== 0
        ? request.data.steps
        : [
            {
              id: 1,
            },
          ],
    );
    setText({
      yes: request.data.text && request.data.text !== null && request.data.text !== '',
      text: request.data.text,
    });
    setImage({
      yes: request.data.type_of_images && request.data.type_of_images !== '',
      imageData: request.data.type_of_images,
    });
    setUploadAssets(request.assets?.length > 0 || request.files?.length > 0);
    setFiles(request.files);
    setAssets(request.assets);
  }, [request]);

  const isFileUploading = () => {
    let hasMediaId = false;
    for (let i = 0; i < files.length; i += 1) {
      const file = files[i];
      if (file.media_id) {
        hasMediaId = true;
      } else {
        hasMediaId = false;
      }

      if (!hasMediaId) {
        break;
      }
    }

    if (hasMediaId && request.form === 'description') {
      toast.success('Files uploaded successfully');
    }
  };

  const handleOnDragEnd = result => {
    const arr = Array.from(inputFieldList);
    const [reorderedItem] = arr.splice(result.source.index, 1);
    arr.splice(result.destination.index, 0, reorderedItem);

    setInputFieldList(arr.map((i, idx) => ({ ...i, id: idx })));
  };

  return (
    <>
      <Card body className="pl-md-0 px-lg-5 no-border pt-1 text-font">
        <Col className="px-0 px-md-3">
          <p className="text-size-xs mb-2">
            <FontAwesomeIcon icon={faCheck} className="text-darker mr-2" />
            <span className="font-weight-600">Request Type: </span>
            {request.data?.type && (
              <span>
                {request.data?.type[0].toUpperCase() +
                  request.data?.type.slice(1).replace('_', ' ')}
              </span>
            )}
          </p>
          <p className="text-size-xs">
            <FontAwesomeIcon icon={faCheck} className="text-darker mr-2" />
            <span className="font-weight-600">
              {request.data?.dimension?.split(' ')[0].replace('_', ' ')}:{' '}
            </span>
            <span>
              {request.data?.dimension?.split(' ').map((slice, index) => {
                if (index > 0) {
                  return `${slice} `;
                }

                return '';
              })}
            </span>
          </p>

          <p className="text-size-xs pt-4 pb-2">
            Add your instructions in list form to help your designer complete all parts of your
            request
          </p>

          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="steps">
              {provided => (
                <ul className="list-unstyled" {...provided.droppableProps} ref={provided.innerRef}>
                  {inputFieldList.map((item, index) => (
                    <Draggable key={item.id} draggableId={String(item.id)} index={index}>
                      {prov => (
                        <li ref={prov.innerRef} {...prov.draggableProps} {...prov.dragHandleProps}>
                          <Form.Group className="shadow">
                            <InputGroup>
                              <InputGroup.Append>
                                <InputGroup.Text>
                                  <FontAwesomeIcon icon={faGripVertical} className="text-muted" />
                                </InputGroup.Text>
                              </InputGroup.Append>
                              <Form.Control
                                id={item.id}
                                type="text"
                                size="lg"
                                placeholder={`Step ${index + 1}`}
                                className="text-size-xs no-border-right"
                                onKeyPress={e => addTextField(e)}
                                value={item.text ? item.text : ''}
                                onChange={e => {
                                  onChange(item.id, e.target.value);
                                }}
                              />
                              <InputGroup.Append>
                                <InputGroup.Text className="px-0 py-0 bg-white">
                                  <Button variant="white" onClick={() => deleteTextField(item.id)}>
                                    <img src={crossIcon} alt="delete" width="10" />
                                  </Button>
                                </InputGroup.Text>
                              </InputGroup.Append>
                            </InputGroup>
                          </Form.Group>
                        </li>
                      )}
                    </Draggable>
                  ))}
                </ul>
              )}
            </Droppable>
          </DragDropContext>

          <p className="text-size-xs py-1">Press Enter for another Step</p>

          <div className="d-flex flex-wrap align-items-center mb-3 pt-2">
            <p className="mr- mb-0 text-size-sm">
              <span className="font-weight-600">Does your design need to include text?</span>
            </p>
            <Switch
              className="ml-0 ml-lg-3 mt-2 mt-lg-0"
              value={text.yes}
              onChange={check => {
                setText({
                  ...text,
                  yes: check,
                });
              }}
            />
          </div>

          <p className="text-size-xs pb-2">
            Include text copy exactly as you&apos;d like it to appear in your design
          </p>

          <Form.Group className="pb-2">
            <Form.Control
              as="textarea"
              rows="4"
              placeholder="Enter exact text copy here.."
              className="shadow text-size-xs"
              value={text.text}
              onChange={e => {
                setText({
                  ...text,
                  text: e.target.value,
                });
              }}
              disabled={!text.yes}
            />
          </Form.Group>

          {showError && text.yes && (!text.text || text.text === '') && (
            <p className="text-size-xs error-text">Please provide a text.</p>
          )}

          <div className="d-flex flex-wrap align-items-center mb-3 pt-3">
            <p className="mr-3 mb-0 text-size-sm">
              <span className="font-weight-600">Do you have any assets to share with us</span>
            </p>
            <Switch
              className="ml-0 ml-lg-3 mt-2 mt-lg-0"
              value={uploadAssets}
              onChange={check => {
                setUploadAssets(check);
              }}
            />
          </div>

          <p className="text-size-xs pt-2 pb-3">
            If you want to share any photo, content, logo, font etc.
          </p>

          <div className="d-flex flex-wrap  my-2 ">
            {assets.map(asset => (
              <AssetImage asset={asset} />
            ))}
          </div>
          <div className="my-2">
            {files.map((file, index) => (
              <MediaImage
                media={file}
                onLoadComplete={media => {
                  const value = {
                    media_id: media.id,
                    media,
                  };
                  files[index] = value;
                  isFileUploading();
                }}
              />
            ))}
          </div>

          <FileButton
            className={`text-size-xs mr-3 mb-0 font-weight-600 text-darker ${
              !uploadAssets ? 'disable-custom-button' : ''
            }`}
            onFileUploaded={setFileForRequest}
            disable={!uploadAssets}
          >
            Add Files
          </FileButton>
          <AssetsButton
            className="text-size-xs font-weight-600 text-darker "
            onClick={() => {
              setShow(true);
            }}
            disable={!uploadAssets}
          >
            Add Assets
          </AssetsButton>

          <div className="d-flex flex-wrap align-items-center my-3 pb-2">
            <Col xs={12} sm={12} md={12} lg={3} xl={3} className="mr-3 mb-0 text-size-sm px-0">
              <span className="font-weight-600 ">Do you need images?</span>
            </Col>
            <Col xs={12} sm={12} md={12} lg={4} xl={4} className="px-0">
              <Switch
                className="ml-0 ml-lg-3 mt-2 mt-lg-0"
                value={image.yes}
                onChange={check => {
                  setImage({
                    ...image,
                    yes: check,
                  });
                }}
              />
            </Col>
          </div>

          <Form.Group>
            <Form.Control
              as="textarea"
              rows="4"
              placeholder="Explain the type of image you need.."
              className="shadow text-size-xs"
              value={image.imageData}
              onChange={e => {
                setImage({
                  ...image,
                  imageData: e.target.value,
                });
              }}
              disabled={!image.yes}
            />
          </Form.Group>

          {showError && image.yes && (!image.imageData || image.imageData === '') && (
            <p className="text-size-xs error-text">Please provide the type of image you need.</p>
          )}

          <div className="d-flex flex-column align-items-center align-items-md-start">
            <Button
              type="button"
              className="text-size-xs mt-5 px-5 font-weight-500"
              onClick={onSubmitForm}
            >
              Continue
            </Button>

            <Button
              type="button"
              variant="link"
              className="text-size-xs mt-2 px-0 d-block text-underline text-darker"
              onClick={onSaveDraft}
            >
              Save Draft
            </Button>
          </div>
        </Col>
      </Card>
      <AssetsViewer show={show} setShow={setShow} onSelectMedia={setMediaForRequest} />
    </>
  );
};

export default RequestDescription;
