import http from '../utils/http';

export const getRoles = () => http.get('/roles');

export const postRoles = data => http.post('/roles', data);

export const putRolesByUserId = (userId, data) => http.put(`/roles/${userId}`, data);

export const getRolesByUserId = userId => http.get(`/roles/${userId}`);

export const getRolePermission = name => http.get(`/roles/${name}/permissions`);
