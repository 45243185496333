import '../styles/modules/Sidebar.scss';
import React from 'react';
import { Collapse, Image } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import fourBoxIcon from '../assets/icons/dash-icon.svg';
import envelopeIcon from '../assets/icons/request-icon.svg';
import chatIcon from '../assets/icons/side-nav-chat-icon.svg';
import helpIcon from '../assets/icons/side-nav-support-icon.svg';
import checkIcon from '../assets/icons/check-icon.svg';
import userIcon from '../assets/icons/side-nav-my-account-icon.svg';
import mainIcon from '../assets/icons/final-main-logo.svg';
import logoutIcon from '../assets/icons/logout.svg';
import { useLogout } from '../hooks/auth.hooks';
import RoleBased from './RoleBased';
import { ROLES, SECTION_KEY } from '../utils/enums';
import UserContext from '../contexts/UserContext';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const [open, setOpen] = React.useState(false);
  const toggle = () => setOpen(!open);

  const { getRole } = React.useContext(UserContext);

  const { mutateAsync: logout } = useLogout();

  const onSignOut = async () => {
    try {
      const res = await logout();
      toast.success(res.message);
      localStorage.removeItem('kimp-user-default-board');
      localStorage.removeItem('kimp-authtoken');

      const query = new URLSearchParams();
      query.append('logout', 'true');
      query.append('redirect_url', process.env.REACT_APP_SITE_URL);

      window.location.href = `${process.env.REACT_APP_WP_URL}?${query.toString()}`;
    } catch (err) {
      toast.error(err?.message);
    }
  };

  return (
    <aside className={`${classnames('bg-primary p-3', isOpen && 'is-open')}`}>
      <div className="logo mb-5 pl-2">
        <Link to="/dashboard">
          <img src={mainIcon} alt="logo" className="main-logo-icon-size" />
        </Link>
      </div>

      <RoleBased
        sectionKey={[SECTION_KEY.task_request_add]}
        acceptedRoles={[
          ROLES.ADMIN,
          ROLES.EDITORS,
          ROLES.MANAGERS,
          ROLES.SUBSCRIBERS,
          ROLES.SUPER_ADMIN,
          ROLES.VIEWERS,
        ]}
      >
        <Link
          to="/dashboard/new-request-name"
          className="text-darker text-size-sm text-font font-weight-500 btn btn-warning d-block mb-5 font-weight-600 d-flex flex-row justify-content-center align-items-center side-nav-request-btn"
        >
          <Image src={checkIcon} alt="checkIcon" />
          New Request
        </Link>
      </RoleBased>
      <NavLink
        activeStyle={{ backgroundColor: '#249D91', fontWeight: 600 }}
        exact
        to="/dashboard"
        className="d-flex flex-row align-items-center "
        onClick={toggleSidebar}
      >
        <Image src={fourBoxIcon} alt="" />
        <div className="text-size-sm text-font">Dashboard</div>
      </NavLink>
      <NavLink
        activeStyle={{ backgroundColor: '#249D91', fontWeight: 600 }}
        to="/dashboard/request-history"
        className="d-flex flex-row align-items-center"
        onClick={toggleSidebar}
      >
        <Image src={envelopeIcon} alt="" />
        <div className="text-size-sm text-font">Request History</div>
      </NavLink>
      <RoleBased
        sectionKey={[
          SECTION_KEY.board_assets_add,
          SECTION_KEY.board_assets_edit,
          SECTION_KEY.board_assets_delete,
          SECTION_KEY.board_assets_full_view,
        ]}
        acceptedRoles={[ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.SUBSCRIBERS, ROLES.VIEWERS]}
      >
        <NavLink
          activeStyle={{ backgroundColor: '#249D91', fontWeight: 600 }}
          to="/dashboard/assets"
          className="d-flex flex-row align-items-center"
          onClick={toggleSidebar}
        >
          <Image src={chatIcon} alt="" />
          <div className="text-size-sm text-font">My Assets</div>
        </NavLink>
      </RoleBased>
      <RoleBased
        sectionKey={[
          SECTION_KEY.settings_edit,
          SECTION_KEY.settings_delete,
          SECTION_KEY.settings_full_view,
        ]}
        acceptedRoles={[ROLES.SUPER_ADMIN, ROLES.ADMIN]}
      >
        <NavLink
          to="/dashboard/support"
          activeStyle={{ backgroundColor: '#249D91', fontWeight: 600 }}
          className="d-flex flex-row align-items-center"
          onClick={toggleSidebar}
        >
          <Image src={helpIcon} alt="" />
          <div className="text-size-sm text-font">Support</div>
        </NavLink>
      </RoleBased>
      <button
        type="button"
        onClick={toggle}
        className={`d-flex flex-row align-items-center ${classnames(
          'btn-collapse',
          open && 'is-open',
        )}`}
      >
        <Image src={userIcon} alt="" />
        <div className={`text-size-sm text-font ${open === true ? `font-weight-600` : null}`}>
          My Account
        </div>
        <FontAwesomeIcon icon={faChevronDown} />
      </button>
      <Collapse in={open}>
        <ul>
          <li>
            <NavLink
              to="/dashboard/team-access"
              activeStyle={{ fontWeight: 700 }}
              className="text-size-xs text-font"
              onClick={toggleSidebar}
            >
              Team Access
            </NavLink>
          </li>
          <RoleBased
            acceptedRoles={[
              ROLES.SUPER_ADMIN,
              ROLES.ADMIN,
              ROLES.MANAGERS,
              ROLES.CONTRIBUTORS,
              ROLES.SUBSCRIBERS,
            ]}
          >
            <li>
              <NavLink
                to="/dashboard/subscriptions"
                activeStyle={{ fontWeight: 700 }}
                className="text-size-xs text-font"
                onClick={toggleSidebar}
              >
                My Subscription
              </NavLink>
            </li>
          </RoleBased>
          {/* <li>
            <NavLink
              to="/pay-his"
              activeStyle={{ fontWeight: 700 }}
              className="text-size-xs text-font"
              onClick={toggleSidebar}
            >
              Payment History
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/invoice"
              activeStyle={{ fontWeight: 700 }}
              className="text-size-xs text-font"
              onClick={toggleSidebar}
            >
              Invoice Address
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/pay"
              activeStyle={{ fontWeight: 700 }}
              className="text-size-xs text-font"
              onClick={toggleSidebar}
            >
              Payment Method
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/sup"
              activeStyle={{ fontWeight: 700 }}
              className="text-size-xs text-font"
              onClick={toggleSidebar}
            >
              Support
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/set"
              activeStyle={{ fontWeight: 700 }}
              className="text-size-xs text-font"
              onClick={toggleSidebar}
            >
              Account Settings
            </NavLink>
          </li> */}
          <li>
            <NavLink
              to="/dashboard/edit-profile"
              activeStyle={{ fontWeight: 700 }}
              className="text-size-xs text-font"
              onClick={toggleSidebar}
            >
              My Profile
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/dashboard/team-holidays"
              activeStyle={{ fontWeight: 700 }}
              className="text-size-xs text-font"
              onClick={toggleSidebar}
            >
              Team Holidays
            </NavLink>
          </li>
        </ul>
      </Collapse>
      {getRole()?.role === ROLES.SUPER_ADMIN ? (
        <NavLink
          to="/dashboard/roles"
          activeStyle={{ backgroundColor: '#249D91', fontWeight: 600 }}
          className="d-flex flex-row align-items-center"
          onClick={toggleSidebar}
        >
          <Image src={helpIcon} alt="" />
          <div className="text-size-sm text-font">Roles &amp; Permissions</div>
        </NavLink>
      ) : null}
      <button
        type="button"
        onClick={onSignOut}
        className={`d-flex flex-row align-items-center ${classnames(
          'btn-collapse',
          open && 'is-open',
        )}`}
      >
        <Image src={logoutIcon} alt="" />
        <div className="text-size-sm text-font">Logout</div>
      </button>
    </aside>
  );
};

export default Sidebar;
