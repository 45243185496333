import { useMutation, useQuery } from 'react-query';
import { getInvite, invite } from '../requests/invites.requests';

export const useSendInvites = () =>
  useMutation(async data => {
    const res = await invite(data);
    return res;
  });

export const useFetchSentInvites = token =>
  useQuery(
    ['invites', token],
    async () => {
      const res = await getInvite(token);
      return res.data;
    },
    {
      enabled: token !== undefined,
      initialData: [],
    },
  );
